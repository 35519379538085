import React, { useState, useContext, useEffect } from 'react';
import { Header, Tab } from 'semantic-ui-react'
import './settings.css';
import { loginContext } from '../../../../Store';
import { Navbar } from '../Navbar/Navbar';
import { GeneralSettings } from './General/GeneralSettings';


export const Settings = (props) => {

    const [isLoginControl, setLoginControl] = useContext(loginContext);

    const panes = [
        {
            menuItem: 'General',
            render: () => <Tab.Pane attached={false}>
                            <GeneralSettings />
                          </Tab.Pane>,
        }
    ]

    return (
        <div>
            { isLoginControl ?
                <div>
                    <div className="super-admin-dashboard-container">
                        <div className="super-admin-dashboard-nav">
                            <Navbar ActiveMenuItem="settings"/>
                        </div>
                        <div className="super-admin-dashboard-content">

                            <Header size='huge'>Settings</Header>

                            <div className="super-admin-content-box">
                                <Tab className="super-admin-tab-menu" menu={{ secondary: true }} panes={panes} />
                            </div>
                        </div>
                    </div>
                </div>
                : ""}
        </div>
    );
}