import axios from 'axios';
import queryString from 'query-string';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Button, Checkbox, Form, Header, Icon, Input, Label, Loader, Modal, Pagination, Popup, Tab, TextArea, Transition, Dropdown, Image } from 'semantic-ui-react';
import Cookies from 'universal-cookie';
import NotFoundSVG from '../../../../images/not-found.svg';
import { loginContext } from '../../../../Store';
import { DashboardNavBar } from '../../Dashboard/DashboardNavBar/DashboardNavBar';
import { SimulationsCard } from '../List/SimulationsCard/SimulationsCard';
import { CharactersDialogs } from './CharactersDialogs/CharactersDialogs';
import './simulationdetail.css';
import { UserListComponent } from './UserListComponent/UserListComponent';
import { Main } from "../../../Canvas/Main";
import { QuestionPanelFinal } from './QuestionPanelFinal/QuestionPanelFinal';
import { ContinuePopup } from './ContinuePopup/ContinuePopup';
import { BackgroundMusic } from '../Detail/BackgroundMusic/BackgroundMusic';
import { ForegroundPhoto } from '../Detail/ForegroundPhoto/ForegroundPhoto';
import { IntroOutroVideo } from '../Detail/IntroOutroVideo/IntroOutroVideo';
import { dateFormat } from '../../../../helper/helper'
import pexels from '../../../../helper/pexels';
import { VolumeSlider } from '../Detail/VolumeSlider/VolumeSlider';
import Broadcast from '../../../../Broadcast';

const cookies = new Cookies();

export const SimulationDetail = (props) => {

    const [pexelsPhotos, setPexelsPhotos] = useState([]);

    var pixiData = "";

    const [startUpload, setStartUpload] = useState(false);
    const fileInputRef = useRef();
    const [simulationDataPixi, setSimulationDataPixi] = useState();
    const [simulationPrevCanvas, setSimulationPrevCanvas] = useState("");

    const [continuePopupOpen, setContinuePopupOpen] = useState(false);

    const [broadcastQuestionId, setBroadcastQuestionId] = useState("");
    const [questionPanelComponent, setQuestionPanelCompanent] = useState("");
    const [continuePopupComponent, setContinueComponent] = useState("");


    const searchPhotoOnPexels = async (text) => {
        const response = await pexels.get(`/v1/search`, {
            params: {
                query: text,
                per_page: 80,
                page: 1
            }
        });

        setPexelsPhotos(response.data.photos);

        console.log(response.data.photos);
    }


    const fileInputClicked = () => {
        fileInputRef.current.click();
    }

    const filesSelected = () => {
        if (fileInputRef.current.files.length) {
            uploadFiles(fileInputRef.current.files[0]);
        }
    }

    const uploadFiles = (file) => {

        console.log(file);

        if (file.size > 0) {
            setStartUpload(true);
            const formData = new FormData();

            formData.append('Data', file);
            formData.append('AuthToken', cookies.get('Token'));
            formData.append('CompanyId', cookies.get('CompanyId'));

            axios({
                method: 'POST',
                url: "/Background/Upload",
                data: formData,
                processData: false,
                contentType: false
            }).then((response) => {
                if (response.data.result.resultCode == 0) {

                    try {
                        getAllBackgrounds();
                    } catch (e) {
                        setHeader("Error");
                        setMessage("Error");
                        setMessageModalOpen(true);
                    }

                    setStartUpload(false);
                }
                else if (response.data.result.resultCode == 9) {
                    setStartUpload(false);
                    setHeader("Error");
                    setMessage("Background photo cannot exceed 10MB.");
                    setMessageModalOpen(true);

                }

                else if (response.data.result.resultCode == 10) {
                    setStartUpload(false);
                    setHeader("Error");
                    setMessage("Background photo can only be png , jpg and jpeg");
                    setMessageModalOpen(true);
                }


            });



        }


    }

    const [tableHeader, setTableHeader] = useState(['User', 'Option']);

    //context ----
    const [isLoginControl, setLoginControl] = useContext(loginContext);
    //context ----

    //date hook
    const [today] = useState(new Date().toISOString().split('T')[0]);
    //date hook

    //simulation data hook
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [estimatedTime, setEstimatedTime] = useState(0);
    const [startingDate, setStartingDate] = useState(today);
    const [dueDate, setDueDate] = useState("");
    const [youtubeVideo, setYoutubeVideo] = useState("");
    const [companyId, setCompanyId] = useState(cookies.get('CompanyId').toString());
    const [isActive, setIsActive] = useState();
    const [id, setId] = useState("");
    const [image, setImage] = useState("");
    const [url, setUrl] = useState("");
    const [shareSimulationImageLink, setShareSimulationImageLink] = useState("");
    const [updateSimulationInfo, setUpdateSimulationInfo] = useState(false);
    //simulation data hook

    //message hook
    const [header, setHeader] = useState("");
    const [message, setMessage] = useState("");
    const [messageModalOpen, setMessageModalOpen] = useState(false);
    //message hook

    //user table hook
    const [currentPage, setCurrentPage] = useState(1);
    const [userData, setUserData] = useState([]);
    const [loaderActive, setLoaderActive] = useState(false);
    const [showNoUsersFound, setShowNoUsersFound] = useState(false);
    //user table hook

    //pagination hook
    const [paginationHtml, setPaginationHtml] = useState("");
    //pagination hook

    //filter text
    const [filterInputText, setFilterInputText] = useState("");
    var filterText = filterInputText;
    //filter text

    const [openEnrolledModal, setOpenEnrolledModal] = useState(false);
    const [alreadyEnrolledModal, setAlreadyEnrolledModal] = useState(false);


    //simulation background
    const [allbackgroundImage, setAllBackgroundImage] = useState([]);
    const [simulationBackgroundImageLink, setSimulationBackgroundImageLink] = useState("");
    const [selectedBackgroundImageElement, setSelectedBackgroundImageElemet] = useState("");
    const [selectedBackgroundId, setSelectedBackgroundId] = useState("");
    //simulation background

    //simulation character
    const [allCharactersImage, setAllCharactersImage] = useState([]);
    const [simulationCharacterImageLink1, setSimulationCharacterImage1] = useState("");
    const [simulationCharacterImageLink2, setSimulationCharacterImage2] = useState("");
    const [selectedCharacter1dId, setSelectedCharacter1Id] = useState([]);
    const [selectedCharacter2dId, setSelectedCharacter2Id] = useState([]);
    //simulation character

    //dialog
    const [currentDialogId, setCurrentDialogId] = useState("");
    //dialog


    //for controll active tabs
    const [firstSimulation, setFirstSimulation] = useState(false);

    const [activeIndexTab, setActiveIndexTab] = useState(0);
    const [activeDetailIndexTab, setActiveDetailIndexTab] = useState(0);


    const [dialogCharacters, setDialogCharacters] = useState(true);
    const [dialogDialog, setDialogDialog] = useState(true);


    //simulation settings

    let correctAnswerSelectedOption = {
        options: [
            { key: 'whenpassed', value: 'whenpassed', text: 'when passed' },
            { key: 'always', value: 'always', text: 'always' }
        ]
    }

    const [simulationSettingsData, setSimulationSettingsData] = useState("");

    const [correctAnswerPointQuestion, setCorrectAnswerPointQuestion] = useState();
    const [responseTimeQuestion, setResponseTimeQuestion] = useState();
    const [successScore, setSuccessScore] = useState();
    const [showQuestionAgainTimes, setShowQuestionAgainTimes] = useState();
    const [showCorrectAnswerSelected, setShowCorrectAnswerSelected] = useState();

    const [correctAnswerPointChecked, setCorrectAnswerPointChecked] = useState(false);
    const [responseTimeChecked, setResponseTimeChecked] = useState(false);
    const [successScoreChecked, setSuccessScoreChecked] = useState(false);
    const [showQuestionAgainChecked, setShowQuestionChecked] = useState(false);
    const [showCorrectAnswerChecked, setShowCorrectAnswerChecked] = useState(false);
    const [showScoreChecked, setShowScoreChecked] = useState(false);

    //notification variables
    const [newSimulationAssignedChecked, setNewSimulationAssignedChecked] = useState(false);
    const [userCompletedSimulationChecked, setUserCompletedSimulationChecked] = useState(false);
    //notification variables

    const [previewTabActive, setPreviewTabActive] = useState(true);

    const [autamaticSave, setAutomaticSave] = useState(false);

    const [deleteBackgroundImageOpen, setDeleteBackgroundImageOpen] = useState(false);
    const [modalSelectedBackgroundImage, setModalSelectedBackgroundImage] = useState("");

    const [simulationSettingsJson, setSimulationSettingsJson] = useState({
        questionTime: {
            point: null,
            isActive: false
        },
        truePoint: {
            point: null,
            isActive: false
        },
        successScore: {
            point: null,
            isActive: false
        },
        showQuestionAgain: {
            isActive: false,
            times: null
        },
        showCorrectAnswer: {
            isActive: false,
            selectedOption: null
        },
        showScore: false,
        notification: {
            newSimulationAssigned: false,
            userCompletedSimulation: false
        }
    });

    //when every change correctAnswerPointQuestion and responseTimeQuestion values set setSimulationSettingsJson new value
    useEffect(() => {

        const newValueSimulationSettings = {
            questionTime: {
                point: responseTimeQuestion,
                isActive: responseTimeChecked
            },
            truePoint: {
                point: correctAnswerPointQuestion,
                isActive: correctAnswerPointChecked
            },
            successScore: {
                point: successScore,
                isActive: successScoreChecked
            },
            showQuestionAgain: {
                times: showQuestionAgainTimes,
                isActive: showQuestionAgainChecked
            },
            showCorrectAnswer: {
                selectedOption: showCorrectAnswerSelected,
                isActive: showCorrectAnswerChecked
            },
            showScore: showScoreChecked,
            notification: {
                newSimulationAssigned: newSimulationAssignedChecked,
                userCompletedSimulation: userCompletedSimulationChecked
            }
        };

        console.log(newValueSimulationSettings);




        setSimulationSettingsJson(newValueSimulationSettings);

    }, [correctAnswerPointQuestion, responseTimeQuestion, correctAnswerPointChecked, responseTimeChecked, successScore, successScoreChecked, showQuestionAgainChecked, showQuestionAgainTimes, showCorrectAnswerChecked, showCorrectAnswerSelected, showScoreChecked, newSimulationAssignedChecked, userCompletedSimulationChecked]);



    useEffect(() => {
        updateSimulationSettings(simulationSettingsJson, true);

        //updateSimulationSettings();
    }, [autamaticSave]);


    //send API by axios
    useEffect(() => {
        console.log(simulationSettingsJson);

        //updateSimulationSettings();
    }, [simulationSettingsJson]);

    //simulation settings
    useEffect(() => {
        if (!isLoginControl) {
            window.location.href = "/";
        }
        setFirstSimulation(true);
        getSimulationData();
        getUsers();
        paginationGet();
        getAllBackgrounds();
        getAllCharacters();
        getDialogsBySimulationId();
        let params = queryString.parse(props.location.search);
        if (params.menu != undefined) {
            setActiveDetailIndexTab(parseInt(params.menu));
            setActiveIndexTab(parseInt(params.subMenu));
        }
        getSimulationSettings();
        //maybe change
        getSimulationDataForPixi();
        Broadcast.on('DIALOG_CONTINUE', () => {
            setContinueComponent(
                <ContinuePopup
                    ContinuePopupOpen={true}
                    CurrentIndex={pixiData.currentIndex}
                    SimulationId={props.match.params.id}
                />
            );
        });
        Broadcast.on('DIALOG_PROGRESS', (progressObject) => {
            var simulationProgressData = {
                UserId: cookies.get('UserId'),
                SimulationId: props.match.params.id,
                DialogCount: progressObject.dialogCount,
                CurrentSimulationIndex: progressObject.currentSimulationIndex
            }

            console.log(simulationProgressData)

            var Token = JSON.stringify({
                AuthToken: cookies.get('Token'),
                Data: simulationProgressData
            });

            axios.post('/Simulation/SimulationProgress', Token, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then((response) => {
                console.log(response);
                if (response.data.resultCode == 0) {

                } else {

                }
            });
        });


        Broadcast.on('ON_QUESTION_OPEN', (questionId) => {

            var simulationSettingsData = {
                SimulationId: props.match.params.id
            }

            var Token = JSON.stringify({
                AuthToken: cookies.get('Token'),
                Data: simulationSettingsData
            });

            axios.post('/SimulationSettings/GetBySimulationId', Token, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then((response) => {

                if (response.data.resultCode == 0) {

                    if (response.data.data != null) {
                        let simulationSettingsData = JSON.parse(response.data.data.settingJSON);
                        setQuestionPanelCompanent("");
                        setQuestionPanelCompanent(
                            <QuestionPanelFinal
                                QuestionPanelVisible={true}
                                SimulationId={props.match.params.id}
                                Duration={10}
                                QuestionId={questionId}
                                SimulationSettings={simulationSettingsData}
                            />
                        );
                    }
                }
            });
        });
    }, []);

    useEffect(() => {
        getUsers();
    }, [currentPage, filterText]);

    const updateSimulationSettings = (settingsValue, boolData) => {

        var simulationSettingsData = "";

        if (settingsValue != null) {
            simulationSettingsData = settingsValue
        } else {
            simulationSettingsData = simulationSettingsJson
        }


        var simulationSettingsData = {
            SettingJSON: JSON.stringify(simulationSettingsData),
            SimulationId: props.match.params.id
        }

        console.log(simulationSettingsData)

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: simulationSettingsData
        });

        axios.post('/SimulationSettings/Add', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);
            if (response.data.resultCode == 0) {
                if (boolData != true) {
                    setMessage("Simulation settings saved");
                    setHeader("Sucess");
                    setMessageModalOpen(true);
                }
            } else {

            }
        });
    }

    const getSimulationSettings = () => {

        var simulationSettingsData = {
            SimulationId: props.match.params.id
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: simulationSettingsData
        });

        axios.post('/SimulationSettings/GetBySimulationId', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            if (response.data.resultCode == 0) {

                if (response.data.data != null) {
                    let simulationSettingsData = JSON.parse(response.data.data.settingJSON);

                    setSimulationSettingsData(simulationSettingsData);

                    if (simulationSettingsData.truePoint.isActive)
                        setCorrectAnswerPointChecked(true);

                    if (simulationSettingsData.questionTime.isActive)
                        setResponseTimeChecked(true);

                    if (simulationSettingsData.successScore.isActive)
                        setSuccessScoreChecked(true);

                    if (simulationSettingsData.showQuestionAgain.isActive)
                        setShowQuestionChecked(true);

                    if (simulationSettingsData.showCorrectAnswer.isActive)
                        setShowCorrectAnswerChecked(true);

                    if (simulationSettingsData.showScore)
                        setShowScoreChecked(true);

                    if (simulationSettingsData.notification ? simulationSettingsData.notification.newSimulationAssigned : false)
                        setNewSimulationAssignedChecked(true);

                    if (simulationSettingsData.notification ? simulationSettingsData.notification.userCompletedSimulation : false)
                        setUserCompletedSimulationChecked(true);


                    setCorrectAnswerPointQuestion(simulationSettingsData.truePoint.point);
                    setResponseTimeQuestion(simulationSettingsData.questionTime.point);
                    setSuccessScore(simulationSettingsData.successScore.point);
                    setShowQuestionAgainTimes(simulationSettingsData.showQuestionAgain.times);
                    setShowCorrectAnswerSelected(simulationSettingsData.showCorrectAnswer ? simulationSettingsData.showCorrectAnswer.selectedOption : "")
                }
            } else {

            }
        });
    }

    const historyPush = (menu, sub) => {
        props.history.push(`${window.location.pathname}?menu=${menu}&subMenu=${sub}`);
    }

    const getSelectedBackgorund = () => {

    }

    const getSelectedCharacters = () => {

    }

    const getDialogsBySimulationId = () => {
        var dialogData = {
            SimulationId: props.match.params.id,
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: dialogData
        });

        //console.log(Token.Data);

        axios.post('/Dialog/GetDialogBySimulationId', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);
            if (response.data.resultCode == 0) {

                if (response.data.data != null) {

                    setSelectedBackgroundImageElemet(response.data.data.backgroundId);
                    setSelectedBackgroundId(response.data.data.backgroundId);
                    setSelectedCharacter1Id(response.data.data.character1Id);
                    setSelectedCharacter2Id(response.data.data.character2Id);


                    if (response.data.data.character1Id != "" &&
                        response.data.data.character2Id != ""
                    ) {
                        setDialogCharacters(false);
                    }


                    setCurrentDialogId(response.data.data.id);
                }


                //alert(response.data.data.backgroundId);

                //setSelectedCharactersId([response.data.data.character1Id, response.data.data.character2Id]);



                if (response.data.data != null) {
                    var allDialogFromDatabase = JSON.parse(response.data.data.dialogJSON);

                    if (allDialogFromDatabase != null) {
                        if (allDialogFromDatabase.length == 0) {

                            setFirstSimulation(true);
                            setPreviewTabActive(true);
                        } else {
                            setFirstSimulation(false);
                            setPreviewTabActive(false);
                        }
                    }

                }
            } else {

            }
        });
    }


    const getAllBackgrounds = () => {

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token')
        });

        axios.post('/Background/GetAllBackgrounds', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);
            if (response.data.resultCode == 0) {

                setAllBackgroundImage(response.data.data);



            } else {

            }
        });
    }

    const getAllCharacters = () => {
        var Token = JSON.stringify({
            AuthToken: cookies.get('Token')
        });

        axios.post('/Character/GetAllCharacters', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);
            if (response.data.resultCode == 0) {
                setAllCharactersImage(response.data.data);

            } else {

            }
        });
    }


    const changeIsActive = (event, data) => {
        setIsActive(data.checked);
    }

    const getSimulationData = () => {

        var simulation = {
            Id: props.match.params.id
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: simulation
        });

        axios.post('/Simulation/GetById', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            console.log("simulation Data", response)
            if (response.data.resultCode == 0) {
                var simulationData = response.data.data;

                setName(simulationData.name);
                setDescription(simulationData.description);
                setEstimatedTime(simulationData.estimatedTime);
                setStartingDate(simulationData.startingDate);
                setDueDate(simulationData.dueDate);
                setIsActive(simulationData.isActive);
                setId(simulationData.id);
                setCompanyId(simulationData.companyId);
                setShareSimulationImageLink(simulationData.image);
                setYoutubeVideo(simulationData.videoLink == null ? "" : simulationData.videoLink);


                /*setHeader("Success");
                setMessage("Simulation has been added successfully");
                setMessageModalOpen(true);
                setIsError(false);*/
            } else {
                /* setHeader("Error");
                 setMessage(response.data.message);
                 setMessageModalOpen(true);
                 setIsError(true); */
                setHeader("Error");
                setMessage(response.data.message);
                setMessageModalOpen(true);

                window.location.href = "/dashboard";
            }
        });
    }

    const uploadSimulationImage = () => {

        return new Promise(resolve => {
            var formData = new FormData();

            formData.append('AuthToken', cookies.get('Token'));
            formData.append('Data', url);

            formData.append('simulationid', props.match.params.id);

            axios.post('/Simulation/UploadSimulationImageToDropbox', formData, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then((response) => {

                console.log(response);
                if (response.data.result.resultCode == 0) {
                    setShareSimulationImageLink(response.data.result.data);
                    console.log(response.data.result.data);
                    resolve(response.data.result.data);
                }
            });
        });


    }

    const updateSimulationDetail = async (automatic) => {
        await saveSimulationSettingsAction();

        setUpdateSimulationInfo(true)

        if (image !== "") {

            const uploadImageResult = await uploadSimulationImage();

            var simulation = {
                Id: id,
                Name: name,
                Description: description,
                IsActive: isActive,
                EstimatedTime: estimatedTime,
                IsDeleted: false,
                StartingDate: startingDate,
                DueDate: dueDate,
                companyId: companyId,
                Image: uploadImageResult,
                VideoLink: youtubeVideo
            }

            var Token = JSON.stringify({
                AuthToken: cookies.get('Token'),
                Data: simulation
            });

            axios.post('/Simulation/Update', Token, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then((response) => {
                console.log(response)
                if (response.data.resultCode == 0) {
                    setUpdateSimulationInfo(false);
                    setHeader("Success");
                    setMessage(response.data.message);
                    setMessageModalOpen(true);
                } else {
                    setUpdateSimulationInfo(false);
                    setHeader("Error");
                    setMessage(response.data.message);
                    setMessageModalOpen(true);
                }
            });

        } else {
            var simulation = {
                Id: id,
                Name: name,
                Description: description,
                IsActive: isActive,
                EstimatedTime: estimatedTime,
                IsDeleted: false,
                StartingDate: startingDate,
                DueDate: dueDate,
                companyId: companyId,
                VideoLink: youtubeVideo
            }

            var Token = JSON.stringify({
                AuthToken: cookies.get('Token'),
                Data: simulation
            });

            axios.post('/Simulation/Update', Token, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then((response) => {
                console.log(response)
                if (response.data.resultCode == 0) {
                    setHeader("Success");
                    setMessage(response.data.message);
                    setMessageModalOpen(true);
                    setUpdateSimulationInfo(false);
                } else {
                    setHeader("Error");
                    setMessage(response.data.message);
                    setMessageModalOpen(true);
                    setUpdateSimulationInfo(false);
                }
            });
        }
    }

    const getUsers = () => {
        setLoaderActive(true);
        var users = {
            currentPage: currentPage,
            CompanyId: cookies.get('CompanyId').toString(),
            SimulationId: props.match.params.id,
            FilterText: filterText
        };

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: users
        });

        axios.post('/User/GetEnrolledUsers', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            let users = [...new Set(response.data.data)];

            console.log(users);
            setUserData(users);
            setLoaderActive(false);

            if (users.length == 0)
                setShowNoUsersFound(true);
            else
                setShowNoUsersFound(false);

        });
    }

    const paginationGet = () => {

        console.log(props.match.params.id);
        var userPagination = {
            CompanyId: cookies.get('CompanyId').toString()
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: userPagination
        });

        axios.post('/User/GetCount', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);


            if (response.data.count) {





                setPaginationHtml(<Pagination
                    defaultActivePage={1}
                    firstItem={null}
                    lastItem={null}
                    pointing
                    secondary
                    totalPages={parseInt(Math.ceil(response.data.count / 25))}
                    onPageChange={(event, data) => setCurrentPage(parseInt(data.activePage))}
                />);
            }
        });
    }

    const filterUserOnChange = () => {
        console.log(filterText);

        if (filterText.trim().length < 1) {
            getUsers();
        }
        else {
            if (filterText.trim().length > 1) {
                let filterItems = {
                    CompanyId: cookies.get('CompanyId').toString(),
                    FilterText: filterText.toString().trim(),
                    SimulationId: props.match.params.id
                }

                var Token = JSON.stringify({
                    AuthToken: cookies.get('Token'),
                    Data: filterItems

                });

                axios.post('/User/SearchEnrolledUsers', Token, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).then((response) => {


                    console.log(response.data.data.result);

                    if (response.data.data.result) {
                        setShowNoUsersFound(false);
                        let usersList = response.data.data.result;
                        setUserData(usersList);

                        if (usersList.length == 0)
                            setShowNoUsersFound(true);


                    } else {

                    }
                });
            } else {
                getUsers();

            }
        }


    }

    const enrollAllUser = () => {

        let userDataEnroll = {
            simulationId: props.match.params.id,
            companyId: cookies.get('CompanyId').toString()
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: userDataEnroll
        });


        axios.post('/User/EnrollAllUser', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);

            if (response.data.resultCode == 0) {

                if (response.data.data.length == 0) {
                    setAlreadyEnrolledModal(true);
                }
                else {
                    setUserData([]);
                    getUsers();
                }

            }

        });


    }

    const handleChangeImage = (e) => {

        const objectUrl = URL.createObjectURL(e.target.files[0]);
        setUrl(e.target.files[0]);
        setImage(objectUrl);
    }

    const selectSimulationBackground = (id) => {
        setSelectedBackgroundId("null");

        var dialog = {
            Id: currentDialogId,
            BackgroundId: id,
            SimulationId: props.match.params.id,
            UserId: cookies.get('UserId'),
            CompanyId: cookies.get('CompanyId'),
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: dialog
        });

        axios.post('/Dialog/Update', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            console.log(response)
            if (response.data.resultCode == 0) {
                //var elements = document.getElementsByClassName("simulation-background-images");

                //for (var i = 0, len = elements.length; i < len; i++) {
                //    elements[i].classList.remove("simulation-background-images-active");
                //}

                //target.classList.add("simulation-background-images-active");

                //setSimulationBackgroundImageLink(target.src);
                //setSelectedBackgroundImageElemet(target.src);
            } else {

            }
        });



    }

    function getData(data, url) {
        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: data
        });
        axios.post('/Simulation/GetSimulationDataForPixi', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            return response;
        });
    }

    const getSimulationDataForPixi = () => {
        setSimulationPrevCanvas("");
        var simulation = {
            Id: props.match.params.id,
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: simulation
        });

        axios.post('/Simulation/GetSimulationDataForPixi', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            console.log(response)
            if (response.data.resultCode == 0) {
                setSimulationDataPixi(response.data.data);
                pixiData = response.data.data;

                console.log(response.data.data);
                setSimulationPrevCanvas(<Main SimulationData={response.data.data} />)

            } else {
                historyPush(2, 0);
                setActiveIndexTab(0);
            }
        });
    }

    const clearCanvasScreen = () => {

        setTimeout(() => {
            if (document.getElementsByTagName("canvas")[0]) {
                document.getElementsByTagName("canvas")[0].remove();
            }
        }, 500);


        setSimulationPrevCanvas("");
        setQuestionPanelCompanent("");
        setContinueComponent("");

    }

    const simulationBackgroundTabOnChange = (e, { activeIndexTab }) => {


        if (e.target.textContent != "Preview") {
            clearCanvasScreen();
        }

        Broadcast.emit('PAGE_CHANGED', true);

        setActiveIndexTab(activeIndexTab);

        switch (e.target.textContent) {
            case 'Background':
                historyPush(2, 0);
                break;

            case 'Characters':
                historyPush(2, 1);
                break;

            case 'Background music':
                historyPush(2, 2);
                break;

            case 'Foreground photo':
                historyPush(2, 3);
                break;

            case 'Video':
                historyPush(2, 4);
                break;

            case 'Dialog':
                historyPush(2, 5);
                break;

            case 'Preview':
                if (document.getElementsByTagName("canvas")[0]) {
                    document.getElementsByTagName("canvas")[0].remove();
                }
                getSimulationDataForPixi();
                return historyPush(2, 6);
        }

    }

    const simulationDetailTabOnChange = (e, { activeIndexTab }) => {

        Broadcast.emit('PAGE_CHANGED', true);

        setActiveDetailIndexTab(activeIndexTab);

        if (e.target.textContent === "Dialogs") {
            if (selectedBackgroundId.length > 1) {
                setSelectedBackgroundImageElemet(selectedBackgroundId);
            }
        }



        switch (e.target.textContent) {
            case 'Simulation Detail':
                return historyPush(0, 2);
            case 'Users':
                return historyPush(1, 2);
            case 'Dialogs':
                return historyPush(2, 2);
            case 'Notification':
                return historyPush(3, 2);

        }
    }

    const selectCharacter1 = (e) => {

        setSelectedCharacter1Id("empty");
        let characterDataId = e.target.dataset.id;
        let target = e.target;

        var dialog = {
            Id: currentDialogId,
            Character1Id: characterDataId,
            SimulationId: props.match.params.id,
            UserId: cookies.get('UserId'),
            CompanyId: cookies.get('CompanyId'),
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: dialog
        });

        axios.post('/Dialog/Update', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            console.log(response)
            if (response.data.resultCode == 0) {
                var elements = document.getElementsByClassName("character-1");

                for (var i = 0, len = elements.length; i < len; i++) {
                    elements[i].classList.remove("simulation-character-images-active");
                }

                target.classList.add("simulation-character-images-active");

                setSelectedCharacter1Id(response.data.data.character1Id);
            } else {

            }
        });




    }

    const selectCharacter2 = (e) => {

        setSelectedCharacter2Id("empty");
        let characterDataId = e.target.dataset.id;
        let target = e.target;

        var dialog = {
            Id: currentDialogId,
            Character2Id: characterDataId,
            SimulationId: props.match.params.id,
            UserId: cookies.get('UserId'),
            CompanyId: cookies.get('CompanyId'),
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: dialog
        });

        axios.post('/Dialog/Update', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            console.log(response)
            if (response.data.resultCode == 0) {
                var elements = document.getElementsByClassName("character-2");

                for (var i = 0, len = elements.length; i < len; i++) {
                    elements[i].classList.remove("simulation-character-images-active");
                }

                target.classList.add("simulation-character-images-active");

                setSelectedCharacter2Id(response.data.data.character2Id);
            } else {

            }
        });




    }

    const nextDialogScreen = (e) => {
        setActiveIndexTab(e.target.value);
        setDialogDialog(false);
    }


    const correnctAnswerPointChange = (event, data) => {
        setCorrectAnswerPointChecked(data.checked);
    }

    const responseTimeQuestionChange = (event, data) => {
        setResponseTimeChecked(data.checked);
        setShowQuestionChecked(!data.checked);
    }

    const successScoreChange = (event, data) => {
        setSuccessScoreChecked(data.checked);
    }

    const showQuestionAgainChange = (event, data) => {

        setShowQuestionChecked(data.checked);

        setResponseTimeChecked(!data.checked)
    }

    const showCorrectAnswerChange = (event, data) => {
        setShowCorrectAnswerChecked(data.checked);
    }

    const showScoreChange = (event, data) => {
        setShowScoreChecked(data.checked)
    }

    const newSimulationAssignedChange = (event, data) => {
        setNewSimulationAssignedChecked(data.checked);
    }

    const userCompletedSimulationChange = (event, data) => {
        setUserCompletedSimulationChecked(data.checked);
    }


    const saveSimulationSettingsAction = () => {
        console.log(simulationSettingsJson);
        updateSimulationSettings();
    }



    const openPreviewTab = () => {
        setPreviewTabActive(false);
        setActiveIndexTab(6);
        getSimulationDataForPixi();
    }

    const handleChangeBackgorundPhoto = (id) => {
        setSelectedBackgroundId(id);
    }

    const deleteBackgorundPhoto = (photoId) => {
        var background = {
            Id: photoId
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: background
        });

        axios.post('/Background/Delete', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            console.log(response)
            if (response.data.resultCode == 0) {
                let deletedBackgroundImageId = response.data.data.id;



                let filteredArray = allbackgroundImage.filter(background => background.id !== deletedBackgroundImageId);

                console.log(filteredArray);

                setAllBackgroundImage(filteredArray);

                setDeleteBackgroundImageOpen(false);

            } else {

            }
        });
    }

    const dialogsPanes = [
        {
            menuItem: { content: 'Background', icon: 'image' }, render: () => <Tab.Pane size="massive">

                <div className="select-backgorund-image-container">
                    <Tab
                        menu={{ secondary: true, pointing: true }}
                        panes={[
                            {
                                menuItem: 'Select or upload',
                                render: () => <Tab.Pane attached={false}>

                                    <div>
                                        <div className="dashoard-bg-box">
                                            <Header as="h2" disabled>Select a background for the simulation</Header>

                                            <Button
                                                onClick={fileInputClicked}
                                                color="orange"
                                                size="big"
                                                content='Add new background'
                                                icon='picture'
                                                labelPosition='left'
                                            />

                                            <input
                                                ref={fileInputRef}
                                                className="file-input"
                                                type="file"
                                                onChange={filesSelected}
                                            />
                                        </div>


                                        <div className="simulation-background-select-box">



                                            {allbackgroundImage.map((item, index) =>

                                                <div className="simulation-wrapper-image">
                                                    {/*<Image*/}
                                                    {/*    key={Date.now()}*/}
                                                    {/*    onClick={selectSimulationBackground}*/}
                                                    {/*    id={item.photo}*/}
                                                    {/*    data-id={item.id}*/}
                                                    {/*    className={selectedBackgroundId == item.id ? "simulation-background-images-active" : "simulation-background-images"}*/}
                                                    {/*    width="100%"*/}
                                                    {/*    height="240px"*/}
                                                    {/*    src={window.location.origin + `/Assets/Backgrounds/${item.photo}`}*/}
                                                    {/*/>*/}



                                                    <Form.Field style={{

                                                        display: "flex",
                                                        alignItems: "center",
                                                        position: "absolute"

                                                    }} key={item.id}>
                                                        <Checkbox
                                                            radio
                                                            label={
                                                                <div
                                                                    id={item.id}
                                                                    style={{

                                                                        margin: "10px",
                                                                        border: selectedBackgroundId == item.id ? "solid 5px red" : "solid 5px #ccc",
                                                                        borderRadius: "5px",
                                                                        cursor: "pointer"
                                                                    }}
                                                                    onClick={(e) => { setSelectedBackgroundId(item.id) }}
                                                                >
                                                                    <Image
                                                                        style={{ objectFit: "cover" }}
                                                                        onError={(e) => {
                                                                            e.target.onerror = null;
                                                                            e.target.src = window.location.origin + "/Assets/images/no-image.png"
                                                                        }}
                                                                        key={item.id}
                                                                        onClick={() => { selectSimulationBackground(item.id) }}
                                                                        id={item.photo}
                                                                        data-id={item.id}
                                                                        width="300px"
                                                                        height="225px"
                                                                        src={window.location.origin + `/Assets/Backgrounds/${item.photo}`}
                                                                    />

                                                                </div>
                                                            }
                                                            name='checkboxRadioGroup'
                                                            value={item.id}
                                                            checked={selectedBackgroundId === item.id}
                                                            onChange={() => { handleChangeBackgorundPhoto(item.id) }}
                                                        />
                                                        <Button
                                                            onClick={() => {
                                                                setModalSelectedBackgroundImage(window.location.origin + `/Assets/Backgrounds/${item.photo}`);
                                                                setDeleteBackgroundImageOpen(true);
                                                                setSelectedBackgroundId(item.id);
                                                            }}
                                                            style={{
                                                                position: "absolute",
                                                                top: "20px",
                                                                right: "16px"
                                                            }}
                                                            size="huge"
                                                            icon='trash'
                                                            color="red"
                                                        />
                                                    </Form.Field>






                                                </div>

                                            )}

                                        </div>

                                    </div>

                                </Tab.Pane>,
                            },
                            {
                                menuItem: 'Search on web',
                                render: () => <Tab.Pane attached={false}>
                                    <div>
                                        <Input
                                            size="big"
                                            icon='search'
                                            placeholder='Search...'
                                            onChange={(e) => { searchPhotoOnPexels(e.target.value) }}
                                        />

                                        <div style={{ display: "flex", flexWrap: "wrap", gap: "1em", paddingTop: "10px" }}>
                                            {pexelsPhotos.map(photo => (
                                                <Image
                                                    src={photo.src.small}

                                                />

                                            ))}

                                        </div>
                                    </div>


                                </Tab.Pane>,
                            }
                        ]}
                    />
                </div>






                <div className="simulation-background-select-button-box">
                    <Button
                        onClick={(e) => { if (selectedBackgroundImageElement != "") { setActiveIndexTab(1); setDialogCharacters(false); } else { setHeader("Warning"); setMessage("Please choose a background"); setMessageModalOpen(true); } }}
                        value={1}
                        size="big"
                        content='Next'
                        icon='right arrow'
                        labelPosition='right'
                    />
                </div>

            </Tab.Pane>
        },
        {
            menuItem: {
                content: 'Characters', icon: 'chess', disabled: firstSimulation ? dialogCharacters : false
            }, render: () => <Tab.Pane size="massive">
                <Header as="h2" disabled>Select characters for the simulation</Header>
                <Button>Upload a new character</Button>
                <div className="simulation-select-characters-box">

                    <div className="simulation-select-charecter-first">
                        <div><Header as="h2" disabled>Character 1</Header></div>
                        <div className="simulation-select-character-list">

                            {allCharactersImage.map((item, index) =>

                                item.isCustomer ?
                                    <div className="simulation-select-character-image-box">
                                        <img
                                            data-id={item.id}
                                            onClick={selectCharacter1}

                                            className={selectedCharacter1dId == item.id ? "simulation-character-images-active" : "simulation-character-images character-1"}
                                            width="100%"
                                            src={item.photo}
                                            id={item.photo} />
                                    </div>
                                    : ""
                            )}

                        </div>

                    </div>
                    <div className="simulation-character-splicer"></div>
                    <div className="simulation-select-charecter-second">
                        <div><Header as="h2" disabled>Character 2</Header></div>
                        <div className="simulation-select-character-list">
                            {allCharactersImage.map((item, index) =>

                                item.isCustomer ?
                                    <div className="simulation-select-character-image-box">
                                        <img
                                            data-id={item.id}
                                            onClick={selectCharacter2}

                                            className={selectedCharacter2dId == item.id ? "simulation-character-images-active" : "simulation-character-images character-2"}
                                            width="100%"
                                            src={item.photo}
                                            id={item.photo} />
                                    </div>
                                    : ""
                            )}

                        </div>
                    </div>
                </div>

                <div className="simulation-character-select-button-box">
                    <Button
                        onClick={(e) => { if (selectedCharacter1dId.length > 2 && selectedCharacter2dId.length > 2) { nextDialogScreen(e); setActiveIndexTab(2); } else { setHeader("Warning"); setMessage("Please select one character for each side"); setMessageModalOpen(true); } }}
                        value={2}
                        size="big"
                        content='Next'
                        icon='right arrow'
                        labelPosition='right'
                    />
                </div>

            </Tab.Pane>

        },
        {
            menuItem: { content: 'Background music', icon: 'music', disabled: firstSimulation ? dialogDialog : false }, render: () => <Tab.Pane size="massive">

                <BackgroundMusic
                    SimulationId={props.match.params.id}
                    CompanyId={cookies.get('CompanyId')}
                    UserId={cookies.get('UserId')}
                />
                <div className="simulation-character-select-button-box">
                    <Button
                        onClick={(e) => { if (selectedCharacter1dId.length > 2 && selectedCharacter2dId.length > 2) { nextDialogScreen(e); setActiveIndexTab(3); } else { setHeader("Warning"); setMessage("Please select one character for each side"); setMessageModalOpen(true); } }}
                        value={3}
                        size="big"
                        content='Next'
                        icon='right arrow'
                        labelPosition='right'
                    />
                </div>

            </Tab.Pane>
        },
        {
            menuItem: { content: 'Foreground photo', icon: 'images', disabled: firstSimulation ? dialogDialog : false }, render: () => <Tab.Pane size="massive">

                <ForegroundPhoto
                    SimulationId={props.match.params.id}
                    CompanyId={cookies.get('CompanyId')}
                    UserId={cookies.get('UserId')}
                />
                <div className="simulation-character-select-button-box">
                    <Button
                        onClick={(e) => { if (selectedCharacter1dId.length > 2 && selectedCharacter2dId.length > 2) { nextDialogScreen(e); setActiveIndexTab(4); } else { setHeader("Warning"); setMessage("Please select one character for each side"); setMessageModalOpen(true); } }}
                        value={4}
                        size="big"
                        content='Next'
                        icon='right arrow'
                        labelPosition='right'
                    />
                </div>

            </Tab.Pane>
        },
        {
            menuItem: { content: 'Video', icon: 'video', disabled: firstSimulation ? dialogDialog : false }, render: () => <Tab.Pane size="massive">

                <IntroOutroVideo
                    SimulationId={props.match.params.id}
                    CompanyId={cookies.get('CompanyId')}
                    UserId={cookies.get('UserId')}
                />
                <div className="simulation-character-select-button-box">
                    <Button
                        onClick={(e) => {
                            if (selectedCharacter1dId.length > 2 &&
                                selectedCharacter2dId.length > 2) {
                                nextDialogScreen(e);
                                setActiveIndexTab(5);
                            }
                            else {
                                setHeader("Warning");
                                setMessage("Please select one character for each side");
                                setMessageModalOpen(true);
                            }
                        }}
                        value={5}
                        size="big"
                        content='Next'
                        icon='right arrow'
                        labelPosition='right'
                    />
                </div>

            </Tab.Pane>
        },
        {
            menuItem: { content: 'Dialog', icon: 'comment', disabled: firstSimulation ? dialogDialog : false }, render: () => <Tab.Pane size="massive">

                <CharactersDialogs
                    CharacterOne={selectedCharacter1dId}
                    CharacterTwo={selectedCharacter2dId}
                    SimulationId={props.match.params.id}
                    SimulationSettigs={simulationSettingsJson}
                    SetSimulationSettings={setSimulationSettingsJson}
                    SetCorrectAnswerPointQuestion={setCorrectAnswerPointQuestion}
                    SetCorrectAnswerPointChecked={setCorrectAnswerPointChecked}
                    SetAutomaticSave={setAutomaticSave}
                    Save={autamaticSave}

                />
                <div className="simulation-detail-dialog-next-button-box">
                    <Button
                        onClick={openPreviewTab}
                        size="big"
                        content='Preview'
                        icon='film'
                        labelPosition='right'
                        color='blue'
                    />
                </div>

            </Tab.Pane>
        },
        {
            menuItem: { content: 'Preview', disabled: previewTabActive, icon: 'film' }, render: () => <Tab.Pane size="massive">


                <div style={{
                    textAlign: "center",
                    width: "100%",
                    position: "relative"
                }} id="preview-canvas">
                    {/*TODO Main Canvas Area*/}
                    {simulationPrevCanvas}
                    {questionPanelComponent}
                    {continuePopupComponent}
                </div>

                {/*media buttons*/}
                <VolumeSlider/>

            </Tab.Pane>
        },
    ]

    const panes = [
        {
            menuItem: { content: 'Simulation Detail', icon: 'chess knight' },
            render: () =>
                <Tab.Pane size="big" attached={false}>
                    <div className="simulation-detail-box">
                        <div className="simulation-detail-form-box">
                            <Form className="simulation-detail-form" size="huge">
                                <Form.Field>
                                    <label>Simulation Image</label>
                                    <input
                                        type="file"
                                        onChange={handleChangeImage}
                                        placeholder='Simulation Name (*)' />
                                </Form.Field>
                                <Form.Field>
                                    <label>Simulation Name (*)</label>
                                    <input
                                        value={name}
                                        onChange={e => { setName(e.target.value) }}
                                        placeholder='Simulation Name (*)' />
                                </Form.Field>
                                <Form.Field>
                                    <label>Description (*)</label>
                                    <TextArea
                                        value={description}
                                        onChange={e => { setDescription(e.target.value) }}
                                        placeholder='Description (*)' style={{ minHeight: 100 }} />
                                </Form.Field>
                                <Form.Field>
                                    <label>Estimated Time (minute)</label>
                                    <Input
                                        value={estimatedTime}
                                        onChange={e => { setEstimatedTime(e.target.value) }}
                                        labelPosition='right'
                                        type='number'
                                        min="0"
                                        placeholder='Estimated Time (minute)'>

                                        <input />
                                        <Label><Icon name="time" /></Label>
                                    </Input>
                                </Form.Field>

                                <Form.Field>
                                    <label>Starting Date</label>
                                    <input
                                        max={dateFormat(dueDate)}
                                        value={dateFormat(startingDate)}
                                        onChange={e => { setStartingDate(e.target.value) }}
                                        type="date"
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <label>Due Date</label>
                                    <input
                                        min={dateFormat(startingDate)}
                                        value={dateFormat(dueDate)}
                                        onChange={e => { setDueDate(e.target.value) }}
                                        type="date" />
                                </Form.Field>

                                <Form.Field>
                                    <label>Youtube Video Embed Link</label>
                                    <input
                                        value={youtubeVideo}
                                        onChange={e => { setYoutubeVideo(e.target.value) }}
                                        placeholder="Youtube Video Link"
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <iframe src={youtubeVideo}
                                        frameborder='0'
                                        allow='autoplay; encrypted-media'
                                        width="100%"
                                        height="300px"
                                        allowfullscreen
                                        title='video'
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <label>Question settings</label>
                                    <div className="simulation-setting-content-box">
                                        <div style={{ visibility: 'visible' }} className="simulation-setting-content">
                                            <div className="simulation-setting-element"><Checkbox checked={correctAnswerPointChecked} onChange={correnctAnswerPointChange} toggle /></div>
                                            <div className="simulation-setting-element"><Header disabled={correctAnswerPointChecked ? false : true} as="h3">Question score given correct answer</Header></div>
                                            <div className="simulation-setting-element"><Input
                                                disabled={correctAnswerPointChecked ? false : true}

                                                value={correctAnswerPointQuestion}
                                                onChange={(e) => { setCorrectAnswerPointQuestion(e.target.value) }}
                                                type="number"
                                                style={{ width: "110px" }}
                                                size="small"
                                                label={{ basic: true, content: 'points' }}
                                                labelPosition='right'
                                                placeholder='points'
                                            /></div>
                                        </div>
                                        <div className="simulation-setting-content">
                                            <div className="simulation-setting-element"><Checkbox checked={responseTimeChecked} toggle onChange={responseTimeQuestionChange} /></div>
                                            <div className="simulation-setting-element"><Header disabled={responseTimeChecked ? false : true} as="h3">Response time for each question</Header></div>
                                            <div className="simulation-setting-element"><Input
                                                disabled={responseTimeChecked ? false : true}
                                                type="number"
                                                value={responseTimeQuestion}
                                                onChange={(e) => { setResponseTimeQuestion(e.target.value) }}
                                                style={{ width: "110px" }}
                                                size="small"
                                                label={{ basic: true, content: 'seconds' }}
                                                labelPosition='right'
                                                placeholder='time'
                                            /></div>
                                        </div>

                                        <div className="simulation-setting-content">
                                            <div className="simulation-setting-element"><Checkbox checked={successScoreChecked} toggle onChange={successScoreChange} /></div>
                                            <div className="simulation-setting-element"><Header disabled={successScoreChecked ? false : true} as="h3">Success score</Header></div>
                                            <div className="simulation-setting-element"><Input
                                                disabled={successScoreChecked ? false : true}
                                                type="number"
                                                value={successScore}
                                                onChange={(e) => { setSuccessScore(e.target.value) }}
                                                style={{ width: "110px" }}
                                                size="small"
                                                label={{ basic: true, content: 'points' }}
                                                labelPosition='right'
                                                placeholder='points'
                                            /></div>
                                        </div>

                                        <div className="simulation-setting-content">
                                            <div className="simulation-setting-element">
                                                <Checkbox
                                                    checked={showQuestionAgainChecked}
                                                    toggle
                                                    onChange={showQuestionAgainChange}
                                                />
                                            </div>
                                            <div className="simulation-setting-element">
                                                <Header
                                                    disabled={showQuestionAgainChecked ? false : true}
                                                    as="h3">Allow repetitions (if not passed)
                                                </Header>
                                            </div>

                                            <div className="simulation-setting-element">
                                                <Transition
                                                    visible={showQuestionAgainChecked}
                                                    animation='scale' duration={500}>


                                                    <div className="simulation-setting-element">
                                                        <Input
                                                            disabled={showQuestionAgainChecked ? false : true}
                                                            type="number"
                                                            value={showQuestionAgainTimes}
                                                            onChange={(e) => { setShowQuestionAgainTimes(e.target.value) }}
                                                            style={{ width: "110px" }}
                                                            size="small"
                                                            label={{ basic: true, content: 'times' }}
                                                            labelPosition='right'
                                                            placeholder='times'
                                                        />
                                                    </div>



                                                </Transition>
                                            </div>
                                        </div>





                                    </div>


                                </Form.Field>





                                <Form.Field>
                                    <label>Completion settings</label>
                                    <div className="simulation-setting-content-box">
                                        <div className="simulation-setting-content">
                                            <div className="simulation-setting-element"><Checkbox checked={showCorrectAnswerChecked} onChange={showCorrectAnswerChange} toggle /></div>
                                            <div className="simulation-setting-element"><Header disabled={showCorrectAnswerChecked ? false : true} as="h3">Show correct answers</Header></div>

                                            <div className="simulation-setting-element">
                                                <Transition
                                                    visible={showCorrectAnswerChecked}
                                                    animation='scale' duration={500}>
                                                    <Dropdown
                                                        placeholder='Select state'
                                                        fluid
                                                        value={showCorrectAnswerSelected}
                                                        selection
                                                        onChange={(e, data) => { setShowCorrectAnswerSelected(data.value) }}
                                                        options={correctAnswerSelectedOption.options}
                                                    />


                                                </Transition>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="simulation-setting-content-box">
                                        <div className="simulation-setting-content">
                                            <div className="simulation-setting-element"><Checkbox checked={showScoreChecked} onChange={showScoreChange} toggle /></div>
                                            <div className="simulation-setting-element"><Header disabled={showScoreChecked ? false : true} as="h3">Show score</Header></div>
                                        </div>

                                    </div>


                                </Form.Field>





                                <Form.Field>
                                    <label>Publish <Popup position='right center' offset={[0, 10]} content='If you activate it, assigned users can see this simulation.' trigger={<Icon color='blue' size='small' name='info' circular />} /></label>

                                    <Checkbox
                                        onChange={changeIsActive}
                                        className="user-edit-is-active-checkbox"
                                        checked={isActive}
                                        toggle
                                    />


                                </Form.Field>

                                <Button
                                    primary
                                    onClick={updateSimulationDetail}
                                    size="huge"
                                    type='submit'
                                >
                                    Save
                                </Button>

                                <Button
                                    size="huge"
                                    onClick={
                                        () => {
                                            historyPush(1, 2);
                                            setActiveDetailIndexTab(1);
                                        }
                                    }
                                >
                                    Assign users
                                </Button>

                                <Transition visible={updateSimulationInfo} animation='scale' duration={500}>
                                    <Loader size='huge'>Saving..</Loader>
                                </Transition>
                            </Form>
                        </div>
                        <div className="example-card-box">
                            <div className="example-card-content">
                                <SimulationsCard Image={image !== "" ? image : shareSimulationImageLink} IsActive={isActive} Clickable={false} Name={name} Desc={description} className="simulation-detail-class" />
                            </div>

                        </div>
                    </div>

                </Tab.Pane>,
        },
        {
            menuItem: { content: 'Users', icon: 'users' },
            render: () =>
                <Tab.Pane size="big" attached={false}>

                    <div className="simulation-digitallica-search-field">
                        <Input

                            id="simulation-digitallica-search-input"
                            size="big"
                            placeholder="Search.."
                            value={filterInputText}
                            onChange={o => { setFilterInputText(o.target.value); filterText = o.target.value; }}
                        />




                        <div className="digitallica-batch-processing-field">

                            <Label onClick={() => { setOpenEnrolledModal(true) }} className="selectedUsersItemsLabel" size="big" color="green">
                                <Icon className="selectedUsersItemsLabel" name='thumbs up' />  Enroll All User
                            </Label>

                        </div>

                    </div>


                    <div className="simulation-digitallica-table-header">
                        {tableHeader.map((item, index) =>
                            <div className="simulation-digitallica-table-theader-items" Key={index}>{item}</div>
                        )}
                    </div>

                    <Transition visible={showNoUsersFound} animation='scale' duration={500}>
                        <div className="no-users-found-box">
                            <div>
                                <h1>No User Found</h1>
                            </div>
                            <div>
                                <img width="20%" src={NotFoundSVG} />
                            </div>
                        </div>
                    </Transition>

                    <div>
                        {userData.map((item, index) =>
                            <UserListComponent
                                Name={item.name}
                                Surname={item.surname}
                                UserType={item.userType}
                                UserId={item.id}
                                SimulationId={props.match.params.id}
                                IsEnrolled={item.isEnrolled}
                                GetUsers={getUsers}
                            />
                        )}
                    </div>

                    <div className="simulation-digitallica-table-pagination-field">
                        <div>
                            {paginationHtml}
                        </div>
                    </div>
                </Tab.Pane>,
        },
        {
            menuItem: { content: 'Dialogs', icon: 'comments' },
            render: () => <Tab.Pane size="big" attached={false}>
                <div className="">
                    <Tab
                        activeIndex={activeIndexTab}
                        onTabChange={simulationBackgroundTabOnChange}
                        className="simulation-tabs-dialogs"
                        size="big"

                        menuPosition='right'
                        panes={dialogsPanes}
                    />
                </div>
            </Tab.Pane>,
        },
        {
            menuItem: { content: 'Notification', icon: 'paper plane' },
            render: () => <Tab.Pane size="big" attached={false}>

                <Header disabled as="h1">E-mail</Header>

                <div className="simulation-setting-content-box">
                    <div className="simulation-setting-content">
                        <div className="simulation-setting-element"><Checkbox checked={newSimulationAssignedChecked} onChange={newSimulationAssignedChange} toggle /></div>
                        <div className="simulation-setting-element"><Header disabled={newSimulationAssignedChecked ? false : true} as="h3">When a new simulation is assigned to the user</Header></div>
                        <div className="simulation-setting-element">
                            <Link
                                to={newSimulationAssignedChecked ?
                                    "/notification/" +
                                    props.match.params.id +
                                    "?notificationId=5c7f6072-29a8-48ab-8078-7fb6e91a16d8" :
                                    "#"
                                }>
                                <Button
                                    disabled={newSimulationAssignedChecked ?
                                        false :
                                        true
                                    }>
                                    Edit
                                </Button>
                            </Link>
                        </div>

                    </div>

                    <div className="simulation-setting-content">
                        <div
                            className="simulation-setting-element"
                        >
                            <Checkbox
                                checked={userCompletedSimulationChecked}
                                onChange={userCompletedSimulationChange}
                                toggle
                            />
                        </div>
                        <div
                            className="simulation-setting-element"
                        >
                            <Header
                                disabled={userCompletedSimulationChecked ?
                                    false :
                                    true
                                }
                                as="h3"
                            >
                                User completed simulation
                            </Header>
                        </div>
                        <div
                            className="simulation-setting-element"
                        >
                            <Link
                                to={userCompletedSimulationChecked ?
                                    "/notification/" +
                                    props.match.params.id +
                                    "?notificationId=ca9528b3-9229-49fc-9d8c-53aadf801d69" :
                                    "#"
                                }
                            >
                                <Button
                                    disabled={userCompletedSimulationChecked ?
                                        false :
                                        true
                                    }>
                                    Edit
                                </Button>
                            </Link>
                        </div>

                    </div>



                </div>


                <div className="simulation-settings-save-button-box">
                    <Button onClick={saveSimulationSettingsAction}>Save</Button>
                </div>


            </Tab.Pane>,
        },
    ]


    return (
        <div>
            <Transition
                visible={startUpload}
                animation='scale'
                duration={500}>
                <Modal
                    className="upload-background-modal"
                    basic
                    open={startUpload}
                    size='small'
                >
                    <Header icon>
                        <Loader size="big">Uploading</Loader>
                    </Header>

                </Modal>
            </Transition>


            <Transition
                visible={deleteBackgroundImageOpen}
                animation='scale'
                duration={500}
            >
                <Modal
                    size='small'
                    style={{
                        height: "auto",
                        margin: "0 auto",
                        position: "unset"
                    }}
                    onClose={() => setDeleteBackgroundImageOpen(false)}
                    open={deleteBackgroundImageOpen}
                >
                    <Modal.Header>Warning</Modal.Header>
                    <Modal.Content
                        image
                    >

                        <Image
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center"
                            }}
                            size='big'
                            src={modalSelectedBackgroundImage}
                            wrapped
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = window.location.origin + "/Assets/images/no-image.png"
                            }}
                        />

                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => setDeleteBackgroundImageOpen(false)}>Cancel</Button>
                        <Button
                            color="red"
                            onClick={() => deleteBackgorundPhoto(selectedBackgroundId)}
                        >
                            Delete
                        </Button>
                    </Modal.Actions>
                </Modal>
            </Transition>

            <Transition
                visible={messageModalOpen}
                animation='scale'
                duration={500}
            >
                <Modal
                    dimmer="blurring"
                    className="users-delete-ask-modal"
                    size={"mini"}
                    open={messageModalOpen}
                    onClose={() => { setMessageModalOpen(false) }}>
                    <Modal.Header>
                        <span
                            className={"account-text"}
                        >
                            {header}
                        </span>
                    </Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Header>{message}</Header>
                        </Modal.Description>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => {
                            setMessageModalOpen(false);

                        }}
                            color='green'>
                            OKEY
                        </Button>

                    </Modal.Actions>
                </Modal>
            </Transition>

            <Transition visible={openEnrolledModal} animation='scale' duration={500}>
                <Modal
                    dimmer="blurring"
                    header='Warning'
                    className="users-delete-ask-modal" size={"mini"}
                    open={openEnrolledModal}
                    onClose={() => setOpenEnrolledModal(false)}
                    content='All users will be enrolled in this simulation, Are you sure?'
                    actions={['Cancel', { key: 'done', content: 'Yes', positive: true, onClick: enrollAllUser }]}
                />
            </Transition>

            <Transition visible={alreadyEnrolledModal} animation='scale' duration={500}>
                <Modal
                    dimmer="blurring"
                    header='Warning'
                    className="users-delete-ask-modal" size={"mini"}
                    open={alreadyEnrolledModal}
                    onClose={() => setAlreadyEnrolledModal(false)}
                    content='All users are already enrolled'
                    actions={['Cancel']}
                />
            </Transition>

            <DashboardNavBar />
            <div className="dashboard-container-content">

                <div className="dashboard-content-box">

                    <div className="simulations-header">
                        <div className="page-header-text">

                            <Header as='h1' disabled>
                                <Link
                                    onClick={() => { Broadcast.emit('PAGE_CHANGED', true); }}
                                    to="/simulations"
                                >
                                    <Button
                                        className="simulation-back-button"
                                        size="massive"
                                        icon
                                    >
                                        <Icon
                                            name='left chevron'
                                        />
                                    </Button>
                                </Link>

                                {name}
                            </Header>
                        </div>
                    </div>

                    <div className="simulation-detail-box">
                        <Tab
                            activeIndex={activeDetailIndexTab}
                            onTabChange={simulationDetailTabOnChange}
                            className="simulation-tabs"
                            menu={{ secondary: true, pointing: true }}
                            panes={panes} />
                    </div>
                </div>
            </div>
        </div>
    )
}