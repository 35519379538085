import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { Form, Input, TextArea, Button, Message, Transition, Table, Checkbox, Loader, Dropdown, Pagination, Icon } from 'semantic-ui-react'
import Cookies from 'universal-cookie';
import { Tab } from 'semantic-ui-react'
import { Header } from 'semantic-ui-react'
import './customerlist.css';

import { CustomerRow } from './Row/CustomerRow';

const cookies = new Cookies();

export const CustomerList = (props) => {

    const [filterInputText, setFilterInputText] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [customersData, setCustomersData] = useState([]);
    const [loaderActive, setLoaderActive] = useState(false);
    const [showNoCompaniesFound, setShowNoCompaniesFound] = useState(false);
    const [selectedFilterText, setSelectedFilterText] = useState("");
    const [selectedFilterId, setSelectedFilterId] = useState("");
    const [paginationHtml, setPaginationHtml] = useState("");
    const [companyId, setCompanyId] = useState("");

    const [companyOptions, setCompanyOptions] = useState([]);


    var filterText = filterInputText;

    useEffect(() => {
        getCustomers();
        paginationGet();
        getCompanies();
    }, []);

    useEffect(() => {
        getCustomers();
    }, [currentPage]);

    useEffect(() => {
        setCurrentPage(1);
        getCustomers();
        paginationGet();
    }, [selectedFilterId, companyId]);

    const getCustomers = () => {

        setLoaderActive(true);
        var data = {
            FilterText: filterText.toString().trim(),
            currentPage: currentPage,
            asc: selectedFilterId == 1 ? true : false,
            name: selectedFilterId == 3 ? true : false,
            deleted: selectedFilterId == 4 ? true : false,
            companyId: companyId

        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: data
        });

        axios.post('/User/GetCustomers', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            console.log(response);

            let customer = response.data.data;

            setCustomersData(customer);
            setLoaderActive(false);

            if (customer.length == 0) {
                setShowNoCompaniesFound(true);
                setCurrentPage(1)
            }
            else
                setShowNoCompaniesFound(false);

        });
    }

    const paginationGet = () => {

        var data = {
            asc: selectedFilterId == 1 ? true : selectedFilterId == 2 ? false : true,
            name: selectedFilterId == 3 ? true : false,
            deleted: selectedFilterId == 4 ? true : false,
            FilterText: filterText.toString().trim(),
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: data
        });

        axios.post('/User/GetCustomerCount', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);

            if (response.data.count) {
                setPaginationHtml(<Pagination
                    defaultActivePage={1}
                    firstItem={null}
                    lastItem={null}
                    pointing
                    secondary
                    totalPages={parseInt(Math.ceil(response.data.count / 25))}
                    onPageChange={(event, data) => setCurrentPage(parseInt(data.activePage))}
                />);
            }
        });
    }

    const filterCompaniesOnChange = () => {

        if (filterText.trim().length < 1) {
            getCustomers();
            paginationGet();
        }
        else {
            if (filterText.trim().length > 1) {
                let filterItems = {
                    FilterText: filterText.toString().trim(),
                    isDeleted: selectedFilterId == 4 ? true : false,
                    companyId: companyId,
                    currentPage: currentPage,
                    asc: selectedFilterId == 1 ? true : false,
                    name: selectedFilterId == 3 ? true : false,
                    deleted: selectedFilterId == 4 ? true : false
                }

                var Token = JSON.stringify({
                    AuthToken: cookies.get('Token'),
                    Data: filterItems
                });

                axios.post('/User/SearchCustomers', Token, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).then((response) => {


                    console.log(response.data.data.result);

                    if (response.data.data.result) {
                        setShowNoCompaniesFound(false);
                        let companiesList = response.data.data.result;
                        setCustomersData(companiesList);

                        paginationGet();

                        if (companiesList.length == 0)
                            setShowNoCompaniesFound(true);


                    } else {

                    }
                });
            } else {
                getCustomers();
            }
        }


    }

    const dateFormat = (dbDate) => {
        const date = new Date(dbDate)
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()

        // add a leading 0 if the number is less than 10. like 9 to 09

        day < 10 && (day = `0${day}`)
        month < 10 && (month = `0${month}`)

        const newFormat = `${day}-${month}-${year}`;

        return newFormat;
    }

    const diffDates = (date1, date2) => {
        const dateFirst = new Date(date1);
        const dateSecond = new Date(date2);


        const diffTime = Math.abs(dateSecond - dateFirst);
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    }

    const filterSelect = (e) => {

        let selectedFilterText = e.target.innerText;
        let selectedFilterId = e.target.dataset.id;

        if (!selectedFilterId) {
            switch (selectedFilterText) {
                case "Date ascending":
                    selectedFilterId = 1;
                    break;
                case "Date descending":
                    selectedFilterId = 2;
                    break;
                case "Name":
                    selectedFilterId = 3;
                    break;
                case "Deleted":
                    selectedFilterId = 4;
                    break;

                default:
            }
        }

        setSelectedFilterText(selectedFilterText);
        setSelectedFilterId(selectedFilterId);
    }

    const getCompanies = () => {

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
        });

        axios.post('/Company/GetAllCompanies', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            if (response.data.resultCode == 0) {

                response.data.data.map((item, index) => {

                    let companyObject = {
                        key: item.id,
                        text: item.name,
                        value: item.id
                    }

                    setCompanyOptions(companyOptions => [...companyOptions, companyObject]);
                });


            }
        });
    }

    const companyChange = (e, data) => {
        setCompanyId(data.value)
    }



    return (
        <div className="company-list-box">

            <div className="super-admin-filter-box">
                <div>
                    <Input
                        size="big"
                        icon='search'
                        placeholder='Search...'
                        value={filterInputText}
                        onChange={o => { setFilterInputText(o.target.value); filterText = o.target.value; filterCompaniesOnChange(); }}
                    />
                </div>
                <div>

                    <Dropdown
                        placeholder='Filter by company'
                        search
                        selection
                        options={companyOptions}
                        className="filter-company-dropdown"
                        onChange={companyChange}
                    />

                    <Dropdown
                        size="big"
                        text={selectedFilterText != "" ? selectedFilterText : "Filter"}
                        icon='filter'
                        floating
                        labeled
                        button
                        className='icon'
                    >
                        <Dropdown.Menu>
                            <Dropdown.Header icon='tags' content='Filter by tag' />
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={filterSelect} icon='sort numeric ascending' text='Date ascending' data-id="1" />
                            <Dropdown.Item onClick={filterSelect} icon='sort numeric descending' text='Date descending' data-id="2" />
                            <Dropdown.Item onClick={filterSelect} icon='sort alphabet down' text='Name' data-id="3" />
                            <Dropdown.Item onClick={filterSelect} icon='trash' text='Deleted' data-id="4" />
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>

            <Transition visible={showNoCompaniesFound} animation='scale' duration={500}>
                <div className="no-users-found-box">
                    <div>
                        <h1>No Companies Found</h1>
                    </div>
                    <div className="super-admin-no-found-icon">
                        <Icon size="big" name="undo" />
                    </div>
                </div>
            </Transition>

            {!showNoCompaniesFound ?
                <>
                    <Table size='large' basic='very' >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Active</Table.HeaderCell>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Surname</Table.HeaderCell>
                                <Table.HeaderCell>Email</Table.HeaderCell>
                                <Table.HeaderCell>Username</Table.HeaderCell>
                                <Table.HeaderCell>Company</Table.HeaderCell>
                                <Table.HeaderCell>Phone</Table.HeaderCell>
                                
                                <Table.HeaderCell>Edit</Table.HeaderCell>
                                <Table.HeaderCell>Delete</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>

                            <Loader active={loaderActive} size='huge'>Loading...</Loader>
                            {customersData.map((item, index) =>
                                <CustomerRow
                                    key={item.id}
                                    Id={item.id}
                                    GetCompaniesFunc={getCustomers}
                                    IsActive={item.isActive}
                                    IsDeleted={item.isDeleted}
                                    Name={item.name}
                                    Surname={item.surname}
                                    Email={item.email}
                                    Username={item.username}
                                    CompanyName={item.companyName}
                                    Phone={item.phone}
                                    OrganizationalStructure={item.organizationalStructure}
                                    RegistrationNumber={item.registrationNumber	}
                                />

                            )}

                        </Table.Body>
                    </Table>

                    <div className="super-admin-pagination-box">
                        <div>
                            {paginationHtml}
                        </div>
                    </div></> : ""
            }

        </div>
    );
}