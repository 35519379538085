import React, { useContext, useEffect, useState } from 'react';
import './mailbodydesign.css';
import queryString from 'query-string';
import { Button, Checkbox, Form, Header, Icon, Input, Label, Loader, Modal, Pagination, Popup, Tab, TextArea, Transition, Dropdown, Confirm } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { DashboardNavBar } from '../../../../Dashboard/DashboardNavBar/DashboardNavBar';

const cookies = new Cookies();

export const MailBodyDesign = (props) => {

    //email body text buttons
    const emailBodyButtons = {
        1: "[RELEATED_USER_MAIL]",
        2: "[RELEATED_USER_FIRST_NAME]",
        3: "[SITE_NAME]",
        4: "[SITE_URL]",
        5: "[TIME]",
        6: "[SIMULATION_NAME]",
        7: "[SIMULATION_URL]",
        8: "[AVARAGE_SIMULATION_SCORE]"   
    }

    const notificationSettingsName = {
        "5c7f6072-29a8-48ab-8078-7fb6e91a16d8": {
            title: "When a new simulation is assigned to the user",
            defaultBody: "You were assigned to a new simulation \nHello [RELEATED_USER_FIRST_NAME], \nYou were assigned to a new simulation named:[SIMULATION_NAME]. \n<i> This email was sent from [SITE_URL] at [TIME] </i>"
        } ,
        "ca9528b3-9229-49fc-9d8c-53aadf801d69": {
            title: "User completed simulation",
            defaultBody: "You have completed a simulation \nCongratulations  [RELEATED_USER_FIRST_NAME]! \nYou recently completed the simulation '[SIMULATION_NAME]'. \n<i> This email was sent from [SITE_URL] at[TIME] </i>"
        } 
    }

    const [emailBodyText, setEmailBodyText] = useState("");

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [modalHeader, setModalHeader] = useState("");
    const [modalDesc, setModalDesc] = useState("");

    const [isOpenConfirm, setIsOpenConfirm] = useState(false);
    const [notificationSettingName, setNotificationSettingName] = useState("");

    

    //notifcation settings

    const [notificationSimulationSettings, setNotificationSimulationSettings] = useState({
        newSimulationAssignedUser: {
            body: null,
            isActive: false
        },
        userCompletedSimulation: {
            body:null
        }
    });

    //when every change
    useEffect(() => {

        let newValueNotificationSimulationSettings;

        switch (queryString.parse(props.location.search).notificationId) {
            case "ca9528b3-9229-49fc-9d8c-53aadf801d69":
                newValueNotificationSimulationSettings = {
                    userCompletedSimulation: {
                        body: emailBodyText,
                    }
                };
                break;

            case "5c7f6072-29a8-48ab-8078-7fb6e91a16d8":
                newValueNotificationSimulationSettings = {
                    newSimulationAssignedUser: {
                        body: emailBodyText,
                    }
                };
                break;

            default:
        }

        
        

        console.log(newValueNotificationSimulationSettings);

        setNotificationSimulationSettings(newValueNotificationSimulationSettings);

    }, [emailBodyText]);

    //page on load
    useEffect(() => {
        getNotificationSimulationMessageBody();

        let params = queryString.parse(props.location.search);
        if (params.notificationId != "") {
            setNotificationSettingName(notificationSettingsName[params.notificationId].title);
        }

    }, []);

    const getNotificationSimulationMessageBody = () => {
        let notificationSimulationData = {
            SimulationId: props.match.params.id,
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: notificationSimulationData
        });

        axios.post('/NotificationSimulationSettings/GetBySimulationId', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            console.log(response)
            if (response.data.resultCode == 0) {
                if (response.data.data) {
            
                    let emailBodyText = JSON.parse(response.data.data.notificationJSON);
                    console.log(JSON.parse(response.data.data.notificationJSON));

                    switch (queryString.parse(props.location.search).notificationId) {
                        case "ca9528b3-9229-49fc-9d8c-53aadf801d69":
                            if (emailBodyText.userCompletedSimulation)
                                setEmailBodyText(emailBodyText.userCompletedSimulation.body);
                            break;

                        case "5c7f6072-29a8-48ab-8078-7fb6e91a16d8":
                            if (emailBodyText.newSimulationAssignedUser)
                                setEmailBodyText(emailBodyText.newSimulationAssignedUser.body);
                            break;

                        default:
                    }
                    
                }
                
            } else {

            }
        });
    }
    

    const [backLink] = useState("/simulation/" + props.match.params.id + "?menu=3&subMenu=2")

    const appendButton = (e) => {

        let dataId = e.target.dataset.id;
        let messageBodyTextarea = document.getElementById("mail-body-textarea");
        let curPos = messageBodyTextarea.selectionStart;
        let end = messageBodyTextarea.selectionEnd;
        let currentMessageBody = messageBodyTextarea.value;
        let textToInsert = emailBodyButtons[dataId];

        
        messageBodyTextarea.value = [currentMessageBody.slice(0, curPos), textToInsert, currentMessageBody.slice(curPos)].join(" ");
        setEmailBodyText(messageBodyTextarea.value);
        messageBodyTextarea.focus();
        messageBodyTextarea.selectionEnd = end + textToInsert.length + 2;

    }

    const saveNotificationSettings = () => {

        let notificationSimulationData = {
            UserId: cookies.get('UserId'),
            SimulationId: props.match.params.id,
            NotificationJSON: JSON.stringify(notificationSimulationSettings)
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: notificationSimulationData
        });

        axios.post('/NotificationSimulationSettings/Add', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            console.log(response)
            if (response.data.resultCode == 0) {
                setModalHeader("Success");
                setModalDesc("Notification mail settings have been saved");
                setIsOpenModal(true);
            } else {
                setModalHeader("Error");
                setModalDesc("An unexpected error has occurred");
                setIsOpenModal(true);
            }
        });
    }

    const resetNotificationSettings = () => {
        setEmailBodyText(notificationSettingsName[queryString.parse(props.location.search).notificationId].defaultBody);
        setIsOpenConfirm(false);
    }

    return (
        <div>

            <Transition visible={isOpenModal} animation='scale' duration={500}>
                <Modal dimmer="blurring" className="users-delete-ask-modal" size={"mini"} open={isOpenModal} onClose={() => { setIsOpenModal(false) }}>
                    <Modal.Header><span className={"account-text"}>{modalHeader}</span></Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Header>{modalDesc}</Header>
                        </Modal.Description>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button color="green" onClick={() => { setIsOpenModal(false); }}>
                            OKEY 
                        </Button>

                    </Modal.Actions>
                </Modal>
            </Transition>

            <Transition visible={isOpenConfirm} animation='scale' duration={500}>
                <Confirm
                    className="users-delete-ask-modal"
                    size={"mini"}
                    open={isOpenConfirm}
                    content='Reset email body template ?'
                    onCancel={() => { setIsOpenConfirm(false)}}
                    onConfirm={resetNotificationSettings}
                />
            </Transition>

            <DashboardNavBar />
            <div className="dashboard-container-content">

                <div className="dashboard-content-box">

                    <div className="simulations-header">
                        <div className="page-header-text">

                            <Header as='h1' disabled>
                                <Link to={backLink}><Button className="simulation-back-button" size="massive" icon>
                                    <Icon name='left chevron' />
                                </Button></Link>

                                {notificationSettingName}
                            </Header>
                        </div>
                    </div>

                    
                    <div className="simulation-detail-box simulation-notification-box">

                        <div className="notification-email-body-header">
                            <Header disabled as="h2">Email body</Header>
                        </div>
                        
                       

                        <TextArea
                            className="mail-body-textarea"
                            id="mail-body-textarea"
                            placeholder='Tell us more'
                            value={emailBodyText || notificationSettingsName[queryString.parse(props.location.search).notificationId].defaultBody}
                            onChange={(e) => { setEmailBodyText(e.target.value) } }
                        />

                        <div className="message-body-button-box">
                            <Button data-id="1" onClick={appendButton} color='google plus'>
                                <Icon data-id="1" name='mail' /> Releated User Mail
                            </Button>

                            <Button data-id="2" onClick={appendButton} color='google plus'>
                                <Icon data-id="2" name='user' /> Releated User First Name
                            </Button>

                            <Button data-id="3" onClick={appendButton} color='google plus'>
                                <Icon data-id="3" name='world' /> Site Name
                            </Button>

                            <Button data-id="4" onClick={appendButton} color='google plus'>
                                <Icon data-id="4" name='world' /> Site Url
                            </Button>

                            <Button data-id="5" onClick={appendButton} color='google plus'>
                                <Icon data-id="5" name='clock' /> Time
                            </Button>

                            <Button data-id="6" onClick={appendButton} color='instagram'>
                                <Icon data-id="6" name='chess knight' /> Simulation Name
                            </Button>

                            <Button data-id="7" onClick={appendButton} color='instagram'>
                                <Icon data-id="7" name='chess knight' /> Simulation Url
                            </Button>

                            <Button data-id="8" onClick={appendButton} color='instagram'>
                                <Icon data-id="8" name='chess knight' /> Avarage Simulation Score
                            </Button>
                        </div>

                        <div className="notification-mail-body-button-box">
                            <Popup content='Reset email body template' trigger={<Button
                                icon="repeat"
                                size="big"
                                onClick={() => { setIsOpenConfirm(true); }}
                            ></Button>} />
                            
                            <Button
                                size="big"
                                color="blue"
                                onClick={saveNotificationSettings}
                            >Save</Button>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        );
}
