import React, { useState, useContext, useEffect } from 'react';
import './simulationslist.css';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { loginContext, fullNameContext } from '../../../../../Store';
import { Button, Divider, Form, Checkbox, Dropdown, Modal, Transition, Icon, Header, Card, Input, Loader, Pagination } from 'semantic-ui-react'
import { SimulationsCard } from './SimulationsCard/SimulationsCard';
import { Link } from 'react-router-dom';
import noImageSimulation from '../../../../../images/matthew.png';
import NotFoundSVG from '../../../../../images/not-found.svg';

const cookies = new Cookies();

export const SimulationsList = (props) => {

    //context ----
    const [isLoginControl, setLoginControl] = useContext(loginContext);
    //context ----

    const [filterInputText, setFilterInputText] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [simulationsData, setSimulationsData] = useState([]);
    const [loaderActive, setLoaderActive] = useState(false);
    const [showNoSimulationsFound, setShowNoSimulationsFound] = useState(false);
    const [paginationHtml, setPaginationHtml] = useState("");

    const [selectedFilterText, setSelectedFilterText] = useState("");
    const [selectedFilterId, setSelectedFilterId] = useState("");



    var filterText = filterInputText;

    useEffect(() => {

        if (!isLoginControl) {
            window.location.href = "/";
        }

        getSimulations();
        paginationGet();

    }, []);

    useEffect(() => {
        getSimulations();
    }, [currentPage]);

    useEffect(() => {
        if (selectedFilterId) {
            setLoaderActive(true);
            var data = {
                currentPage: currentPage,
                UserId: cookies.get('UserId').toString(),
                asc: selectedFilterId == 1 ? true : selectedFilterId == 2 ? false : true,
                name: selectedFilterId == 4 ? true : false
            }

            var Token = JSON.stringify({
                AuthToken: cookies.get('Token'),
                Data: data
            });

            axios.post('/Simulation/GetUserSimulations', Token, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then((response) => {

                console.log(response);

                let simulations = response.data.data;

                setSimulationsData(simulations);
                setLoaderActive(false);

                if (simulations.length == 0)
                    setShowNoSimulationsFound(true);
                else
                    setShowNoSimulationsFound(false);

            });
        }
    }, [selectedFilterId]);


    const getSimulations = () => {
        setLoaderActive(true);
        var data = {
            currentPage: currentPage,
            UserId: cookies.get('UserId').toString()
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: data
        });

        axios.post('/Simulation/GetUserSimulations', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            console.log(response);

            let simulations = response.data.data;

            setSimulationsData(simulations);
            setLoaderActive(false);

            if (simulations.length == 0)
                setShowNoSimulationsFound(true);
            else
                setShowNoSimulationsFound(false);

        });
    }

    const paginationGet = () => {

        //console.log(props.match.params.id);
        var simulationsPagination = {
            CompanyId: cookies.get('CompanyId').toString()
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: simulationsPagination
        });

        axios.post('/Simulation/GetUserSimulationCount', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);

            if (response.data.count) {
                setPaginationHtml(<Pagination
                    defaultActivePage={1}
                    firstItem={null}
                    lastItem={null}
                    pointing
                    secondary
                    totalPages={parseInt(Math.ceil(response.data.count / 25))}
                    onPageChange={(event, data) => setCurrentPage(parseInt(data.activePage))}
                />);
            }
        });
    }

    const filterSimulationsOnChange = () => {
        console.log(filterText);

        if (filterText.trim().length < 1) {
            getSimulations();
        }
        else {
            if (filterText.trim().length > 1) {
                let filterItems = {
                    CompanyId: cookies.get('CompanyId').toString(),
                    FilterText: filterText.toString().trim()
                }

                var Token = JSON.stringify({
                    AuthToken: cookies.get('Token'),
                    Data: filterItems
                });

                axios.post('/Simulation/SearchUserSimulations', Token, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).then((response) => {


                    console.log(response.data.data.result);

                    if (response.data.data.result) {
                        setShowNoSimulationsFound(false);
                        let simulationsList = response.data.data.result;
                        setSimulationsData(simulationsList);

                        if (simulationsList.length == 0)
                            setShowNoSimulationsFound(true);


                    } else {

                    }
                });
            } else {
                getSimulations();
            }
        }


    }



    const dateFormat = (dbDate) => {
        const date = new Date(dbDate)
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()

        // add a leading 0 if the number is less than 10. like 9 to 09

        day < 10 && (day = `0${day}`)
        month < 10 && (month = `0${month}`)

        const newFormat = `${day}-${month}-${year}`;

        return newFormat;
    }

    const filterSelect = (e) => {

        let selectedFilterText = e.target.innerText;
        let selectedFilterId = e.target.dataset.id;

        if (!selectedFilterId) {
            switch (selectedFilterText) {
                case "Date ascending":
                    selectedFilterId = 1;
                    break;
                case "Date descending":
                    selectedFilterId = 2;
                    break;
                case "Completion rate":
                    selectedFilterId = 3;
                    break;
                case "Name":
                    selectedFilterId = 4;
                    break;

                default:
            }
        }

        setSelectedFilterText(selectedFilterText);
        setSelectedFilterId(selectedFilterId);
    }

    return (
        <div>
            
            <div className="dashboard-container-content">

                <div className="dashboard-content-box">

                    <div className="simulations-header">
                        <div className="page-header-text">
                            <Header as='h1' disabled>
                                
                                Simulations
                             </Header>
                        </div>

                        <div className="simulations-search-field">
                            <Input

                                className="search-input"
                                size="big"
                                placeholder="Search.."
                                value={filterInputText}
                                onChange={o => { setFilterInputText(o.target.value); filterText = o.target.value; filterSimulationsOnChange(); }}
                            />
                        </div>
                        
                        <div className="add-new-simulation-box">
                            

                            <Dropdown
                               
                                text={selectedFilterText != "" ? selectedFilterText : "Filter"}
                                icon='filter'
                                floating
                                labeled
                                button
                                className='icon'
                            >
                                <Dropdown.Menu>
                                    <Dropdown.Header icon='tags' content='Filter by tag' />
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={filterSelect} icon='sort numeric ascending' text='Date ascending' data-id="1" />
                                    <Dropdown.Item onClick={filterSelect} icon='sort numeric descending' text='Date descending' data-id="2" />
                                    <Dropdown.Item onClick={filterSelect} icon='sort amount up' text='Completion rate' data-id="3" />
                                    <Dropdown.Item onClick={filterSelect} icon='sort alphabet down' text='Name' data-id="4" />
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                    </div>
                    <Loader active={loaderActive} size='huge'>Loading...</Loader>
                    <Transition visible={showNoSimulationsFound} animation='scale' duration={500}>
                        <div className="no-users-found-box">
                            <div>
                                <h1>No Simulations Found</h1>
                            </div>
                            <div>
                                <img width="20%" src={NotFoundSVG} />
                            </div>
                        </div>
                    </Transition>

                    <div className="simulations-card-group-box">

                        <Card.Group>
                            {simulationsData.map((item, index) =>

                                <SimulationsCard
                                    IsActive={item.isActive}
                                    Clickable={true}
                                    key={item.id}
                                    Id={item.simulationId}
                                    Name={item.name}
                                    Desc={item.description.length > 80 ? item.description.slice(0, 70) + "..." : item.description}
                                    Image={item.image ? item.image : null}
                                    CreatedDate={dateFormat(item.createdDate)}
                                    CompletionRate={item.progressPercentage}
                                />



                            )}


                        </Card.Group>
                    </div>

                    <div className="simulation-pagination-box">
                        <div>
                            {paginationHtml}
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}