import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { Form, Input, TextArea, Button, Message, Transition } from 'semantic-ui-react'
import Cookies from 'universal-cookie';
import { Tab } from 'semantic-ui-react'
import { Header } from 'semantic-ui-react'
import './companyadd.css';

const cookies = new Cookies();

export const CompanyAdd = (props) => {

    const [today] = useState(new Date().toISOString().split('T')[0]);
    const [startingDate, setStartingDate] = useState(today);
    const [dueDate, setDueDate] = useState("");

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");

    const [header, setHeader] = useState("");
    const [message, setMessage] = useState("");
    const [messageModalOpen, setMessageModalOpen] = useState("");
    const [messageType, setMessageType] = useState(true);

    const addCompany = () => {
        setMessageModalOpen(false);
       
        var companyData = {
            name: name,
            description: description,
            startingDate: startingDate,
            dueDate: dueDate
        }

        console.log(companyData);

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: companyData
        });

        axios.post('/Company/Add', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            console.log(response)
            if (response.data.resultCode == 0) {
                setHeader("Success");
                setMessage("Company information added successfully");
                setMessageType(true);
                setMessageModalOpen(true);
            } else {
                setHeader("Error");
                setMessage(response.data.message);
                setMessageType(false);
                setMessageModalOpen(true);
            }


        });
    }

    return (
        <div className="company-add-form-box">
            <Form>
                <Form.Group widths='equal'>
                    <Form.Field
                        control={Input}
                        label='Company name (*)'
                        placeholder='Company name'
                        size="big"
                        value={name}
                        onChange={e => { setName(e.target.value) }}
                    />
                </Form.Group>
                <Form.Field
                    control={TextArea}
                    label='Description (*)'
                    placeholder='Description'
                    size="big"
                    style={{ minHeight: 150, fontSize: "1.3em" }}
                    value={description}
                    onChange={e => { setDescription(e.target.value) }}
                />

                <Form.Field>
                    <label>Starting Date (*)</label>
                    <Input
                        size="big"
                        max={dueDate}
                        min={today}
                        value={startingDate}
                        onChange={e => { setStartingDate(e.target.value) }}
                        type="datetime-local"
                    />
                </Form.Field>

                <Form.Field>
                    <label>End Date (*)</label>
                    <Input
                        size="big"
                        min={startingDate}
                        value={dueDate}
                        onChange={e => { setDueDate(e.target.value) }}
                        type="datetime-local" />
                </Form.Field>

                <Button
                    size="big"
                    color="blue"
                    onClick={addCompany}
                >
                    Save
                </Button>
                
            </Form>

            <Transition visible={messageModalOpen} animation='scale' duration={500}>
                <Message
                    color={messageType ? "green" : "red"}
                    header={header}
                    content={message}
                />
            </Transition>
        </div>
    );
}