import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { Form, Input, TextArea, Button, Message, Transition, Dropdown } from 'semantic-ui-react'
import Cookies from 'universal-cookie';
import { Tab } from 'semantic-ui-react'
import { Header } from 'semantic-ui-react'
import './customeradd.css';

const cookies = new Cookies();

export const CustomerAdd = (props) => {

    const [companyId, setCompanyId] = useState("");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [phone, setPhone] = useState("");
    const [organizationalStructure, setOrganizationalStructure] = useState("");
    const [registrationNumber, setRegistrationNumber] = useState("");


    const [header, setHeader] = useState("");
    const [message, setMessage] = useState("");
    const [messageModalOpen, setMessageModalOpen] = useState("");
    const [messageType, setMessageType] = useState(true);
    const [companyOptions, setCompanyOptions] = useState([]);

    const options = [
        { key: 1, text: 'Choice 1', value: 1 },
        { key: 2, text: 'Choice 2', value: 2 },
        { key: 3, text: 'Choice 3', value: 3 },
    ]


    useEffect(() => {
        getCompanies();
    }, []);

    const addCompany = () => {
        setMessageModalOpen(false);

        var companyData = {
            companyId: companyId,
            name: name,
            surname: surname,
            email: email,
            username: username,
            phone: phone,
            organizationalStructure: organizationalStructure,
            registrationNumber: registrationNumber,
            userType: 1
        }

        console.log(companyData);

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: companyData
        });

        axios.post('/User/Add', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            console.log(response)
            if (response.data.resultCode == 0) {
                setHeader("Success");
                setMessage("User added successfully");
                setMessageType(true);
                setMessageModalOpen(true);
            } else {
                setHeader("Error");
                setMessage(response.data.message);
                setMessageType(false);
                setMessageModalOpen(true);
            }


        });
    }

    const getCompanies = () => {

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
        });

        axios.post('/Company/GetAllCompanies', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            if (response.data.resultCode == 0) {
      
                response.data.data.map((item, index) => {
                    
                    let companyObject = {
                        key: item.id,
                        text: item.name,
                        value: item.id
                    }

                    setCompanyOptions(companyOptions => [...companyOptions, companyObject]);
                });

                
            }
        });
    }

    const changeCompanyOptions = (event, data) => {
        setCompanyId(data.value);
    }

    return (
        <div className="company-add-form-box">
            <Form>
                <Form.Field>
                    <label>Company (*)</label>
                    <Dropdown
                        placeholder='Company'
                        search
                        selection
                        options={companyOptions}
                        onChange={changeCompanyOptions}
                    />
                </Form.Field>
                
                    <Form.Field>
                    <label>Name (*)</label>

                    <Input
                        size="big"
                        value={name}
                        onChange={e => { setName(e.target.value) }}
                    />
                </Form.Field>

                <Form.Field>
                    <label>Surname (*)</label>

                    <Input
                        size="big"
                        value={surname}
                        onChange={e => { setSurname(e.target.value) }}
                     />
                 </Form.Field>

                <Form.Field>
                    <label>Email (*)</label>
                    <Input
                        size="big"
                        value={email}
                        onChange={e => { setEmail(e.target.value) }}
                    />
                </Form.Field>

                <Form.Field>
                    <label>Username (*)</label>
                    <Input
                        size="big"
                        value={username}
                        onChange={e => { setUsername(e.target.value) }}
                     />
                </Form.Field>

                <Form.Field>
                    <label>Phone</label>
                    <Input
                        size="big"
                        value={phone}
                        onChange={e => { setPhone(e.target.value) }}
                    />
                </Form.Field>

                <Form.Field>
                    <label>Organizational Structure</label>
                    <Input
                        size="big"
                        value={organizationalStructure}
                        onChange={e => { setOrganizationalStructure(e.target.value) }}
                    />
                </Form.Field>

                <Form.Field>
                    <label>Registration Number</label>
                    <Input
                        size="big"
                        value={registrationNumber}
                        onChange={e => { setRegistrationNumber(e.target.value) }}
                    />
                </Form.Field>

                <Button
                    size="big"
                    color="blue"
                    onClick={addCompany}
                >
                    Save
                </Button>

            </Form>

            <Transition visible={messageModalOpen} animation='scale' duration={500}>
                <Message
                    color={messageType ? "green" : "red"}
                    header={header}
                    content={message}
                />
            </Transition>
        </div>
    );
}