import React, { useState, useContext, useEffect } from 'react';
import './tbody.css';
import axios from 'axios';
import { Input, Checkbox } from 'semantic-ui-react'
import { Row } from '../Row/Row';


export const Tbody = (props) => {

    useEffect(() => {

 

    }, []);


    return (
        <div>
            <div>
                {props.TableData.map((item, index) =>
                    <Row Key={index} TableData={props.TableData} />
                )}
            </div>
        </div>
    );

}
