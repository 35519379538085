import React, { useState, useContext, useEffect } from 'react';
import './question-option.css';
import axios from 'axios';
import { Button, Divider, Form, Checkbox, Dropdown, Modal, Transition, Icon, Header, Tab, TextArea, Input, Radio } from 'semantic-ui-react';

export const QuestionOption = (props) => {

    const [checkedOption, setCheckedOption] = useState("");

    const [optionText, setOptionText] = useState(props.Text);

    const handleChange = (e, { value }) => {

        setCheckedOption(value);
        props.ChangeCorrectValue(value);
    }

    const optionTextStyle = {
        fontFamily: 'Gelion-Normal'
    }

    return (
        <div className="question-option-field">
            <div className="choose-correct-checkbox">
                <Radio
                    toggle
                    name='checkboxRadioGroup'
                    value={props.Label}
                   
                    onChange={handleChange}
                    defaultChecked={props.IsCorrect}
                />
            </div>
            <div className="choose-text-field">
                <Input
                    onChange={(e) => { setOptionText(e.target.value); props.ChangeValue(e.target.value, props.Label) }}
                    value={optionText}
                    label={props.Label}
                    className="question-options"
                    fluid
                    size='mini'
                    icon='text cursor'
                    placeholder='option...'
                    style={optionTextStyle}
                />
            </div>
        </div>
        )
}