import React, { useState, useEffect, useContext} from 'react';
import './welcomepage.css'
import { NavMenu } from './NavMenu';
import { DashboardNavBar } from '../DashboardPages/Dashboard/DashboardNavBar/DashboardNavBar';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { Input, Transition, Message, Dimmer, Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import { loginContext, fullNameContext } from '../../Store';

const cookies = new Cookies();




export const ForgetPassword = () => {

    const [email, setEmail] = useState("");
    const [isError, setIsError] = useState(false);
    const [messageHeader, setMessageHeader] = useState("");
    const [messageBody, setMessageBody] = useState("");
    const [messageType, setMessageType] = useState("");
    const [loaderActive, setLoaderActive] = useState(false);
    const [subQueryActive, setSubQueryActive] = useState(false);

    const [isLoginControl, setLoginControl] = useContext(loginContext);
    

    useEffect(() => {

    
        if (getParameterByName('email')) {
            setEmail(getParameterByName('email'));

            /* cookies.remove('Token');
            cookies.remove('Name');
            cookies.remove('Surname');
            cookies.remove('Email');
            cookies.remove('UserId');
            cookies.remove('IsLogin');
            cookies.remove('Password');
            cookies.remove('FullName');
            cookies.remove('Username');
            cookies.remove('UserType');

            setLoginControl(false); */

            setSubQueryActive(true);

        }
            
        
    }, []);

    const getParameterByName = (name, url) => {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    const forgetPassword = () => {
        setIsError(false);
        setLoaderActive(true);

        let userData = {
            Email: email
        }

        if (userData.Email.trim() != "") {
            axios.post('/User/ForgetPassword', userData)
                .then((response) => {

                    if (response.data.resultCode === 0) {
                        setIsError(false);
                        setMessageHeader("Success");
                        setMessageBody("There is no such e-mail address or a problem occurred.")
                        setMessageType("green");
                        setIsError(true);
                        setLoaderActive(false);
                    } else {
                        setIsError(false);
                        setMessageHeader("Error");
                        setMessageBody("There is no such e-mail address or a problem occurred.");
                        setMessageType("red");
                        setIsError(true);
                        setLoaderActive(false);
                    }
                });
        } else {
            setIsError(false);
            setMessageHeader("Error");
            setMessageBody("Please do not leave any fields blank.");
            setMessageType("red");
            setIsError(true);
            setLoaderActive(false);

        }

        
    }

    return (
        <div>
            <div id="main-page-header-box-login">

                {!subQueryActive ? <NavMenu /> : <DashboardNavBar/>}
               

                <div id="nav-sub-text-content-id" className="nav-sub-text-content">

                    <h1 className='header-animation-header-text header-text-login'>{!subQueryActive ? "Forget Password" : "Reset Password"} </h1>

                    <div className="login-panel-box">
                        <div className="login-panel-form-element">
                            <Input
                                className="login-form-input"
                                fluid
                                size='huge'
                                icon='mail'
                                placeholder='Email'
                                value={email}
                                onChange={e => { setEmail(e.target.value) }}
                            />
                        </div>

                        <div className="login-form-button">
                            <button
                                onClick={forgetPassword}
                                className="navbar-login-button main-page-start-button">Reset Password</button>
                        </div>

                        
                        <Loader id="forget-password-loader" active={loaderActive} size='huge'>Sending...</Loader>
                   
                       

                        <div className="alert-box">
                            <Transition visible={isError} animation='scale' duration={500}>
                                <Message color={messageType}>
                                    <Message.Header>{messageHeader}</Message.Header>
                                    <p>{messageBody}</p>
                                </Message>
                            </Transition>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );

}