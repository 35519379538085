import React, { useState, useContext, useEffect } from 'react';
import './userlistcomponent.css';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { Button, Modal, Icon, Header, Label, Popup, Loader, Transition } from 'semantic-ui-react';

const cookies = new Cookies();
export const UserListComponent = (props) => {

    let userType = {
        1: "blue",
        4: "orange"
    }

    const [enrollIsActive, setEnrollIsActive] = useState(props.IsEnrolled);
    const [startEnroll, setStartEnroll] = useState(false);
    const [isDeleteEnroll, setIsDeleteEnroll] = useState(false);
    const [isResetProgressModal, setIsResetProgressModal] = useState(false);

    const [selectedUserId, setSelectedUserId] = useState("");
    const [selectedSimulationId, setSelectedSimulationId] = useState("");


    const enrollUserToSimulation = (e) => {
        setStartEnroll(true);
        let userId = e.target.dataset.user;
        let simulationId = e.target.dataset.simulation

        var enrollUserData = {
            Id: null,
            UserId: userId,
            SimulationId: simulationId
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: enrollUserData
        });

        axios.post('/User/EnrollUserToSimulation', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response)
            if (response.data.resultCode == 0) {
                props.GetUsers();
                setStartEnroll(false);
            } 
        });

    }

    const deleteEnrolledUser = (e) => {
        let userId = selectedUserId;
        let simulationId = selectedSimulationId

        let deleteEnrolledUserData = {
            UserId: userId,
            SimulationId: simulationId
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: deleteEnrolledUserData
        });

        axios.post('/User/DeleteEnrollUser', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            console.log(response)
            if (response.data.resultCode == 0) {
                props.GetUsers();
                setIsDeleteEnroll(false);
            }
        });
    }


    const resetProgress = (userId) => {
        var simulation = {
            SimulationId: selectedSimulationId,
            UserId: userId
        }
        
        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: simulation
        });

        axios.post('/Simulation/ResetSimulationProgress', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            if (response.data.resultCode == 0) {
                setIsResetProgressModal(false);
            }
        });
    }


    return (
        <div className="user-list-component-container">


            <Transition visible={isDeleteEnroll} animation='scale' duration={500}>
                <Modal dimmer="blurring" className="users-delete-ask-modal" size={"mini"} open={isDeleteEnroll} onClose={() => { setIsDeleteEnroll(false) }}>
                    <Modal.Header><span className={"account-text"}>Are you sure</span></Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Header>Delete enrolled user</Header>
                        </Modal.Description>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => { setIsDeleteEnroll(false); }} >
                            CANCEL 
                        </Button>
                        <Button onClick={deleteEnrolledUser} color='red'>
                            REMOVE 
                        </Button>

                    </Modal.Actions>
                </Modal>
            </Transition>

            <Transition visible={isResetProgressModal} animation='scale' duration={500}>
                <Modal
                    dimmer="blurring"
                    className="users-delete-ask-modal"
                    size={"mini"}
                    open={isResetProgressModal}
                    onClose={() => { setIsResetProgressModal(false) }}
                >
                    <Modal.Header><span className={"account-text"}>Are you sure</span></Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Header>Reset Progress ?</Header>
                        </Modal.Description>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => { setIsResetProgressModal(false); }} >
                            CANCEL
                        </Button>
                        <Button onClick={() => { resetProgress(props.UserId) }} color='red'>
                            RESET
                        </Button>

                    </Modal.Actions>
                </Modal>
            </Transition>

            <div id={"tablerow-" + props.Id}>
                <div className="simulation-digitallica-table-row-box">

                    <div className="digitallica-table-row">

                        <div  className="simulation-digitallica-row">                {props.Name + " " + props.Surname}
                            <Label
                                style={{ marginLeft: "10px" }}
                                size="big"
                                color={userType[parseInt(props.UserType)]}                horizontal
                            >
                            {props.UserType === 1 ? "Admin" : "User"}
                            </Label> {props.IsEnrolled
                            ?
                                <Label
                                    size="big"
                                    color="violet"
                                    horizontal
                                >
                                    <Icon
                                        name="thumbs up"
                                    /> Enrolled
                                </Label> : ""}
                        </div>

                        <div className="simulation-digitallica-row">

                            {props.IsEnrolled ? 
                                <div>
                                

                                    <Popup
                                        content='Reset Progress'
                                        trigger={
                                            <Button
                                                
                                                onClick={(e) => {
                                                    setSelectedSimulationId(e.target.dataset.simulation);
                                                    setIsResetProgressModal(true)
                                                }}
                                                data-simulation={props.SimulationId}
                                                color='black'
                                                id={props.Id}
                                                icon
                                            >
                                                <Icon name='undo' />
                                        </Button>}
                                    />


                                    <Popup
                                        content='Remove User'
                                        trigger={<Button
                                            onClick={(e) => {
                                                setSelectedUserId(e.target.dataset.user);
                                                setSelectedSimulationId(e.target.dataset.simulation);
                                                setIsDeleteEnroll(true);
                                            }}
                                            color='red'
                                            data-simulation={props.SimulationId}
                                            data-user={props.UserId}
                                            icon>
                                            <Icon
                                                data-simulation={props.SimulationId}
                                                data-user={props.UserId}
                                                name='minus' />
                                        </Button>}
                                    />
                                </div>
                                :
                               
                                <div>
                                    
                                    <Popup
                                        content='Add User'
                                        trigger={
                                            <Button
                                                onClick={enrollUserToSimulation}
                                                color='green'
                                                data-simulation={props.SimulationId}
                                                data-user={props.UserId}
                                                id={props.Id}
                                                icon>
                                                {startEnroll ? <Loader className="enroll-user-loader" size="tiny" active inline /> : <Icon
                                                    name='plus'
                                                    data-simulation={props.SimulationId}
                                                    data-user={props.UserId}

                                                />}
                                                
                                            </Button>}
                                        />
                                    
                                    </div>                             
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
}