import React, { useState, useContext, useEffect } from 'react';
import './usersimulationlist.css';
import { UserSimulationListRow } from './UserSimulationListRow/UserSimulationListRow';
import { Header} from 'semantic-ui-react';

export const UserSimulationList = (props) => {
    return (
        <div>
            <div className="user-profile-simulation-report-screen-table-header">

                <div style={{ marginLeft: "28px" }} className="simulation-name-box-list">
                    <Header disabled as='h3'>Count</Header>
                </div>

                <div style={{ marginLeft: "28px" }} className="simulation-name-box-list">
                    <Header disabled as='h3'>Name</Header>
                </div>

                <div style={{ marginLeft: "57px" }} className="simulation-name-box-list">
                    <Header disabled as='h3'>Rate</Header>
                </div>

                <div style={{ marginLeft: "50px" }} className="simulation-name-box-list">
                    <Header disabled as='h3'>Point</Header>
                </div>

                <div style={{ marginLeft: "100px" }}  className="simulation-name-box-list">
                    <Header disabled as='h3'>Success info</Header>
                </div>

                <div style={{ marginLeft: "43px" }} className="simulation-name-box-list">
                    <Header disabled as='h3'>Question</Header>
                </div>

                <div style={{ marginLeft: "114px" }} className="simulation-name-box-list">
                    <Header disabled as='h3'>Ranking</Header>
                </div>

               

               
            </div> 

            
            
            {props.SimulationList.map((item, index) =>
                <UserSimulationListRow
                    key={item.id}
                    Id={item.id}
                    SimulationId={item.simulationId}
                    Name={item.simulationName}
                    IsActive={item.isActive}
                    Question={item.trueQuestionCount}
                    UserRate={item.userRate}
                    Point={item.point}
                    IsSuccess={item.isSuccess}
                    Percent={item.progress}
                    HaveSettings={item.haveSettings}
                    OtherSimulationCount={item.otherSimulationCount}
                    UserId={item.userId}
                />
            )} 
        </div>
        );
}