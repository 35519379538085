import React, { useState, useContext, useEffect } from 'react';
/*import './user-other-simulations.css';*/
import axios from 'axios';
import Cookies from 'universal-cookie';
import { Button, Icon, Header, Tab, Label, Loader, Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Navbar } from '../../../Navbar/Navbar';
import { OtherSimulationCard } from '../../../../../DashboardPages/UserProfile/UserOtherSimulations/OtherSimulationCard/OtherSimulationCard';
import { OtherSimulationList } from '../../../../../DashboardPages/UserProfile/UserOtherSimulations/OtherSimulationList/OtherSimulationList';
import { dateFormat } from '../../../../../../helper/helper';
const cookies = new Cookies();

export const QuestionReport = (props) => {

    let userTypeColor = {
        1: "blue",
        4: "orange"
    }

    //user data
    const [userType, setUserType] = useState("");
    const [isActive, setIsActive] = useState(true);
    const [id, setId] = useState("");
    const [username, setUsername] = useState("");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [lastLoginDate, setLastLoginDate] = useState("");
    const [organizationalStructure, setOrganizationalStructure] = useState("");
    const [registrationNumber, setRegistrationNumber] = useState("");
    //user data

    //simulation data
    const [simulationName, setSimulationName] = useState("");
    const [estimatedTime, setEstimatedTime] = useState("");
    const [successScore, setSuccessScore] = useState("");
    const [questionGivenPoint, setQuestionGivenPoint] = useState("");
    const [answerCount, setAnswerCount] = useState("");
    const [isActiveSimulation, setIsActiveSimulation] = useState(false);
    const [questionTime, setQuestionTime] = useState(false);

    //simulation data

    //other simulation data
    const [otherSimulationData, setOtherSimulationData] = useState([]);
    const [groupedQuestion, setGroupedQuestion] = useState([]);
    //other simulation data

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getUserInfo();
        getUserOtherSimulationReport();
    }, []);

    const getUserInfo = () => {
        console.log(props.match.params);
        var user = {
            id: props.match.params.userid
        };

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: user
        });

        axios.post('/User/GetById', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            if (response.data.resultCode == 0) {

                let responseData = response.data.data;

                setId(responseData.id);
                setUsername(responseData.username);
                setName(responseData.name);
                setSurname(responseData.surname);
                setEmail(responseData.email);
                setIsActive(responseData.isActive);
                setUserType(responseData.userType);
                setPhone(responseData.phone);
                setOrganizationalStructure(responseData.organizationalStructure);
                setRegistrationNumber(responseData.registrationNumber);
                setLastLoginDate(responseData.lastLogin);
            }
        });

    }

    let groupBy = (array, key) => {
        return array.reduce((result, obj) => {
            (result[obj[key]] = result[obj[key]] || []).push(obj);
            return result;
        }, {});
    };

    const getUserOtherSimulationReport = () => {


        var user = {
            UserId: props.match.params.userid,
            SimulationId: props.match.params.id
        };

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: user
        });

        axios.post('/Simulation/GetOtherSimulationByUserId', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {


            if (response.data.resultCode == 0) {
                let responseData = response.data.data;

               

                setEstimatedTime(responseData.estimatedTime);
                setSimulationName(responseData.name);
                setIsActiveSimulation(responseData.isActive);

                let simulationSettings = JSON.parse(responseData.settingJSON);

                if (!simulationSettings.showCorrectAnswer.isActive) {
                    window.location.href = "/simulation-detail/" + props.match.params.id
                }

            

                if (simulationSettings.showQuestionAgain.isActive)
                    setAnswerCount(parseInt(simulationSettings.showQuestionAgain.times) + 1);

                if (simulationSettings.questionTime.isActive)
                    setQuestionTime(simulationSettings.questionTime.point)
                
                setQuestionGivenPoint(simulationSettings.truePoint.point.toFixed(1));
                setSuccessScore(simulationSettings.successScore.point);



                let otherSimulationData = JSON.parse(responseData.otherSimulationReport);
                let groupedQuestions = groupBy(otherSimulationData.at(-1).Questions, "QuestionId");
                setGroupedQuestion(groupedQuestions);

                //otherSimulationData.at(-1).Questions = [groupedQuestions];


                setOtherSimulationData([otherSimulationData.at(-1)]);

                setIsLoading(false);
            }
        });
    }

    return (
        <div>
            <Navbar />

            <div className="dashboard-container-content">

                <div className="dashboard-content-box">

                    {
                        isLoading ? <Loader active={isLoading} inline='centered' size="massive" /> :
                            <>

                                <div className="simulations-header">
                                    <div className="page-header-text">
                                        <Header as='h1'>
                                            <Link to={"/simulation-detail/" + props.match.params.id}><Button className="simulation-back-button" size="massive" icon>
                                                <Icon name='left chevron' />
                                            </Button></Link>
                                            {name + " " + surname}
                                            <Label
                                                style={{ marginLeft: "10px" }}
                                                size="big"
                                                color={userTypeColor[parseInt(userType)]} horizontal
                                            >
                                                {userType === 1 ? "Admin" : "User"}
                                            </Label>
                                            <Label
                                                style={{ marginLeft: "5px" }}
                                                size="big"
                                                color={isActive === true ? "green" : "grey"} horizontal
                                            >
                                                {isActive === true ? "Active" : "Passive"}
                                            </Label>
                                        </Header>
                                    </div>
                                </div>
                                <div className="dashboard-settings-box">
                                    <div className="user-simulation-other-report-header">
                                        {!isActiveSimulation ?

                                            <Popup content='Simulation access time expired or inactive' trigger={<Button size="huge" circular />} />
                                            :
                                            <Popup content='Simulation is active' trigger={<Button size="huge" circular color="green " />} />
                                        }
                                        <Header
                                            style={{ fontSize: "4.7em" }}
                                            size='large'
                                        >
                                            {simulationName}
                                        </Header>
                                    </div>

                                    <div className="other-simulation-detail-panel">
                                        {answerCount != "" && 
                                            <OtherSimulationCard
                                                Width={"200px"}
                                                Height={"120px"}
                                                Header={"Number of replies"}
                                                Text={"X" + answerCount}
                                            />
                                        }

                                        {questionTime != "" &&
                                            <OtherSimulationCard
                                                Width={"200px"}
                                                Height={"120px"}
                                            Header={"Question Time"}
                                            Text={questionTime + " second(s)"}
                                            />
                                        }
                                        

                                        <OtherSimulationCard
                                            Width={"200px"}
                                            Height={"120px"}
                                            Header={"Success score"}
                                            Text={successScore}
                                        />

                                        <OtherSimulationCard
                                            Width={"200px"}
                                            Height={"120px"}
                                            Header={"One question score"}
                                            Text={questionGivenPoint}
                                        />

                                        <OtherSimulationCard
                                            Width={"200px"}
                                            Height={"120px"}
                                            Header={"Estimated time (minutes)"}
                                            Text={estimatedTime}
                                        />
                                    </div>

                                    <div>
                                        {otherSimulationData.map((item, index) =>
                                            <OtherSimulationList
                                                Index={parseInt(index) + 1}
                                                Point={item.Point}
                                                QuestionInfo={item.QuestionInfo}
                                                UserRateInfo={item.UserRateInfo}
                                                SuccessInfo={item.SuccessInfo}
                                                Questions={item.Questions}
                                                GroupedQuestion={groupedQuestion}
                                                CreatedDate={dateFormat(item.CreatedDate,true)}
                                                EnrolledDate={dateFormat(item.EnrolledDate,true)}
                                                FinishedDate={dateFormat(item.FinishedDate,true)}
                                                StartingDate={dateFormat(item.StartingDate,true)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </>
                    }



                </div>
            </div>
        </div>


    )

}