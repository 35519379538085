import React, { useState, useContext, useEffect } from 'react';
import { Tab } from 'semantic-ui-react'
import { Header } from 'semantic-ui-react'
import './customer.css';
import { loginContext } from '../../../../Store';
import { Navbar } from '../Navbar/Navbar';
import { CustomerAdd } from './Add/CustomerAdd';
import { CustomerList } from './List/CustomerList';

export const Customer = (props) => {

    const [isLoginControl, setLoginControl] = useContext(loginContext);

    const panes = [
        {
            menuItem: 'List',
            render: () => <Tab.Pane attached={false}><CustomerList/></Tab.Pane>,
        },
        {
            menuItem: 'Add',
            render: () => <Tab.Pane attached={false}><CustomerAdd/></Tab.Pane>,
        }
    ]

    return (
        <div>
            { isLoginControl ?
                <div>
                    <div className="super-admin-dashboard-container">
                        <div className="super-admin-dashboard-nav">
                            <Navbar ActiveMenuItem={"customer"} />
                        </div>
                        <div className="super-admin-dashboard-content">
                            <Header size='huge'>Customer</Header>

                            <div className="super-admin-content-box">
                                <Tab className="super-admin-tab-menu" menu={{ secondary: true}} panes={panes} />
                            </div>
                        </div>
                        
                    </div>
                </div>
                : ""}
        </div>
    );
}