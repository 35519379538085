import React, { useState, useEffect } from 'react';
import './welcomepage.css'
import { NavMenu } from './NavMenu';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { Input, Transition, Message, Dimmer, Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom';

const cookies = new Cookies();


export const ResetPassword = () => {

    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");
    const [isError, setIsError] = useState(false);
    const [messageHeader, setMessageHeader] = useState("");
    const [messageBody, setMessageBody] = useState("");
    const [messageType, setMessageType] = useState("");
    
    const getParameterByName = (name, url) => {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }


    const resetPassword = () => {
        setIsError(false);
        if (password.trim() != "" && passwordAgain.trim() != "") {
            if (password === passwordAgain) {
                let userData = {
                    Email: getParameterByName('email'),
                    ForgetPasswordToken: getParameterByName('token'),
                    Password: passwordAgain

                }

                axios.post('/User/ResetPassword', userData)
                    .then((response) => {
                        console.log(response);
                        if (response.data.resultCode === 0) {
                            setIsError(true);
                            setMessageHeader("Success");
                            setMessageBody("Your password has been changed. You can login with a new password.")
                            setMessageType("green");
                            setIsError(true);

                            setTimeout(function () {
                                let time = 5;
                                setInterval(function () {
                                    time--;
                                    setMessageHeader("Success");
                                    setMessageBody("You will be directed to the login page in " + time + " seconds");
                                    setMessageType("orange");

                                    if (time == 1) {
                                        window.location = window.location.origin + '/login';
                                    }
                                }, 1000);
                            }, 2000);

                        } else {
                            setIsError(true);
                            setMessageHeader("Error");
                            setMessageBody("There is no such e-mail address or a problem occurred.");
                            setMessageType("red");
                            setIsError(true);

                        }
                    });
            } else {
                setIsError(true);
                setMessageHeader("Error");
                setMessageType("red");
                setMessageBody("Passwords is not same, please check again.");
            }
        } else {
            setIsError(true);
            setMessageHeader("Error");
            setMessageType("red");
            setMessageBody("Please do not leave any fields blank.");
        }

            
        
    }

    return (
        <div>
            <div id="main-page-header-box-login">

                <NavMenu />

                <div id="nav-sub-text-content-id" className="nav-sub-text-content">

                    <h1 className='header-animation-header-text header-text-login'>Password</h1>

                    <div className="login-panel-box">
                        <div className="login-panel-form-element">
                            <Input
                                className="login-form-input"
                                fluid
                                type="password"
                                size='huge'
                                icon='lock'
                                placeholder='Password'
                                value={password}
                                onChange={e => { setPassword(e.target.value) }}
                            />
                        </div>

                        <div className="login-panel-form-element">
                            <Input
                                className="login-form-input"
                                fluid
                                type="password"
                                size='huge'
                                icon='lock'
                                placeholder='Password again'
                                value={passwordAgain}
                                onChange={e => { setPasswordAgain(e.target.value) }}
                            />
                        </div>

                        <div className="login-form-button">
                            <button
                                onClick={resetPassword}
                                className="navbar-login-button main-page-start-button">Save</button>
                        </div>

                        <div className="alert-box">
                            <Transition visible={isError} animation='scale' duration={500}>
                                <Message color={messageType}>
                                    <Message.Header>{messageHeader}</Message.Header>
                                    <p>{messageBody}</p>
                                </Message>
                            </Transition>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );

}