import React, { useContext, useEffect, useState } from 'react';
import { Input, Dropdown, Pagination, Transition, Loader, Button, Popup, Header, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ReportQuestionCard } from './ReportQuestionCard/ReportQuestionCard';
import NotFoundSVG from '../../../../images/not-found.svg';
import { OtherSimulationCard } from '../../UserProfile/UserOtherSimulations/OtherSimulationCard/OtherSimulationCard';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    AreaChart,
    Area,
    Pie,
    PieChart,
    Cell
} from "recharts";

import { dateFormat, downloadFile } from '../../../../helper/helper';
import Cookies from 'universal-cookie';
const cookies = new Cookies();


export const ReportQuestions = (props) => {

    const [questions, setQuestions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [options, setOptions] = useState([]);
    const [paginationHtml, setPaginationHtml] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [selectedSimulationId, setSelectedSimulationId] = useState("");

    //multiple selected question
    const [selectedQuestions, setSelectedQuestions] = useState([]);

    const [showSelectedQuestion, setShowSelectedQuestion] = useState(false);
    const [testHtml, setTestHtml] = useState("");
    const [showQuestionReport, setShowQuestionReport] = useState(false);
    const [selectedQuestionReportCard, setSelectedQuestionReportCard] = useState("");
    const [questionReportData, setQuestionReportData] = useState({
        question: "",
        correctAnswerCount: 0,
        correctAnswerUsers: [],
        mostLessSelectedOption: [{}],
        noAnswerCount: 0,
        noAnswerUsers: [],
        totalSelectCount: 0,
        totalSelectUsers: [],
        wrongAnswerCount: 0,
        wrongAnswerUsers: [],
        answerRate: {},
        rateProgressArray: []
    });
    const [donutChart, setDonutChart] = useState([]);
    const [progressChart, setProgressChart] = useState([]);
    //for doughnut chart
    const COLORS = ["#82ca9d", "#d88484", "#cccccc"];

    const [selectedQuestionDetailReport, setSelectedQuestionDetailReport] = useState([]);
    const [allReportClick, setAllReportClick] = useState(false);


    useEffect(() => {
       

        if (allReportClick) {
            if (selectedQuestionDetailReport.length === selectedQuestions.length) {

                setTimeout(function () {
                    excelReportQuestion(selectedQuestionDetailReport);
                    setAllReportClick(false);
                }, 500);
                
            }
        }
        

    }, [selectedQuestionDetailReport]);

    const getQuestionReportData = (simulationId) => {

        if (simulationId != undefined)
            simulationId = simulationId.length == 0 ? null : simulationId;


        var data = {
            CompanyId: cookies.get('CompanyId').toString(),
            CurrentPage: currentPage,
            SimulationId: simulationId
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: data
        });

        axios.post('/Question/GetQuestionReport', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);
            if (response.data.resultCode == 0) {
                setQuestions([]);
                let question = response.data.data;
                setQuestions(question);
                setIsLoading(false);
            }

        });
    }

    const getSimulations = () => {
        var data = {
            CompanyId: cookies.get('CompanyId').toString(),
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: data
        });

        axios.post('/Simulation/GetSimulationsByCompanyId', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log("RESPONSEEEEEEE", response);
            if (response.data.resultCode == 0) {
                let simulations = response.data.data;

                simulations.map(simulation => {
                    let optionObject = {
                        key: simulation.id,
                        text: simulation.name,
                        value: simulation.id
                    }

                    setOptions(old => [...old, optionObject]);

                });
            }
        });
    }

    const paginationGet = (filterText, simulationId) => {


        setPaginationHtml("");

        if (simulationId != undefined)
            simulationId = simulationId.length == 0 ? null : simulationId;

        if (filterText)
            filterText = filterText.trim().length > 0 ? filterText : null;

        var userPagination = {
            CompanyId: cookies.get('CompanyId').toString(),
            Id: simulationId,
            Name: filterText
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: userPagination
        });

        axios.post('/Question/GetQuestionsCountByCompanyId', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            
            if (response.data) {

                var dataCount = response.data == 0 ? 1 : response.data

                setPaginationHtml(<Pagination
                    defaultActivePage={1}
                    firstItem={null}
                    lastItem={null}
                    pointing
                    secondary
                    totalPages={parseInt(Math.ceil(dataCount / 25))}
                    onPageChange={(event, data) => setCurrentPage(parseInt(data.activePage))}
                />);
            }
        });
    }

    const search = (filterText, simulationId) => {

        setIsLoading(true);

        if (simulationId != undefined)
            simulationId = simulationId.length == 0 ? null : simulationId;

        if (filterText.trim().length < 1) {
            getQuestionReportData(simulationId);
            paginationGet();
        } else {
            var data = {
                CompanyId: cookies.get('CompanyId').toString(),
                SimulationId: simulationId,
                FilterText: filterText
            }

            var Token = JSON.stringify({
                AuthToken: cookies.get('Token'),
                Data: data
            });

            axios.post('/Question/Search', Token, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then((response) => {
                console.log(response);
                if (response.data.result.resultCode == 0) {

                    setQuestions([]);
                    let question = response.data.result.data;
                    setQuestions(question);
                    setIsLoading(false);
                    paginationGet(filterText, selectedSimulationId);
                }

            });
        }


    }


    //question detail report request
    const getDetailQuestionReport = (questionId) => {


        var data = {
            QuestionId: questionId
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: data
        });

        axios.post('/Question/GetQuestionDetailReport', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            
            if (response.data.result.resultCode == 0) {

                let data = response.data.result.data;

                

                let rateValue = (100 / (data.correctAnswerCount + data.wrongAnswerCount + data.noAnswerCount)).toFixed(1);

                setDonutChart([
                    { name: "True Answer Rate", value: (data.correctAnswerCount * rateValue) },
                    { name: "Wrong Answer Rate", value: data.wrongAnswerCount * rateValue },
                    { name: "No Answer Rate", value: data.noAnswerCount * rateValue }
                ]);


                let totalOption = 0;
                data.mostLessSelectedOption.map(option => {
                    totalOption += parseInt(option.total);
                });

                let rateProgressValue = (100 / (totalOption)).toFixed(1);
                let rateProgressArray = [];
                setProgressChart([]);

                data.mostLessSelectedOption.map(option => {
                    rateProgressArray.push({
                        name: option.option,
                        value: ((option.total) * rateProgressValue).toFixed(1)
                    });
                });

                let correctAnswerUsers = data.correctAnswerUsers;
                let mostLessSelectedOption = data.mostLessSelectedOption;
                let noAnswerUsers = data.noAnswerUsers;
                let totalSelectUsers = data.totalSelectUsers;
                let wrongAnswerUsers = data.wrongAnswerUsers;

                
                setQuestionReportData({
                    ...questionReportData,
                    question: data.question,
                    correctAnswerCount: data.correctAnswerCount,
                    correctAnswerUsers: correctAnswerUsers,
                    mostLessSelectedOption: mostLessSelectedOption,
                    noAnswerCount: data.noAnswerCount,
                    noAnswerUsers: noAnswerUsers,
                    totalSelectCount: data.totalSelectCount,
                    totalSelectUsers: totalSelectUsers,
                    wrongAnswerCount: data.wrongAnswerCount,
                    wrongAnswerUsers: wrongAnswerUsers,
                    answerRate: {
                        TrueAnswerRate: data.correctAnswerCount * rateValue || 0,
                        WrongAnswerRate: data.wrongAnswerCount * rateValue || 0,
                        NoAnswerRate: data.noAnswerCount * rateValue || 0 
                    },
                    rateProgressArray: rateProgressArray
                });

                setSelectedQuestionDetailReport(oldArray => [...oldArray, {
                    ...questionReportData,
                    question: data.question,
                    correctAnswerCount: data.correctAnswerCount,
                    correctAnswerUsers: correctAnswerUsers,
                    mostLessSelectedOption: mostLessSelectedOption,
                    noAnswerCount: data.noAnswerCount,
                    noAnswerUsers: noAnswerUsers,
                    totalSelectCount: data.totalSelectCount,
                    totalSelectUsers: totalSelectUsers,
                    wrongAnswerCount: data.wrongAnswerCount,
                    wrongAnswerUsers: wrongAnswerUsers,
                    answerRate: {
                        TrueAnswerRate: data.correctAnswerCount * rateValue || 0,
                        WrongAnswerRate: data.wrongAnswerCount * rateValue || 0,
                        NoAnswerRate: data.noAnswerCount * rateValue || 0
                    },
                    rateProgressArray: rateProgressArray
                }]);



            }

        });
    }

    const handleChange = (value) => {
        setSearchText("");
        setSelectedSimulationId(value);
        setCurrentPage(1);
        setIsLoading(true);
        getQuestionReportData(value);
        paginationGet(null, value);
    }

    useEffect(() => {
        getQuestionReportData();
        getSimulations();
        paginationGet();
    }, []);

    useEffect(() => {
        search(searchText, selectedSimulationId);
    }, [searchText]);

    useEffect(() => {
        setIsLoading(true);
        getQuestionReportData(searchText, selectedSimulationId);
    }, [currentPage]);

    useEffect(() => {
        console.log(selectedQuestions);
    }, [selectedQuestions]);



    const questionReportBoxStyle = {
        display: "flex",
        gap: "2em",
        flexWrap: "wrap",
        justifyContent: "space-around",
        alignItems: "flex-start",
        marginTop: showSelectedQuestion ? "20px" : "unset"
    }

    const questionReportDetailStyle = {
        display: "flex",
        alignItems: "flex-start",
        flexDirection:"column"
    }

    const questionFilterBox = {
        display: "flex",
        justifyContent: "space-between",
        padding: "25px",
        gap: "1em"
    }

    const multiSelectedQuestionBoxStyle = {
        display: "flex",
        justifyContent: showSelectedQuestion ? "space-between" : "flex-end",
        paddingRight: "25px",
        alignItems: "center"
    }

    const questionDetailReportBoxStyle = {
        display: "flex",
        flexWrap: "wrap",
        marginTop: "30px",
        width: "100%",
        justifyContent: "center"
    }


    const selectQuestion = (questionId, question) => {

        let isExist = selectedQuestions.filter((currentQuestion) => currentQuestion.id == questionId).length;

        if (isExist < 1)
            setSelectedQuestions([...selectedQuestions, question]);
    }

    const removeQuestion = (questionId) => {

        setSelectedQuestions(selectedQuestions.filter(obj => {
            return obj.id !== questionId;
        }));
    }

    const controllSelectedQuestion = (questionId) => {


        let isExist = selectedQuestions.filter((currentQuestion) => currentQuestion.id == questionId).length;

        if (isExist > 0)
            return true;
        
    }

    const openQuestionReport = (question) => {
        setShowQuestionReport(true);
        setShowSelectedQuestion(false);
        setSelectedQuestionReportCard(question);
        getDetailQuestionReport(question.id);
    }



    const excelReportQuestion = (data) => {
        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: data
        });

        axios.post('/Question/QuestionReportToExcel', Token, {
            headers: {
                'Content-Type': 'application/json',
            }

        }).then((response) => {
            let questionBody = JSON.parse(questionReportData.question.questionJSON).Body;
            downloadFile(response.data.result.value, "QuestionReport-" + questionBody + "--" + dateFormat(new Date(), true) + ".xlsx");
            
        });
    }





    const exportReportQuestionAll = () => {
        setSelectedQuestionDetailReport([]);
        setAllReportClick(true);

        selectedQuestions.map(async (question) => {
            await getDetailQuestionReport(question.id);
        });


      
        
    }

    

    return (
        <div>
            <div style={multiSelectedQuestionBoxStyle}>
                <Transition visible={selectedQuestions.length > 0 && !showSelectedQuestion} animation='scale' duration={500}>
                    <div>
                        <Button
                            size="big"
                            color="yellow"
                            content={"Selected Questions (" + selectedQuestions.length + ")"}
                            icon='question'
                            labelPosition='left'
                            onClick={() => { setShowSelectedQuestion(true); setShowQuestionReport(false); }}
                        />
                    </div>
                </Transition>

                <Transition visible={showSelectedQuestion} animation='scale' duration={500}>
                    <div>
                        <Header disabled as="h3">
                            Selected Questions
                        </Header>
                    </div>
                </Transition>
                

                <Transition visible={showSelectedQuestion} animation='scale' duration={500}>
                    <div>
                        

                        <Button
                            size="small"
                            icon="folder"
                            color="green"
                            onClick={exportReportQuestionAll}
                        >
                            {allReportClick ?
                                <Loader size="tiny" active inline='centered' /> :
                                "All report"
                            }
                            
                        </Button>

                        <Popup
                            trigger={
                                <Button
                                    size="small"
                                    icon='long arrow alternate right'
                                    onClick={() => { setShowSelectedQuestion(false); getQuestionReportData(); }}
                                    color={"black"}
                                />}
                            content={"close"}
                            basic
                        />
                    </div>
                </Transition>
            </div>

            <Transition visible={!showSelectedQuestion && !showQuestionReport} animation='scale' duration={500}>
                <div>
                    <div style={questionFilterBox}>
                        <div style={{ width: "90%" }}>
                            <Input
                                size="large"
                                fluid
                                icon='search'
                                placeholder='Search...'
                                value={searchText}
                                onChange={(e) => { setSearchText(e.target.value) }}
                            />
                        </div>
                        <div>
                            <Dropdown
                                placeholder='Select Simulation'
                                size="big"
                                clearable
                                options={options}
                                selection
                                onChange={(e, data) => { handleChange(data.value) }}
                            />
                        </div>
                    </div>
                </div>
            </Transition>


            <Transition visible={isLoading} animation='scale' duration={500}>
                <div>
                    <Loader size="huge" active inline='centered' />
                </div>
            </Transition>

            <Transition visible={!isLoading && !showSelectedQuestion && !showQuestionReport} animation='scale' duration={500}>
                <div>
                    <div style={questionReportBoxStyle}>
                        {questions.map(question => (
                            <ReportQuestionCard
                                key={question.id}
                                QuestionData={question}
                                QuestionId={question.id}
                                SelectQuestion={selectQuestion}
                                RemoveQuestion={removeQuestion}
                                ControllSelectedQuestion={controllSelectedQuestion}
                                OpenQuestionReport={openQuestionReport}
                            />
                        ))}
                    </div>
                </div>
            </Transition>

            <Transition visible={showQuestionReport} animation='scale' duration={500}>
                <div>
                    <div style={questionReportDetailStyle}>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}>
                            <div>
                                <Button
                                    onClick={() => { setShowQuestionReport(false); }}
                                    className="simulation-back-button"
                                    size="massive"
                                    icon>
                                    <Icon
                                        name='left chevron'
                                    />
                                </Button>
                            </div>
                           
                            <div>
                                <Popup
                                    content='Export Excel Report'
                                    trigger={
                                        <Button
                                            onClick={() => { excelReportQuestion([questionReportData]); }}
                                            size="big"
                                            color="green"
                                            icon>
                                            <Icon
                                                name='file excel'
                                            />
                                        </Button>
                                    }
                                />
                                
                            </div>

                            

                        </div>

                        <div style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center"
                        }}>
                            <ReportQuestionCard
                                Width={"50%"}
                                ReportDetail={true}
                                key={selectedQuestionReportCard.id}
                                QuestionData={selectedQuestionReportCard}
                                Selected={true}
                                QuestionId={selectedQuestionReportCard.id}
                                ControllSelectedQuestion={controllSelectedQuestion}
                                OpenQuestionReport={openQuestionReport}
                            />
                            
                        </div>


                        <div style={ questionDetailReportBoxStyle}>

                            <OtherSimulationCard
                                OtherSimulationCard={true}
                                Width={"200px"}
                                Height={"120px"}
                                Header={"Total Select Count"}
                                ModalHeader={"Total Select Users"}
                                Text={questionReportData.totalSelectCount}
                                FontSize={"1.2em"}
                                AlignItems={"flex-start"}
                                Height={"85px"}
                                Padding={"27px"}
                                HeaderMarginBottom={"0"}
                                Margin={"10px"}
                                QuestionReport={true}
                                QuestionUsers={questionReportData.totalSelectUsers}
                            />

                            <OtherSimulationCard
                                OtherSimulationCard={true}
                                Width={"200px"}
                                Height={"120px"}
                                Header={"Correct Answer Count"}
                                ModalHeader={"Correct Answer Users"}
                                Text={questionReportData.correctAnswerCount}
                                FontSize={"1.2em"}
                                AlignItems={"flex-start"}
                                Height={"85px"}
                                Padding={"27px"}
                                HeaderMarginBottom={"0"}
                                Margin={"10px"}
                                QuestionReport={true}
                                QuestionUsers={questionReportData.correctAnswerUsers}
                            />

                            <OtherSimulationCard
                                OtherSimulationCard={true}
                                Width={"200px"}
                                Height={"120px"}
                                Header={"Wrong Answer Count"}
                                ModalHeader={"Wrong Answer Users"}
                                Text={questionReportData.wrongAnswerCount}
                                FontSize={"1.2em"}
                                AlignItems={"flex-start"}
                                Height={"85px"}
                                Padding={"27px"}
                                HeaderMarginBottom={"0"}
                                Margin={"10px"}
                                QuestionReport={true}
                                QuestionUsers={questionReportData.wrongAnswerUsers}
                            />

                            <OtherSimulationCard
                                OtherSimulationCard={true}
                                Width={"200px"}
                                Height={"120px"}
                                Header={"No Answer Count"}
                                ModalHeader={"No Answer Users"}
                                Text={questionReportData.noAnswerCount}
                                FontSize={"1.2em"}
                                AlignItems={"flex-start"}
                                Height={"85px"}
                                Padding={"27px"}
                                HeaderMarginBottom={"0"}
                                Margin={"10px"}
                                QuestionReport={true}
                                QuestionUsers={questionReportData.noAnswerUsers}
                            />
                        </div>

                        <div style={{
                            width: "100%",
                           
                        }}>
                            {questionReportData.mostLessSelectedOption.length > 1 ?
                                <div style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                <OtherSimulationCard
                                    Width={"200px"}
                                    Height={"120px"}
                                    Header={"Much Selected Option"}
                                    ModalHeader={"No Answer Users"}
                                        Text={questionReportData.mostLessSelectedOption[0].option || ""}
                                    FontSize={"1.2em"}
                                    AlignItems={"flex-start"}
                                    Height={"85px"}
                                    Padding={"27px"}
                                    HeaderMarginBottom={"0"}
                                    Margin={"10px"}
                                    QuestionReport={true}
                                    QuestionUsers={questionReportData.noAnswerUsers}
                                />

                                 <OtherSimulationCard
                                    Width={"200px"}
                                    Height={"120px"}
                                    Header={"Less Selected Option"}
                                    ModalHeader={"No Answer Users"}
                                    Text={questionReportData.mostLessSelectedOption[(questionReportData.mostLessSelectedOption.length - 1)].option ||""}
                                    FontSize={"1.2em"}
                                    AlignItems={"flex-start"}
                                    Height={"85px"}
                                    Padding={"27px"}
                                    HeaderMarginBottom={"0"}
                                    Margin={"10px"}
                                    QuestionReport={true}
                                    QuestionUsers={questionReportData.noAnswerUsers}
                                />

                                </div>

                                : questionReportData.mostLessSelectedOption.length > 0 &&

                                <div style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                <OtherSimulationCard
                                    Width={"200px"}
                                    Height={"120px"}
                                    Header={"Much Selected Option"}
                                    ModalHeader={"No Answer Users"}
                                    Text={questionReportData.mostLessSelectedOption[0].option || ""}
                                    FontSize={"1.2em"}
                                    AlignItems={"flex-start"}
                                    Height={"85px"}
                                    Padding={"27px"}
                                    HeaderMarginBottom={"0"}
                                    Margin={"10px"}
                                    QuestionReport={true}
                                    QuestionUsers={questionReportData.noAnswerUsers}
                                />
                                </div>
                            }
                        </div>



                        <div style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            marginTop: "70px"

                        }}>
                            <div style={{textAlign: "center", height:"300px"}}>
                                <Header disabled as='h2'>Response Rate</Header>
                                <PieChart width={300} height={400} style={{ top: "-110px" }}>
                                    <Pie
                                        dataKey="value"
                                        data={donutChart}

                                        innerRadius={40}
                                        outerRadius={80}
                                    >
                                        {donutChart.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                </PieChart>
                            </div>

                        </div>





                        <div style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center"
                        }}>
                            <div style={{ textAlign: "center" }}>
                                <Header disabled as='h1'>Percentage of Options</Header>

                                <BarChart
                                    width={700}
                                    height={300}
                                    data={progressChart}
                                    margin={{
                                        top: 10,
                                        right: 30,
                                        left: 20,
                                        bottom: 5
                                    }}
                                    barSize={20}
                                >
                                    <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <Bar dataKey="value" fill="#8884d8" background={{ fill: "#eee" }} />
                                </BarChart>

                            </div>

                        </div>
                    </div>
                </div>
            </Transition>


            <Transition visible={showSelectedQuestion} animation='scale' duration={500}>
                <div>
                    <div style={questionReportBoxStyle}>
                        {
                            selectedQuestions.map(question => (
                                <ReportQuestionCard
                                    key={question.id}
                                    QuestionData={question}
                                    Selected={true}
                                    SelectQuestion={selectQuestion}
                                    RemoveQuestion={removeQuestion}
                                    QuestionId={question.id}
                                    ControllSelectedQuestion={controllSelectedQuestion}
                                    OpenQuestionReport={openQuestionReport}
                                />
                            ))
                        }
                    </div>
                </div>
            </Transition>



            <Transition visible={questions.length < 1} animation='scale' duration={500}>
                <div>
                    <div className="no-users-found-box">
                        <div>
                            <h1>No Questions Found</h1>
                        </div>
                        <div>
                            <img width="20%" src={NotFoundSVG} />
                        </div>
                    </div>
                </div>
            </Transition>

            {!showSelectedQuestion && !showQuestionReport ?

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "20px",
                        width: "100%"
                    }}>
                    {paginationHtml}
                </div>

                :

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "20px",
                        width: "100%"
                    }}>

                </div>
            }

        </div>

    )
}