import React, { useState, useContext, useEffect } from 'react';
import './simulationslist.css';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { loginContext } from '../../../../Store';
import { Button, Transition, Icon, Header, Card, Input, Loader, Pagination, Confirm, Modal } from 'semantic-ui-react'
import { DashboardNavBar } from '../../Dashboard/DashboardNavBar/DashboardNavBar';
import { SimulationsCard } from './SimulationsCard/SimulationsCard';
import { Link } from 'react-router-dom';
import NotFoundSVG from '../../../../images/not-found.svg';

const cookies = new Cookies();

export const SimulationsList = (props) => {

    //context ----
    const [isLoginControl, setLoginControl] = useContext(loginContext);
    //context ----

    const [filterInputText, setFilterInputText] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [simulationsData, setSimulationsData] = useState([]);
    const [loaderActive, setLoaderActive] = useState(false);
    const [showNoSimulationsFound, setShowNoSimulationsFound] = useState(false);
    const [paginationHtml, setPaginationHtml] = useState("");
    const [isConfirmShown, setIsConfirmShown] = useState(false);
    const [toBeDeletedSimulationId, setToBeDeletedSimulationId] = useState('');

    var filterText = filterInputText;

    useEffect(() => {

        if (!isLoginControl) {
            window.location.href = "/";
        }

        getSimulations();
        paginationGet();

    }, []);

    useEffect(() => {
        getSimulations();
    }, [currentPage]);

    const getData = () => {
        var data = {};
        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: data
        });
        axios.post('/Simulation/GetData', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);
        });
    }

    const getSimulations = () => {
        setLoaderActive(true);
        var data = {
            currentPage: currentPage,
            CompanyId: cookies.get('CompanyId').toString()
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: data
        });

        axios.post('/Simulation/GetSimulations', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            console.log(response);

            let simulations = response.data.data;

            setSimulationsData(simulations);
            setLoaderActive(false);

            if (simulations.length == 0)
                setShowNoSimulationsFound(true);
            else
                setShowNoSimulationsFound(false);

        });
    }

    const paginationGet = () => {
        console.log(props.match.params.id);
        var simulationsPagination = {
            CompanyId: cookies.get('CompanyId').toString()
        }
        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: simulationsPagination
        });

        axios.post('/Simulation/GetCount', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);

            if (response.data.count) {
                setPaginationHtml(<Pagination
                    defaultActivePage={1}
                    firstItem={null}
                    lastItem={null}
                    pointing
                    secondary
                    totalPages={parseInt(Math.ceil(response.data.count / 25))}
                    onPageChange={(event, data) => setCurrentPage(parseInt(data.activePage))}
                />);
            }
        });
    }

    const filterSimulationsOnChange = () => {
        console.log(filterText);

        if (filterText.trim().length < 1) {
            getSimulations();
        }
        else {
            if (filterText.trim().length > 1) {
                let filterItems = {
                    CompanyId: cookies.get('CompanyId').toString(),
                    FilterText: filterText.toString().trim()
                }

                var Token = JSON.stringify({
                    AuthToken: cookies.get('Token'),
                    Data: filterItems
                });

                axios.post('/Simulation/Search', Token, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).then((response) => {
                    console.log(response.data.data.result);
                    if (response.data.data.result) {
                        setShowNoSimulationsFound(false);
                        let simulationsList = response.data.data.result;
                        setSimulationsData(simulationsList);

                        if (simulationsList.length == 0)
                            setShowNoSimulationsFound(true);
                    }
                });
            } else {
                getSimulations();
            }
        }
    }

    const dateFormat = (dbDate) => {
        const date = new Date(dbDate)
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        // add a leading 0 if the number is less than 10. like 9 to 09
        day < 10 && (day = `0${day}`)
        month < 10 && (month = `0${month}`)
        const newFormat = `${day}-${month}-${year}`;
        return newFormat;
    }

    const openConfirmation = (id) => {
        setToBeDeletedSimulationId(id);
        setIsConfirmShown(true);
    }

    const deleteSimulation = () => {
        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: {
                SimulationId: toBeDeletedSimulationId
            }
        });
        axios.post('/Simulation/Delete', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);
            if (response.data.resultCode == 0) {
                getSimulations();
                paginationGet();
                cancel();
            }
        });
    }

    const cancel = () => {
        setIsConfirmShown(false);
        setToBeDeletedSimulationId('');
    }

    return (
        <div>
            <Modal onClose={cancel} open={isConfirmShown} className='message-modal'>
                <Modal.Header>Uyarı</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <p>Silmek istediğinize emin misiniz?</p>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={cancel}>
                        İptal
                    </Button>
                    <Button
                        content="Evet"
                        labelPosition='right'
                        icon='trash alternate'
                        onClick={deleteSimulation}
                        negative
                    />
                </Modal.Actions>
            </Modal>
            <DashboardNavBar />
            <div className="dashboard-container-content">
                <div className="dashboard-content-box">
                    <div className="simulations-header">
                        <div className="page-header-text">
                            <Header as='h1' disabled>
                                <Link to="/dashboard"><Button className="simulation-back-button" size="massive" icon>
                                    <Icon name='left chevron' />
                                </Button></Link>
                                Simulations
                            </Header>
                        </div>
                        <div className="simulations-search-field">
                            <Input

                                className="search-input"
                                size="big"
                                placeholder="Search.."
                                value={filterInputText}
                                onChange={o => { setFilterInputText(o.target.value); filterText = o.target.value; filterSimulationsOnChange(); }}
                            />
                        </div>
                        <div className="add-new-simulation-box">
                            <Link to='add-simulation'>
                                <Button size='big' color='green' icon labelPosition='left'>
                                    <Icon name='plus' />
                                    Add new simulation
                                </Button>
                            </Link>
                        </div>
                    </div>
                    <Loader active={loaderActive} size='huge'>Loading...</Loader>
                    <Transition visible={showNoSimulationsFound} animation='scale' duration={500}>
                        <div className="no-users-found-box">
                            <div>
                                <h1>No Simulations Found</h1>
                            </div>
                            <div>
                                <img width="20%" src={NotFoundSVG} />
                            </div>
                        </div>
                    </Transition>

                    <div className="simulations-card-group-box">                        
                        <Card.Group>
                            {simulationsData.map((item, index) =>

                                <SimulationsCard
                                    IsActive={item.isActive}
                                    Clickable={true}
                                    key={item.id}
                                    Id={item.id}
                                    Name={item.name}
                                    setIsConfirmShown={setIsConfirmShown}
                                    openConfirmation={openConfirmation}
                                    Desc={item.description.length > 80 ? item.description.slice(0, 70) + "..." : item.description}
                                    Image={item.image ? item.image : null}
                                    CreatedDate={dateFormat(item.createdDate)}
                                />
                            )}
                        </Card.Group>
                    </div>
                    <div className="simulation-pagination-box">
                        <div>
                            {paginationHtml}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}