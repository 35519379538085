import React, { useContext, useEffect, useState } from 'react';
import { Popup, Icon, Table, Label, Progress, Header, Loader, Transition } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { OtherSimulationQuestion } from '../../../UserProfile/UserOtherSimulations/OtherSimulationQuestion/OtherSimulationQuestion';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    AreaChart,
    Area,
    Pie,
    PieChart,
    Cell
} from "recharts";
import Cookies from 'universal-cookie';
const cookies = new Cookies();


export const ReportSimulationsQuestions = (props) => {


    const otherSimulationQuestionBoxStyle = {
        display: "flex",
        justifyContent: "space-around",
        marginTop: "30px",
        flexWrap: "wrap",
        flexDirection:"column"
    }

    const questionWithChartContainer = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",

    }


    return (
        <div>

            <div style={otherSimulationQuestionBoxStyle}>
                {props.Questions.map((item, index) =>
                    <div style={ questionWithChartContainer}>
                    <OtherSimulationQuestion
                            Index={parseInt(index) + 1}
                            Question={JSON.parse(item.questionJSON)}
                            Width={"40%"}

                    />

                        <div style={{ marginTop: "30px"}}>
                            <BarChart
                                width={500}
                                height={400}
                                data={item.userAnswerQuestion}
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 20,
                                    bottom: 5
                                }}
                                barSize={20}
                            >
                                <XAxis dataKey="option" scale="point" padding={{ left: 10, right: 10 }} />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <CartesianGrid strokeDasharray="3 3" />
                                <Bar dataKey="count" fill="#8884d8" background={{ fill: "#eee" }} />
                            </BarChart>    
                        </div>
                    </div>
                )}

            </div>


        </div>
    )
}