import React, { useContext, useEffect, useState } from 'react';
import './report.css';
import { Button, Header, Icon, Transition, Tab, Container, Input, Loader, Popup, Label, Segment, Dimmer } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { DashboardNavBar } from '../Dashboard/DashboardNavBar/DashboardNavBar';
import { ReportCard } from './ReportsCard/ReportCard';
import { ReportDataRow } from './ReportDataRow/ReportDataRow';
import { ReportUser } from './ReportUser/ReportUser';
import { ReportSimulation } from './ReportSimulation/ReportSimulation';
import { ReportUserFilter } from './ReportFilter/ReportUserFilter';
import { ReportSimulationFilter } from './ReportFilter/ReportSimulationFilter';
import { ReportSystem } from './ReportSystem/ReportSystem';
import { ReportQuestions } from './ReportQuestions/ReportQuestions';
import { downloadFile, dateFormat } from '../../../helper/helper';
import axios from 'axios';
import Cookies from 'universal-cookie';



const cookies = new Cookies();

export const Reports = (props) => {

    const [systemReportOn, setSystemReportOn] = useState(true);
    const [seachableVisible, setSearchableVisible] = useState(false);
    const [reportDetailVisible, setReportDetailVisible] = useState(false);
    const [reportCardName, setReportCardName] = useState("System");
    const [data, setData] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [clickedRow, setClickedRow] = useState("");
    const [filterInputText, setFilterInputText] = useState("");
    const [systemReportData, setSystemReportData] = useState("");

    const [multiSelectedUsersReportExcel, setMultiSelectedUsersReportExcel] = useState([]);

    const [whenSelectDataLoading, setWhenSelectDataLoading] = useState(false);
    const [multiSelectedSimulationReportExcel, setMultiSelectedSimulationReportExcel] = useState([]);
    const [systemReportExcelData, setSystemReportExcelData] = useState();
   

    const [axiosOption] = useState({
        Users: {
            controller: "User",
            function: "GetByCompanyIdAllUser",
            search: "Search"
        },
        Simulations: {
            controller: "Simulation",
            function: "GetByCompanyIdAllSimulation",
            search: "Search"
        },
        Questions: {
            controller: "Question",
            function: "GetQuestionReport"
        },
        System: {
            controller: "System",
            function: "GetSystemReport"
        }

    });


    const [filterData, setFilterData] = useState({
        Users: {
            isActive: false,
            userType: null
        },
        Simulations: {
            isActive: false,
            startingDate: null,
            dueDate: null
        }
    });


    const [filterBoxOpen, setFilterBoxOpen] = useState(false);
    const [filterContentDOM, setFilterContentDOM] = useState("");
    const [isVisibleRemoveFilter, setIsVisibleRemoveFilter] = useState(false);

    const [selectedDataRow, setSelectedDataRow] = useState([]);

    useEffect(() => {

        console.log(selectedDataRow);
    }, [selectedDataRow]);

    useEffect(() => {

        console.log(multiSelectedSimulationReportExcel);
    }, [multiSelectedSimulationReportExcel]);

    const reportsHeaderBoxStyle = {
        width: "100px",
        height: "290px",
        boxShadow: "rgb(149 157 165 / 20%) 0px 4px 12px",
        borderRadius: "10px",
        padding: "15px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center"
    }

    const reportBoxStyle = {
        display: "flex",
        flexDirection: "row",
        flexWarp: "wrap"
    }

    const [userSearchableWidth, setUserSearchableWidth] = useState("99.9%");
    const [wideView, setWideView] = useState(false);

    useEffect(() => {

        if (wideView) {
            setUserSearchableWidth("100%");
            setReportDetailVisible(false);
        } else
            setUserSearchableWidth("300px");
    }, [wideView]);

    const searchableContentStyle = {
        boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
        borderRadius: "10px",
        padding: "29px",
        display: "flex",
        width: reportCardName == "Simulations" ? "400px" : reportCardName == "Users" ? userSearchableWidth : "100%",
        marginLeft: "20px",
        transition: "all 1s",
        height: systemReportOn || reportCardName == "Questions" ? "auto" : "633px"
    }

    const reportDetailContentStyle = {
        boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
        borderRadius: "10px",
        padding: "29px",
        display: wideView == true ? "none" : "flex",
        width: "70%",
        minHeight: "600px",
        marginLeft: "20px",
        transition: "all 1s",
        position: "relative"
    }

    const selectedDataRowContainerStyle = {
        position: "absolute",
        top: "-79px",
        left: "0",
        padding: "15px",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    }

    const dataRowContainerStyle = {
        height: "395px",
        overflow: "auto",
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "20px",
        padding: "10px",

    }

    const searchableContentFilter = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
    }

    const filterContentContainer = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    }

    const filterContentBox = {
        height: "500px",
        display: filterBoxOpen ? "flex" : "none",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "10px",
        marginTop: "15px",
        position: "absolute",
        width: reportCardName == "Simulations" ? "343px" : "244px",
        margin: "0 auto"

    }



    const setReportCardNameFunc = (name) => {
        setReportCardName(name);
        setClickedRow("");
        setWideView(true);
    }

    const handleClickedRow = (id) => {
        setClickedRow(id);
        setFilterBoxOpen(false);

        setWideView(false);
    }

    useEffect(() => {
        setFilterBoxOpen(false);
        setSelectedDataRow([]);

        filterData.Users.isActive = false;
        filterData.Users.userType = null;

        filterData.Simulations.isActive = false;
        filterData.Simulations.startingDate = null;
        filterData.Simulations.dueDate = null;

        if (reportCardName != "Questions")
            getData();

        switch (reportCardName) {
            case "Simulations":
                setSystemReportOn(false);
                setFilterContentDOM(
                    <ReportSimulationFilter
                        FilterData={filterData}
                        SetFilterData={setFilterData}
                        GetData={getData}
                    />
                )
                break;

            case "Users":
                setSystemReportOn(false);
                setFilterContentDOM(
                    <ReportUserFilter
                        FilterData={filterData}
                        SetFilterData={setFilterData}
                        SetFilterBoxOpen={setFilterBoxOpen}
                        GetData={getData}
                        IsLoadingData={isLoadingData}
                    />
                )
                break;

            case "System":
                setReportDetailVisible(false);
                setSystemReportOn(true);
                break;

            default:
                setFilterContentDOM(<div>users filter</div>)
        }

    }, [reportCardName]);

    useEffect(() => {

        let filterItems = {};

        switch (reportCardName) {
            case "Simulations":
                filterItems = {
                    CompanyId: cookies.get('CompanyId').toString(),
                    FilterText: filterInputText.toString().trim(),
                    IsActive: filterData.Simulations.isActive,
                    StartingDate: filterData.Simulations.startingDate,
                    DueDate: filterData.Simulations.dueDate
                }
                break;

            case "Users":
                filterItems = {
                    CompanyId: cookies.get('CompanyId').toString(),
                    FilterText: filterInputText.toString().trim(),
                    IsActive: filterData.Users.isActive,
                    UserType: filterData.Users.userType || 0
                }
                break;

            default:
                filterItems = {
                    Id: cookies.get('CompanyId').toString(),


                }
        }

        if (filterInputText.trim().length < 1) {
            getData(true, filterItems, isVisibleRemoveFilter);
        }
        else {

            var Token = JSON.stringify({
                AuthToken: cookies.get('Token'),
                Data: filterItems
            });

            axios.post('/' + axiosOption[reportCardName].controller + '/' + axiosOption[reportCardName].search, Token, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then((response) => {

                if (response.data.data.result) {
                    setData(response.data.data.result)
                } else {

                }
            });

        }
    }, [filterInputText]);

    const getData = (byFilterText, filterData, removeFilterVisible) => {
        if (reportCardName != "") {
            setIsVisibleRemoveFilter(false);

            setData([]);
            setIsLoadingData(true);

            if (!byFilterText) {
                setReportDetailVisible(false);
            }

            let data = {};

            if (filterData != null) {
                data = filterData;

                if (removeFilterVisible)
                    setIsVisibleRemoveFilter(true);
            }
            else {

                if (reportCardName == "System")
                    data = {
                        Id: cookies.get('CompanyId').toString()
                    }

                else
                    data = {
                        CompanyId: cookies.get('CompanyId').toString()
                    }

            }


            var Token = JSON.stringify({
                AuthToken: cookies.get('Token'),
                Data: data
            });

            axios.post('/' + axiosOption[reportCardName].controller + '/' + axiosOption[reportCardName].function, Token, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then((response) => {
                console.log(response);
                if (response.data.resultCode == 0) {
                    if (reportCardName != "System") {
                        setData(response.data.data);
                        setIsLoadingData(false);
                        setFilterBoxOpen(false);
                    }

                    else {
                        setSystemReportData(
                            <ReportSystem
                                Data={response.data.data}
                            />
                        );

                        setSystemReportExcelData(response.data.data)
                    }

                } else {

                }
            });
        }
    }

    const removeAllFilter = () => {


        setFilterData({
            Users: {
                isActive: false,
                userType: null
            },
            Simulations: {
                isActive: false,
                startingDate: null,
                dueDate: null
            }
        });

        getData();

    }

    const exportAllExcelReport = () => {

        const distinctItems = [...new Map(multiSelectedUsersReportExcel.map(item => [item.User["id"], item])).values()];

        var filteredArray = [];
        distinctItems.filter((item) => {
            var found = selectedDataRow.find((a) => a.id === item.User.id);

            if (found) {
                filteredArray.push(item);
            }
        });

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: filteredArray
        });

        axios.post('/User/UserReportExportAll', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            downloadFile(response.data.result.value, "UserReportAll.xlsx");
        });
    }

    const exportAllSimulationReport = () => {
        const distinctItems = [...new Map(multiSelectedSimulationReportExcel.map(item => [item["simulationId"], item])).values()];

        var filteredArray = [];
        distinctItems.filter((item) => {
            var found = selectedDataRow.find((a) => a.id === item.simulationId);
      
            if (found) {
                filteredArray.push(item);
            }
        });

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: filteredArray
        });

        axios.post('/Simulation/SimulationReportToExcel', Token, {
            headers: {
                'Content-Type': 'application/json',
            }

        }).then((response) => {
            downloadFile(response.data.result.value, "SimulationReportAll.xlsx");
        });
    }

    const systemReportToExcel = () => {
        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: systemReportExcelData
        });

        axios.post('/System/SystemReportToExcel', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            downloadFile(response.data.result.value, "SystemReport" + "--" + dateFormat(new Date(),true) +
                ".xlsx");
        });
    }


    return (
        <div>
            <DashboardNavBar />
            <div className="dashboard-container-content">

                <div className="dashboard-content-box">

                    <div className="simulations-header">
                        <div className="page-header-text">
                            <Header as='h1' disabled>
                                <Link to="/dashboard"><Button className="simulation-back-button" size="massive" icon>
                                    <Icon name='left chevron' />
                                </Button></Link>
                                Reports
                             </Header>
                        </div>
                    </div>

                    <div
                        style={reportBoxStyle}
                        className="dashboard-settings-box"
                    >
                        <div style={reportsHeaderBoxStyle}>

                            <ReportCard
                                Icon="user"
                                Name="Users"
                                SetSeachableVisible={setSearchableVisible}
                                SetReportCardNameFunc={setReportCardNameFunc}
                                ReportCardName={reportCardName}
                            />

                            <ReportCard
                                Icon="chess knight"
                                Name="Simulations"
                                SetSeachableVisible={setSearchableVisible}
                                SetReportCardNameFunc={setReportCardNameFunc}
                                ReportCardName={reportCardName}
                            />

                            <ReportCard
                                Icon="question"
                                Name="Questions"
                                SetSeachableVisible={setSearchableVisible}
                                SetReportCardNameFunc={setReportCardNameFunc}
                                ReportCardName={reportCardName}
                            />

                            <ReportCard
                                Icon="setting"
                                Name="System"
                                SetSeachableVisible={setSearchableVisible}
                                SetReportCardNameFunc={setReportCardNameFunc}
                                ReportCardName={reportCardName}
                            />

                        </div>

                        <Transition
                            animation='scale'
                            visible={seachableVisible || systemReportOn}
                            duration={500}
                        >
                            <div
                                style={searchableContentStyle}>
                                <div style={searchableContentFilter}>

                                    <Header
                                        disabled
                                        as='h2'
                                    >
                                        {filterBoxOpen ? "Filter" : reportCardName}
                                    </Header>

                                    <div style={{ display: "flex", gap: "0.5em" }}>


                                        <Transition visible={isVisibleRemoveFilter && systemReportOn == false} animation='scale' duration={500}>
                                            <div>
                                                <Popup
                                                    content='Remove all filter'
                                                    trigger={
                                                        <Button
                                                            size="small"
                                                            color={"red"}
                                                            icon='redo'
                                                            onClick={removeAllFilter}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </Transition>

                                        <Transition visible={!systemReportOn && reportCardName != "Questions"} animation='scale' duration={500}>
                                            <div>
                                                <Popup
                                                    content='Filter'
                                                    trigger={
                                                        <Button
                                                            size="small"
                                                            color={filterBoxOpen ? "black" : ""}
                                                            icon='filter'
                                                            onClick={() => {
                                                                setFilterBoxOpen(!filterBoxOpen);
                                                            }}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </Transition>

                                        <Transition visible={reportCardName == "Users" && !wideView} animation='scale' duration={500}>
                                            <div>
                                                <Popup
                                                    content='Wide view'
                                                    trigger={
                                                        <Button
                                                            size="small"
                                                            color={"green"}
                                                            icon='long arrow alternate right'
                                                            onClick={() => {
                                                                setWideView(true)

                                                            }}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </Transition>

                                    </div>

                                </div>

                                <Transition
                                    animation='scale'
                                    visible={filterBoxOpen}
                                    duration={500}
                                >
                                    <div
                                        style={filterContentContainer}
                                    >
                                        <div style={filterContentBox}>
                                            <div>
                                                {filterContentDOM}
                                            </div>


                                        </div>


                                    </div>


                                </Transition>

                                <Transition
                                    animation='scale'
                                    visible={!filterBoxOpen && !systemReportOn && reportCardName != "Questions"}
                                    duration={500}
                                >
                                    <div style={{ marginTop: "20px" }}>
                                        <div style={{ marginTop: "20px", marginBottom: "20px" }}>

                                            {filterData.Users.userType != null ?
                                                <Label
                                                    color="black" as='a'
                                                    content={filterData.Users.userType == 0 ? "All Users" : filterData.Users.userType == 1 ? "Admin" : "User"} icon={<Icon name="user" />}
                                                /> : ""}



                                            {filterData.Users.isActive ?
                                                <Label
                                                    color="black" as='a'
                                                    content='Active' icon={<Icon name="circle" color="green" />}
                                                /> : ""}

                                            {filterData.Simulations.isActive ?
                                                <Label
                                                    color="black" as='a'
                                                    content='Active' icon={<Icon name="circle" color="green" />}
                                                /> : ""}

                                            {filterData.Simulations.startingDate ?
                                                <Label
                                                    color="black" as='a'
                                                    content='Starting Date' icon={<Icon name="calendar alternate" />}
                                                /> : ""}

                                            {filterData.Simulations.dueDate ?
                                                <Label
                                                    color="black" as='a'
                                                    content='End Date' icon={<Icon name="calendar alternate" />}
                                                /> : ""}

                                            {!filterData.Simulations.isActive && !filterData.Simulations.startingDate && !filterData.Simulations.dueDate && isVisibleRemoveFilter && reportCardName == "Simulations" ?
                                                <Label
                                                    color="black" as='a'
                                                    content='All Simulations' icon={<Icon name="chess knight" />}
                                                /> : ""}


                                        </div>


                                        <Input
                                            size="big"
                                            fluid
                                            icon='search'
                                            placeholder='Search...'
                                            onChange={(e) => { setFilterInputText(e.target.value) }}
                                        />

                                        {isLoadingData ?

                                            <Loader
                                                style={{ marginTop: "20%" }}
                                                size="huge"
                                                active={isLoadingData}
                                                inline='centered'
                                            /> :


                                            <div style={dataRowContainerStyle}>
                                                <div style={{
                                                    display: "flex",
                                                    gap: "1em",
                                                    justifyContent: "space-between",
                                                    width: "90%",
                                                }}>
                                                    {reportCardName == "Users" &&
                                                        <>
                                                        
                                                            <div
                                                                style={{
                                                                    width: "170px",
                                                                    fontWeight: "bold"
                                                            }}
                                                        >{whenSelectDataLoading ? "Loading.." : "Fullname"}</div>

                                                            {wideView && <>
                                                                <div style={{
                                                                    width: "170px",
                                                                    fontWeight: "bold"
                                                                }}>Username</div>
                                                                <div style={{
                                                                    width: "170px",
                                                                    fontWeight: "bold"
                                                                }}>Email</div>
                                                                <div style={{
                                                                    width: "170px",
                                                                    fontWeight: "bold"
                                                                }}>Organizational Structure</div>
                                                                <div style={{
                                                                    width: "170px",
                                                                    fontWeight: "bold"
                                                                }}>phone</div>
                                                                <div style={{
                                                                    width: "170px",
                                                                    fontWeight: "bold"
                                                                }}>Registration Number</div>
                                                                <div style={{
                                                                    width: "170px",
                                                                    fontWeight: "bold"
                                                                }}>User Type</div>

                                                            </>}


                                                        </>

                                                    }
                                                </div>

                                                <Transition
                                                    visible={!whenSelectDataLoading}
                                                    animation='scale'
                                                    duration={500}
                                                    
                                                >
                                                    <div style={{
                                                        width: "100%"
                                                    }}>
                                                        {data.length > 0  ?
                                                            data.map((item, index) =>
                                                                <ReportDataRow
                                                                    key={item.id}
                                                                    SelectedRow={clickedRow}
                                                                    Id={item.id}
                                                                    Content={item.email || item.description}
                                                                    Text={item.surname != undefined ? item.name + " " + item.surname : item.name}
                                                                    IsActive={item.isActive}
                                                                    Icon={item.surname != undefined ? "user circle" : "chess knight"}
                                                                    SetReportDetailVisible={setReportDetailVisible}
                                                                    HandleClickedRow={handleClickedRow}
                                                                    SetSelectedDataRow={setSelectedDataRow}
                                                                    SelectedDataRow={selectedDataRow}
                                                                    IsUserData={item.email != undefined && true}
                                                                    AllItems={item}
                                                                    WideView={wideView}
                                                                    WhenSelectDataLoading={whenSelectDataLoading}
                                                                />
                                                            ) :



                                                            <div style={{ width: "100%", height: "300px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                                                <Header disabled as='h2'>No data found</Header>
                                                                <Icon disabled size={"massive"} name="dropbox" />
                                                            </div>

                                                        }

                                                    </div>
                                                </Transition>

                                                <Transition visible={whenSelectDataLoading} animation='scale' duration={500}>
                                                    <div>
                                                        <Loader size="big" active inline='centered' />

                                                    </div>
                                                </Transition>

                                                


                                            </div>

                                        }
                                    </div>
                                </Transition>

                                <Transition
                                    animation='scale'
                                    visible={systemReportOn && reportCardName == "System"}
                                    duration={500}
                                >
                                    <div>

                                        {systemReportData != "" && 
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "space-between"
                                            }}>
                                                <div>
                                                    <Header disabled as='h4'>System Report</Header>
                                                </div>

                                                <div>
                                                    <Popup
                                                        content='Export Excel Report'
                                                        trigger={
                                                            <Button
                                                                onClick={systemReportToExcel}
                                                                color="green"
                                                                icon>
                                                                <Icon
                                                                    name='file excel'
                                                                />
                                                            </Button>
                                                        }
                                                    />


                                                </div>
                                            </div>
                                        
                                        }
                                        

                                        {systemReportData}

                                        <Loader
                                            style={{ marginTop: "18%" }}
                                            size="huge"
                                            active={systemReportData == ""}
                                            inline='centered'
                                        />
                                    </div>
                                </Transition>


                                <Transition
                                    animation='scale'
                                    visible={reportCardName == "Questions"}
                                    duration={500}
                                >
                                    <div>

                                        <ReportQuestions />

                                    </div>
                                </Transition>







                            </div>
                        </Transition>


                        {
                            !systemReportOn &&

                            <Transition
                                animation='scale'
                                visible={reportDetailVisible}
                                duration={500}
                            >
                                <div style={reportDetailContentStyle}>

                                    <Transition
                                        animation='scale'
                                        visible={selectedDataRow.length > 0}
                                        duration={500}
                                    >
                                        <div>
                                            <div style={selectedDataRowContainerStyle}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        gap: "2em",
                                                        width: "85%",

                                                    }}>
                                                    <Label
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center"
                                                        }}
                                                        color="red">
                                                        {selectedDataRow.length}
                                                    </Label>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            gap: "1em",
                                                            width: "90%",
                                                            overflow: "auto",
                                                            overflowY: "hidden"
                                                        }}>
                                                        {selectedDataRow.map(selected => (
                                                            <Button
                                                                size="mini"
                                                                color={clickedRow == selected.id && "black"}
                                                                onClick={() => { handleClickedRow(selected.id) }}
                                                            >{selected.text}</Button>
                                                        ))}
                                                    </div>

                                                </div>
                                                <div>
                                                    <Button
                                                        onClick={
                                                            reportCardName == "Users" ?
                                                                exportAllExcelReport :
                                                                exportAllSimulationReport
                                                        }
                                                        color="green"
                                                        size="mini"
                                                        icon
                                                        labelPosition='right'
                                                    >
                                                        Export Report ({selectedDataRow.length})
                                        <Icon name='file excel outline' />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Transition>
                                    <div>
                                        {reportCardName == "Users" ?
                                            <ReportUser
                                                SetWhenSelectDataLoading={setWhenSelectDataLoading}
                                                SetMultiSelectedUsersReportExcel={setMultiSelectedUsersReportExcel}
                                                Id={reportCardName == "Users" ? clickedRow : false}

                                            /> :
                                            <ReportSimulation
                                                SetWhenSelectDataLoading={setWhenSelectDataLoading}
                                                MultiSelectedSimulationReportExcel={multiSelectedSimulationReportExcel}
                                                SetMultiSelectedSimulationReportExcel={setMultiSelectedSimulationReportExcel}
                                                Id={clickedRow}
                                            />
                                        }
                                    </div>
                                </div>
                            </Transition>
                        }


                    </div>



                </div>
            </div>


        </div>
    )
}