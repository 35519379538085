import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, Header, Popup, Button, Select, Transition, Loader, Label } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const ReportUserFilter = (props) => {

    const [filterSettings, setFilterSettings] = useState(props.FilterData);

    const [isActive, setIsActive] = useState(false);
    const [userType, setUserType] = useState(0);
    const [isFiltered, setIsFiltered] = useState(false);

    const countryOptions = [
        { key: '0', value: '0', text: 'All Users' },
        { key: '1', value: '1', text: 'Admin' },
        { key: '4', value: '4', text: 'User' }
    ];

    const filterRowStyle = {
        width: "100%",
        height: "56px",
        padding: "15px",
        borderRadius: "10px",
        boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
        marginTop: "20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"

    }

    const filterRowColumnStyle = {
        width: "100%",
        height: "56px",
        padding: "15px",
        borderRadius: "10px",
        boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
        marginTop: "20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        gap: "1em",
        flexDirection: "column",
        height: "auto"
    }

    const filterRowInfoBoxStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "0.5em"
    }

    const filterContainerFlexStyle = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "530px"

    }

    const handleStatus = (event, data) => {
        //filterSettings.Users.isActive = data.checked;
        setIsActive(data.checked);
    }

    const handleUserType = (event, data) => {
        //filterSettings.Users.userType = data.value;
        setUserType(data.value);
    }

    const filterUser = () => {
        setIsFiltered(true);

        filterSettings.Users.isActive = isActive;
        filterSettings.Users.userType = userType;

        let filterData = {
            CompanyId: cookies.get('CompanyId').toString(),
            IsActive: isActive,
            UserType: userType
        }

        props.GetData(true, filterData,true);
        props.SetFilterData(filterSettings);

        setTimeout(function () {
            setIsFiltered(false);
        }, 500);

    }

    console.log(props);

    return (
        <div style={filterContainerFlexStyle}>
            <div>
                <div style={filterRowStyle}>
                    <div style={filterRowInfoBoxStyle}>
                        <Popup
                            content='active or passive'
                            trigger={
                                <Button size="mini" icon='info' />
                            }
                        />

                        <p>Status</p>
                    </div>

                    <Checkbox
                        slider
                        onChange={handleStatus}
                    />
                </div>

                <div style={filterRowColumnStyle}>
                    <div style={filterRowInfoBoxStyle}>
                        <p>User Type</p>
                    </div>

                    <Select
                        placeholder='Select user type'
                        options={countryOptions}
                        defaultValue={"0"}
                        onChange={handleUserType}
                    />
                </div>
            </div>


            <div>
                <Transition visible={!isFiltered} animation='scale' duration={500}>
                <Button
                    size="big"
                    fluid
                    color="black"
                    onClick={filterUser}
                >
                    Filter
                    </Button>
                </Transition>

                <Transition visible={isFiltered} animation='scale' duration={500}>
                    <Label style={{width: "90%", position:"absolute", bottom: "-40px", margin: "0 auto"}} fluid>
                        <Loader active inline='centered' />
                    </Label>

                </Transition>
            </div>


        </div>
    )
}