import React, { useContext, useEffect, useState } from 'react';
import { Popup,Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';


export const ReportCard = (props) => {



    const cardStyle = {
        width: "60px",
        height: "60px",
        boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
        borderRadius: "10px",
        padding: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        cursor: "pointer",
        margin: "10px",
        backgroundColor: props.ReportCardName == props.Name ? "antiquewhite" : "unset",
        transition: "all 0.7s"
    }

    const ClickReportChoose = () => {
        props.SetReportCardNameFunc(props.Name);
        props.SetSeachableVisible(true);
    }

    return (
        <Popup
            content={props.Name}
            trigger={
                <div
                    style={cardStyle}
                    onClick={ClickReportChoose}
                >
                    <Icon
                        disabled
                        size="big"
                        name={props.Icon}
                    />
                </div>
            }
        />
    )
}