import React, { useState, useContext, useEffect } from 'react';
import './charactersdialogs.css';
import axios from 'axios';
import { Button, Divider, Form, Checkbox, Dropdown, Modal, Transition, Icon, Header, Tab, Confirm, TextArea } from 'semantic-ui-react';
import { Dialog } from './Dialog/Dialog';
import { Question } from './Question/Question';
import { QuestionPanel } from './QuestionPanel/QuestionPanel';
import Cookies from 'universal-cookie';

const cookies = new Cookies();


export const CharactersDialogs = (props) => {

    const [allDialog, setAllDialog] = useState([]);
    const [openQuestionPanel, setOpenQuestionPanel] = useState(false);
    const [isQuestion, setIsQuestion] = useState(false);
    const [currentIndexQuestion, setCurrentIndexQuestion] = useState("");

    const [modalHeader, setModalHeader] = useState("");
    const [modalDesc, setModalDesc] = useState("");
    const [isOpenModal, setIsOpenModal] = useState(false);

    const [currentQuestionId, setCurrentQuestionId] = useState("");

    const [confirmDelete, setConfirmDelete] = useState(false);

    const [currentDialogId, setCurrentDialogId] = useState("");

    const [clickedElementIndex, setClickedElementIndex] = useState("");

    const [character1FromDb, setCharacter1FromDb] = useState("");
    const [character2FromDb, setCharacter2FromDb] = useState("");

    const [character1FromDbId, setCharacter1FromDbId] = useState("");
    const [character2FromDbId, setCharacter2FromDbId] = useState("");




    const [currentSelectedElement, setCurrentSelectedElement] = useState();

    useEffect(() => {
       
        getDialogsBySimulationId();
        getCharactersLink();
        addAllDialogDb();


        console.log("simulation settingssssssssssss", props.SimulationSettigs)
       
    }, [])

    

    const getDialogsBySimulationId = () => {
        var dialogData = {
            SimulationId: props.SimulationId,
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: dialogData
        });

        console.log(Token.Data);

   

        axios.post('/Dialog/GetDialogBySimulationId', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);
            if (response.data.resultCode == 0) {
                if (response.data.data != null) {
                    var allDialogFromDatabase = JSON.parse(response.data.data.dialogJSON);
                    console.log(JSON.parse(response.data.data.dialogJSON));
                    //setAllDialog([{}]);
                    console.log(allDialogFromDatabase)

                    if (JSON.parse(response.data.data.dialogJSON) == null) {
                        response.data.data.dialogJSON = []
                    } else {
                        

                        //setAllDialog([]);
                        setAllDialog(JSON.parse(response.data.data.dialogJSON));


  
                    }

                    
                    
                }
            } else {

            }
        });
    }

    useEffect(() => {


        addAllDialogDb();

        console.log("allDialog", allDialog);

    }, [allDialog]);

    const addAllDialogDb = () => {


        allDialog.map((item) => {
            item.characterLink = character1FromDb
            item.otherCharacterLink = character2FromDb
        });


      

        var dialogData = {
            Id: null,
            Character1Id: props.CharacterOne,
            Character2Id: props.CharacterTwo,
            BackgroundId: "",
            SimulationId: props.SimulationId,
            UserId: cookies.get('UserId'),
            CompanyId: cookies.get('CompanyId'),
            DialogJSON: JSON.stringify(allDialog)
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: dialogData
        });

        console.log("dialogData", dialogData);

        axios.post('/Dialog/Add', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);
            if (response.data.resultCode == 0) {

                if (response.data.data) {
                    setCurrentDialogId(response.data.data.id);

                    console.log(JSON.parse(response.data.data.dialogJSON))
                    setCharacter1FromDbId(response.data.data.character1Id);
                    setCharacter2FromDbId(response.data.data.character2Id);
                }


                

            } else {

            }
        });
    }


    const getCharactersLink = () => {


        var Token = JSON.stringify({
            AuthToken: cookies.get('Token')
        });

        axios.post('/Character/GetAllCharacters', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);
            if (response.data.resultCode == 0) {


                var dialogDataArray = response.data.data;


                if (props.CharacterOne.length == 0 && props.CharacterTwo.length == 0) {
                    var dialogData = {
                        SimulationId: props.SimulationId,
                    }

                    var Token = JSON.stringify({
                        AuthToken: cookies.get('Token'),
                        Data: dialogData
                    });

                    console.log(Token.Data);

                    axios.post('/Dialog/GetDialogBySimulationId', Token, {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }).then((response) => {
                        console.log(response);
                        if (response.data.resultCode == 0) {
                            let data = response.data.data;
                            let character1 = data.character1Id;
                            let character2 = data.character2Id;

                           
                           

                            let filteredCharacterArray = dialogDataArray.filter(character => (character.id == character1) || (character.id == character2));

                            
                            if (filteredCharacterArray.length > 0) {
                                
                                setCharacter1FromDb(filteredCharacterArray[0].photo);
                                setCharacter2FromDb(filteredCharacterArray[1].photo);
                            }

                           

                        } else {

                        }
                    });
                } else {

                    let filteredCharacterArray = dialogDataArray.filter(character => (character.id == props.CharacterOne) || (character.id == props.CharacterTwo )).reverse();

                    if (filteredCharacterArray.length > 0) {
                        setCharacter1FromDb(filteredCharacterArray[0].photo);
                        setCharacter2FromDb(filteredCharacterArray[1].photo);
                    }
                }

               

               

               

            } else {

            }
        });
    }

    const setCurrentQuestionIdFunc = (questionId) => {
        setCurrentQuestionId(questionId);
    }

    const addDialog = (e) => {

        let align = e.target.dataset.id;
        
        var dialogObject = {
            align: align,
            characterLink: character1FromDb ,
            image: character1FromDb ,
            otherCharacterLink:character2FromDb,
            characterTwo: character2FromDb,
            right: align == "left" ? false : true,
            comment: "",
            audio: null,
            emotionId: "1645fdcb-ba6f-4c60-973b-faac69b92c06"
        }

        console.log("dialogObject", dialogObject);

       
        

        //here
        setAllDialog([...allDialog, dialogObject]);
        
   
        

    }

    const addDialogInsert = (e) => {

        let align = e.target.dataset.id;
        let dialogIndex = parseInt(e.target.dataset.index) + 1;

       

        var dialogObject = {
            align: align,
            image: align == "left" ? character1FromDb : character2FromDb,
            characterLink: align == "left" ? character1FromDb : character2FromDb,
            otherCharacterLink: align == "left" ? character2FromDb : character1FromDb,
            characterTwo: character2FromDb,
            right: align == "left" ? false : true,
            comment: "",
            audio: null,
            emotionId: "1645fdcb-ba6f-4c60-973b-faac69b92c06"
        }

        
            allDialog.splice(dialogIndex, 0, dialogObject);
            setAllDialog([]);
            setAllDialog([...allDialog]);

           

     
        
      
    }

    const deleteDialogConfirm = (e) => {

        setCurrentSelectedElement(
            {
                dialogIndex: parseInt(e.target.dataset.index),
                isQuestion: e.target.dataset.question,
                id: e.target.id,
            }
        );

       
        setModalHeader("Are you sure you want to delete this?");

        if (e.target.dataset.question) {
            setModalDesc(
                <div style={{ width: "auto" }} className="dialog-box">
                    
                    <div className="dialog-left-section">
                        <div>
                            <img className="dialog-mini-rounded-image" src={character1FromDb} />
                        </div>
                    </div>
                    <div className="dialog-textare-section">
                        <div
                            id={props.QuestionId}
                            className="question-show-box"
                        >{e.target.dataset.body}</div>
                    </div>
                </div>
            );

        } else {
            setModalDesc(

                <div style={{width:"auto"}} className="dialog-box">
                    <div className="dialog-left-section">
                        <div>
                            <img className="dialog-mini-rounded-image" src={e.target.dataset.right == "false" ? character1FromDb : character2FromDb} />
                        </div>
                    </div>
                    <div className="dialog-textare-section">
                        <TextArea
                            disabled
                            value={e.target.dataset.comment}
                            data-index={props.Index}
                            className="dialog-textarea"
                            placeholder='dialog...'
                            style={{ minHeight: 160 }} />
                    </div>
                </div>
            );
        }
        
        setConfirmDelete(true);

    }

    const deleteDialog = (e) => {

        let dialogIndex = e.dialogIndex
        let isQuestion = e.isQuestion;

        if (isQuestion === "true") {
                var questionData = {
                    Id: e.id,
                }

                var Token = JSON.stringify({
                    AuthToken: cookies.get('Token'),
                    Data: questionData
                });

                axios.post('/Question/Delete', Token, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).then((response) => {
                    console.log(response);
                    if (response.data.resultCode == 0) {
                        allDialog.splice(dialogIndex, 1);
                        setAllDialog([]);
                        setAllDialog([...allDialog]);
                        setConfirmDelete(false);
                    } else {
                        setModalHeader("Warning");
                        setModalDesc("There is a problem. Please try again");
                        setIsOpenModal(true);
                    }
                });
            

            

        } else {

           
                allDialog.splice(dialogIndex, 1);
                setAllDialog([]);
            setAllDialog([...allDialog]);
            setConfirmDelete(false);
            
        }

   
        
        
    }

    const onChangeTextarea = (e) => {
        let dialogIndex = parseInt(e.target.dataset.index);

        allDialog[dialogIndex].comment = e.target.value;
        setAllDialog([...allDialog]);
    }

    const onChangeAudio = (audio) => {
       
        allDialog[audio.DataIndex].audio = {
            Id: audio.Id || null,
            AudioLink: audio.AudioLink || null,
            Second: parseInt(audio.Second) || null
        }

        setAllDialog([...allDialog]);
    }

    const addQuestion = (e) => {
        setIsQuestion(false);
        setOpenQuestionPanel(true);
        setCurrentIndexQuestion(parseInt(e.target.dataset.index) + 1);
    }

    const closeQuestionPanel = () => {
        setOpenQuestionPanel(false)

    }

    const showDetailQuestion = () => {
        setIsQuestion(true);
        setOpenQuestionPanel(true);
    }

    const clickedElementIndexValue = (index) => {
        setClickedElementIndex(index);
    }

    return (
        <div>

            <Transition visible={isOpenModal} animation='scale' duration={500}>
                <Modal dimmer="blurring" className="users-delete-ask-modal" size={"mini"} open={isOpenModal} onClose={() => { setIsOpenModal(false) }}>
                    <Modal.Header><span className={"account-text"}>{modalHeader}</span></Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Header>{modalDesc}</Header>
                        </Modal.Description>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => { setIsOpenModal(false); }} color='green'>
                            OKEY <Icon name='chevron right' />
                        </Button>

                    </Modal.Actions>
                </Modal>
            </Transition>

            <Transition visible={confirmDelete} animation='scale' duration={500}>
                <Modal dimmer="blurring" className="users-delete-ask-modal" size={"mini"} open={confirmDelete} onClose={() => { setConfirmDelete(false) }}>
                    <Modal.Header><span className={"account-text"}>{modalHeader}</span></Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Header>{modalDesc}</Header>
                        </Modal.Description>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => { setConfirmDelete(false); }}>
                            Cancel
                        </Button>

                        <Button onClick={() => { deleteDialog(currentSelectedElement); }} color='green'>
                            Delete
                        </Button>

                    </Modal.Actions>
                </Modal>
            </Transition>

            <QuestionPanel
                CurrentClickedElementIndex={clickedElementIndex}
                CurrentIndex={currentIndexQuestion}
                CharacterLink={character1FromDb}
                OtherCharacterLink={character2FromDb}
                AllDialog={allDialog}
                SetAllDialog={setAllDialog}
                OpenPanel={openQuestionPanel}
                ClosePanel={closeQuestionPanel}
                SimulationId={props.SimulationId}
                IsQuestion={isQuestion}
                CurrentQuestionId={currentQuestionId}
                GetDialogsBySimulationId={getDialogsBySimulationId}
                SetCorrectAnswerPointQuestion={props.SetCorrectAnswerPointQuestion}
                SetCorrectAnswerPointChecked={props.SetCorrectAnswerPointChecked}
                SetAutomaticSave={props.SetAutomaticSave}
                Save={props.Save}
            />
            

            <div className="character-images-box">
                <div><img className="character-image-img" src={character1FromDb} /></div>
                <div><img className="character-image-img" src={character2FromDb} /></div>
            </div>

            <div id="character-dialogs-content-id" className="character-dialogs-content">

                {allDialog ? 
                    
                    
                        allDialog.map((item, index) =>

                            !item.question ?
                                <Dialog
                                    Audio={item.audio}
                                    ChangeAudio={onChangeAudio}
                                    SimulationId={props.SimulationId}
                                    ChangeTextarea={onChangeTextarea}
                                    DeleteDialog={deleteDialogConfirm}
                                    AddDialogInsert={addDialogInsert}
                                    Comment={item.comment}
                                    Index={index}
                                    key={index}
                                    Image={item.characterLink || character1FromDb}
                                    CharacterTwo={item.characterTwo || item.otherCharacterLink || character2FromDb}
                                    Right={item.right}
                                    addQuestion={addQuestion}
                                />
                                :
                                <Question
                                    ClickedElementIndexValue={clickedElementIndexValue}
                                    Body={item.questionContent.Body}
                                    DeleteDialog={deleteDialogConfirm}
                                    AddDialogInsert={addDialogInsert}
                                    Index={index}
                                    key={index}
                                    Image={item.characterLink || character1FromDb}
                                    CharacterTwo={item.otherCharacterLink}
                                    addQuestion={addQuestion}
                                    QuestionId={item.questionId}
                                    ShowDetailQuestion={showDetailQuestion}
                                    SetCurrentQuestionId={setCurrentQuestionIdFunc}
                                />

                        )
                     : ""
                    }
                
            </div>

            

            <div className="character-add-dialog-content">
                <div className="character-one-options">
                    <div>
                        <Button onClick={ addDialog} data-id="left" icon>
                            <Icon data-id="left" name='plus' />
                        </Button>
                    </div>
                    <div>
                        <Button onClick={ addQuestion } icon>
                            <Icon name='question' />
                        </Button>
                    </div>
                </div>
                <div className="character-two-options">
                    <Button onClick={addDialog} data-id="right" icon>
                        <Icon data-id="right" name='plus' />
                    </Button>
                </div>
            </div>
        </div>
        )
}
