import React, { useState, useContext, useEffect } from 'react';
import './usersimulationdetail.css';
import { Header, Button, Icon, Rating, Image, Progress, Loader } from 'semantic-ui-react';
import { loginContext } from '../../../../../Store';
import { Navbar } from '../../Navbar/Navbar';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';
import noImageSimulation from '../../../../../images/matthew.png';
import { dateFormat } from '../../../../../helper/helper';

const cookies = new Cookies();
export const UserSimulationDetail = (props) => {

    const [isLoginControl, setLoginControl] = useContext(loginContext);

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [estimatedTime, setEstimatedTime] = useState(0);
    const [startingDate, setStartingDate] = useState("");
    const [dueDate, setDueDate] = useState("");
    const [companyId, setCompanyId] = useState(cookies.get('CompanyId').toString());
    const [isActive, setIsActive] = useState();
    const [id, setId] = useState("");
    const [shareSimulationImageLink, setShareSimulationImageLink] = useState("");
    const [progressPercentage, setProgressPercentage] = useState("");
    const [videoLink, setVideoLink] = useState("");
    const [loading, setLoading] = useState(true);

    const [averageStar, setAverageStar] = useState();
    const [averageStarLoader, setAverageStarLoader] = useState(false);

    useEffect(() => {

        if (!isLoginControl) {
            window.location.href = "/";
        }

        getSimulationData();
        getSimulationAverageStar();

    }, []);

    const getSimulationData = () => {

        var simulation = {
            Id: props.match.params.id
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: simulation
        });

        axios.post('/Simulation/GetSimulationByUserId', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            if (response.data.resultCode == 0) {
                var simulationData = response.data.data;
                setVideoLink(simulationData.videoLink);
                setName(simulationData.name);
                setDescription(simulationData.description);
                setEstimatedTime(simulationData.estimatedTime == null ? 0 : simulationData.estimatedTime);
                setStartingDate(simulationData.startingDate);
                setDueDate(simulationData.dueDate);
                setIsActive(simulationData.isActive);
                setId(simulationData.id);
                setCompanyId(simulationData.companyId);
                setShareSimulationImageLink(simulationData.image);
                setProgressPercentage(simulationData.progressPercentage == null ? 0 : simulationData.progressPercentage);

                setLoading(false);
                
            } 
        });
    }

    const changeStar = (e, data) => {
        setAverageStarLoader(true);

        var simulation = {
            SimulationId: props.match.params.id,
            UserId: cookies.get('UserId'),
            Star: data.rating
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: simulation
        });

        axios.post('/User/UpdateEnrolledUser', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            if (response.data.resultCode == 0) {
                setAverageStar(parseInt(response.data.data));
                setAverageStarLoader(false);
            }
        });
    }

    const getSimulationAverageStar = () => {
        setAverageStarLoader(true);
        var simulation = {
            SimulationId: props.match.params.id,
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: simulation
        });

        axios.post('/User/GetAverageStarBySimulationId', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            if (response.data.resultCode == 0) {
                setAverageStar(parseInt(response.data.data));
                setAverageStarLoader(false);
            }
        });
    }

    return (

        <div>
            {isLoginControl ?
                <div>
                    <Navbar />

                    <div className="dashboard-container-content">

                        <div className="dashboard-content-box">
                            {
                                loading ? <Loader active inline='centered' size='massive' /> :
                                    <>

                                        <div className="simulations-header">
                                            <div className="page-header-text">
                                                <Header as='h1' disabled>
                                                    <Link to="/user-dashboard"><Button className="simulation-back-button" size="massive" icon>
                                                        <Icon name='left chevron' />
                                                    </Button></Link>
                                                    {name}
                                                </Header>
                                            </div>
                                            <div>
                                                {averageStarLoader ? <Loader active inline='centered' /> : <Rating onRate={changeStar} maxRating={5} defaultRating={averageStar} icon='star' size='massive' />}



                                            </div>


                                        </div>

                                        <div className="user-simulations-detail-content">
                                            <div className="user-simulations-detail-image">
                                                <Image
                                                    size='large'
                                                    src={shareSimulationImageLink ? shareSimulationImageLink : noImageSimulation}
                                                    rounded
                                                />

                                                <Link to={"/simulation-watch/" + props.match.params.id}><Button className="start-simulation-button" color='green' fluid size="huge" animated>
                                                    <Button.Content visible>Start Simulation</      Button.Content>
                                                    <Button.Content hidden>
                                                        <Icon name='arrow right' />
                                                    </Button.Content>
                                                </Button>
                                                </Link>
                                            </div>

                                            <div className="user-simulation-detail-content-box">

                                                <div className="user-simulation-detail-content-column">
                                                    <Header disabled as='h2'>
                                                        <Icon name='percent' />
                                                        <Header.Content>Progress Percentage ({"% " + progressPercentage})</Header.Content>
                                                    </Header>
                                                    <Progress
                                                        className="user-dashboard-card-progress-bar user-simulation-detail-progress"
                                                        percent={progressPercentage}
                                                        indicating
                                                    />
                                                </div>


                                                <div className="user-simulation-detail-content-column user-simulation-top">
                                                    <Header disabled as='h2'>
                                                        <Icon name='chess knight' />
                                                        <Header.Content>Description</Header.Content>
                                                    </Header>
                                                    <p>{description} </p>
                                                </div>

                                                <div className="user-simulation-detail-content-column user-simulation-top">
                                                    <Header disabled as='h2'>
                                                        <Icon name='time' />
                                                        <Header.Content>Estimated Time</Header.Content>
                                                    </Header>
                                                    <p>{estimatedTime + " minute(s)"} </p>
                                                </div>

                                                <div className="user-simulation-detail-content-column user-simulation-top">
                                                    <Header disabled as='h2'>
                                                        <Icon name='calendar alternate' />
                                                        <Header.Content>Starting Date - End Date</Header.Content>
                                                    </Header>
                                                    <p>{dateFormat(startingDate, true) + " - " + dateFormat(dueDate, true)} </p>
                                                </div>

                                                {
                                                    videoLink != null ?
                                                        <div className="user-simulation-detail-content-column user-simulation-top">
                                                            <Header disabled as='h2'>
                                                                <Icon name='video camera' />
                                                                <Header.Content>Video</Header.Content>
                                                            </Header>
                                                            <iframe src={videoLink}
                                                                frameborder='0'
                                                                allow='autoplay; encrypted-media'
                                                                width="100%"
                                                                height="500px"
                                                                allowfullscreen
                                                                title='video'
                                                            />
                                                        </div> : ""
                                                }



                                            </div>
                                        </div>
                                    </>
                            }

                        </div>
                    </div>
                </div>
                : ""}
        </div>













    )
}