import React, { useState, useContext, useEffect } from 'react';
import './search.css';
import axios from 'axios';
import { Input } from 'semantic-ui-react'


export const Search = (props) => {



    useEffect(() => {




    }, []);



    return (
        <div className="digitallica-search-input-box">

            <Input

                id="digitallica-search-input"
                size="big"
                placeholder= "Search.."
            />

        </div>
    );

}
