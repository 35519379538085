import React, { useContext, useEffect, useState } from 'react';
import { Popup, Icon, Loader, Header, Label, Table, Transition } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { SystemReportCard } from './SystemReportCard/SystemReportCard';

import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    AreaChart,
    Area,
    Pie,
    PieChart,
    Cell
} from "recharts";


import axios from 'axios';
import Cookies from 'universal-cookie';


const cookies = new Cookies();



export const ReportSystem = (props) => {


    const [data] = useState(props.Data);

    //for doughnut chart
    const COLORS = ["#82ca9d", "#d88484"];

    const [donutChart] = useState([
        { name: "In Progress Rate", value: data.systemSimulationCompletionRate.completionRate.inProgressRate },
        { name: "Not Started Rate", value: data.systemSimulationCompletionRate.completionRate.notStartedRate }
    ]);

    const [donutPassChart] = useState([
        { name: "Passed Rate", value: ((data.systemSimulationCompletionRate.passedCount * 100) / (data.systemSimulationCompletionRate.passedCount + data.systemSimulationCompletionRate.notPassedCount))},
        { name: "Not Passed Rate", value: ((data.systemSimulationCompletionRate.notPassedCount * 100) / (data.systemSimulationCompletionRate.passedCount + data.systemSimulationCompletionRate.notPassedCount))}
    ]);

    const [users] = useState([
        {
            Header: "Users",
            Icon: "users",
            Text: data.systemReportUserList[0].count + data.systemReportUserList[1].count,
        },
        {
            Header: "Learner",
            Icon: "user",
            Text: data.systemReportUserList[0].count,
            IconColor: "orange"
        },
        {
            Header: "Admin",
            Icon: "add user",
            Text: data.systemReportUserList[1].count,
            IconColor: "blue"
        }
    ]);

    const [simulationsCompletionRate] = useState([
        {
            Header: "Assigments",
            Icon: "user plus",
            Text: data.systemSimulationCompletionRate.enrolledAllUserCount
        },
        {
            Header: "Completions",
            Icon: "user plus",
            Text: data.systemSimulationCompletionRate.completionCount
        },
        {
            IsGraph: true,
            Html: <PieChart width={300} height={400} style={{ top: "-110px" }}>
                <Pie
                    dataKey="value"
                    data={donutChart}

                    innerRadius={40}
                    outerRadius={80}
                >

                    {donutChart.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip />
            </PieChart>
        },
        {
            Header: "Not Passed",
            Icon: "user plus",
            Text: data.systemSimulationCompletionRate.notPassedCount
        },
        {
            Header: "In Progress",
            Icon: "user plus",
            Text: data.systemSimulationCompletionRate.inProgressCount
        },
    ]);

    const [simulationPassRate] = useState([
        {
            Header: "Passed",
            Icon: "user",
            Text: data.systemSimulationCompletionRate.passedCount
        },
        {
            IsGraph: true,
            Html: <PieChart width={300} height={400} style={{ top: "-110px" }}>
                <Pie
                    dataKey="value"
                    data={donutPassChart}

                    innerRadius={40}
                    outerRadius={80}
                >

                    {donutPassChart.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip />
            </PieChart>
        },
        {
            Header: "Not Passed",
            Icon: "user",
            Text: data.systemSimulationCompletionRate.notPassedCount
        }
    ]);



    useEffect(() => {
        console.log(data);
    }, []);

    //style
    const systemReportContainer = {
        width: "90%",
        minHeight: "500px",
        margin: "0 auto",
        padding: "15px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    }
    const cardBoxStyle = {
        display: "flex",
        gap: "2em"
    }
    const headerStyle = {
        fontSize: "3.5em"
    }
    //style

    return (
        <div>
            <div style={systemReportContainer}>
                <Header style={headerStyle} disabled as='h1'>Users</Header>

                <div style={cardBoxStyle}>
                    {users.map(user => (
                        <SystemReportCard
                            Header={user.Header}
                            Icon={user.Icon}
                            IconColor={user.IconColor}
                            Text={user.Text}
                        />
                    ))}
                </div>


                <div style={{ height: "150px" }}></div>

                <Header style={headerStyle} disabled as='h1'> Simulations Completion </Header>

                <div style={cardBoxStyle}>
                    {simulationsCompletionRate.map(simulation => (
                        simulation.IsGraph ? simulation.Html :
                            <SystemReportCard
                                Height={"220px"}
                                Header={simulation.Header}
                                Icon={simulation.Icon}
                                Text={simulation.Text}
                            />
                    ))}
                </div>

               

                <Header style={headerStyle} disabled as='h1'> Questions </Header>

                <div style={cardBoxStyle}>
                    <SystemReportCard
                        Height={"220px"}
                        Header={"Questions"}
                        Icon={"question"}
                        Text={data.questionCount}
                    />
                </div>

                <div style={{ height: "150px" }}></div>


                <Header style={headerStyle} disabled as='h1'> Simulation Pass Rate </Header>

                <div style={cardBoxStyle}>
                    {simulationPassRate.map(simulationPass => (
                        simulationPass.IsGraph ? simulationPass.Html :
                            <SystemReportCard
                                Height={"220px"}
                                Header={simulationPass.Header}
                                Icon={simulationPass.Icon}
                                Text={simulationPass.Text}
                            />
                    ))}
                </div>


            </div>
        </div>
    )
}