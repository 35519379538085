import React, { useEffect, useContext} from 'react';
import './welcomepage.css'
import { NavMenu } from './NavMenu';
import Cookies from 'universal-cookie';
import { nameContext } from '../../Store.jsx';
import { Link } from 'react-router-dom';
import AnimateMakerSVG from '../../images/blogging.svg';
import BuildSVG from '../../images/build.svg';
import LoginSVG from '../../images/login.svg';
import FooterSVG from '../../images/footer.svg';

import { loginContext } from '../../Store';

const cookies = new Cookies();

window.onscroll = function () { scrollFunction() };

function scrollFunction() {

 
    if (document.body.scrollTop > 120 || document.documentElement.scrollTop > 120) {

        if (document.getElementById("main-page-header-box")) {
            document.getElementById("main-page-header-box").style.height = "15vh";

            document.getElementById("main-page-header-box").style.position = "fixed";
            document.getElementById("main-page-header-box").style.width = "100%";
            document.getElementById("main-page-header-box").style.zIndex = "999";

            document.getElementById("main-page-header-box").style.backgroundColor = "#f6f5f5";

            document.getElementById("main-page-header-box").style.borderBottomLeftRadius = "50px";
            document.getElementById("main-page-header-box").style.borderBottomRightRadius = "50px";
            document.getElementById("main-page-header-box").style.borderColor = "#f2f2f2";
        }
        
        if (document.getElementsByClassName("nav-sub-text-content")[0])
            document.getElementsByClassName("nav-sub-text-content")[0].style.zIndex = "-1";

        if (document.getElementById("nav-sub-text-content-id")) {
            document.getElementById("nav-sub-text-content-id").style.visibility = "hidden";
            document.getElementById("nav-sub-text-content-id").style.opacity = "0";
        }


        if (document.getElementById("footer-screet-section"))
            document.getElementById("footer-screet-section").style.visibility = "hidden";

        if (document.getElementById("final-box"))
            document.getElementById("final-box").style.backgroundColor = "#efefef";

    } else {

        if (document.getElementById("main-page-header-box")) {
            document.getElementById("main-page-header-box").style.borderBottomLeftRadius = "170px";
            document.getElementById("main-page-header-box").style.borderBottomRightRadius = "170px";
            document.getElementById("main-page-header-box").style.borderColor = "#efefef";
            document.getElementById("main-page-header-box").style.position = "absolute";
            document.getElementById("main-page-header-box").style.height = "80vh";
        }

        if (document.getElementById("nav-sub-text-content-id")) {
            document.getElementById("nav-sub-text-content-id").style.display = "block";
        }
        

        if (document.getElementById("main-page-header-box")) {
            document.getElementById("main-page-header-box").style.backgroundColor = "#f6f5f5";
        }
       
        if (document.getElementById("nav-sub-text-content-id")) {
            document.getElementById("nav-sub-text-content-id").style.visibility = "visible";
            document.getElementById("nav-sub-text-content-id").style.opacity = "1";
        }
        

        if (document.getElementById("footer-screet-section")) {
            document.getElementById("footer-screet-section").style.visibility = "hidden";
        }

        if (document.getElementById("final-box")) {
            document.getElementById("final-box").style.backgroundColor = "#efefef";
        }

        
    }


   




   
}

export const MainPage = () => {

    const [isLoginControl, setLoginControl] = useContext(loginContext);
    useEffect(() => {
       
        if (isLoginControl) {
            //window.location.href = "/dashboard";

            if (cookies.get('UserType') == "4") {
                window.location.href = "/user-dashboard";
            } else if (cookies.get('UserType') == "1") {
                window.location.href = "/dashboard";
            }

        }

    }, []);
    

    return (
        
        <div>

            <div id="main-page-header-box">

                <NavMenu />

                <div id="nav-sub-text-content-id" className="nav-sub-text-content">
                    <img id='main-page-first-svg' width="20%" src={AnimateMakerSVG} />
                    <h1 className='header-animation-header-text'>Simulation Maker</h1>
                    <p className="header-animation-header-p">Easily create animations using virtual characters </p>
                    <Link to="/login"><button className="navbar-login-button main-page-start-button">Start Now</button></Link>

                </div>

                <div id="footer-screet-section">
                    <div className="footer-section-box">
                        <img id='sub-footer-svg' width="30%" src={FooterSVG} />
                    </div>
                    <div className="footer-header">
                        <h1 id='last-footer-header-text'>Start making your animations right away</h1>  
                    </div>
                    <div className="footer-start-button">
                        <Link to="/login"> <button className="navbar-login-button main-page-start-button">Start Now</button></Link>
                    </div>
                    
                </div>
            </div>
            <div className="main-page-content-first">
                <div className="box-2-content">
                    <div>
                        <h1 className="box-2-text">
                        Diversify your animation by choosing the characters you want.
                        </h1>
                    </div>
                    <div className="box-2-svg-box">
                        <img id='robot-svg' width="700%" src={BuildSVG} />
                    </div>
                </div>
            </div>
            {/*<div id="final-box" className="main-page-content-third">*/}
            {/*    <div className="box-3-content">*/}
                    
            {/*    </div>*/}
            {/*</div>*/}

           
        </div>
    );

}
