import React, { useState, useContext, useEffect } from 'react';
import './dashboardreportcard.css'
import { Icon, Button } from 'semantic-ui-react'
import { loginContext } from '../../../../Store';


import { Link } from 'react-router-dom';


export const DashboardReportCard = (props) => {

    const [isLoginControl, setLoginControl] = useContext(loginContext);

    const cardStyle = {
        color: props.Color,
        backgroundColor: props.BackColor,
       
    };

    return (
        <div style={cardStyle} className = "dashboard-report-card" >

            <div><Icon size="huge" name={props.Icon} /></div>
            <div className="report-card-info-box">
                <div className="dashboard-report-card-number">{ props.Count}</div>
                <div className="dashboard-report-card-header">{props.Text}</div>
            </div>
        </div>
    );

}
