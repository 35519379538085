import React, { useState, useContext, useEffect} from 'react';
import './dashboard.css';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { loginContext } from '../../../Store';
import { DashboardNavBar } from './DashboardNavBar/DashboardNavBar';
import { DashboardCard } from './DashboardCard/DashboardCard';
import { DashboardReportCard } from './DashboardReportCard/DashbpardReportCard';

import { Link } from 'react-router-dom';

const cookies = new Cookies();

export const Dashboard = () => {

    const [isLoginControl, setLoginControl] = useContext(loginContext);

    const [dashboardData, setDashboardData] = useState({
        activeUserCount: 8,
        passiveUserCount: 1,
        simulationCount: 4,
        userCount: 6
    });

    useEffect(() => {
       
        if (!isLoginControl) {
            window.location.href = "/";
        }

        getDashboardData();

    }, []);

    const getDashboardData = () => {
        var data = {
            CompanyId: cookies.get('CompanyId')
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: data
        });

        axios.post('/Settings/GetAllDashboardData', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            if (response.data.resultCode == 0) {
                let dashboardData = response.data.data;
                setDashboardData({
                    activeUserCount: dashboardData.activeUserCount,
                    passiveUserCount: dashboardData.passiveUserCount,
                    simulationCount: dashboardData.simulationCount,
                    userCount: dashboardData.userCount
                });
            }
            
        });
    }

    return (
        <div>
            {isLoginControl ?
                <div>

                    <DashboardNavBar/>
                    <div className="dashboard-container-content">
                       
                        <div className="dashboard-cards-box">
                      
                            <div className="dashboard-card-box">
                                <h1>Functions</h1>

                                <Link to="/users">
                                    <DashboardCard
                                        BackColor="#ed1952"                                     Color="#fafafa"
                                        Text="Users"
                                        Icon="users"
                                    />
                                </Link>

                                <Link to="/simulations">
                                    <DashboardCard
                                        BackColor="#ffcf62"                                     Color="#fafafa"
                                        Text="Simulations"
                                        Icon="chess knight"
                                    />
                                </Link>

                                <Link to="/reports">
                                    <DashboardCard
                                        BackColor="#ff7862"
                                        Color="#fafafa"
                                        Text="Reports"
                                        Icon="pie graph"
                                    />
                                </Link>

                                <Link to="/settings">
                                    <DashboardCard
                                        BackColor="#333"
                                        Color="#fafafa"
                                        Text="Settings"
                                        Icon="setting"
                                    />
                                </Link>

                            </div>

                            <div className="dashboard-report-cards-container">
                                <h1>Dashboard</h1>
                                <div className="dashboard-report-card-box">
                                    <DashboardReportCard
                                        BackColor="#ed1952"
                                        Color="#fafafa"
                                        Text="Users"
                                        Icon="users"
                                        Count={dashboardData.userCount}
                                    />
                                    <DashboardReportCard
                                        BackColor="#f47820"
                                        Color="#fafafa"
                                        Text="Active Users"
                                        Icon="user"
                                        Count ={dashboardData.activeUserCount}
                                    />
                                    <DashboardReportCard
                                        BackColor="#6f2e8e"
                                        Color="#fafafa"
                                        Text="Passive Users"
                                        Icon="remove user"
                                        Count={dashboardData.passiveUserCount}
                                    />
                                </div>
                                <div className="dashboard-report-card-box">
                                    <DashboardReportCard
                                        BackColor="#ffcf62"
                                        Color="#fafafa"
                                        Text="Simulations"
                                        Icon="chess knight"
                                        Count={dashboardData.simulationCount}
                                    />
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>

                : ""}
                
              
        </div>
    );

}
