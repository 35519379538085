import React, { useState, useContext, useEffect, useParams } from 'react';

import './questionpanel.css';
import axios from 'axios';
import { Button, Divider, Form, Checkbox, Dropdown, Modal, Transition, Icon, Header, Tab, TextArea, Input, Segment, Dimmer, Loader } from 'semantic-ui-react';
import { QuestionOption } from './QuestionOption/QuestionOption';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const QuestionPanel = (props) => {


    const [question, setQuestion] = useState({
        Body: "",
        A: "",
        B: "",
        C: "",
        D: "",
        E: "",
        CorrectOption: ""
    });

    const [questionDataFromDb, setQuestionDataFromDb] = useState("");

    const [options, setOptions] = useState([
        { Id: 'A', Text: '', IsCorrect: false },
        { Id: 'B', Text: '', IsCorrect: false }
    ]);

    const [optionsLimit, setOptionLimit] = useState(false);
    const [visibleRemove, setVisibleRemove] = useState(false);
    const [questionBodyText, setQuestionBody] = useState("");
    const [currentSelectedChooseOption, setCurrentSelectedChooseOption] = useState("");

    const [modalHeader, setModalHeader] = useState("");
    const [modalDesc, setModalDesc] = useState("");
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenModalQuestion, setIsOpenModalQuestion] = useState(false);
    const [questionLoading, setQuestionLoading] = useState(false);
    useEffect(() => {

        if (props.CurrentQuestionId) {
            getSavedQuestion();
            
        } else {
  
        }
    }, [props.CurrentQuestionId]);

    const getSavedQuestion = () => {
        var questionData = {
            Id: props.CurrentQuestionId,
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: questionData
        });

        axios.post('/Question/GetById', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            console.log(response);

            if (response.data.resultCode == 0) {

                let questionDataJSON = JSON.parse(response.data.data.questionJSON);

                let questionData = response.data.data;

                //console.log(questionDataJSON);

                setQuestion(questionDataJSON);
                setQuestionDataFromDb(questionData);
                setQuestionBody(questionDataJSON.Body);

                var newQuestionOptionArray = [];

                [questionDataJSON].forEach(e => {
                    setOptions([]);
                    ["A", "B", "C", "D", "E"].forEach(k => {

                        let newQuestionObject = {
                            Id: k,
                            Text: e[k],
                            IsCorrect: e.CorrectOption == k ? true : false
                        }

                        if (newQuestionObject.Text != "")
                            newQuestionOptionArray.push(newQuestionObject)
                    });
                });

                setOptions(newQuestionOptionArray);


            } else {

            }
        });
    }

    useEffect(() => {
        question.Body = questionBodyText;
        setQuestion(question);
        console.log(question);
    }, [questionBodyText]);

    const changeOptionText = (value, option) => {
        question[option] = value;
        console.log(question);
    }

    const changeCorrectOption = (value) => {
        question.CorrectOption = value;
        setQuestion(question);

        console.log(question);
    }

    useEffect(() => {
        if (options.length == 5)
            setOptionLimit(true);
        else
            setOptionLimit(false);

        if (options.length > 2)
            setVisibleRemove(true);

        if (options.length == 2)
            setVisibleRemove(false);
    }, [options]);
    


    /* functions */

    const addOption = () => {
        if (options.length <= 4) {
            let optionText = "";

            if (options.length == 2)
                optionText = "C";
            else if (options.length == 3)
                optionText = "D";
            else {
                optionText = "E";
            }

            let newOption = {
                Id: optionText,
                Text: '',
                IsCorrect: false
            }

            setOptions([...options, newOption]);
        } 
    }

    const removeOption = () => {
        if (options.length >= 3) {
            options.splice(-1, 1);
            console.log(options);
            setOptions([]);
            setOptions([...options]);

            let optionLength = options.length;
            if (optionLength == 4) {
                question.E = "";
            } else if (optionLength == 3) {
                question.D = "";
            } else if (optionLength == 2) {
                question.C = "";
            }

            setQuestion(question);
            console.log(question);   
        }
    }

    const saveQuestion = (e) => {
        setQuestionLoading(true);
        let questionIndex = props.CurrentIndex;

        if (question.Body === "") {
            setModalHeader("Warning");
            setModalDesc("Please do not leave the question body blank");
            setIsOpenModal(true);
        } else {
            if (question.CorrectOption === "") {
                setModalHeader("Warning");
                setModalDesc("Please select the correct option");
                setIsOpenModal(true);

            } else if (question[question.CorrectOption] === "") {
                setModalHeader("Warning");
                setModalDesc("You cannot mark the blank option as correct answer");
                setIsOpenModal(true);
            } else {



                var questionData = {
                    Id: null,
                    UserId: cookies.get('UserId').toString(),
                    SimulationId: props.SimulationId,
                    CompanyId: cookies.get('CompanyId').toString(),
                    QuestionJSON: JSON.stringify(question)
                }

                var Token = JSON.stringify({
                    AuthToken: cookies.get('Token'),
                    Data: questionData
                });

                axios.post('/Question/Add', Token, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).then((response) => {

                    console.log(response);
                    console.log(JSON.parse(response.data.data.questionJSON));
                    if (response.data.resultCode == 0) {

                        setIsOpenModal(false);

                        var questionObject = {
                            align: "left",
                            right: false,
                            question: true,
                            questionContent: JSON.parse(response.data.data.questionJSON),
                            characterLink: props.CharacterLink,
                            otherCharacterLink: props.OtherCharacterLink,
                            questionId: response.data.data.id
                        }

                        console.log("questionObject", questionObject);

                  

                        props.AllDialog.splice(questionIndex || (props.AllDialog.length), 0, questionObject);

                        var dialogData = {
                            SimulationId: props.SimulationId,
                            DialogJSON: JSON.stringify(props.AllDialog)
                        }

                        var Token = JSON.stringify({
                            AuthToken: cookies.get('Token'),
                            Data: dialogData
                        });

                        axios.post('/Dialog/Add', Token, {
                            headers: {
                                'Content-Type': 'application/json',
                            }
                        }).then((response) => {
                            console.log(response);
                            if (response.data.resultCode == 0) {
                                props.ClosePanel();
                                setQuestionLoading(false)
                                //props.SetAllDialog([...props.AllDialog, questionObject]);

                                //console.log(props.AllDialog);
                                var questionCount = 0;
                                props.AllDialog.map((dialogData) => {
                                    if (dialogData.question) {
                                        questionCount++;
                                    }
                                })

                                console.log("question ponint = " + (100 / questionCount));
                                

                                props.SetCorrectAnswerPointQuestion((100 / questionCount));
                                props.SetCorrectAnswerPointChecked(true);

                                props.SetAutomaticSave(!props.Save);
                                
                                
                            } else {

                            }
                        });

                    } else {
                        let responseData = response.data;
                       
                        setModalHeader("Warning");
                        setModalDesc(responseData.message);
                        setIsOpenModal(true);
                    }
                });
            }
        }
    }

    const updateQuestion = () => {
        
        console.log(question);
        console.log("questionDataFromDb", questionDataFromDb);

        if (question.CorrectOption != "")
            setCurrentSelectedChooseOption(question.CorrectOption);

        let questionData = {
            Id: questionDataFromDb.id,
            QuestionJSON: JSON.stringify(question)
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: questionData
        });

        axios.post('/Question/Update', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);

            let responseMessage = response.data.message;
           
            if (response.data.resultCode == 0) {
                console.log(questionDataFromDb);
                props.AllDialog[props.CurrentClickedElementIndex].questionContent = question;

                console.log(props.AllDialog[props.CurrentClickedElementIndex].questionContent);
                console.log(JSON.parse(questionDataFromDb.questionJSON));


                
                props.SetAllDialog(props.AllDialog);


                var dialogData = {
                    Id: null,
                    Character1Id: "",
                    Character2Id: "",
                    BackgroundId: "",
                    SimulationId: props.SimulationId,
                    UserId: cookies.get('UserId'),
                    CompanyId: cookies.get('CompanyId'),
                    DialogJSON: JSON.stringify(props.AllDialog)
                }

                var Token = JSON.stringify({
                    AuthToken: cookies.get('Token'),
                    Data: dialogData
                });

                //console.log(Token);

                axios.post('/Dialog/Add', Token, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).then((response) => {
                    console.log(response);
                    if (response.data.resultCode == 0) {

                        getSavedQuestion();
                            
                        setModalHeader("Success");
                        setModalDesc(responseMessage);
                        setIsOpenModal(true);

                        //setAllDialog([...allDialog, dialogObject]);
                    } else {
                        setModalHeader("Error");
                        setModalDesc(responseMessage);
                        setIsOpenModal(true);
                    }
                });


                
            } else {
                setModalHeader("Error");
                setModalDesc(response.data.message);
                setIsOpenModal(true);
            }

        });

    
    }

    /* functions */

    /* style */

    const panel = {
        width: '40%',
        height: '100vh',
        position: 'absolute',
        backgroundColor: '#efefef',
        right: props.OpenPanel ? '0%' : '-125%',
        top: '0',
        zIndex: '11',
        borderBottomLeftRadius: '30px',
        borderTopLeftRadius: '30px',
        padding: '20px',
        transition: 'all 0.5s cubic-bezier(.6, 0, .43, 1)',
        overflowY: 'auto',
        fontSize: "1.5em"
    };

    const backgroundPanel = {
        width: '200%',
        top: '0',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        position: 'absolute',
        zIndex: '10',
        transitionProperty: 'visibility, opacity',
        transitionDuration: '0s, 1s',
        transition: 'display 0.5s, opacity 0.5s linear',
        visibility: props.OpenPanel ? 'visible' : 'hidden',
    }

    const containerBox = {
        width: '100%',
        height: '100vh',
        position: 'fixed',
        top: '0%',
        left: '0%',
        zIndex: props.OpenPanel ? '10' : '-1'
    }

    const questionText = {
        minHeight: 150,
        width: '100%',
        border: 'none',
        borderRadius: '5px',
        padding: '20px',
        color: '#3c3c3c',
        backgroundColor: '#e4e3e3',
        fontFamily: 'Gelion-Normal'
    }

    /* style */


   

    return (
        <div style={containerBox}>

            

            <Transition visible={isOpenModal} animation='scale' duration={500}>
                <Modal className="users-delete-ask-modal" size={"mini"} open={isOpenModal} onClose={() => { setIsOpenModal(false) }}>
                    <Modal.Header><span className={"account-text"}>{modalHeader}</span></Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Header>{modalDesc}</Header>
                        </Modal.Description>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => { setIsOpenModal(false); props.GetDialogsBySimulationId(); /*props.ClosePanel(); */ }}>
                            Done
                        </Button>

                    </Modal.Actions>
                </Modal>
            </Transition>

            

            <div style={panel}>

               


                <div className="question-panel-header">
                    <div>
                        <Button size="big" onClick={props.ClosePanel} icon='arrow left' />
                    </div>
                    <div>
                        <Header as='h1' disabled>
                            {props.IsQuestion ? " Update question" : "Add question"}
                           
                        </Header>
                    </div>
                </div>
               
                

                <div className="question-box">
                    <div className="question-text-field">
                        <Header as='h3' disabled>
                            Question body
                        </Header>
                        <TextArea
                            value={questionBodyText}
                            onChange={e => { setQuestionBody(e.target.value)}}
                            placeholder='Question body...'
                            style={questionText} />
                    </div>
                    <div className="question-choose-field">
                        <Header as='h3' disabled>
                            Question options
                        </Header>

                            {options.map((item, index) =>
                                <QuestionOption
                                    ChangeCorrectValue={changeCorrectOption}
                                    ChangeValue={changeOptionText}
                                    IsCorrect={ item.IsCorrect}
                                    key={index}
                                    Text={item.Text}
                                    Label={item.Id} />
                            )}
           
                    </div>
                    <div className="question-option-field">
                        {optionsLimit ?
                            <Button onClick={ removeOption } color="red" icon='minus' />
                            :
                            <div className="question-option-add-remove-box">
                                <div>
                                    <Transition visible={visibleRemove} animation='scale' duration={500}>
                                        <Button onClick={removeOption} color="red" icon='minus' />
                                    </Transition>
                                </div>
                                <Button onClick={addOption} icon='plus' />
                            </div>
                        }
                        
                    </div>
                    <div className="question-save-button-field">
                        <Button
                            onClick={props.IsQuestion ? updateQuestion :  saveQuestion}
                            color={props.IsQuestion ? "orange" : "green"}
                            size="big"
                            content={questionLoading ? <Loader size="mini" active inline /> :  props.IsQuestion ? "Update" : "Save"}
                            icon='save'
                            labelPosition='left' />
                    </div>
                </div>
               
            </div>

            <div style={backgroundPanel}></div>
        </div>
            
        )
}
