import React, { useState, useEffect, useContext} from 'react';
import './welcomepage.css'
import { NavMenu } from './NavMenu';
import axios from 'axios';
import Cookies from 'universal-cookie';

import { Input, Transition, Message, Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import { loginContext } from '../../Store';


const cookies = new Cookies();
export const Login = (props) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isError, setIsError] = useState(false);

    const [isLoginControl, setLoginControl] = useContext(loginContext);

    const [noLogin, setNoLogin] = useState(false);
    const [noPasswordFullName, setNoPasswordFullName] = useState("");
    const [noPasswordUserId, setNoPasswordUserId] = useState("");
    const [noPasswordUserEmail, setNoPasswordUserEmail] = useState("");
    const [noPassword, setNoPassword] = useState("");
    const [noPasswordAgain, setNoPasswordAgain] = useState("");
    const [loaderActive, setLoaderActive] = useState(false);
    const [messageColor, setMessageColor] = useState("");

    const [header, setHeader] = useState("");
    const [message, setMessage] = useState("");

    const [noAccess, setNoAccess] = useState(false);

    useEffect(() => {
        getSettingsById();
    }, []);

    const getSettingsById = () => {
        var data = {
            Id: "47d88bff-0fef-4e9c-b75b-cf68191d5dc5",
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: data
        });

        axios.post('/Settings/GetById', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(JSON.parse(response.data.data.settingsJSON));
            if (response.data.resultCode == 0) {
                let settings = response.data.data;
                if (JSON.parse(settings.settingsJSON).closeAccessSite.IsActive) {
                    setNoAccess(true);
                  }
                    
            }

        });
    }
   

    const loginProces = (props) => {
        let userData = {
            Email: email,
            Password: password
        }

        if (userData.Email.trim() != "") {
            setLoaderActive(true);
            axios.post('/User/Login', userData)
                .then((response) => {
                    console.log(response);
                    setLoaderActive(false);

                    if (response.data.resultCode == 7) {
                        setNoLogin(true);
                        setNoPasswordFullName(response.data.data.name + " " + response.data.data.surname);
                        setNoPasswordUserId(response.data.data.id);
                        setNoPasswordUserEmail(response.data.data.email);
                        setMessageColor("blue")
                        setIsError(true);
                        setHeader("Warning");
                        setMessage("We sent you an e-mail to define a password. Please continue via the incoming mail.");
                    }
                    else {
                        if (response.data.resultCode === 0) {
                            setIsError(false);
                            cookies.set('Token', response.data.data.token);
                            cookies.set('Name', response.data.data.name);
                            cookies.set('Surname', response.data.data.surname);
                            cookies.set('FullName', response.data.data.name + " " + response.data.data.surname);
                            cookies.set('UserId', response.data.data.id);
                            cookies.set('CompanyId', response.data.data.companyId);
                            cookies.set('Email', response.data.data.email);
                            cookies.set('Username', response.data.data.username);
                            cookies.set('UserType', response.data.data.userType);
                            cookies.set('IsLogin', true);
                            setLoginControl(true);


                            if (response.data.data.userType == "2") {
                                window.location.href = 'superadmin';
                            }

                            if (response.data.data.userType == "4") {
                                window.location.href = "/user-dashboard";
                            } else if (response.data.data.userType == "1") {
                                window.location.href = "/dashboard";
                            } else {
                                window.location.href = 'superadmin';
                            }
                            

                        } else {
                            setIsError(true);
                            setHeader("Error");
                            setMessage("Email or password is not correct, please try again.");
                            setLoginControl(false);
                            setMessageColor("red")

                            if (response.data.resultCode == 8) {
                                setIsError(true);
                                setHeader("Error");
                                setMessage(response.data.message);
                                setLoginControl(false);
                                setMessageColor("red")
                            }
                        }
                    }


                    
                });
        } else {
            setIsError(true);
        }

        
    } 



    return (
        <div>
            <div id="main-page-header-box-login">

                <NavMenu />

                <div id="nav-sub-text-content-id" className="nav-sub-text-content">

                    <h1 className='header-animation-header-text header-text-login'>Login
                    </h1>

                    <div className="login-panel-box">
                        <div className="login-panel-form-element">
                            <Input
                                className="login-form-input"
                                fluid
                                size='huge'
                                icon='mail'
                                placeholder='Email'
                                value={email}
                                onChange={e => { setEmail(e.target.value) }}
                            />
                        </div>

                        <div className="login-panel-form-element">
                            <Input
                                className="login-form-input"
                                type="password"
                                fluid
                                size='huge'
                                icon='lock'
                                placeholder='Password'
                                value={password}
                                onChange={e => { setPassword(e.target.value) }}
                            />
                        </div>
                        <div>
                            <Link to="/forget-password" > <span className="forget-password-text">Forget my password</span></Link>
                        </div>

                        <div className="login-form-button">
                            <button
                                onClick={loginProces}
                                className="navbar-login-button main-page-start-button">
                                {loaderActive ? <Loader size="medium" active inline /> : "Login"}
                                </button>
                        </div>

                        <div className="alert-box">
                            <Transition visible={isError} animation='scale' duration={500}>
                                <Message color={messageColor}>
                                    <Message.Header>{header}</Message.Header>
                                    <p>{message}</p>
                                </Message>
                            </Transition>
                        </div>

                    </div>

                    
                </div>
            </div>
        </div>
    );

}