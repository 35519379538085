import React, { useState, useContext, useEffect, useRef } from 'react';
import {
    Header,
    Input,
    Form,
    Checkbox,
    Transition,
    Loader,
    Button,
    Icon
} from 'semantic-ui-react'
import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const ForegroundPhoto = (props) => {

    const [startingUpload, setStartingUpload] = useState(false);
    const [foregroundPhotos, setForegroundPhotos] = useState([]);
    const [selectedPhoto, setSelectedPhoto] = useState("any");

    const [data] = useState({
        userId: props.UserId,
        companyId: props.CompanyId,
        simulationId: props.SimulationId
    });

    const handleChangeFileInput = (file) => {
        console.log(file);
        uploadPhoto(file);
    }

    const handleChangePhoto = (id) => {
        setSelectedPhoto(id);
    }

    const selectPhoto = (photoId) => {
        updateSimulation(photoId)
    }

    const updateSimulation = (photoId) => {
        setStartingUpload(true);
        let backgroundMusicData = {
            Id: data.simulationId,
            ForegroundPhotoId: photoId
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: backgroundMusicData
        });

        axios.post('/Simulation/UpdateForegroundPhoto', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);

            if (response.data.resultCode == 0) {
                let data = response.data.data;

                if (data.foregroundPhotoId == null) 
                    setSelectedPhoto("");
                
                setStartingUpload(false)
            }
        });
    }

    const uploadPhoto = (file) => {
        setStartingUpload(true);
        let foregroundPhotoData = {
            companyId: data.companyId,
            userId: data.userId,
            simulationId: data.simulationId
        }

        var formData = new FormData();
        formData.append('Data', file);
        formData.append('AuthToken', cookies.get('Token'));
        formData.append('ForegroundPhoto', JSON.stringify(foregroundPhotoData));

        axios({
            method: 'POST',
            url: "/ForegroundPhoto/Upload",
            data: formData
        }).then((response) => {
            console.log(response);

            if (response.data.result.resultCode == 0) {
                getForegroundPhotos();
                setSelectedPhoto(response.data.result.data.id);
                setStartingUpload(false);
            }
            else {
                alert(response.data.result.message);
                setStartingUpload(false);
            }
        });
    }

    const getForegroundPhotos = () => {

        var foregroundPhotoData = {
            CompanyId: data.companyId
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: foregroundPhotoData
        });

        axios.post('/ForegroundPhoto/GetByCompanyId', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);

            if (response.data.resultCode == 0) {
                let photos = response.data.data;
                setForegroundPhotos(photos);
            }
        });
    }

    const getSelectedPhoto = () => {
        setStartingUpload(true);
        let foregroundPhotoData = {
            Id: data.simulationId,
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: foregroundPhotoData
        });

        axios.post('/Simulation/GetById', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);

            if (response.data.resultCode == 0) {
                setSelectedPhoto(response.data.data.foregroundPhotoId);
                setStartingUpload(false);
            }
        });
    }

    const deletePhoto = (photoId) => {
        setStartingUpload(true);
        let foregroundPhotoData = {
            Id: photoId,
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: foregroundPhotoData
        });

        axios.post('/ForegroundPhoto/Delete', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);

            if (response.data.resultCode == 0) {

                setForegroundPhotos([...foregroundPhotos.filter(
                    photo =>
                        photo.id != response.data.data.id
                )]);

                setStartingUpload(false);
            }
        });
    }

    useEffect(() => {
        getForegroundPhotos();
        getSelectedPhoto();
    }, []);

    const styles = {
        uploadNewMusicContent: {
            marginTop: "40px"
        },
        musicsForm: {
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "2em"
        },
        musicField: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }
    }


    return (
        <div>
            <Header
                disabled
                as='h2'
            >
                Upload new photo or choose from installed ones
            </Header>

            <div style={styles.uploadNewMusicContent}>

                <Header
                    disabled
                    as='h3'
                    color="orange"
                >
                    Upload new photo
                </Header>

                <Input
                    accept="image/*"
                    type="file"
                    onChange={(e) => handleChangeFileInput(e.target.files[0])}
                />

            </div>

            <div style={styles.uploadNewMusicContent}>

                <Header
                    disabled
                    as='h3'
                    color="orange"
                >
                    Select a foreground photo
                </Header>

                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%"
                }}>
                    <Button
                        onClick={() => { selectPhoto(null)}}
                        color = "red"
                        size="big"
                        animated
                    >
                        <Button.Content visible>Cancel selection</Button.Content>
                        <Button.Content hidden>
                            <Icon name='cancel' />
                        </Button.Content>
                    </Button>
                </div>

                <Transition visible={startingUpload} animation='scale' duration={500}>
                    <Loader size="huge" active inline='centered' />
                </Transition>

                <Transition visible={!startingUpload} animation='scale' duration={500}>
                    <>
                        <div style={styles.musicsForm}>
                            {foregroundPhotos.map(photo => (
                                <Form.Field
                                    key={photo.id}
                                    style={styles.musicField}>
                                    <Checkbox
                                        radio
                                        label={
                                            <div
                                                id={photo.id}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems:"center",
                                                    width: "300px",
                                                    height:"300px",
                                                    padding: "10px",
                                                    margin: "10px",
                                                    border: selectedPhoto == photo.id ? "solid 3px red" : "solid 3px #ccc",
                                                    borderRadius: "5px",
                                                    cursor: "pointer"
                                                }}
                                                onClick={(e) => { selectPhoto(photo.id) }}
                                            >
                                              
                                                <img

                                                    width= "100%"
                                                        src={window.location.origin + `/Assets/ForegroundPhotos/` + photo.photoUrl}
                                                    />
                                                    
                                               
                                            </div>
                                        }
                                        name='checkboxRadioGroup'
                                        value={photo.id}
                                        checked={selectedPhoto === photo.id}
                                        onChange={() => { handleChangePhoto(photo.id) }}
                                    />
                                    <Button
                                        onClick={() => {
                                            deletePhoto(photo.id)
                                            }
                                        }
                                        size="huge"
                                        icon='trash'
                                        color="red"
                                    />
                                </Form.Field>
                            ))}
                        </div>
                    </>
                </Transition>

                <Transition
                    visible={foregroundPhotos.length == 0}
                    animation='scale'
                    duration={500}
                >
                    <div>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            width: "100%"
                        }}>
                            <Header
                                disabled
                                as='h2'
                            >
                                Not found Foreground Photos
                            </Header>

                            <Icon
                                disabled
                                name="images"
                                size="huge"
                            />
                        </div>
                    </div>

                </Transition>

            </div>
        </div >
    );
};