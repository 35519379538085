import React, { useState, useContext, useEffect } from 'react';
import { Header, Icon, Label, Popup, Button } from 'semantic-ui-react';
import { OtherSimulationCard } from '../OtherSimulationCard/OtherSimulationCard';
import { OtherSimulationQuestion } from '../OtherSimulationQuestion/OtherSimulationQuestion';

export const OtherSimulationList = (props) => {


    console.log("props.props.Questions", props.Questions)

    const unique = [...new Map(props.Questions.reverse().map(item => [item["QuestionId"], item])).values()]
    console.log("unique", unique);


    const otherSimulationListBoxStyle = {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between"
    }

    const otherSimulationQuestionBoxStyle = {
        display: "flex",
        justifyContent: "space-around",
        marginTop: "30px",
        flexWrap: "wrap"
    }

    const otherSimulationHeaderStyle = {
        display: "flex",
        justifyContent: "space-between",
    }

    return (
        <div className="other-simulation-list-box">
            <div style={ otherSimulationHeaderStyle}>
                <Label color="yellow" size={"big"}>
                    {props.Index}
                </Label>

                <Popup
                    content={props.SuccessInfo ? "User has succeeded" : "User did not pass the success score"}
                    trigger={
                        <Button
                            color={ props.SuccessInfo ? "green" : "red"}
                            size="large"
                            icon={
                                <Icon
                                    color={ props.SuccessInfo ? "green" : "red"}
                                    name={ props.SuccessInfo ? "check" : "close"}
                                />
                            }
                        >{props.SuccessInfo ? "Success" : "Fail"}</Button>
                    }
                    />
            </div>
            <div style={otherSimulationListBoxStyle}>
                <OtherSimulationCard

                    Header={"Point"}
                    Text={parseFloat(props.Point).toFixed(1)}
                    Width="140px"
                    Height="140px"
                    Color="white"
                    Padding="20px"
                    BorderRadius={"5px"}
                    HeaderColor={"white"}
                    MarginTop={"20px"}
                />

                <OtherSimulationCard

                    Header={"True questions"}
                    Text={props.QuestionInfo[0]}
                    Width="160px"
                    Height="140px"
                    Color="white"
                    Padding="20px"
                    BorderRadius={"5px"}
                    HeaderColor={"white"}
                    MarginTop={"20px"}

                />

                <OtherSimulationCard

                    Header={"Total questions"}
                    Text={props.QuestionInfo[1]}
                    Width="170px"
                    Height="140px"
                    Color="white"
                    Padding="20px"
                    BorderRadius={"5px"}
                    HeaderColor={"white"}
                    MarginTop={"20px"}

                />

                <OtherSimulationCard

                    Header={"User Rate"}
                    Text={props.UserRateInfo[0]}
                    Width="170px"
                    Height="140px"
                    Color="white"
                    Padding="20px"
                    BorderRadius={"5px"}
                    HeaderColor={"white"}
                    MarginTop={"20px"}

                />

                <OtherSimulationCard

                    Header={"Enrolled Users"}
                    Text={props.UserRateInfo[1]}
                    Width="170px"
                    Height="140px"
                    Color="white"
                    Padding="20px"
                    BorderRadius={"5px"}
                    HeaderColor={"white"}
                    MarginTop={"20px"}

                />

                <OtherSimulationCard

                    Header={"Success info"}
                    Text={props.SuccessInfo == true ? "Success" : "Fail"}
                    Width="170px"
                    Height="140px"
                    Color="white"
                    Padding="20px"
                    BorderRadius={"5px"}
                    HeaderColor={"white"}
                    MarginTop={"20px"}
                    FontSize={"1.3em"}

                />
                <OtherSimulationCard
                    Header={"Enrolled date"}
                    Text={props.EnrolledDate}
                    Width="170px"
                    Height="140px"
                    Color="white"
                    Padding="20px"
                    BorderRadius={"5px"}
                    HeaderColor={"white"}
                    MarginTop={"20px"}
                    FontSize={"1.3em"}

                />

                <OtherSimulationCard
                    Header={"Starting date"}
                    Text={props.StartingDate}
                    Width="170px"
                    Height="140px"
                    Color="white"
                    Padding="20px"
                    BorderRadius={"5px"}
                    HeaderColor={"white"}
                    MarginTop={"20px"}
                    FontSize={"1.3em"}

                />

                <OtherSimulationCard
                    Header={"Finished date"}
                    Text={props.FinishedDate}
                    Width="170px"
                    Height="140px"
                    Color="white"
                    Padding="20px"
                    BorderRadius={"5px"}
                    HeaderColor={"white"}
                    MarginTop={"20px"}
                    FontSize={"1.3em"}

                />


            </div>

            <div>
                <Header style={{ marginTop: "50px" }} as='h1'>Questions</Header>
            </div>
            <div style={otherSimulationQuestionBoxStyle}>
                {unique.map((item, index) =>
                    <OtherSimulationQuestion
                        Index={parseInt(index) + 1}
                        QuestionId={item.QuestionId}
                        UserAnswer={item.UserAnswer}
                        GroupedQuestion={props.GroupedQuestion ? props.GroupedQuestion[item.QuestionId] : null}

                    />
                )} 
                
            </div>
        </div>
    )
}