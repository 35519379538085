import React, { useState, useContext, useEffect } from 'react';
import { Header, Icon } from 'semantic-ui-react';

export const SystemReportCard = (props) => {

    const cardStyle = {
        height: props.Height || "auto",
        width: props.Width || "200px",
        backgroundColor: "white",
        boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
        padding: props.Padding || "30px",
        borderRadius: props.borderRadius || "10px",
        display: "flex",
        alignItems: props.AlignItems || "center",
        justifyContent: "center",
        flexDirection: "column",
        fontSize: props.FontSize || "3em",
        fontWeight: "bold",
        color: "#9c9c9c",
        marginRight: "30px",
        marginTop: props.MarginTop || "20px",
        margin: props.Margin || "10px"
    };

    const textStyle = {
        marginTop: "20px",
        fontSize: props.FontSize || "1em"
    }

    const iconStyle = {
        marginTop:"20px"
    }

    return (
        <div style={cardStyle}>
            <h2
                style={{ color: "#9c9c9c", marginBottom: props.HeaderMarginBottom || "" }} >
                {props.Header}
            </h2>

            <Icon
                color={props.IconColor || ""}
                style={iconStyle}
                name={props.Icon}
            />

            <span
                style={textStyle}>
                {props.Text}
            </span>
        </div>
    )
}