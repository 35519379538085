import React, { useState, useContext, useEffect } from 'react';
import './useradd.css';
import axios from 'axios';
import { Button, Divider, Form, Checkbox, Dropdown, Modal, Transition, Icon, Header } from 'semantic-ui-react';
import Cookies from 'universal-cookie';
import { DashboardNavBar } from '../Dashboard/DashboardNavBar/DashboardNavBar';

const cookies = new Cookies();

export const UserAdd = (props) => {
    const [messageModalOpen, setMessageModalOpen] = useState(false);
    const [header, setHeader] = useState("");
    const [message, setMessage] = useState("");

    const [id, setId] = useState("");
    const [username, setUsername] = useState("");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isActive, setIsActive] = useState(true);
    const [userType, setUserType] = useState("");
    const [phone, setPhone] = useState("");
    const [organizationalStructure, setOrganizationalStructure] = useState("");
    const [registrationNumber, setRegistrationNumber] = useState("");

    let userTypeOptions = {
        options: [
            { value: '1', text: 'Admin' },
            { value: '4', text: 'User' },
        ],
        selected: userType,
    }

    const changeIsActive = (event, data) => {
        setIsActive(data.checked);
    }

    const changeUserTypeOptions = (event, data) => {
        setUserType(data.value);
    }

    const addUser = () => {
        var updateUser = {
            id: id,
            name: name,
            surname: surname,
            username: username,
            email: email,
            password: password,
            isActive: isActive,
            userType: userType,
            phone: phone,
            OrganizationalStructure: organizationalStructure,
            RegistrationNumber: registrationNumber,
            companyId: cookies.get('CompanyId')
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: updateUser
        });

        axios.post('/User/Add', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            console.log(response)
            if (response.data.resultCode == 0) {
                setHeader("Success");
                setMessage("User is added");
                setMessageModalOpen(true);
            } else {
                setHeader("Error");
                setMessage("User is not added");
                setMessageModalOpen(true);
            }


        });
    }

    return (



        <div>

            <Transition visible={messageModalOpen} animation='scale' duration={500}>
                <Modal className="users-delete-ask-modal" size={"mini"} open={messageModalOpen} onClose={() => { setMessageModalOpen(false) }}>
                    <Modal.Header><span className={"account-text"}>{header}</span></Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Header>{message}</Header>
                        </Modal.Description>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => { setMessageModalOpen(false) }} color='green'>
                            OKEY <Icon name='chevron right' />
                        </Button>

                    </Modal.Actions>
                </Modal>
            </Transition>


            <DashboardNavBar />

            <div className="dashboard-container-content user-edit-container-header">
                <h1>User Add</h1>

                <div className="user-edit-container">
                    <Form size={"big"}>
                        <Form.Field
                            label='First name'
                            value={name}
                            onChange={e => { setName(e.target.value) }}
                            control='input'
                            placeholder='First name'
                        />

                        <Form.Field
                            label='Last name'
                            value={surname}
                            onChange={e => { setSurname(e.target.value) }}
                            control='input'
                            placeholder='Last name'
                        />

                        <Form.Field
                            label='Username'
                            value={username}
                            onChange={e => { setUsername(e.target.value) }}
                            control='input'
                            placeholder='Username'
                        />

                        <Form.Field
                            autoComplete='off'
                            label='E-mail'
                            value={email}
                            onChange={e => { setEmail(e.target.value) }}
                            control='input'                            
                            placeholder='E-mail'
                        />

                        <Form.Field
                            autoComplete='off'
                            label='Password'
                            value={password}
                            onChange={e => { setPassword(e.target.value) }}
                            control='input'
                            type='password'
                            placeholder='Şifre'
                        />                        

                        <Form.Field>
                            <div className="form-field-label-text">Is Active</div>
                            <Checkbox
                                onChange={changeIsActive}
                                className="user-edit-is-active-checkbox"
                                checked={isActive}
                                toggle
                            />
                        </Form.Field>

                        <Form.Field>
                            <div className="form-field-label-text">User Type</div>
                            <Dropdown
                                selection
                                className="user-edit-is-active-checkbox"
                                placeholder='Select user type'
                                options={userTypeOptions.options}
                                value={userType.toString()}
                                onChange={changeUserTypeOptions}
                            />
                        </Form.Field>

                        <Form.Field
                            label='Phone'
                            value={phone}
                            onChange={e => { setPhone(e.target.value) }}
                            control='input'
                            placeholder='Phone'
                        />

                        <Form.Field
                            label='Organizational Structure'
                            value={organizationalStructure}
                            onChange={e => { setOrganizationalStructure(e.target.value) }}
                            control='input'
                            placeholder='Organizational Structure'
                        />

                        <Form.Field
                            label='Registration Number'
                            value={registrationNumber}
                            onChange={e => { setRegistrationNumber(e.target.value) }}
                            control='input'
                            placeholder='Registration Number'
                        />


                        <Button onClick={addUser} type='submit'>Save</Button>
                        <Divider hidden />
                    </Form>
                </div>
            </div>


        </div>
        );
}