import React, { useState, useContext, useEffect } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

export const CircleTime = (props) => {

    
    return (
        <CountdownCircleTimer
            size={65}
            isPlaying={props.CircleTimeIsPlaying}
            duration={props.Duration}
            colors={[
                //['#004777', 0.33],
                //['#F7B801', 0.33],
                //['#A30000', 0.33],
                ['#ffffff', 0.33],
                ['#ffffff', 0.33],
                ['#ffffff', 0.33],
            ]}
        >
            {({ remainingTime }) => remainingTime}
        </CountdownCircleTimer>
    );
}