import React, { useContext, useEffect, useState } from 'react';
import { Popup, Icon, Checkbox, Label } from 'semantic-ui-react';
import { Link } from 'react-router-dom';


export const ReportDataRow = (props) => {

    console.log("AllItems", props.AllItems);


    const cardStyle = {
        width: "100%",
        height: "60px",
        boxShadow: "rgb(149 157 165 / 10%) 0px 8px 24px",
        borderRadius: "10px",
        padding: "10px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        cursor: "pointer",
        marginTop: "17px",
        fontSize: "1.1em",
        backgroundColor: props.SelectedRow == props.Id ? "antiquewhite" : "unset",
        transition: "all 0.7s"
    }

    const cardTextField = {
        display: "flex",
        alignItems: "center",
        gap: "0.2em",
        width: "90%"
    }

    const openReportDetailPanel = () => {
        props.SetReportDetailVisible(true);
        props.HandleClickedRow(props.Id);
    }

    const rowStyle = {
        display: "flex",
        gap: "1em",
        justifyContent: "space-between",
        width: "100%"
    }

    const rowItemStyle = {
        width: props.WideView ? "170px" : "",
        overflowWrap: props.WideView ? "anywhere" : "none"
    }

    let userTypeColor = {
        1: "blue",
        4: "orange"
    }

    const handleChange = (event, data) => {

        console.log(data);



        let selectedRow = {
            id: data.id,
            text: data.Text,
            content: data.Content
        }

        if (data.checked) {
            let findedElement = [...props.SelectedDataRow].findIndex(item => item.id == selectedRow.id);

            findedElement == -1 && props.SetSelectedDataRow([...props.SelectedDataRow, selectedRow])


        }

        else {

            let newSelectedDataRow = [...props.SelectedDataRow].filter(item =>
                item.id !== data.id);

            props.SetSelectedDataRow(newSelectedDataRow);

        }

    }

    return (
        <Popup
            content={props.Content}
            trigger={
                <div
                    style={cardStyle}
                    onClick={openReportDetailPanel}
                >
                    <div style={cardTextField}>
                        <Checkbox
                            onChange={handleChange}
                            Content={props.Content}
                            Text={props.Text}
                            id={props.Id}
                        />
                        <Icon
                            disabled
                            size="big"
                            name={props.Icon}
                        />
                        <div style={rowStyle}>
                            <div style={ rowItemStyle}>
                                {props.Text}
                            </div>
                            {props.IsUserData && props.WideView &&
                                <>
                                <div style={rowItemStyle}>
                                        {props.AllItems.username}
                                    </div>

                                <div style={rowItemStyle}>
                                        {props.AllItems.email}
                                    </div>

                                <div style={rowItemStyle}>
                                        {props.AllItems.organizationalStructure || "-"}
                                    </div>

                                <div style={rowItemStyle}>
                                        {props.AllItems.phone || "-"}
                                    </div>

                                <div style={rowItemStyle}>
                                        {props.AllItems.registrationNumber || "-"}
                                    </div>

                                <div style={rowItemStyle}>
                                    <Label
                                        style={{ marginLeft: "10px" }}
                                        
                                        color={userTypeColor[parseInt(props.AllItems.userType)]} horizontal
                                    >
                                        {props.AllItems.userType === 1 ? "Admin" : "User"}
                                    </Label>

                                        
                                    </div>
                                </>
                            }
                        </div>
                    </div>

                    <div>
                        <Icon
                            color={props.IsActive ? "green" : "grey"}
                            disabled
                            name={"circle"}
                        />
                    </div>

                </div>
            }
        />
    )
}