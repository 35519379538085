import React, { useState, useContext, useEffect, useRef } from 'react';
import {
    Header,
    Input,
    Form,
    Checkbox,
    Transition,
    Loader,
    Button,
    Icon
} from 'semantic-ui-react'
import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const BackgroundMusic = (props) => {

    const [startingUpload, setStartingUpload] = useState(false);
    const [backgroundMusics, setBackgroundMusics] = useState([]);
    const [selectedBackgroundMusic, setSelectedBackgroundMusic] = useState("any2");

    const [data] = useState({
        userId: props.UserId,
        companyId: props.CompanyId,
        simulationId: props.SimulationId
    });

    const handleChangeFileInput = (file) => {
        console.log(file);
        uploadSound(file);
    }

    const handleChangeUploadedMusic = (id) => {
        setSelectedBackgroundMusic(id);
    }

    const selectMusic = (backgroundMusicId) => {
        updateSimulation(backgroundMusicId)
    }

    const updateSimulation = (backgroundMusicId) => {
        setStartingUpload(true);
        let backgroundMusicData = {
            Id: data.simulationId,
            BackgroundMusicId: backgroundMusicId
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: backgroundMusicData
        });

        axios.post('/Simulation/UpdateBackgroundMusic', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);

            if (response.data.resultCode == 0) {

                let data = response.data.data;

                if (data.backgroundMusicId == null)
                    setSelectedBackgroundMusic("");

                setStartingUpload(false)
            }
        });
    }

    const uploadSound = (file) => {
        setStartingUpload(true);
        let backgroundMusicData = {
            companyId: data.companyId,
            userId: data.userId,
            simulationId: data.simulationId
        }

        var formData = new FormData();
        formData.append('Data', file);
        formData.append('AuthToken', cookies.get('Token'));
        formData.append('BackgroundMusic', JSON.stringify(backgroundMusicData));

        axios({
            method: 'POST',
            url: "/BackgroundMusic/Upload",
            data: formData
        }).then((response) => {
            console.log(response);

            if (response.data.result.resultCode == 0) {
                getBackgroundMusics();
                setSelectedBackgroundMusic(response.data.result.data.id);
                setStartingUpload(false);
            }
            else {
                alert(response.data.result.message);
                setStartingUpload(false);
            }
        });
    }

    const getBackgroundMusics = () => {

        var backgroundMusicData = {
            CompanyId: data.companyId
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: backgroundMusicData
        });

        axios.post('/BackgroundMusic/GetByCompanyId', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);

            if (response.data.resultCode == 0) {
                let musics = response.data.data;
                setBackgroundMusics(musics);
            }
        });
    }

    const getSelectedBackgroundMusic = () => {
        setStartingUpload(true);
        let backgroundMusicData = {
            Id: data.simulationId,
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: backgroundMusicData
        });

        axios.post('/Simulation/GetById', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);

            if (response.data.resultCode == 0) {
                setSelectedBackgroundMusic(response.data.data.backgroundMusicId);
                setStartingUpload(false);
            }
        });
    }

    const deleteBackgroundMusic = (backgroundMusicId) => {
        setStartingUpload(true);
        let backgroundMusicData = {
            Id: backgroundMusicId,
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: backgroundMusicData
        });

        axios.post('/BackgroundMusic/Delete', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);

            if (response.data.resultCode == 0) {

                setBackgroundMusics([...backgroundMusics.filter(
                    music =>
                        music.id != response.data.data.id
                )]);

                setStartingUpload(false);
            }
        });
    }

    useEffect(() => {
        getBackgroundMusics();
        getSelectedBackgroundMusic();
    }, []);

    const styles = {
        uploadNewMusicContent: {
            marginTop: "40px"
        },
        musicsForm: {
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "2em"
        },
        musicField: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }
    }


    return (
        <div>
            <Header
                disabled
                as='h2'
            >
                Upload new music or choose from installed ones
            </Header>

            <div style={styles.uploadNewMusicContent}>

                <Header
                    color="orange"
                    disabled
                    as='h3'
                >
                    Upload new music
                </Header>

                <Input
                    accept="audio/mp3,audio/*;capture=microphone"
                    type="file"
                    onChange={(e) => handleChangeFileInput(e.target.files[0])}
                />

            </div>

            <div style={styles.uploadNewMusicContent}>

                <Header
                    disabled
                    as='h3'
                    color="orange"
                >
                    Selec a background music
                </Header>

                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%"
                }}>
                    <Button
                        onClick={() => { selectMusic(null) }}
                        color="red"
                        size="big"
                        animated
                    >
                        <Button.Content visible>Cancel selection</Button.Content>
                        <Button.Content hidden>
                            <Icon name='cancel' />
                        </Button.Content>
                    </Button>
                </div>

                <Transition visible={startingUpload} animation='scale' duration={500}>
                    <Loader size="huge" active inline='centered' />
                </Transition>

                <Transition visible={!startingUpload} animation='scale' duration={500}>
                    <>
                        <div style={styles.musicsForm}>
                            {backgroundMusics.map(music => (
                                <Form.Field key={music.id} style={styles.musicField}>
                                    <Checkbox
                                        radio
                                        label={
                                            <div
                                                id={music.id}
                                                style={{
                                                    padding: "10px",
                                                    margin: "10px",
                                                    border: selectedBackgroundMusic == music.id ? "solid 3px red" : "solid 3px #ccc",
                                                    borderRadius: "5px",
                                                    cursor: "pointer"
                                                }}
                                                onClick={(e) => { selectMusic(music.id) }}
                                            >
                                                <audio
                                                    controls
                                                >
                                                    <source
                                                        src={window.location.origin + `/Assets/BackgroundMusics/` + music.musicUrl}
                                                        type="audio/mpeg"
                                                    />
                                                    Your browser does not support the audio element.
                                                </audio>
                                            </div>
                                        }
                                        name='checkboxRadioGroup'
                                        value={music.id}
                                        checked={selectedBackgroundMusic === music.id}
                                        onChange={() => { handleChangeUploadedMusic(music.id) }}
                                    />
                                    <Button
                                        onClick={() => {
                                            deleteBackgroundMusic(music.id)
                                        }
                                        }

                                        size="huge"
                                        icon='trash'
                                        color="red"
                                    />
                                </Form.Field>
                            ))}
                        </div>
                    </>
                </Transition>

                <Transition
                    visible={backgroundMusics.length == 0}
                    animation='scale'
                    duration={500}
                >
                    <div>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            width: "100%"
                        }}>
                            <Header
                                disabled
                                as='h2'
                            >
                                Not found Background Musics
                            </Header>

                            <Icon
                                disabled
                                name="music"
                                size="huge"
                            />
                        </div>
                    </div>

                </Transition>

            </div>
        </div >
    );
};