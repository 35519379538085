import React, { useContext, useEffect, useState } from 'react';
import { Popup, Icon, Loader, Header, Label, Table, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { OtherSimulationCard } from '../../UserProfile/UserOtherSimulations/OtherSimulationCard/OtherSimulationCard';
import { ReportUserTable } from './ReportUserTable/ReportUserTable';
import { UserDegreeTable } from './UserDegreeTable/UserDegreeTable';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    AreaChart,
    Area,
    Pie,
    PieChart,
    Cell
} from "recharts";
import { dateFormat, downloadFile } from '../../../../helper/helper';
import { saveAs } from 'file-saver';

import axios from 'axios';
import Cookies from 'universal-cookie';


const cookies = new Cookies();



export const ReportUser = (props) => {

    //for doughnut chart
    const COLORS = ["#82ca9d", "#d88484"];

    const [progressChart, setProgressChart] = useState([]);
    const [trueWrongChart, setTrueWrongChart] = useState([]);
    const [generalSuccessRateChart, setGeneralSuccessRateChart] = useState([]);
    const [donutChart, setDonutChart] = useState([]);
    const [degreeSimulations, setDegreeSimulations] = useState([]);

    const [reportTableIsLoading, setReportTableIsLoading] = useState(false);

    const [user, setUser] = useState("");
    const [loading, setLoading] = useState(true);
    const [gamificationPoint, setGamificationPoint] = useState(0);

    const [multiSelectedUsersReportExcel, setMultiSelectedUsersReportExcel] = useState([]);


    //export excel data
    const [reportUserSimulationData, setReportUserSimulationData] = useState([]);


    let userTypeColor = {
        1: "blue",
        4: "orange"
    }

    const headerStyle = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        alignContent: "center"
    }

    const subHeaderStyle = {
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        marginTop: "40px",
        justifyContent: "flex-start",
        flexWrap: "wrap"
    }

    const tableContentStyle = {
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        marginTop: "40px",
        justifyContent: "center",
        flexWrap: "wrap",
        padding: "18px"
    }

    const chartContainerStyle = {
        display: "flex",
        flexDirection: "column",
        width:"100%"
    }

    const twoSectionStyle = {
        display: "flex",
        flexDirection: "row",
        marginTop: "90px",
        justifyContent: "space-evenly",
        padding:"20px"
    }

    const chartContainer = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop:"50px"
    }

    const degreeContainer = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    }

    useEffect(() => {

        if (props.Id) {
            setReportTableIsLoading(false);
            getByUserId(props.Id);
            getUserGamificationPoint(props.Id);
        }

        

    }, [props.Id]);

    useEffect(() => {

        console.log(multiSelectedUsersReportExcel);
        props.SetMultiSelectedUsersReportExcel(multiSelectedUsersReportExcel);


    }, [multiSelectedUsersReportExcel]);

    

    const getByUserId = (id) => {
        setLoading(true);
        let data = {
            Id: id
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: data
        });

        axios.post('/User/GetById', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            
            if (response.data.resultCode == 0) {
                setUser(response.data.data);
                setLoading(false);
            } else {

            }
        });

    }

    const getUserGamificationPoint = (id) => {
        
        let data = {
            Id: id
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: data
        });

        axios.post('/User/GetGamificationPoint', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
           
            if (response.data) {
                setGamificationPoint(response.data.toFixed(1));
            } 
        });
    }

    const userExportDataToExcel = () => {

        let userData = {
            User: user,
            UserProfileSimulationReportView: reportUserSimulationData,
            UserOverallSuccessRateModel: donutChart
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: userData
        });

        axios.post('/User/UserReportExport', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
            
        }).then((response) => {
            downloadFile(response.data.result.value, "UserReport-" + user.name + "-" + user.surname + "--" + dateFormat(new Date(), true) + ".xlsx");
        });
    }


    return (
        <div>
            {
                loading ?
                    <Loader
                        style={{ marginTop: "18%" }}
                        size="huge"
                        active={loading}
                        inline='centered'
                    />
                    :
                    <div>

                        <div style={headerStyle}>
                            <div>
                                <Header as='h1'>
                                    {user.name + " " + user.surname}
                                    <Label
                                        style={{ marginLeft: "10px" }}
                                        size="big"
                                        color={userTypeColor[parseInt(user.userType)]} horizontal
                                    >
                                        {user.userType === 1 ? "Admin" : "User"}
                                    </Label>
                                    <Label
                                        style={{ marginLeft: "5px" }}
                                        size="big"
                                        color={user.isActive === true ? "green" : "grey"} horizontal
                                    >
                                        {user.isActive === true ? "Active" : "Passive"}
                                    </Label>
                                </Header>
                            </div>

                            <div style={{
                                display: "flex",
                                alignItems: "center"
                            }}>
                                <Label color="yellow" size="big">
                                    <Icon name='winner' />
                                    {gamificationPoint}
                                <Label.Detail>Gamification Point</          Label.Detail>
                                </Label>

                                {reportTableIsLoading &&
                                    <Popup 
                                        content='Export Excel Report' 
                                        trigger={
                                            <Button

                                                onClick={userExportDataToExcel}
                                                color="green"
                                                icon>
                                                <Icon
                                                    name='file excel'
                                                />
                                            </Button>
                                        } 
                                    />
                                    
                                }
                                
                            </div>
                            


                        </div>

                        <div style={subHeaderStyle}>
                            <OtherSimulationCard
                                Width={"200px"}
                                Height={"120px"}
                                Header={"Email"}
                                Text={user.email}
                                FontSize={"1.2em"}
                                AlignItems={"flex-start"}
                                Height={"85px"}
                                Padding={"27px"}
                                HeaderMarginBottom={"0"}
                                Margin={"10px"}
                            />

                            {user.organizationalStructure ?
                                <OtherSimulationCard
                                    Width={"200px"}
                                    Height={"120px"}
                                    Header={"Organizational Structure"}
                                    Text={user.organizationalStructure}
                                    FontSize={"1.2em"}
                                    AlignItems={"flex-start"}
                                    Height={"85px"}
                                    Padding={"27px"}
                                    HeaderMarginBottom={"0"}
                                    Margin={"10px"}
                                /> : ""

                            }

                            {user.phone ?
                                <OtherSimulationCard
                                    Width={"200px"}
                                    Height={"120px"}
                                    Header={"Phone"}
                                    Text={user.phone}
                                    FontSize={"1.2em"}
                                    AlignItems={"flex-start"}
                                    Height={"85px"}
                                    Padding={"27px"}
                                    HeaderMarginBottom={"0"}
                                    Margin={"10px"}
                                /> : ""
                            }

                            {user.createdDate ?
                                <OtherSimulationCard
                                    Width={"200px"}
                                    Height={"120px"}
                                    Header={"Created Date"}
                                    Text={dateFormat(user.createdDate,true)}
                                    FontSize={"1.2em"}
                                    AlignItems={"flex-start"}
                                    Height={"85px"}
                                    Padding={"27px"}
                                    HeaderMarginBottom={"0"}
                                    Margin={"10px"}
                                /> : ""
                            }

                            {user.registrationNumber ?
                                <OtherSimulationCard
                                    Width={"200px"}
                                    Height={"120px"}
                                    Header={"Registration Number"}
                                    Text={user.registrationNumber}
                                    FontSize={"1.2em"}
                                    AlignItems={"flex-start"}
                                    Height={"85px"}
                                    Padding={"27px"}
                                    HeaderMarginBottom={"0"}
                                    Margin={"10px"}
                                /> : ""
                            }

                        </div>

                        <div style={tableContentStyle}>
                            <ReportUserTable
                                SetWhenSelectDataLoading={props.SetWhenSelectDataLoading}
                                SetProgressChart={setProgressChart}
                                SetTrueWrongChart={setTrueWrongChart}
                                SetReportTableIsLoading={setReportTableIsLoading}
                                SetDonutChart={setDonutChart}
                                SetDegreeSimulations={setDegreeSimulations}
                                UserId={user.id}
                                User={user}
                                ReportUserSimulationData={reportUserSimulationData}
                                SetReportUserSimulationData={setReportUserSimulationData}
                                SetMultiSelectedUsersReportExcel={setMultiSelectedUsersReportExcel}
                                MultiSelectedUsersReportExcel={multiSelectedUsersReportExcel}
                            />
                        </div>

                        {reportTableIsLoading ?
                            <div style={chartContainerStyle}>
                                <div style={ chartContainer}>
                                    <Header disabled as='h1'>Simulation Progress Percentage Chart</Header>

                                    <BarChart
                                        width={700}
                                        height={300}
                                        data={progressChart}
                                        margin={{
                                            top: 10,
                                            right: 30,
                                            left: 20,
                                            bottom: 5
                                        }}
                                        barSize={20}
                                    >
                                        <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <Bar dataKey="ProgressPercentage" fill="#8884d8" background={{ fill: "#eee" }} />
                                    </BarChart>

                                </div>

                                <div style={chartContainer}>
                                    <Header disabled as='h1'>Questions Answer Rate Chart</Header>
                                    <BarChart
                                        width={700}
                                        height={300}
                                        data={trueWrongChart}
                                        margin={{
                                            top: 20,
                                            right: 30,
                                            left: 20,
                                            bottom: 5
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="TrueCount" stackId="a" fill="#82ca9d" />
                                        <Bar dataKey="WrongCount" stackId="a" fill="#d88484" />
                                        <Bar dataKey="UnansweredCount" fill="#ffc658" />
                                    </BarChart>
                                </div>

                                <div style={twoSectionStyle}>
                                    <div>
                                        <Header disabled as='h2'>Overall Success Rate Chart</Header>
                                        <PieChart width={300} height={400} style={{top:"-110px"}}>
                                            <Pie
                                                dataKey="value"
                                                data={donutChart}

                                                innerRadius={40}
                                                outerRadius={80}
                                            >
                                                {donutChart.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                ))}
                                            </Pie>
                                            <Tooltip />
                                        </PieChart>
                                    </div>

                                    <div style={degreeContainer}>
                                        <Header disabled as='h2'>Achievement Degree Table</Header>

                                        <UserDegreeTable
                                            Simulations={degreeSimulations}
                                        />

                                    </div>
                                </div>


                            </div> : ""
                            }



                    </div>
            }


        </div>
    )
}