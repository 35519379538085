import React from 'react';
import './simulationscard.css';
import { Card, Icon, Image, Button, Label, Progress } from 'semantic-ui-react';
import noImageSimulation from '../../../../../../images/matthew.png';
import { Link } from 'react-router-dom';


export const SimulationsCard = (props) => {
    
    return (
        <Card className='simulation-card'>
            <Link to={"/simulation-detail/" + props.Id}>
                <Image
                    width="290"
                    height="290"
                    className="simulation-card-image"
                    src={props.Image ? props.Image : noImageSimulation}
                    wrapped
                    ui={false}
                />
            </Link>
            <Card.Content>
                <Card.Header>
                    <Link to={"/simulation/" + props.Id}>
                        <Label as='a' color='red'>New</Label>
                        <span
                            className="simulation-card-header-text">
                            {props.Name}
                        </span>
                    </Link>
                </Card.Header>
                <Card.Meta className="simulation-card-date-box">
                    <span className='date'>{props.CreatedDate}</span>
                </Card.Meta>
                <Card.Description> {props.Desc} </Card.Description>
            </Card.Content>
            <Card.Content extra>

                <div className="completion-text">completion rate : %{props.CompletionRate}</div>
                <Progress
                    className="user-dashboard-card-progress-bar"
                    percent={props.CompletionRate}
                    indicating
                />
            </Card.Content>
        </Card>
    )
}

