import React, { useState, useContext, useEffect } from 'react';
import './simulationsadd.css';
import axios from 'axios';
import Cookies from 'universal-cookie';

import { Button, Divider, Form, Checkbox, Dropdown, Modal, Transition, Icon, Header, Card, Input, TextArea, Label } from 'semantic-ui-react';
import { DashboardNavBar } from '../../Dashboard/DashboardNavBar/DashboardNavBar';
import { Link } from 'react-router-dom';

const cookies = new Cookies();

export const SimulationsAdd = (props) => {

    //modal
    const [messageModalOpen, setMessageModalOpen] = useState(false);
    const [header, setHeader] = useState("");
    const [message, setMessage] = useState("");
    const [isError, setIsError] = useState(true);
    //modal

    //date hook
    const [today] = useState(new Date().toISOString().split('T')[0]);
    //date hook

    //simulation data hook
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [estimatedTime, setEstimatedTime] = useState(0);
    const [startingDate, setStartingDate] = useState(today);
    const [dueDate, setDueDate] = useState("");
    const [companyId, setCompanyId] = useState(cookies.get('CompanyId').toString());
    //simulation data hook

    const [simulationId, setSimulationId] = useState("");

    const addSimulation = () => {

        var simulation = {
            Id: null,
            name: name.trim(),
            description: description.trim(),
            EstimatedTime: parseInt(estimatedTime),
            startingDate: startingDate,
            dueDate: dueDate,
            companyId: companyId,
        }

        if (simulation.name != "" && simulation.description != "") {

                var Token = JSON.stringify({
                    AuthToken: cookies.get('Token'),
                    Data: simulation
                });

                 axios.post('/Simulation/Add', Token, {
                     headers: {
                         'Content-Type': 'application/json',
                     }
                 }).then((response) => {
     
                     console.log(response)
                     if (response.data.resultCode == 0) {
                         setHeader("Success");
                         setMessage("Simulation has been added successfully");
                         setMessageModalOpen(true);
                         setIsError(false);
                         setSimulationId(response.data.data.id);
                     } else {
                         setHeader("Error");
                         setMessage(response.data.message);
                         setMessageModalOpen(true);
                         setIsError(true);
                     } 
                });
          
        } else {
            setHeader("Error");
            setMessage("Required fields should not be left blank");
            setMessageModalOpen(true);
            setIsError(true);
        }

        
    }

    const modalOkayButton = () => {
        setMessageModalOpen(false);

        //if (!isError)
        //    window.location.href = "/simulations";
    }

    return (
        <div>

            <Transition visible={messageModalOpen} animation='scale' duration={500}>
                <Modal className="users-delete-ask-modal" size={"mini"} open={messageModalOpen} onClose={() => { setMessageModalOpen(false) }}>
                    <Modal.Header><span className={"account-text"}>{header}</span></Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Header>{message}</Header>
                        </Modal.Description>

                    </Modal.Content>
                    <Modal.Actions>
                        <Link to={"/simulation/" + simulationId}>
                        <Button onClick={modalOkayButton} color='green'>
                            OKEY <Icon name='chevron right' />
                        </Button>
                        </Link>

                    </Modal.Actions>
                </Modal>
            </Transition>

            <DashboardNavBar />
            <div className="dashboard-container-content">
               
                <div className="simulations-add">
                    <div className="add-simulation-box">
                       
                        <Header as='h1' disabled>
                            <Link to="/simulations"><Button className="simulation-back-button" size="massive" icon>
                                <Icon name='left chevron' />
                            </Button></Link>
                            Add Simulation
                         </Header>
                        <Form size="huge">
                            <Form.Field>
                                <label>Simulation Name (*)</label>
                                <input
                                    value={name}
                                    onChange={e => { setName(e.target.value) }}
                                    placeholder='Simulation Name (*)' />
                            </Form.Field>
                            <Form.Field>
                                <label>Description (*)</label>
                                <TextArea
                                    value={description}
                                    onChange={e => { setDescription(e.target.value) }}
                                    placeholder='Description (*)' style={{ minHeight: 100 }} />
                            </Form.Field>
                            <Form.Field>
                                <label>Estimated Time</label>
                                <Input
                                    value={estimatedTime}
                                    onChange={e => { setEstimatedTime(e.target.value) }}
                                    labelPosition='right'
                                    type='number'
                                    min="0"
                                    placeholder='Estimated Time (minute)'>
                                    
                                    <input />
                                    <Label><Icon name="time" /></Label>
                                </Input>
                            </Form.Field>

                            <Form.Field>
                                <label>Starting Date</label>
                                <input
                                    max={dueDate}
                                    min={today}
                                    value={startingDate}
                                    onChange={e => { setStartingDate(e.target.value) }}
                                    type="date"
                                     />
                            </Form.Field>

                            <Form.Field>
                                <label>End Date</label>
                                <input
                                    min={startingDate}
                                    value={dueDate}
                                    onChange={e => { setDueDate(e.target.value) }}
                                    type="date" />
                            </Form.Field>
                           
                            <Button onClick={addSimulation} size="huge" type='submit'>Save</Button>
                        </Form>
                    </div>
                </div>

            </div>
        </div>
        )
}