import React, { useState, useContext, useEffect } from 'react';
import './theader.css';
import axios from 'axios';
import { Input, Checkbox } from 'semantic-ui-react'


export const Theader = (props) => {

    console.log(props.TableHeader);
    return (
        <div>
            <div className="digitallica-table-checkbox-field">
                <Checkbox />
            </div>
            <div className="digitallica-table-header">
                {props.TableHeader.map((item, index) =>
                    <div className="digitallica-table-theader-items" Key={index}>{item}</div>
                )}

            </div>
        </div>
    );

}
