import React, { useState, useContext, useEffect } from 'react';
import './useredit.css';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { Button, Divider, Form, Checkbox, Dropdown, Modal, Transition, Icon, Header } from 'semantic-ui-react'
import { DashboardNavBar } from '../Dashboard/DashboardNavBar/DashboardNavBar';
import { Link } from 'react-router-dom';

const cookies = new Cookies();

export const UserEdit = (props) => {

    const [messageModalOpen, setMessageModalOpen] = useState(false);
    const [header, setHeader] = useState("");
    const [message, setMessage] = useState("");

    const [id, setId] = useState("");
    const [username, setUsername] = useState("");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [isActive, setIsActive] = useState(true);
    const [userType, setUserType] = useState("");
    const [phone, setPhone] = useState("");
    const [organizationalStructure, setOrganizationalStructure] = useState("");
    const [registrationNumber, setRegistrationNumber] = useState("");

    let userTypeOptions = {
        options: [
            { value: '1', text: 'Admin' },
            { value: '4', text: 'User' },
        ],
        selected: userType,
    }


    useEffect(() => {
        getUserInfo();
    }, []);

    const changeIsActive = (event, data) => {
        setIsActive(data.checked);
    }

    const changeUserTypeOptions = (event, data) => {
        setUserType(data.value);
    }

    useEffect(() => {
        console.log(userType);
    }, [userType]);

    const getUserInfo = () => {

        var user = {
            id: props.match.params.id
        };

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: user
        });

        axios.post('/User/GetById', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            console.log(response)
            if (response.data.resultCode == 0) {

                let responseData = response.data.data;

                setId(responseData.id);
                setUsername(responseData.username);
                setName(responseData.name);
                setSurname(responseData.surname);
                setEmail(responseData.email);
                setIsActive(responseData.isActive);
                setUserType(responseData.userType);
                setPhone(responseData.phone);
                setOrganizationalStructure(responseData.organizationalStructure);
                setRegistrationNumber(responseData.registrationNumber);
            }


        });
    
    }

    const updateUser = () => {
        var updateUser = {
            id: id,
            name: name,
            surname: surname,
            username: username,
            email: email,
            isActive: isActive,
            userType: userType,
            phone: phone,
            OrganizationalStructure: organizationalStructure,
            RegistrationNumber: registrationNumber
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: updateUser
        });

        axios.post('/User/Update', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            console.log(response)
            if (response.data.resultCode == 0) {
                setHeader("Success");
                setMessage("User is updated");
                setMessageModalOpen(true);
            } else {
                setHeader("Error");
                setMessage("User is not updated");
                setMessageModalOpen(true);
            }


        });
    }



    return (
        <div>

            <Transition visible={messageModalOpen} animation='scale' duration={500}>
                <Modal className="users-delete-ask-modal" size={"mini"} open={messageModalOpen} onClose={() => { setMessageModalOpen(false) }}>
                    <Modal.Header><span className={"account-text"}>{header}</span></Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Header>{message}</Header>
                        </Modal.Description>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => { setMessageModalOpen(false)}} color='green'>
                            OKEY <Icon name='chevron right' />
                        </Button>

                    </Modal.Actions>
                </Modal>
            </Transition>


            <DashboardNavBar />





            <div className="dashboard-container-content">

                <div className="dashboard-content-box">

                    <div className="simulations-header">
                        <div className="page-header-text">
                            <Header as='h1' disabled>
                                <Link to="/users"><Button className="simulation-back-button" size="massive" icon>
                                    <Icon name='left chevron' />
                                </Button></Link>
                                User edit
                             </Header>
                        </div>
                    </div>
                    <div className="dashboard-settings-box">
                        <div className="user-edit-container">
                            <Form size={"big"}>
                                <Form.Field
                                    label='First name'
                                    value={name}
                                    onChange={e => { setName(e.target.value) }}
                                    control='input'
                                    placeholder='First name'
                                />

                                <Form.Field
                                    label='Last name'
                                    value={surname}
                                    onChange={e => { setSurname(e.target.value) }}
                                    control='input'
                                    placeholder='Last name'
                                />

                                <Form.Field
                                    label='Username'
                                    value={username}
                                    onChange={e => { setUsername(e.target.value) }}
                                    control='input'
                                    placeholder='Username'
                                />

                                <Form.Field
                                    label='E-mail'
                                    value={email}
                                    onChange={e => { setEmail(e.target.value) }}
                                    control='input'
                                    placeholder='Last name'
                                />

                                <Form.Field>
                                    <div className="form-field-label-text">Is Active</div>
                                    <Checkbox
                                        onChange={changeIsActive}
                                        className="user-edit-is-active-checkbox"
                                        checked={isActive}
                                        toggle
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <div className="form-field-label-text">User Type</div>
                                    <Dropdown
                                        selection
                                        className="user-edit-is-active-checkbox"
                                        placeholder='Select user type'
                                        options={userTypeOptions.options}
                                        value={userType.toString()}
                                        onChange={changeUserTypeOptions}
                                    />
                                </Form.Field>

                                <Form.Field
                                    label='Phone'
                                    value={phone}
                                    onChange={e => { setPhone(e.target.value) }}
                                    control='input'
                                    placeholder='Phone'
                                />

                                <Form.Field
                                    label='Organizational Structure'
                                    value={organizationalStructure}
                                    onChange={e => { setOrganizationalStructure(e.target.value) }}
                                    control='input'
                                    placeholder='Organizational Structure'
                                />

                                <Form.Field
                                    label='Registration Number'
                                    value={registrationNumber}
                                    onChange={e => { setRegistrationNumber(e.target.value) }}
                                    control='input'
                                    placeholder='Registration Number'
                                />


                                <Button onClick={updateUser} type='submit'>Update</Button>
                                <Divider hidden />
                            </Form>
                        </div>
                    </div>
                </div>
            </div>









           
        </div>
        
        );
};