import React, { useState} from 'react';
import { Button, Popup, Modal, Transition, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

export const OtherSimulationCard = (props) => {
    const [userListOpen, setUserListOpen] = useState(false);


    const cardStyle = {
        height: props.Height || "133px",
        backgroundColor: "white",
        boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
        padding: props.Padding || "20px",
        borderRadius: props.borderRadius || "10px",
        display: "flex",
        alignItems: props.AlignItems || "center",
        justifyContent: "center",
        flexDirection: "column",
        fontSize: props.FontSize || "3em",
        fontWeight: "bold",
        color: "#9c9c9c",
        marginRight: "30px",
        marginTop: props.MarginTop || "20px",
        margin: props.Margin || "10px"
    };

    const textStyle = {
        marginTop: "10px",
        fontSize: props.FontSize || "1em"
    }

    const headerStyle = {
        display: "flex",
        justifyContent: "space-between",
        gap: "1em"
    }

    return (
        <div style={cardStyle}>
            {props.OtherSimulationCard && 
                <div>
                <Transition
                    visible={userListOpen}
                    animation='scale'
                    duration={500}
                >
                        <Modal
                            size="small"
                            style={{
                                left: "auto",
                                height: "auto",
                                top: "5%"
                            }}
                            open={userListOpen}

                        >
                            <Modal.Header>{props.ModalHeader}</Modal.Header>
                            <Modal.Content>
                                <Modal.Description>
                                    <Table basic='very'>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>Name</Table.HeaderCell>
                                                <Table.HeaderCell>Surname</Table.HeaderCell>
                                                <Table.HeaderCell>Email</Table.HeaderCell>
                                                <Table.HeaderCell>Profile</Table.HeaderCell>

                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            {props.QuestionUsers.map(user => (
                                                <Table.Row key={user.id}>
                                                    <Table.Cell>{user.name}</Table.Cell>
                                                    <Table.Cell>{user.surname}</Table.Cell>
                                                    <Table.Cell>{user.email}</Table.Cell>
                                                    <Table.Cell>
                                                        <Link to={"/user-profile/" + user.id}>
                                                            <Button icon='user' />
                                                        </Link>
                                                    </Table.Cell>
                                                </Table.Row>
                                            ))}

                                        </Table.Body>
                                    </Table>

                                </Modal.Description>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button color='black' onClick={() => setUserListOpen(false)}>
                                    Close
                            </Button>
                            </Modal.Actions>
                        </Modal>
                    </Transition>
                </div>
            
            }
            


            <div style={headerStyle}>
                <h3
                    style={{
                        color: "#9c9c9c",
                        marginBottom:
                            props.HeaderMarginBottom || ""
                    }} >{props.Header}
                </h3>
                {props.QuestionReport && parseInt(props.Text) > 0 &&
                    <Popup 
                        content='Users List' 
                        trigger={
                            <Button
                                icon='user'
                                color="black"
                                size="mini"
                                onClick={() => { setUserListOpen(true);}}
                            />
                        } 
                    />
                }
            </div>
            <span style={textStyle}>{props.Text}</span>
        </div>
    )
}