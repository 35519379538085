import React, { useState, useContext, useEffect } from 'react';
import './usersimulationlistrow.css';
import { Header, Progress, Label, Button, Popup, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

export const UserSimulationListRow = (props) => {
    return (
        <div>
            <div className="user-simulation-list-row">

                <div style={{width:'70px'}}>
                    {props.OtherSimulationCount != 0 ?
                        <Popup content='It shows how many times the user has completed this simulation before. If you click, you can access the detailed report.' trigger={
                            <Link to={"/user-other-simulations/" + props.SimulationId + "/" + props.UserId}>
                                <Label
                                    as="a"
                                    color="yellow"
                                    style={{ width: "60px", height: '30px' }}                         size="large"
                                >
                                    <Icon
                                        name='chess knight'
                                    />
                                        {props.OtherSimulationCount}
                                </Label>
                            </Link>
                        } />
                        : <Label disabled style={{ width: "60px", height: '30px' }} size="large">
                            
                        </Label>
                        }
                   
                </div>

                <div className="simulation-name-box-list">
                    <Link to={"/simulation/" + props.SimulationId}><Header color="orange" disabled as='h3'>{ props.Name}</Header></Link>
                </div>

                <div className="user-assigned-simulation-progress">
                    <Progress style={{ width: "180px" }} percent={props.Percent} indicating />
                    <span>{props.Percent}%</span>
                </div>

                <div>
                    <Label style={{ width: "180px" }} size="big" color={props.HaveSettings ? "blue" : "white"} horizontal>
                        {props.HaveSettings ? props.Point[0].toFixed(1) + " / " + props.Point[1] + " Points" : "-"}
                    </Label> 
                    
                </div>

                <div>
                    {props.HaveSettings ?  
                            props.IsSuccess ? <Label style={{ width: "130px" }} size="big" color={"green"} horizontal>
                            Succeess
                    </Label> : <Label style={{ width: "130px" }} size="big" color={"red"} horizontal>
                                    Unsucceess
                    </Label>

                        : <Label style={{ width: "130px" }} size="big" color={"white"} horizontal>
                            -
                    </Label>
                        
                        }
                    
                    
                </div>

                <div>
                    <Label color="teal" style={{ width: "200px" }} size="big" horizontal>
                        {props.Question[0] + " True / " + props.Question[1] + " Question(s)"}
                    </Label>
                </div>

                <div>
                    <Label style={{ width: "180px" }} size="big" color={props.HaveSettings ? "yellow" : "white"} horizontal>
                        {props.HaveSettings ?  props.UserRate[0] + " / " + props.UserRate[1] + " Person(s)"  : "-"}
                         
                    </Label>
                </div>

                <div>
                    {!props.IsActive ?
                        
                        <Popup content='Simulation access time expired or inactive' trigger={<Button circular />} />
                        :
                        <Popup content='Simulation is active' trigger={<Button circular color="green " />} />
                        }
                    
                </div>

            </div>
        </div>
        );
}