import React, { useState, useContext, useEffect } from 'react';
import './profile.css'
import { Icon, Button } from 'semantic-ui-react'
import { loginContext, fullNameContext } from '../../../../Store';
import { DashboardNavBar } from '../../Dashboard/DashboardNavBar/DashboardNavBar';
import { Input, Transition, Message } from 'semantic-ui-react'
import axios from 'axios';
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';
import ProfileSVG from '../../../../images/profile.svg';

const cookies = new Cookies();
export const Profile = (props) => {

    //context ----
    const [isLoginControl, setLoginControl] = useContext(loginContext);
    const [fullName, setFullName] = useContext(fullNameContext);
    //context ----

    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [mail, setMail] = useState("");
    const [password, setPassword] = useState("");
    const [username, setUsername] = useState("");

    const [isError, setIsError] = useState(false);
    const [messageHeader, setMessageHeader] = useState("");
    const [messageBody, setMessageBody] = useState("");
    const [messageType, setMessageType] = useState("");

    
    useEffect(() => {

        if (!isLoginControl) {
            window.location.href = "/";
        }

        getUserById();
    }, []);

    const isEmpty = (value) => {
        return (value == null || value.length === 0 || value === "")
    }

    const resetPassword = () => {
        window.location.href = "/forget-password?from=dashboard&email=" + mail + "";
    }

    const getUserById = () => {
        var data = {
            Id: cookies.get('UserId')
        };

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: data
        });


        console.log(Token);

        axios.post('/User/GetById', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);
            if (response.data.resultCode == 0) {
                setMail(response.data.data.email);
                setName(response.data.data.name);
                setSurname(response.data.data.surname);
                setUsername(response.data.data.username);
            }

        });
    }

    const saveProfile = () => {

        var profileData = {
            Id: cookies.get('UserId'),
            UserType: parseInt(cookies.get('UserType')),
            Email: mail.trim(),
            Name: name.trim(),
            Surname: surname.trim(),
            Username: username.trim(),
            CompanyId: cookies.get('CompanyId')
            
        }

        console.log(profileData);

        if ((

            isEmpty(profileData.Email) ||
            isEmpty(profileData.Name) ||
            isEmpty(profileData.Surname) ||
            isEmpty(profileData.Username) 

        )) {
            setIsError(false);
            setMessageHeader("Error");
            setMessageBody("Do not leave any fields blank.");
            setMessageType("red");
            setIsError(true);
        } else {

            setIsError(false);

            var Token = JSON.stringify({
                AuthToken: cookies.get('Token'),
                Data: profileData
            });


            axios.post('/User/Update', Token, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then((response) => {
                if (response.data.resultCode == 0) {
                    setIsError(false);

                    setMessageHeader("Success");
                    setMessageBody("Your profile information has been updated.");
                    setMessageType("green");

                    
                    setFullName(response.data.data.name + " " + response.data.data.surname);
                    console.log(response);


                    setIsError(true);

                    
                     getUserById();
                    
                    
                }
            });

        }

    }


    return (
        <div>

            <DashboardNavBar />
            <div className="dashboard-container-content profile-container-content">
                <div>
                    <h1>Profile</h1>

               
                    <div className="dashboard-profile-input">
                        <Input
                            label='Name'
                        
                        
                            size='big'
                            placeholder='Name'
                            value={name}
                            onChange={e => { setName(e.target.value) }}
                        />
                    </div>

                    <div className="dashboard-profile-input">
                        <Input
                            label='Surname'
                        

                            size='big'
                            placeholder='Surname'
                            value={surname}
                            onChange={e => { setSurname(e.target.value) }}
                        />
                    </div>

                    <div className="dashboard-profile-input">
                        <Input
                            label='Username'
                       

                            size='big'
                            placeholder='Username'
                            value={username}
                            onChange={e => { setUsername(e.target.value) }}
                        />
                    </div>

                    <div className="dashboard-profile-input">
                        <Input
                            label='Email'
                        

                            size='big'
                            placeholder='Email'
                            value={mail}
                            onChange={e => { setMail(e.target.value) }}
                        />
                    </div>

                    <div className="dashboard-profile-input">

                 
                        <Input placeholder='Password' type="password" disabled value="default" size='big' action>
                            <input />
                        
                            <Button onClick={resetPassword} type='submit'>Reset password</Button>
                        </Input>

                    </div>

                    <div className="dashboard-profile-button">
                        <button onClick={saveProfile} className="navbar-login-button digitallica-button">Save</button>
                    </div>

                    <div className="alert-box alert-box-profile">
                        <Transition visible={isError} animation='scale' duration={500}>
                            <Message color={messageType}>
                                <Message.Header>{messageHeader}</Message.Header>
                                <p>{messageBody}</p>
                            </Message>
                        </Transition>
                    </div>

                </div>


                <div className="profile-image-section">
                    <img src={ProfileSVG} />
                </div>
            </div>
            
        </div>
    );

}
