import React, { useState, useEffect, useContext } from 'react';
import {useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';
import { Login } from './WelcomePages/Login';
import Cookies from 'universal-cookie';
import { loginContext } from '../Store';

const cookies = new Cookies();
export default function Layout(props) {
  
    const [userFullName, setUserFullName] = useState("");
    const [isLoginControl, setLoginControl] = useContext(loginContext);

    /* for server
    if (location.protocol != "") {
        if (location.protocol !== 'https:') {
            location.replace(`https:${location.href.substring(location.protocol.length)}`);
        }
    }
    */

    const location = useLocation()

    React.useEffect(() => {
        // runs on location, i.e. route, change
        console.log('handle route change here', location)
    }, [location.pathname])

     
    return (
        <div>
            <div>
                {props.children}
            </div>
        </div>
    );
  
}
