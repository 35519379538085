import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import Layout from './components/Layout';
import Cookies from 'universal-cookie';


import { MainPage } from './components/WelcomePages/MainPage';
import { Login } from './components/WelcomePages/Login';
import { NoAccessPage } from './components/NoAccessPage/NoAccessPage';
import { ForgetPassword } from './components/WelcomePages/ForgetPassword';
import { ResetPassword } from './components/WelcomePages/ResetPassword';
import { Dashboard } from './components/DashboardPages/Dashboard/Dashboard';
import { Profile } from './components/DashboardPages/Dashboard/Profile/Profile';
import { Users } from './components/DashboardPages/Users/Users';
import { UserEdit } from './components/DashboardPages/UserEdit/UserEdit';
import { UserAdd } from './components/DashboardPages/UserAdd/UserAdd';
import { UserProfile } from './components/DashboardPages/UserProfile/UserProfile';
import { Canvas } from './components/DashboardPages/Dashboard/Canvas/Canvas';
import { SimulationsList } from './components/DashboardPages/Simulations/List/SimulationsList';
import { SimulationsAdd } from './components/DashboardPages/Simulations/Add/SimulationsAdd';
import { SimulationDetail } from './components/DashboardPages/Simulations/Detail/SimulationDetail';
import { MailBodyDesign } from './components/DashboardPages/Simulations/Detail/Notifications/MailBodyDesign/MailBodyDesign';
import { DashboardSettings } from './components/DashboardPages/Settings/DashboardSettings';
import { UserOtherSimulations } from './components/DashboardPages/UserProfile/UserOtherSimulations/UserOtherSimulations';
import { Reports } from './components/DashboardPages/Reports/Reports';
import { ScormSimulation } from './components/UserDashboard/Dashboard/Simulations/Watch/ScormSimulation';


//user pages
import { UserDashboard } from './components/UserDashboard/Dashboard/UserDashboard';
import { UserSimulationDetail } from './components/UserDashboard/Dashboard/Simulations/Detail/UserSimulationDetail';
import { QuestionReport } from './components/UserDashboard/Dashboard/Simulations/Watch/QuestionReport/QuestionReport';
import { WatchSimulation } from './components/UserDashboard/Dashboard/Simulations/Watch/WatchSimulation';
//user pages

//super admin pages
import { SuperAdminDashboard } from './components/SuperAdminDashboard/Dashboard/SuperAdminDashboard';
import { Customer } from './components/SuperAdminDashboard/Dashboard/Customer/Customer';
import { Company } from './components/SuperAdminDashboard/Dashboard/Company/Company';
import { Settings } from './components/SuperAdminDashboard/Dashboard/Settings/Settings';
import { SuperAdminProfile } from './components/SuperAdminDashboard/Dashboard/Profile/SuperAdminProfile';
import { CompanyEdit } from './components/SuperAdminDashboard/Dashboard/Company/Edit/CompanyEdit';
import { CustomerEdit } from './components/SuperAdminDashboard/Dashboard/Customer/Edit/CustomerEdit';
//super admin pages


import 'semantic-ui-css/semantic.min.css'
import './custom.css'

import { loginContext } from './Store';

const cookies = new Cookies();

export const App = (props) => {

    const [userType] = useState(cookies.get('UserType'))
    const [isCloseAccessSite, setIsCloseAccessSite] = useState(false);

    useEffect(() => {
        // if (userType == undefined && window.location.pathname !== "/login") {
        //     window.location.href = "/login";
        // }

        getSettingsById();
    }, []);

    const getSettingsById = () => {
        var data = {
            Id: "47d88bff-0fef-4e9c-b75b-cf68191d5dc5",
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: data
        });

        axios.post('/Settings/GetById', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            if (response.data.resultCode == 0) {
                let settings = response.data.data;
                if (JSON.parse(settings.settingsJSON).closeAccessSite.IsActive)
                    setIsCloseAccessSite(true);
            }

        });
    }


    return (
        <Layout className="main-page-container">
            <BrowserRouter>
                {

                    userType == 1 ?
                        </*admin*/>
                            <Route exact path='/' component={MainPage} />
                            <Route path='/login' component={Login} />
                            <Route path='/forget-password' component={ForgetPassword} />
                            <Route path='/reset-password' component={ResetPassword} />
                            <Route path='/dashboard' component={Dashboard} />
                            <Route path='/profile' component={Profile} />
                            <Route path='/users' component={Users} />
                            <Route path='/user-edit/:id' component={UserEdit} />
                            <Route path='/user-add' component={UserAdd} />
                            <Route path='/user-profile/:id' component={UserProfile} />
                            <Route path='/canvas' component={Canvas} />
                            <Route path='/simulations' component={SimulationsList} />
                            <Route path='/add-simulation' component={SimulationsAdd} />
                            <Route path='/simulation/:id' component={SimulationDetail} />
                            <Route path='/notification/:id' component={MailBodyDesign} />
                            <Route path='/settings' component={DashboardSettings} />
                            <Route path='/user-other-simulations/:id/:userid' component={UserOtherSimulations} />
                            <Route path='/reports' component={Reports} />


                        </>

                        : userType == 2 ?
                            </*super admin*/>
                                <Route exact path='/' component={MainPage} />
                                <Route path='/login' component={Login} />
                                <Route path='/forget-password' component={ForgetPassword} />
                                <Route path='/reset-password' component={ResetPassword} />
                                <Route path='/superadmin' component={SuperAdminDashboard} />
                                <Route path={`/customer`} component={Customer} />
                                <Route path={`/customer-edit/:id`} component={CustomerEdit} />
                                <Route path={`/company`} component={Company} />
                                <Route path={`/company-edit/:id`} component={CompanyEdit} />
                                <Route path={`/settings`} component={Settings} />
                                <Route path={`/profile`} component={SuperAdminProfile} />
                            </>

                            : userType == 4 ?
                                </*user*/>
                                    <Route exact path='/' component={MainPage} />
                                    <Route path='/login' component={Login} />
                                    <Route path='/forget-password' component={ForgetPassword} />
                                    <Route path='/reset-password' component={ResetPassword} />
                                    <Route path='/user-dashboard' component={UserDashboard} />
                                    <Route path='/profile' component={Profile} />
                                    <Route path='/simulation-detail/:id' component={UserSimulationDetail} />
                                    <Route path='/simulation-watch/:id' component={WatchSimulation} />
                                    <Route path='/question-report/:id/:userid' component={QuestionReport} />
                                </>

                                :
                                </*default*/>
                                    <Route path='/simulation-scorm/:id' component={ScormSimulation} />

                                    <Route exact path='/' component={MainPage} />
                                    <Route path='/login' component={Login} />
                                    <Route path='/forget-password' component={ForgetPassword} />
                                    <Route path='/reset-password' component={ResetPassword} />
                                    <Route exact path='/noaccess' component={NoAccessPage} />
                                </>
                }

            </BrowserRouter>
        </Layout>


    );

}
