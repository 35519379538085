import React, { useState, useContext, useEffect } from 'react';
import './question.css';
import axios from 'axios';
import { Button, Divider, Form, Checkbox, Dropdown, Modal, Transition, Icon, Header, Tab, TextArea, Popup } from 'semantic-ui-react';



export const Question = (props) => {

    const leftStyle = {
        flexDirection: "row",
        float: "left",
        fontSize: "2em"
    }

    const [dialog, setDialog] = useState("");

    const showQuestionDetail = (e) => {
        //alert(e.target.id);
        props.ClickedElementIndexValue(props.Index);
        props.ShowDetailQuestion();
        props.SetCurrentQuestionId(e.target.id);
    }


    return (
        <div style={leftStyle} className="dialog-box">
            <div>
                <Button id={props.QuestionId} onClick={props.DeleteDialog} data-body={props.Body} data-question={true} data-index={props.Index} color="red" circular inverted icon>
                    <Icon id={props.QuestionId} data-body={props.Body} data-index={props.Index} data-question={true} name='delete' />
                </Button>
            </div>
            <div className="dialog-left-section">
                <div>
                    <img className="dialog-mini-rounded-image" src={props.Image} />
                </div>
               
                <div>
                    <Popup
                        position={props.Right ? "right center" : "left center"}
                        content='I will not flip!'
                        on='click'
                        pinned
                        trigger={<Button circular icon content='Button'><Icon name="comments" /></Button>}
                    >
                        <div className="add-comment-choose-character-box">
                            <div>
                                <Button data-id={props.Right ? "right" : "left"} onClick={props.AddDialogInsert} data-index={props.Index}>
                                    <img
                                        data-id={props.Right ? "right" : "left"}
                                        data-index={props.Index}
                                        className="dialog-mini-image"
                                        src={props.Image}
                                    />
                                </Button>
                            </div>
                            <div>
                                <Button data-id={props.Right ? "left" : "right"} onClick={props.AddDialogInsert} data-index={props.Index}>
                                    <img
                                        data-id={props.Right ? "left" : "right"}
                                        data-index={props.Index}
                                        className="dialog-mini-image"
                                        src={props.CharacterTwo}
                                    />
                                </Button>
                            </div>
                            <div>
                               

                                <Button className="insert-question-button" color='yellow' data-id={"left"} onClick={props.addQuestion} data-index={props.Index}>
                                    <div data-id={"left"} data-index={props.Index}><Icon data-id={"left"} data-index={props.Index} size="big" name="question" /></div>
                                </Button>

                            </div>
                        </div>

                    </Popup>
                </div>
            </div>
            <div className="dialog-textare-section">
                <div
                    onClick={showQuestionDetail}
                    id={props.QuestionId}
                    className="question-show-box"
                >{props.Body}</div>
            </div>
        </div>
    )
}
