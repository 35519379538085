import React, { useContext, useEffect, useState } from 'react';
import { Popup, Icon, Table, Label, Progress, Header, Loader, Transition } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const ReportSimulationUserTable = (props) => {


    const [tableHeader] = useState(['Name', 'Surname', 'Email', 'ProgressPercentage', 'Point', 'Rate']);

    const progressContainerStyle = {
        width: "80%",
        position: "relative"
    }

    const progressSpan = {
        position: "absolute",
        top: "2px",
        left: "37%",
        fontWeight: "bold",
        fontSize: "0.9em"
    }

    const colorDegree = {
        1: "1st",
        2: "2st",
        3: "3st"
    }

    const RATE_COLOR = {
        1: "yellow",
        2: "orange",
        3: "grey"
    };

    return (
        <div>
           
                <Table style={{ width: "100%" }} verticalAlign="middle" size="large" basic='very'>
                    <Table.Header>
                        <Table.Row>
                            {tableHeader.map((item, index) =>
                                <Table.HeaderCell key={item}>{item}</Table.HeaderCell>
                            )}
                        </Table.Row>
                    </Table.Header>


                    <Table.Body>

                        {props.Users.map((user, index) =>
                            <Table.Row key={user.id}>

                                <Table.Cell style={{ width: "150px" }}>                     {user.name}
                                </Table.Cell>

                                <Table.Cell>
                                    {user.surname}
                                </Table.Cell>

                                <Table.Cell>
                                    {user.email}
                                </Table.Cell>

                                <Table.Cell>
                                    <div style={progressContainerStyle} >
                                        <Progress style={{ height: "24px" }} percent={user.progressPercentage} indicating />
                                        <span style={progressSpan}> {user.progressPercentage}%</span>
                                    </div>
                                </Table.Cell>

                                <Table.Cell>
                                    {user.point.toFixed(1)}
                                </Table.Cell>

                                <Table.Cell>
                                    <Popup content={colorDegree[user.rate]} trigger={<Icon size="big" color={RATE_COLOR[user.rate]} key={index} name="trophy" />} />
                                </Table.Cell>
                               
                            </Table.Row>
                        )}

                    </Table.Body>

                </Table>

            
        </div>
    )
}