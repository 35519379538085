import React, { useState, useContext, useEffect } from 'react';
import './table.css';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { Search } from './Search/Search';
import { Theader } from './Theader/Theader';
import { Tbody } from './Tbody/Tbody';


const cookies = new Cookies();

export const Table = (props) => {

   

    useEffect(() => {



        console.log(props);

    }, []);

   

   


    return (
        <div >
                <div className="digitallica-table">

                    <div className="digitallica-search-field">
                        <Search />
                    </div>

                    <div className="digitallica-header-field">
                    <Theader TableHeader={props.TableHeaderMenu}/>
                    </div>

                    <div className="digitallica-pagination-field">
                        <Tbody TableData={props.TableData} />
                    </div>

                </div>
        </div>
    );

}
