import React, { useState, useContext, useEffect } from 'react';
import { Modal, Button, Transition } from 'semantic-ui-react';
import './continuepopup.css';
import Broadcast from '../../../../../Broadcast';
import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const ContinuePopup = (props) => {

    const [isOpen, setIsOpen] = useState(props.ContinuePopupOpen);

    const [warningModal, setWarningModal] = useState(false);

    const resetProgress = () => {
        var simulation = {
            SimulationId: props.SimulationId,
            UserId: cookies.get('UserId')
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: simulation
        });

        axios.post('/Simulation/ResetSimulationProgress', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            if (response.data.resultCode == 0) {
                Broadcast.emit('DIALOG_CONTINUE_RESPONSE', {
                    currentIndex: -1
                });
                setIsOpen(false);
                setWarningModal(false);
            }
        });
    }
    return (
        <>
        <Modal
            closeOnEscape={false}
            closeOnDimmerClick={false}
            className="continue-popup-modal"
            size={"mini"}
            open={isOpen}
           
        >
            <Modal.Header>Warning</Modal.Header>
            <Modal.Content>
                <p>Would you like to continue where you left off?</p>
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={() => {
                    setWarningModal(true);
                }}>
                    No
          </Button>
                    <Button positive onClick={() => {
                        console.log("Emit atildi");
                        Broadcast.emit('DIALOG_CONTINUE_RESPONSE', {
                        currentIndex: props.CurrentIndex
                    });
                    
                    setIsOpen(false);
                }}>
                    Yes
          </Button>
            </Modal.Actions>
            </Modal>


            <Transition visible={warningModal} animation='scale' duration={500}>
            <Modal
                closeOnEscape={false}
                closeOnDimmerClick={false}
                className="continue-popup-modal"
                size={"mini"}
                open={warningModal}

            >
                <Modal.Header>Warning</Modal.Header>
                <Modal.Content>
                    <p>Are you sure ?</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => {
                        setWarningModal(false);
                    }}>
                        Cancel
          </Button>
                        <Button positive onClick={resetProgress}>
                        Okey
          </Button>
                </Modal.Actions>
                </Modal>
            </Transition>
        </>
        );
}