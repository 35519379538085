import React, { useState, useContext, useEffect } from 'react';
import './userprofile.css';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { Button, Icon, Header, Tab, Label, Loader } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { DashboardNavBar } from '../Dashboard/DashboardNavBar/DashboardNavBar';
import { UserSimulationList } from './UserSimulationList/UserSimulationList';

const cookies = new Cookies();

export const UserProfile = (props) => {

    const [id, setId] = useState("");
    const [username, setUsername] = useState("");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [isActive, setIsActive] = useState(true);
    const [userType, setUserType] = useState("");
    const [phone, setPhone] = useState("");
    const [lastLoginDate, setLastLoginDate] = useState("");
    const [organizationalStructure, setOrganizationalStructure] = useState("");
    const [registrationNumber, setRegistrationNumber] = useState("");
    const [isLoadingSimulationReport, setIsLoadingSimulationReport] = useState(true);
    const [simulationsData, setSimulationsData] = useState([]);

    let userTypeColor = {
        1: "blue",
        4: "orange"
    }


    useEffect(() => {
        getUserInfo();
        getSimulationsByUserId();
    }, []);

    const getUserInfo = () => {

        var user = {
            id: props.match.params.id
        };

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: user
        });

        axios.post('/User/GetById', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            console.log(response)
            if (response.data.resultCode == 0) {

                let responseData = response.data.data;

                setId(responseData.id);
                setUsername(responseData.username);
                setName(responseData.name);
                setSurname(responseData.surname);
                setEmail(responseData.email);
                setIsActive(responseData.isActive);
                setUserType(responseData.userType);
                setPhone(responseData.phone);
                setOrganizationalStructure(responseData.organizationalStructure);
                setRegistrationNumber(responseData.registrationNumber);
                setLastLoginDate(responseData.lastLogin);
            }


        });

    }

    const getSimulationsByUserId = () => {
        var user = {
            id: props.match.params.id
        };

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: user
        });

        axios.post('/Simulation/GetSimulationsByUserId', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            console.log(response)
            if (response.data.resultCode == 0) {
                setSimulationsData(response.data.data);
                setIsLoadingSimulationReport(false);
            }
        });
    }

    const dateFormat = (dbDate) => {
        const date = new Date(dbDate)
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()

        // add a leading 0 if the number is less than 10. like 9 to 09

        day < 10 && (day = `0${day}`)
        month < 10 && (month = `0${month}`)

        const newFormat = `${day}-${month}-${year}`;

        return newFormat;
    }

    const panes = [
        {
            menuItem: 'Simulations',
            render: () => <Tab.Pane>
                {isLoadingSimulationReport ? <Loader active inline='centered' /> : <UserSimulationList SimulationList={simulationsData} />}
                
            </Tab.Pane>,
        },
        { menuItem: 'Tab 2', render: () => <Tab.Pane>Tab 2 Content</Tab.Pane> },
        { menuItem: 'Tab 3', render: () => <Tab.Pane>Tab 3 Content</Tab.Pane> },
    ]

    return (
        <div>
            <DashboardNavBar />
            
            <div className="dashboard-container-content">

                <div className="dashboard-content-box">

                    <div className="simulations-header">
                        <div className="page-header-text">
                            <Header as='h1'>
                                <Link to="/users"><Button className="simulation-back-button" size="massive" icon>
                                    <Icon name='left chevron' />
                                </Button></Link>
                                {name + " " + surname}
                                <Label
                                    style={{ marginLeft: "10px" }}
                                    size="big"
                                    color={userTypeColor[parseInt(userType)]} horizontal
                                >
                                    {userType === 1 ? "Admin" : "User"}
                                </Label>
                                <Label
                                    style={{ marginLeft: "5px" }}
                                    size="big"
                                    color={isActive === true ? "green" : "grey"} horizontal
                                >
                                    {isActive === true ? "Active" : "Passive"}
                                </Label>
                             </Header>
                        </div>
                    </div>
                    <div className="dashboard-settings-box">

                        <Header disabled as='h3' icon='mail' content={ email == null ? "Email = " : "Email = " + email} />

                        <Header disabled as='h3' icon='phone' content={phone == null ? "Phone = " : "Phone = " + phone} />

                        <Header disabled as='h3' icon='building' content={organizationalStructure == null ? "Organizational structure = " : "Organizational structure = " +organizationalStructure} />

                        <Header disabled as='h3' icon='calendar' content={lastLoginDate == null ? "Last login date = " : "Last login date = " + dateFormat(lastLoginDate)} />

                        <div>
                            <Tab
                                className="users-tabs-container"
                                panes={panes}
                                menu={{ secondary: true }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>


        )

}