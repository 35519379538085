import React, { useState, useEffect, useRef } from 'react';
import './dropzone.css';
import { Icon, Button, Transition, Modal, Header, Loader } from 'semantic-ui-react';
import Cookies from 'universal-cookie';
import axios from 'axios';



const cookies = new Cookies();

export const DropZone = (props) => {

   

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [validFiles, setValidFiles] = useState([]);
    const [unsupportedFiles, setUnsupportedFiles] = useState([]);
    const fileInputRef = useRef();
    const [IsMessage, setIsMessage] = useState(false);
    const [message, setMessage] = useState("");
    const [messageHeader, setMessageHeader] = useState("");
    const [startImport, setStartImport] = useState(false);
    const [showUnSuccessList, setShowUnSuccessList] = useState(false);

    const [unSuccessImported, setUnSuccessImported] = useState([]);

    useEffect(() => {
        let filteredArray = selectedFiles.reduce((file, current) => {
            const x = file.find(item => item.name === current.name);
            if (!x) {
                return file.concat([current]);
            } else {
                return file;
            }
        }, []);
        setValidFiles([...filteredArray]);

    }, [selectedFiles]);

    const dragOver = (e) => {
        e.preventDefault();
    }

    const dragEnter = (e) => {
        e.preventDefault();
    }


    const dragLeave = (e) => {
        e.preventDefault();
    }

    const fileType = (fileName) => {
        return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
    }

    const fileSize = (size) => {
        if (size === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(size) / Math.log(k));
        return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }

    const handleFiles = (files) => {
        for (let i = 0; i < files.length; i++) {
            if (validateFile(files[i])) {
                setSelectedFiles(prevArray => [...prevArray, files[i]]);
            } else {
                // add a new property called invalid

                files[i]['invalid'] = true;
                setSelectedFiles(prevArray => [...prevArray, files[i]]);
                setErrorMessage('File type not permitted');

                setUnsupportedFiles(prevArray => [...prevArray, files[i]]);
            }
        }
    }

    const uploadFiles = () => {

        setStartImport(true);

        if (validFiles.length === 1) {
            const formData = new FormData();

            formData.append('Data', validFiles[0]);
            formData.append('AuthToken', cookies.get('Token'));
            formData.append('CompanyId', cookies.get('CompanyId'));

            axios({
                method: 'POST',
                url: "/User/ImportUsers",
                data: formData,
                processData: false,
                contentType: false
            }).then((response) => {
                console.log(response);
                if (response.data.resultCode == 0) {

                    let successImportedUser = response.data.data.item1.length;
                    let unSuccessImportedUser = response.data.data.item2.length;

                    setUnSuccessImported(response.data.data.item2);

                    setStartImport(false);

                    if (unSuccessImportedUser > 0 && successImportedUser > 0) {
                        setIsMessage(true);
                        setMessageHeader("Success");
                        setMessage(successImportedUser + " users are imported. But " + unSuccessImportedUser + " users could not be imported");
                        setShowUnSuccessList(true);
                    } else {
                        if (successImportedUser > 0) {
                            setIsMessage(true);
                            setMessageHeader("Success");
                            setMessage(successImportedUser + " users are imported");
                            setShowUnSuccessList(false);
                        } else {
                            setIsMessage(true);
                            setMessageHeader("Error");
                            setMessage("No users could be imported. Maybe it's all already inside");
                            setShowUnSuccessList(true);
                        }
                    }

                    
                    
                }
            });

            

        } else {
            setIsMessage(true);
            setMessageHeader("Warning");
            setMessage("Please select just one excel file");
        }
        
        
    }

    const modalOkayEvent = () => {
        if (messageHeader == "Error") {
            setIsMessage(false);
        } else {
            if (messageHeader == "Success" && unSuccessImported.length == 0) {
                window.location.href = '/users';
            } else {
                
            }
        }

        if (messageHeader == "Success") {
            window.location.href = '/users';
        }
    }

    const fileInputClicked = () => {
        fileInputRef.current.click();
    }

    const filesSelected = () => {
        if (fileInputRef.current.files.length) {
            handleFiles(fileInputRef.current.files);
        }
    }

    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length) {
            handleFiles(files);
        }
    }

    const validateFile = (file) => {
        console.log(file);
        const validTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'text/csv'];
        if (validTypes.indexOf(file.type) === -1) {
            return false;
        }
        return true;
    }

    const removeFile = (name) => {
        // find the index of the item
        // remove the item from array

        const validFileIndex = validFiles.findIndex(e => e.name === name);
        validFiles.splice(validFileIndex, 1);
        // update validFiles array
        setValidFiles([...validFiles]);
        const selectedFileIndex = selectedFiles.findIndex(e => e.name === name);
        selectedFiles.splice(selectedFileIndex, 1);
        // update selectedFiles array

        setSelectedFiles([...selectedFiles]);


        const unsupportedFileIndex = unsupportedFiles.findIndex(e => e.name === name);
        if (unsupportedFileIndex !== -1) {
            unsupportedFiles.splice(unsupportedFileIndex, 1);
            // update unsupportedFiles array
            setUnsupportedFiles([...unsupportedFiles]);
        }
    }

    return (
        <div className="container">

            


            <Transition visible={IsMessage} animation='scale' duration={500}>
                <Modal

                    className={"message-modal"}
                    open={IsMessage}
                    onClose={() => setIsMessage(false)}
                    onOpen={() => IsMessage(true)}
                    
                >
                    <Modal.Header>{messageHeader}</Modal.Header>
                <Modal.Content image scrolling>
                   

                    <Modal.Description>
                            <h1>{message}</h1>


                            {showUnSuccessList ?
                                <div>
                                <h3>Already imported users list</h3>

                                <div className="unsuccess-table-header">
                                    <div className="unsuccess-table-item">Name</div>
                                    <div className="unsuccess-table-item">Surname</div>
                                    <div className="unsuccess-table-item">Email</div>
                                </div>  </div>: ""

                                }
                            
                            

                            {unSuccessImported && showUnSuccessList ? unSuccessImported.map((item, index) =>

                                <div className="unsuccess-import-users" key={index}>
                                    <div className="unsuccess-item">{item.name}</div>
                                    <div className="unsuccess-item">{item.surname}</div>
                                    <div className="unsuccess-item">{item.email}</div>
                                </div>

                            ) : ""}

                    </Modal.Description>
                </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={modalOkayEvent} primary>
                        OKAY <Icon name='chevron right' />
                    </Button>
                </Modal.Actions>
                </Modal>
                </Transition>








            {unsupportedFiles.length === 0 && validFiles.length ? <Button className="file-upload-btn" color='blue' onClick={() => uploadFiles()}>Import Users</Button> : ''}
            {unsupportedFiles.length ? <p className="upload-file-warning-message">Please remove all unsupported files.</p> : ''}
            <div onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}
                onClick={fileInputClicked}
                className="drop-container">

                {!startImport ?

                    <div className="drop-message">
                        <div className="upload-icon">
                            <Icon size='massive' name=' file excel outline' />
                        </div>
                        <div className="upload-text-field">
                            Drag & Drop excel file here or click to upload
                        </div>
                    </div>

                    :

                    <div className="drop-message">
                        <Loader className="dropzone-importing-gif" active={startImport} size='huge'>Importing...</Loader>
                    </div>

                    }
                

                <input
                    ref={fileInputRef}
                    className="file-input"
                    type="file"
                    multiple
                    onChange={filesSelected}
                />

            </div>

            <div className="file-display-container">

                {
                    validFiles.map((data, i) =>
                       
                        <div className="file-status-bar" key={i}>
                            <div className="upload-file-dropzone">
                                <div className="upload-file-first-section">
                                <div className="file-type-logo"> <Icon size='big' name=' file excel outline' /></div>

                                <div><span className={`file-name ${data.invalid ? 'file-error' : ''}`}>{data.name}</span></div>
                                <div><span className="file-size">({fileSize(data.size)})</span> {data.invalid && <span className='file-error-message'>({errorMessage})</span>}</div>

                                </div>
                                <div>
                                    <Button onClick={() => removeFile(data.name)} size='mini' color='red' icon>
                                        <Icon name='trash' />
                                    </Button>
                                </div>
                               
                            </div>
                            
                        </div>

                    )
                }

            </div>
        </div>
    )
}
