import React, { useState, useContext, useEffect } from 'react';
import { Header, Icon, Label, Button, Popup, Transition } from 'semantic-ui-react';
import { OtherSimulationCard } from '../OtherSimulationCard/OtherSimulationCard';
import { QuestionOption } from './QuestionOption/QuestionOption';
import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const OtherSimulationQuestion = (props) => {

    const [question, setQuestion] = useState({});
    const [options, setOptions] = useState([]);
    const [showPreviousAnswers, setShowPreviousAnswers] = useState(false);
    const [previousAnswerHtml, setPreviousAnswerHtml] = useState("");

    const questionBoxStyle = {
        height: "auto",
        width: props.Width || "30%",
        backgroundColor: "white",
        boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
        padding: props.Padding || "20px",
        borderRadius: props.borderRadius || "10px",
        display: "flex",
        flexDirection: "column",
        color: "#9c9c9c",
        marginTop: "30px"
    }

    const questionInfoStyle = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    }

    useEffect(() => {
        if (props.QuestionId != null) {
            getQuestionById();
        } else {
            setQuestion(props.Question);

            var newQuestionOptionArray = [];

            [props.Question].forEach(e => {
                ["A", "B", "C", "D", "E"].forEach(k => {

                    let newQuestionObject = {
                        Id: k,
                        Text: e[k],
                        IsCorrect: e.CorrectOption == k ? true : null,
                        CorrectOption: e.CorrectOption,
                    }

                    if (newQuestionObject.Text != "")
                        newQuestionOptionArray.push(newQuestionObject)
                });
            });

            setOptions(newQuestionOptionArray);
        }

        console.log(props.UserAnswer);

        console.log("GroupedQuestion", props.GroupedQuestion)




        if (props.GroupedQuestion) {
            for (var i = 0; i < props.GroupedQuestion.reverse().length - 1; i++) {


                let newAnswer = <QuestionOption
                    Key={i}
                    Text={""}
                    Option={props.GroupedQuestion[i].UserAnswer}
                    IsCorrect={props.GroupedQuestion[i].IsCorrect}
                    UserAnswer={props.GroupedQuestion[i].UserAnswer}
                    Color={props.GroupedQuestion[i].IsCorrect ? "#00800054" : props.GroupedQuestion[i].IsCorrect == null ? "white" : "#ff000054"}
                />

                setPreviousAnswerHtml(oldArray => [...oldArray, newAnswer]);

            }
        }
        

    }, []);

    const getQuestionById = () => {
        var user = {
            id: props.QuestionId
        };

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: user
        });

        axios.post('/Question/GetById', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            if (response.data.resultCode == 0) {
                let question = JSON.parse(response.data.data.questionJSON);

                setQuestion(question);

                var newQuestionOptionArray = [];

                [question].forEach(e => {
                    ["A", "B", "C", "D", "E"].forEach(k => {

                        let newQuestionObject = {
                            Id: k,
                            Text: e[k],
                            IsCorrect: e.CorrectOption == k ? true : ((e.CorrectOption != k) && (k != props.UserAnswer)) ? null : false,
                            CorrectOption: e.CorrectOption,
                        }

                        if (newQuestionObject.Text != "")
                            newQuestionOptionArray.push(newQuestionObject)
                    });
                });

                setOptions(newQuestionOptionArray);
                console.log(question);
            }
        });
    }

    return (
        <div style={questionBoxStyle}>
            <div>
                <div style={questionInfoStyle}>
                    <div>
                        <Label color="black" size={"big"}>
                            {props.Index}
                        </Label>
                    </div>

                    <div>

                        {props.GroupedQuestion && props.GroupedQuestion.length > 1 ?
                            <Popup
                                content={"Previous answers"}
                                trigger={
                                    <Button
                                        color={showPreviousAnswers && "black"}
                                        onClick={() => { setShowPreviousAnswers(!showPreviousAnswers) }}
                                        size="large"
                                        icon={
                                            <Icon
                                                name={"time"}
                                            />
                                        }

                                    />
                                } /> : "" 

                        }



                        {props.UserAnswer ?

                            <Popup
                                content={question.CorrectOption == props.UserAnswer ? "User answered this question correctly" : "User answered this question incorrectly"}
                                trigger={
                                    <Button
                                        basic
                                        color={question.CorrectOption == props.UserAnswer ? "green" : "red"}
                                        size="large"
                                        icon={
                                            <Icon
                                                color={question.CorrectOption == props.UserAnswer ? "green" : "red"}
                                                name={question.CorrectOption == props.UserAnswer ? "check" : "close"}
                                            />
                                        }
                                    />
                                } />
                            : ""
                        }
                    </div>




                </div>

                <Header as='h3'>{question.Body}</Header>

                <Transition visible={showPreviousAnswers} animation='scale' duration={500}>
                    <div>
                        <Header
                            disabled
                            as='h2'
                        >
                            Previous answers
                        </Header>

                        {previousAnswerHtml}


                        {/*{props.GroupedQuestion.map((item, index) =>*/}
                        {/*    <QuestionOption*/}
                        {/*        Key={index}*/}
                        {/*        Text={item.Text}*/}
                        {/*        Option={item.UserAnswer}*/}
                        {/*        IsCorrect={item.IsCorrect}*/}
                        {/*        UserAnswer={item.UserAnswer}*/}
                        {/*        Color={item.IsCorrect ? "#00800054" : item.IsCorrect == null ? "white" : "#ff000054"}*/}
                        {/*    />*/}
                        {/*)}*/}

                    </div>
                </Transition>


                <Transition visible={!showPreviousAnswers} animation='scale' duration={500}>
                    <div>
                        {options.map((item, index) =>
                            <QuestionOption
                                Key={index}
                                Text={item.Text}
                                Option={item.Id}
                                IsCorrect={item.IsCorrect}
                                UserAnswer={props.UserAnswer}
                                CorrectOption={item.CorrectOption}
                                Color={item.IsCorrect ? "#00800054" : item.IsCorrect == null ? "white" : "#ff000054"}
                            />
                        )}
                    </div>
                </Transition>



            </div>
        </div>
    )
}