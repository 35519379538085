import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { Form, Input, TextArea, Button, Message, Transition, Icon } from 'semantic-ui-react'
import Cookies from 'universal-cookie';
import { Header } from 'semantic-ui-react';
import { Navbar } from '../../Navbar/Navbar';
import { loginContext } from '../../../../../Store';
import { Link } from 'react-router-dom';
import { dateFormat } from '../../../../../helper/helper';
import './companyedit.css';

const cookies = new Cookies();

export const CompanyEdit = (props) => {
    const [isLoginControl, setLoginControl] = useContext(loginContext);
    const [today] = useState(new Date().toISOString().split('T')[0]);
    const [startingDate, setStartingDate] = useState(today);
    const [dueDate, setDueDate] = useState("");

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");

    const [header, setHeader] = useState("");
    const [message, setMessage] = useState("");
    const [messageModalOpen, setMessageModalOpen] = useState("");
    const [messageType, setMessageType] = useState(true);

    useEffect(() => {

        if (!isLoginControl) {
            window.location.href = "/";
        }

        getCompanyData();

    }, []);

    const updateCompany = () => {
        setMessageModalOpen(false);

        var companyData = {
            name: name,
            description: description,
            startingDate: startingDate,
            dueDate: dueDate,
            id: props.match.params.id
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: companyData
        });

        axios.post('/Company/Update', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            if (response.data.resultCode == 0) {
                setHeader("Success");
                setMessage("company information has been updated successfully");
                setMessageType(true);
                setMessageModalOpen(true);
            } else {
                setHeader("Error");
                setMessage(response.data.message);
                setMessageType(false);
                setMessageModalOpen(true);
            }


        });
    }

    const getCompanyData = () => {

        var company = {
            Id: props.match.params.id
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: company
        });

        axios.post('/Company/GetById', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            if (response.data.resultCode == 0) {
                var simulationData = response.data.data;

                setName(simulationData.name);
                setDescription(simulationData.description);

                setStartingDate(simulationData.startingDate);
                setDueDate(simulationData.dueDate);
            }
        });
    }

    return (
        <>
            {isLoginControl ?
                <div>
                    <div className="super-admin-dashboard-container" >
                        <div className="super-admin-dashboard-nav">
                            <Navbar />
                        </div>
                        <div className="super-admin-dashboard-content">

                            <Header as='h1' className="super-admin-edit-company-header">
                                <Link to="/company"><Button className="simulation-back-button" size="massive" icon>
                                    <Icon name='left chevron' />
                                </Button></Link>

                                {name}
                            </Header>

                            <div className="company-add-form-box">
                                <Form>
                                    <Form.Group widths='equal'>
                                        <Form.Field
                                            control={Input}
                                            label='Company name (*)'
                                            placeholder='Company name'
                                            size="big"
                                            value={name}
                                            onChange={e => { setName(e.target.value) }}
                                        />
                                    </Form.Group>
                                    <Form.Field
                                        control={TextArea}
                                        label='Description (*)'
                                        placeholder='Description'
                                        size="big"
                                        style={{ minHeight: 150, fontSize: "1.3em" }}
                                        value={description}
                                        onChange={e => { setDescription(e.target.value) }}
                                    />

                                    <Form.Field>
                                        <label>Starting Date (*)</label>
                                        <Input
                                            size="big"
                                            max={dateFormat(dueDate, true)}
                                            value={startingDate}
                                            onChange={e => { setStartingDate(e.target.value) }}
                                            type="datetime-local"
                                        />
                                    </Form.Field>

                                    <Form.Field>
                                        <label>End Date (*)</label>
                                        <Input
                                            size="big"
                                            min={dateFormat(startingDate, true)}
                                            value={dueDate}
                                            onChange={e => { setDueDate(e.target.value) }}
                                            type="datetime-local" />
                                    </Form.Field>

                                    <Button
                                        size="big"
                                        color="blue"
                                        onClick={updateCompany}
                                    >
                                        Save
                                    </Button>

                                </Form>

                                <Transition visible={messageModalOpen} animation='scale' duration={500}>
                                    <Message
                                        color={messageType ? "green" : "red"}
                                        header={header}
                                        content={message}
                                    />
                                </Transition>
                            </div>
                        </div>
                    </div >
                </div >
                : ""}
        </>
    );
}