import React, { useState, useContext, useEffect } from 'react';
import './questionchoose.css';
import axios from 'axios';
import { Button, Divider, Form, Checkbox, Dropdown, Modal, Transition, Icon, Header, Tab, TextArea, Popup } from 'semantic-ui-react';
import Broadcast from '../../../../../../Broadcast';

import Confetti from 'react-confetti';
import Cookies from 'universal-cookie';

import TrueBg from '../../../../../../images/true.png';
import WrongBg from '../../../../../../images/wrong.png';


const cookies = new Cookies();

export const QuestionChoose = (props) => {





    const [isOpenFeedbackModal, setIsOpenFeedbackModal] = useState(false);
    const [modalHeader, setModalHeader] = useState("Wrong");
    const [modalDesc, setModalDesc] = useState("Points earned: 0");
    const [confettiActive, setConfettiActive] = useState(false);
    const [modalTextColor, setModalTextColor] = useState("white");

    const [modalBg, setModalBg] = useState(TrueBg);


    const [questionAgainCount, setQuestionAgainCount] = useState(parseInt(props.ShowQuestionTimes + 1));
    const [isRightModalOpen, setIsRightModalOpen] = useState(false);
    const [isWrongModalOpen, setIsWrongModalOpen] = useState(false);

    const [optionBgColor, setOptionBgColor] = useState("white");



    useEffect(() => {


        if (props.ShowQuestionTimes == -1) {
            props.CloseHook(false);
            sendEmitToBroadcast();
        }
        //props.SetQuestionAnswerCount(questionAgainCount - 1);

    }, [props.ShowQuestionTimes]);

    useEffect(() => {
        console.log("questionAgainCount", questionAgainCount);
    }, []);


    const feedbackModalContent = {
        position: "relative",
        top: "21%",
        left: "1%"
    }

    const feedbackModalHeader = {
        fontSize: "4vw",
        fontWeight: "bold",
        color: modalTextColor,
        fontFamily: "Gelion-Bold"
    }

    const feedbackModalDesc = {
        fontSize: "3vw",
        fontWeight: "bold",
        fontFamily: "cursive",
        color: modalTextColor,
        fontFamily: "Gelion-Thin"
    }

    const feedbackModalCloseContent = {
        position: "absolute",
        top: "0%",
        right: "0%",
        padding: "20px"

    }

    const feedbackModalCloseContentTrue = {
        position: "absolute",
        top: "75%",
        left: "25%",
        width: "284px",
        height: "168px",

    }

    const feedbackModalCloseButton = {
        width: "100%",
        height: "100%",
        background: "none",
        border: "none",
        fontFamily: "gameFont",
        fontSize: "5em",
        color: "white"
    }


    const sendEmitToBroadcast = () => {
        Broadcast.emit('ON_QUESTION_CLOSE');
    }

    const sendAnswer = () => {

        props.SetBlockOptions(true);

        var userAnswerData = {
            SimulationId: props.SimulationId,
            QuestionId: props.QuestionId,
            UserId: cookies.get('UserId'),
            IsCorrect: props.CorrectAnswer == props.ChooseKeyword ? true : false,
            UserAnswer: props.ChooseKeyword
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: userAnswerData
        });

        axios.post('/QuestionAnswers/Add', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);
            if (response.data.resultCode == 0) {

                props.SetCircleTimeIsPlaying(false);
                props.ClearIntervalFunc();





                if (response.data.data.isCorrect) {
                    setIsRightModalOpen(true);

                    setModalHeader("Right");

                    if (props.SimulationSettingsJson.truePoint.point != undefined) {
                        setModalDesc(props.SimulationSettingsJson.truePoint.point.toFixed(1) + " Points");
                        setConfettiActive(true);
                    } else {
                        setModalDesc("");
                    }


                    //setIsOpenFeedbackModal(true);



                } else {
                    setModalBg(WrongBg);
                    setModalTextColor("white")
                    if (props.SimulationSettingsJson.truePoint.point == undefined) {
                        setModalDesc("");
                    }

                    //setQuestionAgainCount(questionAgainCount - 1);
                    //setIsOpenFeedbackModal(true);
                    setIsWrongModalOpen(true);


                }

                //sendEmitToBroadcast();
            } else {
                props.CloseHook(false);
                sendEmitToBroadcast();
            }
        });



    }

    const closeModelEvents = () => {

        setIsOpenFeedbackModal(false);

        if (props.ShowQuestionAgainTimes != null) {


            if (props.ShowQuestionTimes == 0) {
                props.CloseHook(false);
                sendEmitToBroadcast();
            }

            if (confettiActive == false) {
                props.SetShowQuestionTimes(props.ShowQuestionTimes - 1);
                props.SetQuestionAnswerCount(props.QuestionAnswerCount - 1);
                setIsWrongModalOpen(false);
                props.SetBlockOptions(false);
            }

            else {
                props.CloseHook(false);
                sendEmitToBroadcast();
            }

        }
        else {

            var questionOptions = document.getElementsByClassName("question-sub-choose");

            for (var i = 0; i < questionOptions.length; i++) {
                questionOptions[i].style.pointerEvents = "none";
            }

            props.CloseHook(false);
            sendEmitToBroadcast();
        }

     
    }

    return (
        <>
            <Confetti
                style={{
                    zIndex: "3",
                    top: "-100%"
                }}
                run={confettiActive}
                width={500}
                height={600}
            />

            <Transition visible={isWrongModalOpen} animation='scale' duration={500}>
            <div
                className={"isWrongModalStyle"}
                >
                    <div style={feedbackModalCloseContent}>
                        {/*<button*/}
                        {/*    onClick={closeModelEvents}*/}
                        {/*    style={feedbackModalCloseButton}*/}
                        {/*>*/}

                        {/*</button>*/}

                        <Icon
                            style={{
                                color: "white",
                                cursor: "pointer"
                            }}
                            size="big"
                            onClick={closeModelEvents}
                            name="close" />

                        {/*<Button*/}
                        {/*    color="grey"*/}
                            
                        {/*    icon='close'*/}
                        {/*/>*/}
                    </div>

                    <div style={feedbackModalContent}>
                        <h1
                            style={feedbackModalHeader}
                        >
                            {modalHeader}
                        </h1>

                        <h3
                            style={feedbackModalDesc}
                        >
                            {modalDesc}
                        </h3>
                    </div>

                </div>
            </Transition>


            <Transition visible={isRightModalOpen} animation='scale' duration={500}>
                <div
                    className={"isRightModalStyle"}

            >
                    <div style={feedbackModalCloseContent}>
                        <Icon
                            style={{
                                color: "white",
                                cursor: "pointer"
                            }}
                            size="big"
                            onClick={closeModelEvents}
                            name="close" />
                    </div>

                    <div style={feedbackModalContent}>
                        <h1
                            style={feedbackModalHeader}
                        >
                            {modalHeader}
                        </h1>

                        <h3
                            style={feedbackModalDesc}
                        >
                            {modalDesc}
                        </h3>
                    </div>

                </div>
            </Transition>

            <div
                onMouseEnter={() => setOptionBgColor("#cdffc9")}
                onMouseLeave={() => setOptionBgColor("white")}
                onClick={sendAnswer}
                style={{
                display: "flex",
                justifyContent: "space-between",
                width: "40%",
                alignItems: "center",
                boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
                borderRadius: "10px",
                pointerEvents: props.BlockOptions && "none",
                backgroundColor: optionBgColor,
                transition: "background-color .5s",
                cursor: "pointer"
            }}>
                {/*<div*/}
                {/*    onClick={sendAnswer}*/}
                {/*    className="question-sub-purple-button"*/}
                {/*>*/}
                {/*    {props.ChooseKeyword}*/}
                {/*</div>*/}

                <div
                 
                    className="question-sub-choose">
                    <span>{props.Text}</span>
                </div>
            </div>

        </>
    );
}