import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { Form, Input, TextArea, Button, Message, Transition, Table, Checkbox, Loader, Modal, Icon, Dropdown } from 'semantic-ui-react'
import Cookies from 'universal-cookie';
import { Tab } from 'semantic-ui-react'
import { Header } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import './companyrow.css';

const cookies = new Cookies();

export const CustomerRow = (props) => {
    console.log(props);
    const [isActiveCustomer, setIsActiveCustomer] = useState(props.IsActive);
    const [messageModalOpen, setMessageModalOpen] = useState(false);
    const [deleteMessageModalOpen, setDeleteMessageModalOpen] = useState(false);
    const [clickedCustomerName, setClickedCustomerName] = useState("");
    const [clickedUserId, setClickedUserId] = useState("");
    const [undoDeleted, setUndoDeleted] = useState(false);
    const [mailModal, setMailBody] = useState(false);

    const [emailBodyText, setEmailBodyText] = useState("");
    const [emailTitle, setEmailTitle] = useState("");


    

    const options = [
        { key: 1, text: 'onur.sakalli@digitallica.com.tr', value: 1 },
        { key: 2, text: 'emre@digitallica.com.tr', value: 2 },
    ]

    const renderLabel = (label) => ({
        color: 'black',
        content: `${label.text}`,
        icon: 'check',
    })


    const handleIsActive = (event, data) => {
        setMessageModalOpen(true);
        setClickedUserId(event.target.id);

    }

    const setActiveCompany = (e, data) => {
        setIsActiveCustomer(data);
        setMessageModalOpen(false);
    }

    const deleteCustomer = () => {
        var data = {
            Id: clickedUserId,
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: data
        });

        axios.post('/User/Delete', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            console.log(response);

            if (response.data.result.resultCode == 0) {
                props.GetCompaniesFunc();
                setDeleteMessageModalOpen(false);
            }


        });
    }

    const undoDeletedCustomer = () => {
        var data = {
            Id: clickedUserId,
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: data
        });

        axios.post('/User/UndoDeleted', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            console.log(response);

            if (response.data.result.resultCode == 0) {
                props.GetCompaniesFunc();
                setDeleteMessageModalOpen(false);
            }


        });
    }

    const changeActivityStatuCustomer = () => {
        var data = {
            Id: clickedUserId,
            IsActive: !isActiveCustomer
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: data
        });

        axios.post('/User/ChangeActivityCustomer', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);

            if (response.data.result.resultCode == 0) {
                props.GetCompaniesFunc();
                setIsActiveCustomer(response.data.result.data.isActive);
                setMessageModalOpen(false)
            }

        });
    }


    return (
        <>
            <Transition visible={messageModalOpen} animation='scale' duration={500}>
                <Modal dimmer="blurring" className="users-delete-ask-modal" size={"mini"} open={messageModalOpen} onClose={() => { setMessageModalOpen(false) }}>
                    <Modal.Header><span className={"account-text"}>Are you sure ?</span></Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            {
                                isActiveCustomer ?
                                    "If you turn it off, will not be able to login."
                                    :
                                    "Open back"
                            }

                        </Modal.Description>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => { setMessageModalOpen(false); }}>
                            CANCEL
                        </Button>
                        <Button onClick={() => { changeActivityStatuCustomer(); }} color='green'>
                            OKEY
                        </Button>

                    </Modal.Actions>
                </Modal>
            </Transition>


            <Transition visible={deleteMessageModalOpen} animation='scale' duration={500}>
                <Modal dimmer="blurring" className="users-delete-ask-modal" size={"mini"} open={deleteMessageModalOpen} onClose={() => { setDeleteMessageModalOpen(false) }}>
                    <Modal.Header><span className={"account-text"}>Are you sure ?</span></Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            {clickedCustomerName}

                        </Modal.Description>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => { setDeleteMessageModalOpen(false); }}>
                            CANCEL
                        </Button>
                        <Button onClick={() => { undoDeleted ? undoDeletedCustomer() : deleteCustomer(); }} color={undoDeleted ? "black" : "red"}>
                            {undoDeleted ? "UNDO" : "DELETE"}
                        </Button>

                    </Modal.Actions>
                </Modal>
            </Transition>

            <Table.Row>
                <Table.Cell collapsing>
                    <Checkbox
                        disabled={props.IsDeleted}
                        onChange={handleIsActive}
                        checked={isActiveCustomer}
                        id={props.Id}
                        name={props.Id}
                        toggle />
                </Table.Cell>
                <Table.Cell disabled={!isActiveCustomer || props.IsDeleted}>{props.Name}</Table.Cell>
                <Table.Cell disabled={!isActiveCustomer || props.IsDeleted}>{props.Surname}</Table.Cell>
                <Table.Cell disabled={!isActiveCustomer || props.IsDeleted}>{props.Email}</Table.Cell>
                <Table.Cell disabled={!isActiveCustomer || props.IsDeleted}>{props.Username}</Table.Cell>
                <Table.Cell disabled={!isActiveCustomer || props.IsDeleted}>{props.CompanyName}</Table.Cell>
                <Table.Cell disabled={!isActiveCustomer || props.IsDeleted}>{props.Phone}</Table.Cell>


              
                <Table.Cell>

                    <Link to={"/customer-edit/" + props.Id}>
                        <Button size="mini" icon='edit' />
                    </Link>

                </Table.Cell>
                <Table.Cell>
                    {
                        !props.IsDeleted ?
                            <Button
                                data-name={props.Name}
                                data-id={props.Id}
                                onClick={
                                    (e) => {
                                        setDeleteMessageModalOpen(true);
                                        setClickedCustomerName(e.target.dataset.name);
                                        setClickedUserId(e.target.dataset.id);
                                    }}
                                size="mini"
                                color="red"
                                icon={
                                    <Icon onClick={
                                        (e) => {
                                            setDeleteMessageModalOpen(true);
                                            setClickedCustomerName(e.target.dataset.name);
                                            setClickedUserId(e.target.dataset.id);
                                        }}
                                        data-name={props.Name}
                                        data-id={props.Id}
                                        name="trash"
                                    />
                                }
                            />
                            :
                            <Button
                                data-name={props.Name}
                                data-id={props.Id}
                                size="mini"
                                color="black"
                                onClick={
                                    (e) => {
                                        setDeleteMessageModalOpen(true);
                                        setUndoDeleted(true);
                                        setClickedCustomerName(e.target.dataset.name);
                                        setClickedUserId(e.target.dataset.id);
                                    }
                                }
                                icon={
                                    <Icon
                                        data-name={props.Name}
                                        data-id={props.Id}
                                        name="undo"
                                        onClick={
                                            (e) => {
                                                setDeleteMessageModalOpen(true);
                                                setUndoDeleted(true);
                                                setClickedCustomerName(e.target.dataset.name);
                                                setClickedUserId(e.target.dataset.id);
                                            }
                                        }
                                    />
                                }
                            ></Button>
                    }

                </Table.Cell>
            </Table.Row>
        </>
    );
}