import Cookies from 'universal-cookie';
const cookies = new Cookies();


export const clearCookies = async () =>
    document.cookie
    .split(';')
    .forEach((c) => (document.cookie = c.replace(/^ +/, '')
        .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`)));

export const reSetCookies = async (userData, user) => {
    cookies.set('Token', userData.token);
    cookies.set('Name', userData.name);
    cookies.set('Surname', userData.surname);
    cookies.set('FullName', userData.name + " " + userData.surname);
    cookies.set('UserId', userData.id);
    cookies.set('CompanyId', userData.companyId);
    cookies.set('Email', userData.email);
    cookies.set('Username', userData.username);
    cookies.set('UserType', userData.userType);
    cookies.set('IsLogin', true);


    cookies.set('forceLogin', true);
    cookies.set('adminToken', user.adminUser.adminToken);
    cookies.set('adminName', user.adminUser.adminUser.name);
    cookies.set('adminSurname', user.adminUser.adminUser.surname);
    cookies.set('adminFullName', user.adminUser.adminUser.name + " " + user.adminUser.adminUser.surname);
    cookies.set('adminUserId', user.adminUser.adminUser.id);
    cookies.set('adminCompanyId', user.adminUser.adminUser.companyId);
    cookies.set('adminEmail', user.adminUser.adminUser.email);
    cookies.set('adminUsername', user.adminUser.adminUser.username);
    cookies.set('adminUserType', user.adminUser.adminUser.userType);
}

export const dateFormat = (dbDate,reverse?) => {
    const date = new Date(dbDate)
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()

    // add a leading 0 if the number is less than 10. like 9 to 09

    day < 10 && (day = `0${day}`)
    month < 10 && (month = `0${month}`)

    const newFormat = reverse ? `${year}-${month}-${day}` : `${day}-${month}-${year}`;

    return newFormat;
}

export const base64ToByteArray = (base64) => {
    let binaryString = window.atob(base64);
    let len = binaryString.length;

    let bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

export const downloadFile = (fileContents, fileName) => {
    let bytes = base64ToByteArray(fileContents);

    let blob = new Blob([bytes], { type: "application/octet-stream" });
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);

    link.download = fileName;
    link.click();
}