import React, { useState, useContext, useEffect, useRef } from 'react';
import {
    Header,
    Input,
    Form,
    Checkbox,
    Transition,
    Loader,
    Button,
    Icon
} from 'semantic-ui-react'
import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const IntroOutroVideo = (props) => {

    const [startingUploadIntro, setStartingUploadIntro] = useState(false);
    const [startingUploadOutro, setStartingUploadOutro] = useState(false);

    const [uploadBlocker, setUploadBlocker] = useState(true);

    const [data] = useState({
        userId: props.UserId,
        companyId: props.CompanyId,
        simulationId: props.SimulationId
    });

    const [selectedVideoType, setSelectedVideoType] = useState("");
    const [introVideos, setIntroVideos] = useState([]);
    const [outroVideos, setOutroVideos] = useState([]);

    const [selectedIntroVideo, setSelectedIntroVideo] = useState("");
    const [selectedOutroVideo, setSelectedOutroVideo] = useState("");

    const styles = {
        videoContainer: {
            display: "flex",
            justifyContent: "space-between",
            width: "90%",
            margin: "0 auto",
            padding: "10px",
            marginTop: "50px",
            minHeight: "300px"
        },

        notFoundContainer: {
            marginTop: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%"
        },

        line: {
            width: "100%",
            height: "1px",
            backgroundColor: "#dedede",
            marginTop: "30px"
        },

        videoListContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
            gap: "2em",
            padding: "40px"
        },

        video: {

        },

        videoSection: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        }
    }

    const uploadVideo = (file, videoType) => {
        videoType == 1 ?
            setStartingUploadIntro(true) :
            setStartingUploadOutro(true);

        let videoData = {
            companyId: data.companyId,
            userId: data.userId,
            videoType: videoType
        }

        var formData = new FormData();
        formData.append('Data', file);
        formData.append('AuthToken', cookies.get('Token'));
        formData.append('Video', JSON.stringify(videoData));

        axios({
            method: 'POST',
            url: "/Video/Upload",
            data: formData
        }).then((response) => {
            console.log(response);

            if (response.data.result.resultCode == 0) {
                getVideos();

                let uploadedVideoId = response.data.result.data.id
                videoType == 1 ? setSelectedIntroVideo(uploadedVideoId) : setSelectedOutroVideo(uploadedVideoId);

                videoType == 1 ?
                    setStartingUploadIntro(false) :
                    setStartingUploadOutro(false);
                
            }
            else {
                alert(response.data.result.message);

                videoType == 1 ?
                    setStartingUploadIntro(false) :
                    setStartingUploadOutro(false);
            }
        });
    }

    const getVideos = () => {

        var videoData = {
            CompanyId: data.companyId
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: videoData
        });

        axios.post('/Video/GetByCompanyId', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);

            if (response.data.resultCode == 0) {
                let videos = response.data.data;

                setIntroVideos(videos.filter(v => v.videoType == 1));
                setOutroVideos(videos.filter(v => v.videoType == 2));

                setStartingUploadIntro(false);
                setStartingUploadOutro(false);
            }
        });
    }

    const handleChangeFileInput = (file, videoType) => {
        uploadVideo(file, videoType);
        setSelectedVideoType(videoType)
    }

    const updateSimulation = (selectedIntroVideoId, selectedOutroVideoId) => {

        selectedVideoType == 1 ?
            setStartingUploadIntro(true) :
            setStartingUploadOutro(true);

        let videoData = {
            Id: data.simulationId,
            companyId: data.companyId,
            introVideoId: selectedIntroVideoId,
            outroVideoId: selectedOutroVideoId
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: videoData
        });

        axios.post('/Simulation/UpdateIntroOutroVideo', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);

            if (response.data.resultCode == 0) {
                let data = response.data.data;

                if (data.introVideoId == null)
                    setSelectedIntroVideo("");

                if (data.outroVideoId == null)
                    setSelectedOutroVideo("");



                selectedVideoType == 1 ?
                    setStartingUploadIntro(false) :
                    setStartingUploadOutro(false);
                
            }
        });
    }

    const getSelectedPhoto = () => {
       
        let videoData = {
            Id: data.simulationId,
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: videoData
        });

        axios.post('/Simulation/GetById', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);

            if (response.data.resultCode == 0) {
                let simulationData = response.data.data;

                setSelectedIntroVideo(simulationData.introVideoId);
                setSelectedOutroVideo(simulationData.outroVideoId);

            }
        });
    }

    useEffect(() => {
        getVideos();
        getSelectedPhoto();
    }, []);

    useEffect(() => {
        if (!uploadBlocker)
            updateSimulation(selectedIntroVideo, selectedOutroVideo);
    }, [selectedIntroVideo, selectedOutroVideo]);


    return (
        <div>
            <Header
                disabled
                as='h2'
            >
                Upload intro and outro video
            </Header>


            <div style={styles.videoContainer}>
                <div style={styles.videoSection}>
                    <Header as='h2'>Intro Video</Header>

                    <Header
                        disabled
                        as='h3'
                        color="orange"
                    >
                        Upload new intro video
                    </Header>

                    <Input
                        accept="video/*"
                        type="file"
                        onChange={(e) => handleChangeFileInput(e.target.files[0], 1)}
                    />

                    

                    <Header
                        disabled
                        as='h3'
                        color="orange"
                    >
                        Select a intro video
                    </Header>

                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%"
                    }}>
                        <Button
                            onClick={() => {
                                setSelectedIntroVideo(null);
                                updateSimulation(null, selectedOutroVideo)
                            }}
                            color="red"
                            size="big"
                            animated
                        >
                            <Button.Content visible>Cancel selection</Button.Content>
                            <Button.Content hidden>
                                <Icon name='cancel' />
                            </Button.Content>
                        </Button>
                    </div>

                    <Transition visible={startingUploadIntro} animation='scale' duration={500}>
                        <Loader size="huge" active inline='centered' />
                    </Transition>

                    <Transition
                        visible={introVideos.length > 0}
                        animation='scale'
                        duration={500}
                    >
                        <div style={styles.videoListContainer}>
                            <div>
                                {introVideos.map(video => (
                                    <div style={{
                                        padding: "10px",
                                        margin: "10px",
                                        border: selectedIntroVideo == video.id ? "solid 5px red" : "solid 5px #ccc",
                                        borderRadius: "5px",
                                        cursor: "pointer"
                                    }}
                                        onClick={(e) => {
                                            setSelectedIntroVideo(video.id);
                                            setUploadBlocker(false);
                                        }}
                                    >
                                        <video poster="" width="100%" controls>
                                            <source
                                                src={window.location.origin + `/Assets/Videos/` + video.videoUrl}
                                                type="video/mp4">
                                           </source>
                                        </video>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </Transition>

                        <Transition
                            visible={introVideos.length == 0}
                            animation='scale'
                            duration={500}
                        >
                            <div>
                                <div style={styles.notFoundContainer}>
                                    <Header
                                        disabled
                                        as='h3'
                                    >
                                        Not found Intro Video
                                    </Header>

                                    <Icon
                                        disabled
                                        name="video"
                                        size="large"
                                    />
                                </div>
                            </div>

                        </Transition>
                        </div>

                    <div style={{
                        width: "1px",
                        backgroundColor: "#dedede"
                    }}></div>

                <div style={styles.videoSection}>
                        <Header as='h2'>Outro Video</Header>

                        <Header
                            disabled
                            as='h3'
                            color="orange"
                        >
                            Upload new outro video
                        </Header>

                        <Input
                            accept="video/*"
                            type="file"
                            onChange={(e) => handleChangeFileInput(e.target.files[0], 2)}
                        />

                        

                        <Header
                            disabled
                            as='h3'
                            color="orange"
                        >
                            Select a outro video
                    </Header>

                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%"
                    }}>
                        <Button
                            onClick={() => {
                                setSelectedOutroVideo(null);
                                updateSimulation(selectedIntroVideo, null);
                            }}
                            color="red"
                            size="big"
                            animated
                        >
                            <Button.Content visible>Cancel selection</Button.Content>
                            <Button.Content hidden>
                                <Icon name='cancel' />
                            </Button.Content>
                        </Button>
                    </div>

                    <Transition visible={startingUploadOutro} animation='scale' duration={500}>
                        <Loader size="huge" active inline='centered' />
                    </Transition>

                    <Transition
                        visible={outroVideos.length > 0}
                        animation='scale'
                        duration={500}
                    >
                        <div>
                            <div style={styles.videoListContainer}>
                                <div>
                                    {outroVideos.map(video => (
                                        <div style={{
                                            padding: "10px",
                                            margin: "10px",
                                            border: selectedOutroVideo == video.id ? "solid 5px red" : "solid 5px #ccc",
                                            borderRadius: "5px",
                                            cursor: "pointer"
                                        }}
                                            onClick={(e) => {
                                                setSelectedOutroVideo(video.id);
                                                setUploadBlocker(false);
                                            }}
                                        >
                                            <video width="100%" controls>
                                                <source
                                                    src={window.location.origin + `/Assets/Videos/` + video.videoUrl}
                                                    type="video/mp4">
                                                </source>
                                            </video>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                    </Transition>

                        <Transition
                            visible={introVideos.length == 0}
                            animation='scale'
                            duration={500}
                        >
                            <div>
                                <div style={styles.notFoundContainer}>
                                    <Header
                                        disabled
                                        as='h3'
                                    >
                                        Not found Outro Videos
                                    </Header>

                                    <Icon
                                        disabled
                                        name="video"
                                        size="large"
                                    />
                                </div>
                            </div>

                        </Transition>

                    </div>
                </div>

            </div >
            );
};