import React, { useState, useContext, useEffect } from 'react';
import './questionpanelfinal.css';
import axios from 'axios';
import { Button, Divider, Form, Checkbox, Dropdown, Modal, Transition, Icon, Header, Tab, TextArea, Popup, Loader, Statistic } from 'semantic-ui-react';

import { QuestionChoose } from './QuestionChoose/QuestionChoose';
import { CircleTime } from './CircleTime/CircleTime';
import Cookies from 'universal-cookie';
import Broadcast from '../../../../../Broadcast';
import WrongBg from '../../../../../images/wrong.png';


const cookies = new Cookies();

export const QuestionPanelFinal = (props) => {

    const [isLoading, setIsLoading] = useState(true);

    const [options, setOptions] = useState([
        { Id: 'A', Text: '', IsCorrect: false },
        { Id: 'B', Text: '', IsCorrect: false }
    ]);

    const [questionCount, setQuestionCount] = useState(5);
    const [isVisible, setIsVisible] = useState(props.QuestionPanelVisible ? props.QuestionPanelVisible : false);

    const [circleTimerHtml, setCircleTimerHtml] = useState("");

    const [simulationSettingsJson] = useState(props.SimulationSettings);

    const [questionAnswerCount, setQuestionAnswerCount] = useState(parseInt(simulationSettingsJson.showQuestionAgain.times));
    const [showQuestionTimes, setShowQuestionTimes] = useState(simulationSettingsJson.showQuestionAgain.isActive == true ? parseInt(simulationSettingsJson.showQuestionAgain.times) : null);


    const [circleTimeIsPlaying, setCircleTimeIsPlaying] = useState(true);
    const [questionInterval, setQuestionInterval] = useState();

    const [isOpenFeedbackModal, setIsOpenFeedbackModal] = useState(false);
    const [isTimesIsUpModal, setIsTimesIsUpModal] = useState(false);


    const [blockOptions, setBlockOptions] = useState(false);


    const feedbackModalCloseButton = {
        width: "100%",
        height: "100%",
        background: "none",
        border: "none"
    }

    const feedbackModalCloseContent = {
        position: "absolute",
        top: "0%",
        right: "0%",
        padding: "20px"

    }

    const feedbackModal = {
        backgroundImage: `url(${WrongBg})`,
        width: "600px",
        height: "600px",
        position: "absolute",
        top: "6%",
        left: "29%",
        zIndex: "2"
    }

    const feedbackModalContent = {
        position: "relative",
        top: "30%",
        display: "flex",
        justifyContent:"center"
    }

    const feedbackModalHeader = {
        fontSize: "3vw",
        fontWeight: "bold",
        fontFamily: "cursive",
        color: "white",
        fontFamily: "Gelion-Bold"
    }

    const feedbackModalDesc = {
        fontSize: "5em",
        fontWeight: "bold",
        fontFamily: "cursive",
        color: "white",
        fontFamily: "Gelion-Thin"
    }

    useEffect(() => {
        console.log(showQuestionTimes);
    }, [showQuestionTimes]);


    //const questionPanelStyle = {
    //    width: "97%",
    //    height: "45%",
    //    position: "absolute",
    //    bottom: "35px",
    //    left: "0",
    //    right: "0",
    //    marginLeft: "auto",
    //    marginRight: "auto",
    //    borderTopLeftRadius: "20px",
    //    borderTopRightRadius: "20px",

    //    backgroundRepeat: "no-repeat",
    //    backgroundSize:"contain"
    //}

    const [question, setQuestion] = useState({
        Body: "",
        A: "",
        B: "",
        C: "",
        D: "",
        E: "",
        CorrectOption: ""
    });

    const getSavedQuestion = (isActive) => {
        //setIsVisible(true);
        var questionData = {
            Id: props.QuestionId,
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: questionData
        });

        axios.post('/Question/GetById', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            if (response.data.resultCode == 0) {

                let questionDataJSON = JSON.parse(response.data.data.questionJSON);

                console.log(questionDataJSON);

                setQuestion(questionDataJSON);

                var newQuestionOptionArray = [];

                [questionDataJSON].forEach(e => {
                    setOptions([]);
                    ["A", "B", "C", "D", "E"].forEach(k => {

                        let newQuestionObject = {
                            Id: k,
                            Text: e[k],
                            IsCorrect: e.CorrectOption == k ? true : false
                        }

                        if (newQuestionObject.Text != "")
                            newQuestionOptionArray.push(newQuestionObject)
                    });
                });

                setOptions(newQuestionOptionArray);
                timeDecreaseQuestion(isActive);
                setIsVisible(true);
                setIsLoading(false);


            }
        });
    }

    useEffect(() => {
        setQuestionCount(options.length);
    }, [options]);


    useEffect(() => {
        getSavedQuestion(simulationSettingsJson.questionTime.isActive);
    }, [])


    const timeDecreaseQuestion = (isActive) => {

        if (isActive) {
            var currentTime = parseInt(simulationSettingsJson.questionTime.point);

            window.intervalX = window.setInterval(function () {
                currentTime = currentTime - 1;

                if (currentTime <= 0) {

                    setIsVisible(false);
                    window.clearInterval(window.intervalX);
                    //setIsOpenFeedbackModal(true);
                    setIsTimesIsUpModal(true);

                    //send true for the question is over
                }
            }, 1000);


        }

    }

    const clearIntervalFunc = () => {
        window.clearInterval(window.intervalX);
    }



    return (
        <div>
            <Transition visible={isTimesIsUpModal} animation='scale' duration={500}>
               
            <div
                className={"isTimesIsUpModal"}
            >

                <div style={feedbackModalCloseContent}>
                    {/*<button*/}
                    {/*    onClick={() => {*/}
                    {/*        setIsTimesIsUpModal(false);*/}
                    {/*        Broadcast.emit('ON_QUESTION_CLOSE');*/}
                    {/*    }}*/}
                    {/*    style={feedbackModalCloseButton}*/}
                    {/*>*/}

                    {/*</button>*/}

                    <Icon
                        onClick={() => {
                            setIsTimesIsUpModal(false);
                            Broadcast.emit('ON_QUESTION_CLOSE');
                        }}
                        style={{
                            color: "white",
                            cursor: "pointer"
                        }}
                        size="big"
                        
                        name="close" />
                </div>

                <div style={feedbackModalContent}>
                    <h1
                        style={feedbackModalHeader}
                    >
                        Süreniz doldu..
                            </h1>

                    <h3
                        style={feedbackModalDesc}
                    >

                    </h3>
                </div>

                    </div>
                
            </Transition>


            <Transition visible={isVisible} animation='scale' duration={500}>
                <div className="question-panel-style">






                    <Transition visible={isLoading} animation='scale' duration={500}>
                        <Loader style={{ marginTop: "10%" }} size="massive" active inline='centered' />
                    </Transition>


                    <Transition visible={isVisible} animation='scale' duration={500}>
                        <>
                            <div className="question-sub-panel-header">
                                <div style={{ display: 'flex', width: "100%", alignItems:"center" }}>

                                    <div style={{
                                        position: "absolute",
                                        top: "-80px",
                                        left: "4px"
                                    }}>
                                        {props.SimulationSettings && simulationSettingsJson.questionTime.isActive &&
                                            <CircleTime
                                                Duration={parseInt(simulationSettingsJson.questionTime.point)}
                                                CircleTimeIsPlaying={circleTimeIsPlaying}
                                            />
                                        }


                                        {/*{circleTimerHtml}*/}
                                    </div>

                                    <div className="question-body-text" as='h1'>{question.Body}</div>
                                </div>



                                {simulationSettingsJson.showQuestionAgain.isActive == true ?
                                    <div className="user-answer-count-box">
                                        <Statistic inverted>
                                            <Statistic.Value>
                                                <span
                                                    className="user-answer-count-box-span"
                                                >
                                                    {parseInt(questionAnswerCount + 1) + " X"}
                                                </span>
                                            </Statistic.Value>
                                        </Statistic>
                                    </div> : ""}

                                {/*{simulationSettingsJson.questionTime.isActive == true ?*/}
                                {/*    <div className="user-answer-count-box-time">*/}

                                {/*    </div> : ""}*/}

                            </div>

                            <div className="question-sub-panel-choose">

                                {options.map((item, index) =>
                                    <QuestionChoose
                                        SimulationSettingsJson={simulationSettingsJson}
                                        CloseHook={setIsVisible}
                                        key={item.Id}
                                        ChooseKeyword={item.Id}
                                        SimulationId={props.SimulationId}
                                        QuestionId={props.QuestionId}
                                        Text={item.Text}
                                        IsCorrect={item.IsCorrect}
                                        CorrectAnswer={item.IsCorrect ? item.Id : null}
                                        ShowQuestionAgainTimes={simulationSettingsJson.showQuestionAgain.isActive == true ? parseInt(simulationSettingsJson.showQuestionAgain.times) : null}
                                        SetShowQuestionTimes={setShowQuestionTimes}
                                        ShowQuestionTimes={showQuestionTimes}
                                        QuestionAnswerCount={questionAnswerCount}
                                        SetQuestionAnswerCount={setQuestionAnswerCount}
                                        SetCircleTimeIsPlaying={setCircleTimeIsPlaying}
                                        ClearIntervalFunc={clearIntervalFunc}
                                        SetBlockOptions={setBlockOptions}
                                        BlockOptions={blockOptions}
                                    />
                                )}
                            </div>
                        </>
                    </Transition>
                </div>
            </Transition>

        </div>



        //<Transition visible={isVisible} animation='scale' duration={500}>

        //    <div className="question-panel-container">
        //        <Transition.Group animation={"scale"} duration={1200}>
        //            {isVisible && (
        //                <div className="question-panel-style" >
        //                    {
        //                        isLoading ? <Loader style={{ marginTop: "10%" }} size="massive" active inline='centered' /> :
        //                            <>
        //                                <div className="question-sub-panel-header">
        //                                    <div style={{ display: 'flex' }}>

        //                                        <div style={{
        //                                            position: "absolute",
        //                                            top: "-80px",
        //                                            left: "4px"
        //                                        }}>
        //                                            {props.SimulationSettings && simulationSettingsJson.questionTime.isActive &&
        //                                                <CircleTime
        //                                                    Duration={parseInt(simulationSettingsJson.questionTime.point)}
        //                                                    CircleTimeIsPlaying={circleTimeIsPlaying}
        //                                                />
        //                                            }


        //                                            {/*{circleTimerHtml}*/}
        //                                        </div>

        //                                        <div className="question-body-text" as='h1'>{question.Body}</div>
        //                                    </div>



        //                                    {simulationSettingsJson.showQuestionAgain.isActive == true ?
        //                                        <div className="user-answer-count-box">
        //                                            <Statistic inverted>
        //                                                <Statistic.Value>
        //                                                    <span
        //                                                        className="user-answer-count-box-span"
        //                                                    >
        //                                                        {parseInt(questionAnswerCount + 1)}
        //                                                    </span>
        //                                                </Statistic.Value>
        //                                            </Statistic>
        //                                        </div> : ""}

        //                                    {simulationSettingsJson.questionTime.isActive == true ?
        //                                        <div className="user-answer-count-box-time">

        //                                        </div> : ""}

        //                                </div>

        //                                <div className="question-sub-panel-choose">

        //                                    {options.map((item, index) =>
        //                                        <QuestionChoose
        //                                            SimulationSettingsJson={simulationSettingsJson}
        //                                            CloseHook={setIsVisible}
        //                                            key={item.Id}
        //                                            ChooseKeyword={item.Id}
        //                                            SimulationId={props.SimulationId}
        //                                            QuestionId={props.QuestionId}
        //                                            Text={item.Text}
        //                                            IsCorrect={item.IsCorrect}
        //                                            CorrectAnswer={item.IsCorrect ? item.Id : null}
        //                                            ShowQuestionAgainTimes={simulationSettingsJson.showQuestionAgain.isActive == true ? parseInt(simulationSettingsJson.showQuestionAgain.times) : null}
        //                                            SetShowQuestionTimes={setShowQuestionTimes}
        //                                            ShowQuestionTimes={showQuestionTimes}
        //                                            QuestionAnswerCount={questionAnswerCount}
        //                                            SetQuestionAnswerCount={setQuestionAnswerCount}
        //                                            SetCircleTimeIsPlaying={setCircleTimeIsPlaying}
        //                                            ClearIntervalFunc={clearIntervalFunc}
        //                                        />
        //                                    )}
        //                                </div>
        //                            </>

        //                    }


        //                </div>
        //            )}
        //        </Transition.Group>

        //    </div>
        //</Transition>

    );
}