import React, { useContext, useEffect, useState } from 'react';
import { Popup, Icon, Table, Label, Progress, Header, Loader, Transition } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const ReportUserTable = (props) => {

    const progressContainerStyle = {
        width: "80%",
        position: "relative"
    }

    const progressSpan = {
        position: "absolute",
        top: "2px",
        left: "37%",
        fontWeight: "bold",
        fontSize: "0.9em"
    }

    const [tableHeader] = useState(['Count', 'Name', 'Progress Percentage', 'Point', 'Success Info', 'Question', 'Raking']);

    const [isLoading, setIsLoading] = useState(true);

    const [simulation, setSimulations] = useState([]);

    const RATE_COLOR = {
        1: "yellow",
        2: "orange",
        3: "grey"
    };

    useEffect(() => {
        getSimulationsByUserId(props.UserId);
    }, [props.UserId]);

   
    const getSimulationsByUserId = (userid) => {

        props.SetWhenSelectDataLoading(true);

        var user = {
            id: userid
        };

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: user
        });

        axios.post('/Simulation/GetSimulationsByUserId', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            console.log(response)
            if (response.data.resultCode == 0) {

                let simulationsData = response.data.data;
                setSimulations(simulationsData);

                let chartArray = [];
                let trueWrongRateArray = [];
                let successCount = 0;
                let unsuccessCount = 0;
                let donutChartRate = [];
                let degreeSimulations = [];

                console.log(simulationsData);

                props.SetReportUserSimulationData(simulationsData);
        
               

                simulationsData.map((obj, i) => {

                    if (obj.userRate[0] < 4) {
                        degreeSimulations.push({
                            name: obj.simulationName,
                            rate: obj.userRate[0],
                            color: RATE_COLOR[obj.userRate[0]]
                        });
                    }

                    if (obj.haveSettings) {
                        if (obj.isSuccess)
                            successCount++;
                        else
                            unsuccessCount++;
                    }

                    chartArray.push({
                        name: obj.simulationName.slice(0, 15),
                        ProgressPercentage: obj.progress
                    });

                    trueWrongRateArray.push({
                        name: obj.simulationName.slice(0, 15),
                        UnansweredCount: obj.trueQuestionCount[2],
                        TrueCount: obj.trueQuestionCount[0],
                        WrongCount: (obj.trueQuestionCount[1] - (obj.trueQuestionCount[0] + obj.trueQuestionCount[2] || 0))  
                    });
                });

                chartArray =  chartArray.sort((a, b) => {
                    return b.ProgressPercentage - a.ProgressPercentage;
                });

                let rateValue = (100 / (successCount + unsuccessCount)).toFixed(1);
                donutChartRate[0] = (successCount * rateValue);
                donutChartRate[1] = (unsuccessCount * rateValue);

                props.SetProgressChart(chartArray);
                props.SetTrueWrongChart(trueWrongRateArray);
                props.SetDonutChart([
                    { name: "Success Rate", value: donutChartRate[0] },
                    { name: "Unsuccess Rate", value: donutChartRate[1] }
                ]);
                props.SetDegreeSimulations(degreeSimulations);

                let excelData = {
                    User: props.User,
                    UserProfileSimulationReportView: simulationsData,
                    UserOverallSuccessRateModel: [
                        { name: "Success Rate", value: donutChartRate[0] },
                        { name: "Unsuccess Rate", value: donutChartRate[1] }
                    ]
                }
                
                props.SetMultiSelectedUsersReportExcel([...props.MultiSelectedUsersReportExcel, excelData]);

                setIsLoading(false);
                props.SetReportTableIsLoading(true);
                props.SetWhenSelectDataLoading(false);
                
            }
        });
    }

    return (
        <div>
            <div>
            <Loader
                style={{ marginTop: "18%"}}
                size="huge"
                active={isLoading}
                inline='centered'
                />
            </div>
        <Transition visible={!isLoading} animation='scale' duration={500}>
                <Table style={{width:"100%"}}  verticalAlign="middle" size="large" basic='very'>
            <Table.Header>
                <Table.Row>
                    {tableHeader.map((item, index) =>
                        <Table.HeaderCell key={item}>{item}</Table.HeaderCell>
                    )}
                </Table.Row>
            </Table.Header>
            
            
            <Table.Body>
                
                {simulation.map((simulation, index) =>
                    <Table.Row key={simulation.id}>
                        <Table.Cell>

                            {simulation.otherSimulationCount != 0 ?
                                <Popup content='It shows how many times the user has completed this simulation before. If you click, you can access the detailed report.' trigger={
                                    <Link to={"/user-other-simulations/" + simulation.simulationId + "/" + simulation.userId}>
                                        <Label
                                            style={{ width: "90px" }}
                                            size="big"
                                            as="a"
                                            color="yellow"
                                           
                                        >
                                            <Icon
                                                name='chess knight'
                                            />
                                            {simulation.otherSimulationCount}
                                        </Label>
                                    </Link>
                                } />
                                : <Label
                                    size="big"
                                    disabled
                                    style={{ width: "75px" }}
                                >
                                    <Icon
                                        name='chess knight'
                                    /> 0
                                </Label>
                            }
                        </Table.Cell>
                        <Table.Cell style={{ width:"150px" }}><Header color="orange" disabled as='h4'>{simulation.simulationName}</Header></Table.Cell>
                        <Table.Cell>
                            <div style={progressContainerStyle} >
                                <Progress style={{height:"24px"}} percent={simulation.progress} indicating />
                                <span style={progressSpan}>{simulation.progress}%</span>
                            </div>
                        </Table.Cell>
                        <Table.Cell>
                            <Label style={{ width: "130px" }} size="big"  color={simulation.haveSettings ? "blue" : "white"} horizontal>
                                {simulation.haveSettings ? simulation.point[0].toFixed(1) + " / " + simulation.point[1] + " " : "-"}
                            </Label> 
                        </Table.Cell>
                        <Table.Cell>
                            {simulation.haveSettings ?
                                simulation.isSuccess ?


                                    <Popup content='Success' trigger={
                                        <Label size="big" color={"green"} horizontal>
                                            S
                                        </Label>
                                    } />
                                    :

                                    <Popup content='Unsuccess' trigger={
                                        <Label size="big" color={"red"} horizontal>
                                            U
                                        </Label>
                                    } />

                                : <Label size="big"  color={"white"} horizontal>
                                    -
                    </Label>

                            }
                        </Table.Cell>
                        <Table.Cell>
                            <Label style={{ width: "90px" }} size="big" color="teal"   horizontal>
                                {simulation.trueQuestionCount[0] + " / " + simulation.trueQuestionCount[1]}
                            </Label>

                        </Table.Cell>
                        <Table.Cell>
                            <Label style={{ width: "70px" }} size="big"  color={simulation.haveSettings ? "yellow" : "white"} horizontal>
                                {simulation.haveSettings ? simulation.userRate[0] + " / " + simulation.userRate[1] : "-"}

                            </Label>

                        </Table.Cell>
                    </Table.Row>
                )}
               
                </Table.Body>
            
        </Table>

            </Transition>
            </div>
    )
}