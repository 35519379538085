import React, { useState } from 'react';



import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const loginContext = React.createContext();
export const fullNameContext = React.createContext();

const Store = ({ children }) => {
    const [isLoginControl, setLoginControl] = useState(cookies.get('IsLogin'));
    const [fullName, setFullName] = useState(cookies.get('FullName'));

    return (
        <loginContext.Provider value={[isLoginControl, setLoginControl]}>
            <fullNameContext.Provider value={[fullName, setFullName]}>
                {children}
            </fullNameContext.Provider>
        </loginContext.Provider>
    );
};

export default Store;