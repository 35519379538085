import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { Form, Input, TextArea, Button, Message, Transition, Table, Checkbox, Loader, Modal, Icon, Dropdown } from 'semantic-ui-react'
import Cookies from 'universal-cookie';
import { Tab } from 'semantic-ui-react'
import { Header } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import './companyrow.css';

const cookies = new Cookies();

export const CompanyRow = (props) => {

    const [isActiveCompany, setIsActiveCompany] = useState(props.IsActive);
    const [messageModalOpen, setMessageModalOpen] = useState(false);
    const [deleteMessageModalOpen, setDeleteMessageModalOpen] = useState(false);
    const [clickedCompanyName, setClickedCompanyName] = useState("");
    const [clickedCompanyId, setClickedCompanyId] = useState("");
    const [undoDeleted, setUndoDeleted] = useState(false);
    const [mailModal, setMailBody] = useState(false);

    const [emailBodyText, setEmailBodyText] = useState("");
    const [emailTitle, setEmailTitle] = useState("");


    const options = [
        { key: 1, text: 'onur.sakalli@digitallica.com.tr', value: 1 },
        { key: 2, text: 'emre@digitallica.com.tr', value: 2 },
    ]

    const renderLabel = (label) => ({
        color: 'black',
        content: `${label.text}`,
        icon: 'check',
    })


    const handleIsActive = (event, data) => {
        setMessageModalOpen(true);
        setClickedCompanyId(event.target.id);
        
    }

    const setActiveCompany = (e, data) => {
        setIsActiveCompany(data);
        setMessageModalOpen(false);
    }

    const deleteCompany = () => {
        var data = {
            Id: clickedCompanyId,
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: data
        });

        axios.post('/Company/Delete', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            console.log(response);

            if (response.data.result.resultCode == 0) {
                props.GetCompaniesFunc();
                setDeleteMessageModalOpen(false);
            }
           

        });
    }

    const undoDeletedCompany = () => {
        var data = {
            Id: clickedCompanyId,
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: data
        });

        axios.post('/Company/UndoDeleted', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            console.log(response);

            if (response.data.result.resultCode == 0) {
                props.GetCompaniesFunc();
                setDeleteMessageModalOpen(false);
            }


        });
    }

    const changeActivityStatuCompany = () => {
        var data = {
            Id: clickedCompanyId,
            IsActive: !isActiveCompany
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: data
        });

        axios.post('/Company/ChangeActivity', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);

            if (response.data.result.resultCode == 0) {
                props.GetCompaniesFunc();
                setIsActiveCompany(response.data.result.data.isActive);
                setMessageModalOpen(false)
            }

        });
    }

    const getCompanyAdminEmailList = () => {
        var data = {
            Id: clickedCompanyId,
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: data
        });

        axios.post('/Company/GetAdminEmails', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            console.log(response);

            if (response.data.result.resultCode == 0) {
               
            }


        });
    }

    
    return (
        <>
            <Transition visible={messageModalOpen} animation='scale' duration={500}>
                <Modal dimmer="blurring" className="users-delete-ask-modal" size={"mini"} open={messageModalOpen} onClose={() => { setMessageModalOpen(false) }}>
                    <Modal.Header><span className={"account-text"}>Are you sure ?</span></Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            {
                                isActiveCompany ?
                                    "If you turn it off, company users cannot use the system."
                                    :
                                    "Open back"
                            }
                            
                        </Modal.Description>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => { setMessageModalOpen(false);}}>
                            CANCEL
                        </Button>
                        <Button onClick={() => { changeActivityStatuCompany();}} color='green'>
                            OKEY
                        </Button>

                    </Modal.Actions>
                </Modal>
            </Transition>


            <Transition visible={deleteMessageModalOpen} animation='scale' duration={500}>
                <Modal dimmer="blurring" className="users-delete-ask-modal" size={"mini"} open={deleteMessageModalOpen} onClose={() => { setDeleteMessageModalOpen(false) }}>
                    <Modal.Header><span className={"account-text"}>Are you sure ?</span></Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            {clickedCompanyName}

                        </Modal.Description>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => { setDeleteMessageModalOpen(false); }}>
                            CANCEL
                        </Button>
                        <Button onClick={() => { undoDeleted ? undoDeletedCompany() : deleteCompany(); }} color={undoDeleted ? "black" : "red"}>
                            {undoDeleted ? "UNDO" : "DELETE"}
                        </Button>

                    </Modal.Actions>
                </Modal>
            </Transition>

            <Transition visible={mailModal} animation='scale' duration={500}>
                <Modal dimmer="blurring" className="users-delete-ask-modal" size={"mini"} open={mailModal} onClose={() => { setMailBody(false) }}>
                    <Modal.Header><span className={"account-text"}>Send mail to <b>{clickedCompanyName}</b></span></Modal.Header>
                    <Modal.Content>

                        <Form>
                            <Form.Field>
                                <label>Admin's List</label>
                                <Dropdown
                                    multiple
                                    selection
                                    fluid
                                    options={options}
                                    placeholder='Choose an option'
                                    renderLabel={renderLabel}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Mail title</label>
                                <Input
                                    size="big"
                                    value={emailTitle}
                                    onChange={e => { setEmailTitle(e.target.value) }}
                                    placeholder="Mail title"
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Mail body</label>
                                <TextArea
                                    placeholder='Mail body'
                                    style={{ minHeight: 200 }}
                                    onChange={(e) => { setEmailBodyText(e.target.value) }}
                                />
                            </Form.Field>
                            

                        </Form>

                        
                       

                       

                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => { setMailBody(false); }}>
                            CANCEL
                        </Button>
                        <Button color="blue">
                           Send
                        </Button>

                    </Modal.Actions>
                </Modal>
            </Transition>


        <Table.Row>
            <Table.Cell collapsing>
                    <Checkbox
                        disabled={props.IsDeleted}
                        onChange={handleIsActive}
                        checked={isActiveCompany}
                        id={props.Id}
                        name={props.Id}
                        toggle />
            </Table.Cell>
            <Table.Cell disabled={!isActiveCompany || props.IsDeleted}>{props.Name}</Table.Cell>
                <Table.Cell disabled={!isActiveCompany || props.IsDeleted}>{props.StartingDate}</Table.Cell>
                <Table.Cell disabled={!isActiveCompany || props.IsDeleted}>{props.EndDate}</Table.Cell>
                <Table.Cell disabled={!isActiveCompany || props.IsDeleted}>{props.CreatedDate}</Table.Cell>
                <Table.Cell style={{ color: props.LeftDays < 10 || props.LeftDays.length > 1 ? "red" : "", fontWeight: props.LeftDays < 10 || props.LeftDays.length > 1 ? "bold" : "" }} disabled={!isActiveCompany || props.IsDeleted}>{props.LeftDays}</Table.Cell>
                <Table.Cell>
                    <Button
                        size="mini"
                        data-name={props.Name}
                        onClick={
                            e => {
                                setClickedCompanyName(e.target.dataset.name);
                                setMailBody(true);
                            }}
                        icon={
                            <Icon onClick={
                                e => {
                                    setClickedCompanyName(e.target.dataset.name);
                                    setMailBody(true);
                                }}
                                data-name={props.Name}
                                name="mail"
                            />
                        }
                    />
                </Table.Cell>
                <Table.Cell>

                    <Link to={"/company-edit/" + props.Id}>
                        <Button size="mini" icon='edit'/>
                    </Link>

                </Table.Cell>
                <Table.Cell>
                    {
                        !props.IsDeleted ? 
                            <Button
                                data-name={props.Name}
                                data-id={props.Id}
                                onClick={
                                    (e) => {
                                        setDeleteMessageModalOpen(true);
                                        setClickedCompanyName(e.target.dataset.name);
                                        setClickedCompanyId(e.target.dataset.id);
                                    }}
                                size="mini"
                                color="red"
                                icon={
                                    <Icon onClick={
                                        (e) => {
                                            setDeleteMessageModalOpen(true);
                                            setClickedCompanyName(e.target.dataset.name);
                                            setClickedCompanyId(e.target.dataset.id);
                                        }}
                                        data-name={props.Name}
                                        data-id={props.Id}
                                        name="trash"
                                    />
                                }
                            />
                            :
                            <Button
                                data-name={props.Name}
                                data-id={props.Id}
                                size="mini"
                                color="black"
                                onClick={
                                    (e) => {
                                        setDeleteMessageModalOpen(true);
                                        setUndoDeleted(true);
                                        setClickedCompanyName(e.target.dataset.name);
                                        setClickedCompanyId(e.target.dataset.id);
                                    }
                                }
                                icon={
                                    <Icon 
                                        data-name={props.Name}
                                        data-id={props.Id}
                                        name="undo"
                                        onClick={
                                            (e) => {
                                                setDeleteMessageModalOpen(true);
                                                setUndoDeleted(true);
                                                setClickedCompanyName(e.target.dataset.name);
                                                setClickedCompanyId(e.target.dataset.id);
                                            }
                                        }
                                    />
                                }
                            ></Button>
                    }
                    
                </Table.Cell>
            </Table.Row>
        </>
    );
}