import React, { useState, useContext, useEffect } from 'react';
import './dashboardnavbar.css'
import { Input, Menu, Image, Button, Icon, Dropdown, Message, Transition } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import Broadcast from '../../../../Broadcast';
import Cookies from 'universal-cookie';
import { loginContext, fullNameContext } from '../../../../Store';

const cookies = new Cookies();

export const DashboardNavBar = () => {

    const [isLoginControl, setLoginControl] = useContext(loginContext);

    const [userFullName, setUserFullName] = useContext(fullNameContext);

    const [companyLogo, setCompanyLogo] = useState("");

    const [isActiveNotification, setIsActiveNotification] = useState(false);
    const [notificationText, setNotificationText] = useState("Notification text");
    const [messageType, setMessageType] = useState("");

    useEffect(() => {
        getCompanyData();
        getSettingsById();
    }, []);

    const clearCookies = () => document.cookie
        .split(';')
        .forEach((c) => (document.cookie = c.replace(/^ +/, '').replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`)));

    const logout = () => {
        
        cookies.remove('Token');
        cookies.remove('Name');
        cookies.remove('Surname');
        cookies.remove('Email');
        cookies.remove('UserId');
        cookies.remove('IsLogin');
        cookies.remove('Password');
        cookies.remove('FullName');
        cookies.remove('Username');
        cookies.remove('UserType');

        clearCookies();

        setLoginControl(false);

        setTimeout(() => {
            window.location.href = '/';
        }, 500)
        

    }

    const sendBroadcast = () => {
        Broadcast.emit('PAGE_CHANGED', true);
    }

    const options = [

        { key: 'profile', text: 'Profile', as: Link, to: '/profile', onClick: sendBroadcast },
        { key: 'sign-out', text: 'Exit', as: Link, onClick: logout },
    ]

    const DropdownTriggerExample = () => (
        <div>
            <Button.Group color='teal'>
                <Button className={"navbar-login-button-dashboard"}> {userFullName}</Button>
                <Dropdown
                    className='button icon navbar-login-button-dashboard-arrow'
                    floating
                    options={options}
                    trigger={<></>}
                />
            </Button.Group>
        </div>
    )

    const getSettingsById = () => {
        var data = {
            Id: "47d88bff-0fef-4e9c-b75b-cf68191d5dc5",
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: data
        });

        axios.post('/Settings/GetById', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);

            if (response.data.resultCode == 0) {
                let settings = response.data.data;
                if (settings.isActive) {
                    let settingsJsonData = JSON.parse(settings.settingsJSON);

                    setIsActiveNotification(settings.isActive);
                    setNotificationText(settingsJsonData.notificationText.Text);
                    setMessageType(settingsJsonData.notificationText.Color);
                }
                

            } 

        });
    }

    const getCompanyData = () => {

        var company = {
            Id: cookies.get('CompanyId')
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: company
        });

        axios.post('/Company/GetById', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            if (response.data.resultCode == 0) {
                var companyData = response.data.data;
                setCompanyLogo(companyData.logo == null ? "https://www.dropbox.com/s/ev83fn0g1adgd1x/Digitallica_Logo_h.png?raw=1" : companyData.logo);
            }
        });
    }
    return (
        <div>
            <div className="system-settings-message-box">
                <Transition  visible={isActiveNotification} animation='scale' duration={500}>
                    <Message
                        style={{marginTop: "1%"}}
                        color={messageType}
                        onDismiss={(e) => { setIsActiveNotification(false); }}
                        header={notificationText}
                    />
                </Transition>
            </div>



            <Menu id="main-page-nav-id" className="main-page-nav" secondary>
                <Link
                    onClick={() => { Broadcast.emit('PAGE_CHANGED', true);}}
                    to={cookies.get('UserType') == 4 ? "/user-dashboard" : "/dashboard"}
                >
                    <Menu.Item
                        name='home'>
                    <Image
                        size="medium"
                        id='main-page-logo'
                        src={companyLogo}
                    />
                    </Menu.Item>
                </Link>

                <Menu.Menu position='right'>
                    <Menu.Item name='home'>
                        <DropdownTriggerExample />
                    </Menu.Item>
                </Menu.Menu>
            </Menu>

        </div>
    );

}
