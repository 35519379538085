import React, { useState, useContext, useEffect } from 'react';
import './row.css';
import axios from 'axios';
import { Input, Checkbox, Label, Button, Icon, Tab } from 'semantic-ui-react'


export const Row = (props) => {





    useEffect(() => {

        console.log(props.TableData);
    
     }, []);
    
    
    
        return (
        <div>
            <div className="digitallica-table-row-box">
                <div className="digitallica-table-checkbox-field">
                    <div><Checkbox /></div>
                </div>
                <div className="digitallica-table-row">


                    <div className="digitallica-row">Onur</div>
                    <div className="digitallica-row">SAKALLI</div>
                    <div className="digitallica-row">
                        <Label size="big" color='green' horizontal>
                            Active
                        </Label>
                    </div>
                    <div className="digitallica-row">01.01.2020</div>
                    <div className="digitallica-row">01.01.2021</div>




                    <div className="digitallica-row">
                        <Button color="orange" icon>
                            <Icon name='eye' />
                        </Button>
                        <Button color="yellow" icon>
                            <Icon name='pencil' />
                        </Button>
                        <Button color="violet" icon>
                            <Icon name='area graph' />
                        </Button>
                        <Button color="red" icon>
                            <Icon name='trash' />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );

}
