import React, { useState, useContext, useEffect } from 'react';
import './dashboardcard.css'
import { Icon, Button } from 'semantic-ui-react'
import { loginContext } from '../../../../Store';


import { Link } from 'react-router-dom';


export const DashboardCard = (props) => {

    const [isLoginControl, setLoginControl] = useContext(loginContext);

    const cardStyle = {
        color: props.Color,
        backgroundColor: props.BackColor,

    };

    return (
        <Button style={cardStyle} className="dashboard-card">

            <div><Icon size="huge" name={props.Icon} /></div>
            <div className="dashboard-card-header">{props.Text}</div>
            
        </Button>
    );

}
