import React, { useState, useContext, useEffect } from 'react';
import { Table, Checkbox, Modal, Button, Transition, Header, TextArea, Dropdown, Message } from 'semantic-ui-react';
import './generalsettings.css';
import Cookies from 'universal-cookie';
import axios from 'axios';

const cookies = new Cookies();

export const GeneralSettings = (props) => {

    const [isCloseAccessSite, setIsCloseAccessSite] = useState(false);
    const [isActiveNotification, setIsActiveNotification] = useState(false);

    const [infoMessage, setInfoMessage] = useState(false);
    const [infoMessageHeader, setInfoMessageHeader] = useState(false);
    const [infoMessageDesc, setInfoMessageDesc] = useState(false);
    const [infoMessageColor, setInfoMessageColor] = useState(false);

    const [notificationText, setNotificationText] = useState("Notification text");

    const [messageType, setMessageType] = useState("green");

    useEffect(() => {
        getSettingsById();
    }, []);

    const getSettingsById = () => {
        var data = {
            Id: "47d88bff-0fef-4e9c-b75b-cf68191d5dc5",
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: data
        });

        axios.post('/Settings/GetById', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);

            if (response.data.resultCode == 0) {
                let settings = response.data.data;
                let settingsJsonData = JSON.parse(settings.settingsJSON);

                console.log(settingsJsonData);
               
                setIsActiveNotification(settings.isActive);
                setNotificationText(settingsJsonData.notificationText.Text);
                setMessageType(settingsJsonData.notificationText.Color);
                setIsCloseAccessSite(settingsJsonData.closeAccessSite.IsActive);

            } else {
                setInfoMessageHeader("Error");
                setInfoMessageDesc("Site settings is not saved");
                setInfoMessageColor("red");
                setInfoMessage(true);
            }

        });
    }
    

    const handleIsActiveSite = (event, data) => {
        setIsCloseAccessSite(data.checked)
        

    }

    const handleIsActiveNotification = (event, data) => {
        setIsActiveNotification(data.checked)
        //setMessageModalOpen(true);

    }



    let notificationMessageType = {
        options: [
            { value: 'red', text: 'red', label: { color: 'red', empty: true, circular: true } },
            { value: 'orange', text: 'orange', label: { color: 'orange', empty: true, circular: true } },
            { value: 'yellow', text: 'yellow', label: { color: 'yellow', empty: true, circular: true } },
            { value: 'green', text: 'green', label: { color: 'green', empty: true, circular: true } },
            { value: 'blue', text: 'blue', label: { color: 'blue', empty: true, circular: true }},
            { value: 'violet', text: 'violet', label: { color: 'violet', empty: true, circular: true } },
            { value: 'black', text: 'black', label: { color: 'black', empty: true, circular: true } },
        ],
        selected: messageType,
    }

    const changeNotificationTypeOptions = (event, data) => {
        setMessageType(data.value);
    }

    const saveSettings = () => {
        var data = {
            Id: "47d88bff-0fef-4e9c-b75b-cf68191d5dc5",
            IsActive: isActiveNotification,
            SettingsJSON: JSON.stringify(
                {
                    closeAccessSite: {
                        IsActive: isCloseAccessSite
                    },
                    notificationText: {
                        Text: notificationText,
                        Color: messageType
                    }
                },

            )
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: data
        });

        axios.post('/Settings/Add', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);

            if (response.data.resultCode == 0) {
                setInfoMessageHeader("Success");
                setInfoMessageDesc("Site settings is saved");
                setInfoMessageColor("green");
                setInfoMessage(true);
            } else {
                setInfoMessageHeader("Error");
                setInfoMessageDesc("Site settings is not saved");
                setInfoMessageColor("red");
                setInfoMessage(true);
            }

        });
    }

    return (
        <>
            

            <div className = "settings-item">
                <div>
                    <Checkbox
                        disabled={props.IsDeleted}
                        onChange={handleIsActiveSite}
                        checked={isCloseAccessSite}
                        id={props.Id}
                        name={props.Id}
                        toggle
                    />
                </div>
                <div>
                    <span>close access to the site</span>    
                </div> 
            </div> 

            
            <div className="settings-item">
                
                <div>
                    <Checkbox
                        disabled={props.IsDeleted}
                        onChange={handleIsActiveNotification}
                        checked={isActiveNotification}
                        id={props.Id}
                        name={props.Id}
                        toggle
                    />
                </div>
                <div>
                    <span>Notification text</span>
                    
                </div>
            </div>
            <Transition visible={isActiveNotification} animation='scale' duration={500}>
                <div className="super-dashboard-settings-notification-text-box">
                    <Dropdown
                        selection
                        className="user-edit-is-active-checkbox"
                        placeholder='Select notification text color (default color : green)'
                        options={notificationMessageType.options}
                        value={messageType}
                        onChange={changeNotificationTypeOptions}
                    />

                    <TextArea
                        className="super-dashboard-settings-notification-text"
                        placeholder='Text...'
                        value={notificationText}
                        onChange={e => { setNotificationText(e.target.value)}}
                    />

                    <Message
                        color={messageType}
                        header={notificationText}
                    />
                </div>
            </Transition>

            <div className="super-dashboard-settings-notification-save">
                <Button
                    onClick={saveSettings}
                    size="big">
                    Save
                </Button>

                <Transition visible={infoMessage} animation='scale' duration={500}>

                    <Message color={infoMessageColor}>
                        <Message.Header>{infoMessageHeader}</Message.Header>
                        <p> {infoMessageDesc}</p>
                    </Message>
                </Transition>
            </div>


            
            

        
            </>
        )
}