import React, { useState, useContext, useEffect } from 'react';
import './userdashboard.css';
import { loginContext } from '../../../Store';
import { Navbar } from './Navbar/Navbar';
import { SimulationsList } from './Simulations/List/SimulationsList';

export const UserDashboard = (props) => {

    const [isLoginControl, setLoginControl] = useContext(loginContext);

    return (
        <div>
            { isLoginControl ?
                <div>
                    <Navbar />

                    <div>
                        <SimulationsList/>
                    </div>
                </div>
              : ""}
        </div>
    );
}