import React, { useState } from 'react';
import './simulationscard.css';
import axios from 'axios';
import { Card, Icon, Image, Button, Label, Popup, Dimmer, Header, Loader } from 'semantic-ui-react';
import noImageSimulation from '../../../../../images/matthew.png';
import { downloadFile } from '../../../../../helper/helper';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const SimulationsCard = (props) => {

    const [isActiveDimmer, setIsActiveDimmer] = useState(false);
    

    const downloadScorm2004 = () => {
        setIsActiveDimmer(true);

        var data = {
            Id: props.Id,
            Name: props.Name
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: data
        });

        axios.post('/Simulation/Create2004Scorm', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            console.log(response);
            if (response.status == 200) {
                downloadFile(response.data.fileContents, props.Name + "-Scorm2004.zip");
                setIsActiveDimmer(false);
            }
        });
    }

    return (
        <Card className='simulation-card'>
            <Dimmer active={isActiveDimmer} page>
                <Header as='h2' icon inverted>
                    <Loader size="huge" active inline='centered' />
                    Preparing...
                    <Header.Subheader>Please wait</Header.Subheader>
                </Header>
            </Dimmer>
            <Link to={"/simulation/" + props.Id}>
                <Image
                    width="290"
                    height="290"
                    className="simulation-card-image"
                    src={props.Image ? props.Image : noImageSimulation} wrapped ui={false}
                />
            </Link>
            <Card.Content>
                <Card.Header className="simulation-card-header-content">
                    <Link to={"/simulation/" + props.Id}>
                        <span className="simulation-card-header-text">              {props.Name}
                        </span>
                    </Link>

                    <Link to={"/simulation/" + props.Id + "?menu=2&subMenu=6"}>
                        <Button size="mini" icon>
                            <Icon name='film' />
                            Preview
                        </Button>
                    </Link>

                </Card.Header>
                <Card.Meta>
                    <span className='date'>{props.CreatedDate}</span>
                </Card.Meta>
                <Card.Description>
                    {props.Desc}
                </Card.Description>
            </Card.Content>
            <Card.Content extra>
                {
                    props.IsActive ?
                        <Label size='large' color='green'>
                            <Icon name='eye' />
                            published
                        </Label> :

                        <Label size='large' color='red'>
                            <Icon name='eye slash' />
                            unpublished
                        </Label>
                }
                <Popup
                    content={
                        <>
                            <Button onClick={downloadScorm2004} content='2004' />
                            <Button disabled content='1.2' />
                        </>
                    }
                    on='click'
                    pinned
                    trigger={<Label
                        size='large'
                    >
                        Scorm
                    </Label>}
                />
                <Label size='large' color='red' className='deleteSimulationIcon' onClick={() => { props.openConfirmation(props.Id); }}>
                    <Icon name='trash alternate' />
                    Sil
                </Label>
            </Card.Content>
        </Card>
    );
}

