import React, { useState, useContext, useEffect } from 'react';
import './users.css';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { DashboardNavBar } from '../Dashboard/DashboardNavBar/DashboardNavBar';
import { loginContext, fullNameContext } from '../../../Store';
import { Link } from 'react-router-dom';
import { clearCookies, reSetCookies, dateFormat } from '../../../helper/helper';

import {
    Input,
    Checkbox,
    Label,
    Button,
    Icon,
    Modal,
    Header,
    Transition,
    Pagination,
    Loader,
    Popup,
    Dimmer
} from 'semantic-ui-react'
import NotFoundSVG from '../../../images/not-found.svg';
import { Table } from '../Table/Table';
import { DropZone } from '../DropZone/DropZone';

const cookies = new Cookies();


let selectedUsersArray = [];
export const Users = (props) => {

    //context ----
    const [isLoginControl, setLoginControl] = useContext(loginContext);
   
    //context ----

    const [panelDarkBack, setPanelDarkBack] = useState(false);
    

    const [paginationHtml, setPaginationHtml] = useState("");

    //selected items process----
    const [selectedItemsNumber, setSelectedItemsNumber] = useState(0);
    const [selectedUsersList, setSelectedUsersList] = useState([]);

    const [isSelectedItems, setIsSelectedItems] = useState(false);
    //selected items process----

    //show messageModal ---

    const [messageModalOpen, setMessageModalOpen] = useState(false);
    const [messageModalHeader, setMessageModalHeader] = useState("");
    const [messageModalDesc, setMessageModalDesc] = useState("");    

    //show messageModal ---

    const [tableHeader, setTableHeader] = useState(['Name', 'Surname' ,'Status', 'User Type' ,'Created Date', 'Last Login', 'More']);
    const [tableHtml, setTableHtml] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [userData, setUserData] = useState([]);
    const [deletedUser, setDeletedUser] = useState("");
    const [deletedUserId, setDeletedUserId] = useState("");
    const [deleteModalAsk, setDeleteModalAsk] = useState(false);
    const [selectedUsersModal, setSelectedUsersModal] = useState(false);
    const [loaderActive, setLoaderActive] = useState(false);
    const [filterInputText, setFilterInputText] = useState("");
    const [showNoUsersFound, setShowNoUsersFound] = useState(false);

    const [isActiveDimmer, setIsActiveDimmer] = useState(false);

    var filterText = filterInputText;

    let userType = {
        1: "blue",
        4 : "orange"       
    }

    useEffect(() => {

        if (!isLoginControl) {
            window.location.href = "/";
        }
        selectedUsersArray = [];
        getUsers();
        paginationGet();        
    }, []);

    useEffect(() => {
        getUsers();
    }, [currentPage]);

    useEffect(() => {
        getUsers();
    }, [filterText]);

    const getUsers = () => {
        
        setLoaderActive(true);
        var users = {
            currentPage: currentPage,
            CompanyId: cookies.get('CompanyId').toString(),
            FilterText: filterText
        };

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: users
        });

        axios.post('/User/GetUsers', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            let users = response.data.data;

            setUserData(users);
            setLoaderActive(false);

            if (users.length == 0)
                setShowNoUsersFound(true);
            else
                setShowNoUsersFound(false);
            
        });
    }

    const paginationGet = () => {

        console.log(props.match.params.id);
        var userPagination = {
            CompanyId: cookies.get('CompanyId').toString()
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: userPagination
        });

        axios.post('/User/GetCount', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);


            if (response.data.count) {
               
                

                
                
                setPaginationHtml(<Pagination
                    defaultActivePage={1}
                    firstItem={null}
                    lastItem={null}
                    pointing
                    secondary
                    totalPages={parseInt(Math.ceil(response.data.count / 25))}
                    onPageChange={(event, data) => setCurrentPage(parseInt(data.activePage))}
                />);
            }
        });
    }    

    const deleteUser = (e) => {
        let deleteUser = e.target.id;
        setDeletedUserId(deleteUser);
        setDeletedUser(e.target.dataset.name);
        setDeleteModalAsk(true);
    }

    const deleteUserProcess = () => {
        var data = {
            Id: deletedUserId,
            CompanyId: cookies.get('CompanyId')
                
        };

        console.log(data);

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: data
        });

        axios.post('/User/Delete', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);
            if (response.data.result.resultCode == 0) {
                //let element = document.getElementById("tablerow-" + response.data.result.data.id);
                //element.remove();
                //getUsers();

                setDeleteModalAsk(false);

                const userIndex = userData.findIndex(e => e.id === response.data.result.data.id);
               
                userData.splice(userIndex, 1);

                setUserData([...userData]);

                var checkboxes = document.querySelectorAll('input[type=checkbox]').length;

                if (checkboxes == 1)
                    window.location.href = '/users';
            }
        });
    }

    const handleCheckBox = (e) => {


        let checkedState = e.target.checked;

        let usersInfoElement = document.querySelector('[data-id="row-' + e.target.id + '"]');

        let selectedUsersObj = {
            id: e.target.id,
            name: usersInfoElement.dataset.username,
            surname: usersInfoElement.dataset.usersurname,
            email: usersInfoElement.dataset.usermail
        }

        console.log(selectedUsersObj);
        

        if (checkedState) {
            selectedUsersArray.push(selectedUsersObj);
        }
        else {
            selectedUsersArray = selectedUsersArray.filter(item => item.id != selectedUsersObj.id);

            var allCheckbox = document.querySelectorAll('input[type=checkbox]')[0];
            allCheckbox.checked = false;
        }
      

        if (selectedUsersArray.length > 0) {
            setSelectedItemsNumber(selectedUsersArray.length);
            setIsSelectedItems(true);
        } else
            setIsSelectedItems(false);
        
    }

    const selectAllCheckbox = (e) => {
        var checkboxes = document.querySelectorAll('input[type=checkbox]');

        if (e.target.checked) {
            let numberOfSelectedUsers = 0;
            for (var i = 1; i < checkboxes.length; i++) {
                numberOfSelectedUsers++;

                var currenCheckboxField = "" + checkboxes[i].dataset.id;

                

                let currentId = currenCheckboxField.toString().slice(4, 40);

                console.log(currentId);

                let currentCheckboxElement = document.querySelector('[data-id="' + checkboxes[i].dataset.id + '"]');


                let currentCheckbox = {
                    id: currentId,
                    name: currentCheckboxElement.dataset.username,
                    surname: currentCheckboxElement.dataset.usersurname,
                    email: currentCheckboxElement.dataset.usermail
                }

                checkboxes[i].checked = true;

                selectedUsersArray.push(currentCheckbox); 
            }

            setSelectedItemsNumber(numberOfSelectedUsers);
            setIsSelectedItems(true);

        } else {

            var checkboxes = document.querySelectorAll('input[type=checkbox]');


            if (!e.target.checked) {
               
                for (var i = 1; i < checkboxes.length; i++) {
                    checkboxes[i].checked = false;
                }
            }
            selectedUsersArray = [];
            setSelectedItemsNumber(0);
            setIsSelectedItems(false);
        }
    }

    const whenSearchKeywordCheckedUsersSet = () => {
        selectedUsersArray = [];
        setSelectedItemsNumber(0);
        setIsSelectedItems(false);
        var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')
        for (var i = 0; i < checkboxes.length; i++) {
            console.log(checkboxes[i]);
            checkboxes[i].checked = false;
        }
    }
    
    const deleteSelectedPersons = () => {
       
        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: selectedUsersArray
        });

        axios.post('/User/DeleteSelectedUsers', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);
            if (response.data.result.data) {
                selectedUsersArray = [];
                setSelectedItemsNumber(0);
                setIsSelectedItems(false);

                response.data.result.data.map((item, index) => {
                    //let element = document.getElementById("tablerow-" + item.id);
                    //element.remove();

                    setDeleteModalAsk(false);

                    const userIndex = userData.findIndex(e => e.id === item.id);

                    userData.splice(userIndex, 1);

                    setUserData([...userData]);

                });

                setSelectedUsersModal(false);

                setMessageModalHeader("Success");
                setMessageModalDesc("Selected users deleted")
                setMessageModalOpen(true);

                var checkboxes = document.querySelectorAll('input[type=checkbox]');

                for (let i = 1; i < checkboxes.length; i++) {
                    checkboxes[i].checked = false;
                }

            }
        });
    }

    const showSelectedItems = () => {
        setSelectedUsersModal(true);

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: selectedUsersArray
        });

        axios.post('/User/DeleteSelectedUsers', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            console.log(response);

            

            if (response.data.result.data) {

               
                selectedUsersArray = [];
                setSelectedItemsNumber(0);
                setIsSelectedItems(false);

                response.data.result.data.map((item, index) => {
                    //let element = document.getElementById("tablerow-" + item.id);
                    //element.remove();

                    setDeleteModalAsk(false);

                    const userIndex = userData.findIndex(e => e.id === item.id);

                    userData.splice(userIndex, 1);

                    setUserData([...userData]);

                });

                setSelectedUsersModal(false);

                setMessageModalHeader("Success");
                setMessageModalDesc("Selected users deleted")
                setMessageModalOpen(true);

                var checkboxes = document.querySelectorAll('input[type=checkbox]');

                for (let i = 1; i < checkboxes.length; i++) {
                    checkboxes[i].checked = false;
                }

            }
        });
      
    }

    const excelImportOpenPanel = () => {
        
        
        document.getElementById("open-panel-from-right").style.right = "0%";
        document.getElementById("panel-dark-back").style.visibility = "visible";
        window.scrollTo(0, 0);
        document.body.style.overflowY = 'hidden';
        setPanelDarkBack(true);
    }


    const closePanel = () => {
        
        document.getElementById("open-panel-from-right").style.right = "-40%";
        document.getElementById("panel-dark-back").style.visibility = "hidden";
        window.scrollTo(0, 0);
        document.body.style.overflowY = "visible";
  
    }

    const closeMessageEvent = () => {
        setMessageModalOpen(false);

        var checkboxes = document.querySelectorAll('input[type=checkbox]').length;

        if (checkboxes == 1)
            window.location.href = '/users';
    }

    async function removeCookies(userData, user) {
        clearCookies();
        await reSetCookies(userData, user);
        redirectPage();
    }

    async function redirectPage() {
        userData.userType == 1 ? window.location.href = "/users" :
            window.location.href = "/user-dashboard";
    }

    async function loginUserAccount (userId) {
        setIsActiveDimmer(true);

        let user = {
            id: userId
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: user
        });

        axios.post('/User/LoginUserAccount', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            console.log(response);

            if (response.data.result.resultCode == 0) {

                let user = response.data.result.data;

                let userData = {
                    id: user.userId,
                    name: user.name,
                    surname: user.surname,
                    email: user.email,
                    companyId: user.companyId,
                    token: user.token,
                    userName: user.userName,
                    userType: user.userType
                };

                
                removeCookies(userData, user);
                
            }


        });
    }

    return (
        <div>
            <Dimmer active={isActiveDimmer} page>
                <Header as='h2' icon inverted>
                    <Loader size="huge" active inline='centered' />
                    Logging in...
                    <Header.Subheader>Please wait</Header.Subheader>
                </Header>
            </Dimmer>
           
                <div id="panel-dark-back"></div>
            
           

            <div id="open-panel-from-right">
                <div className="panel-header">
                    <div className="panel-close-section">
                        <Button onClick={closePanel} color="red" icon='right arrow' />
                    </div>
                    <div className="header-section">
                        <h2>Excel Import </h2>
                    </div>
                </div>

                <div className="panel-content">
                    <div className="drop-zone-panel">
                        <DropZone ClosePanel={closePanel} GetUsers={getUsers} />
                    </div>
                </div>

            </div>


            

            <Transition visible={deleteModalAsk} animation='scale' duration={500}>
                <Modal className="users-delete-ask-modal" size={"mini"} open={deleteModalAsk} onClose={() => { setDeleteModalAsk(false) }}>
                    <Modal.Header><span className={"account-text"}>Warning</span></Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Header>{deletedUser} delete person?</Header>
                        </Modal.Description>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={deleteUserProcess} className={"digitallica-button"} primary>
                            YES <Icon name='chevron right' />
                        </Button>

                        <Button onClick={() => { setDeleteModalAsk(false) }} className={"digitallica-button"} primary>
                            NO <Icon name='chevron right' />
                        </Button>
                    </Modal.Actions>
                </Modal>
            </Transition>

            <Transition visible={selectedUsersModal} animation='scale' duration={500}>
               
                <Modal

                    className={"message-modal"}
                    open={selectedUsersModal}
                    onClose={() => setSelectedUsersModal(false)}
                    onOpen={() => setSelectedUsersModal(true)}

                >
                    <Modal.Header>Selected Persons</Modal.Header>
                    <Modal.Content image scrolling>


                        <Modal.Description>
                            <div>
                                <div className="selected-users-modal-table-header">
                                    <div className="selected-users-modal-table-header-th">Name</div>
                                    <div className="selected-users-modal-table-header-th">Surname</div>
                                    <div className="selected-users-modal-table-header-th">Email</div>
                                </div>
                               
                                {selectedUsersArray.map((item, index) =>
                                    <div key={item.id} className="selected-users-modal-content-desc">
                                        <div className="selected-users-modal-text">{item.name}</div>
                                        <div className="selected-users-modal-text">{item.surname}</div>
                                        <div className="selected-users-modal-text">{item.email}</div>
                                    </div>
                                )}

                            </div>

                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={deleteSelectedPersons} color="green"  >
                            DELETE ALL <Icon onClick={deleteSelectedPersons} name='trash' />
                        </Button>

                        <Button onClick={() => { setSelectedUsersModal(false) }} color="red"  >
                            CANCEL <Icon name='cancel' />
                        </Button>
                    </Modal.Actions>
                </Modal>









            </Transition>



            <Transition visible={messageModalOpen} animation='scale' duration={500}>
                <Modal className="users-delete-ask-modal" size={"mini"} open={messageModalOpen} onClose={() => { setMessageModalOpen(false) }}>
                    <Modal.Header><span className={"account-text"}>{messageModalHeader}</span></Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Header>{messageModalDesc}</Header>
                        </Modal.Description>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={ closeMessageEvent} color='green'>
                            OKEY <Icon name='chevron right' />
                        </Button>

                    </Modal.Actions>
                </Modal>
            </Transition>


            <DashboardNavBar />












            <div className="dashboard-container-content">

                <div className="dashboard-content-box">

                    <div className="simulations-header">
                        <div className="page-header-text">
                            <Header as='h1' disabled>
                                <Link to="/dashboard"><Button className="simulation-back-button" size="massive" icon>
                                    <Icon name='left chevron' />
                                </Button></Link>
                                Users
                             </Header>
                        </div>

                        <div className="simulations-search-field">
                            <Input

                                id="digitallica-search-input"
                                size="big"
                                placeholder="Search.."
                                value={filterInputText}
                                onChange={o => { setFilterInputText(o.target.value); filterText = o.target.value; /*filterUserOnChange();*/ }}
                            />
                        </div>
                        <div className="add-new-simulation-box">
                            <div className="digitallica-batch-processing-field">
                                <Link to="/user-add"><Button  size="big" icon={ <Icon name="plus" /> }></Button></Link>
                                <Label className="selectedUsersItemsLabel" onClick={excelImportOpenPanel} size="big" color="green">
                                    <Icon className="selectedUsersItemsLabel" onClick={excelImportOpenPanel} name='file excel outline' />  Excel Import
                                    </Label>

                                <Transition visible={isSelectedItems} animation='scale' duration={500}>
                                    <Label className="selectedUsersItemsLabel" onClick={showSelectedItems} size="big" color="red">
                                        <Icon className="selectedUsersItemsLabel" onClick={showSelectedItems} name='box' />  {selectedItemsNumber}
                                    </Label>
                                </Transition>
                            </div>
                        </div>
                    </div>
                    
                   

                    <div className="admin-dashboard-users-list-box">

                        <div className="digitallica-header-field">
                            <div>
                                <div className="digitallica-table-checkbox-field">

                                    <input onChange={selectAllCheckbox} type="checkbox" id={"select-all-users"} />
                                </div>
                                <div className="digitallica-table-header">
                                    {tableHeader.map((item, index) =>
                                        <div className="digitallica-table-theader-items" Key={index}>{item}</div>
                                    )}
                                </div>

                                <Transition visible={showNoUsersFound} animation='scale' duration={500}>
                                    <div className="no-users-found-box">
                                        <div>
                                            <h1>No User Found</h1>
                                        </div>
                                        <div>
                                            <img width="20%" src={NotFoundSVG} />
                                        </div>
                                    </div>
                                </Transition>



                            </div>
                        </div>


                        <div className="digitallica-pagination-field">
                            <div>
                                <div>
                                    <Loader active={loaderActive} size='huge'>Loading...</Loader>
                                    {userData.map((item, index) =>

                                        <div id={"tablerow-" + item.id}>
                                            <div className="digitallica-table-row-box">
                                                <div className="digitallica-table-checkbox-field">
                                                    <div><input type="checkbox" data-id={"row-" + item.id} data-username={item.name} data-usersurname={item.surname} data-usermail={item.email} id={item.id} onChange={handleCheckBox} /></div>
                                                </div>
                                                <div className="digitallica-table-row">


                                                    <div className="digitallica-row">{item.name}</div>
                                                    <div className="digitallica-row">{item.surname}</div>
                                                    <div className="digitallica-row">
                                                        <Label size="big" color='green' horizontal>
                                                            Active
                                                            </Label>
                                                    </div>
                                                    <div className="digitallica-row">
                                                        <Label size="big" color={userType[parseInt(item.userType)]} horizontal>
                                                            {item.userType === 1 ? "Admin" : "User"}
                                                        </Label>
                                                    </div>
                                                    <div className="digitallica-row">{dateFormat(item.createdDate)}</div>
                                                    <div className="digitallica-row">{item.lastLogin != null ? dateFormat(item.lastLogin) : <span className='nullData'>Last Login</span>}</div>




                                                    <div className="digitallica-row">

                                                        <Link to={'user-profile/' + item.id}>
                                                            <Button id={item.id} color="orange" icon>
                                                                <Icon name='eye' />
                                                            </Button>
                                                        </Link>

                                                        
                                                            <Popup
                                                                content='Login user account'
                                                                trigger={
                                                                    <Button
                                                                        onClick={()=> loginUserAccount(item.id)}
                                                                        id={item.id}
                                                                        color="green"
                                                                        icon
                                                                    >
                                                                        <Icon
                                                                            name='sign-in'
                                                                        />
                                                                    </Button>
                                                                }
                                                            />
                                                            
                                                        

                                                        <Link to={'user-edit/' + item.id}>
                                                            <Button id={item.id} color="yellow" icon>
                                                                <Icon name='pencil' />
                                                            </Button>
                                                        </Link>

                                                        <Button id={item.id} color="violet" icon>
                                                            <Icon name='area graph' />
                                                        </Button>

                                                        <Button data-name={item.name + " " + item.surname} onClick={deleteUser} id={item.id} color="red" icon>
                                                            <Icon id={item.id} data-name={item.name + " " + item.surname} onClick={deleteUser} name='trash' />
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>






                    </div>

                    <div className="simulation-pagination-box">
                        <div>
                            {paginationHtml}
                        </div>
                    </div>
                </div>
            </div>
 
        </div>


    );

}
