import React, { useState, useContext, useEffect } from 'react';
import { Tab } from 'semantic-ui-react'
import { Header } from 'semantic-ui-react'
import './company.css';
import { loginContext } from '../../../../Store';
import { Navbar } from '../Navbar/Navbar';
import { CompanyAdd } from './Add/CompanyAdd';
import { CompanyList } from './List/CompanyList';


export const Company = (props) => {

    const [isLoginControl, setLoginControl] = useContext(loginContext);

    const panes = [
        {
            menuItem: 'List',
            render: () => <Tab.Pane attached={false}><CompanyList/></Tab.Pane>,
        },
        {
            menuItem: 'Add',
            render: () => <Tab.Pane attached={false}><CompanyAdd/></Tab.Pane>,
        }
    ]

    return (
        <div>
            { isLoginControl ?
                <div>
                    <div className="super-admin-dashboard-container">
                        <div className="super-admin-dashboard-nav">
                            <Navbar ActiveMenuItem={"company"} />
                        </div>
                        <div className="super-admin-dashboard-content">
                            <Header size='huge'>Company</Header>

                            <div className="super-admin-content-box">
                                <Tab className="super-admin-tab-menu" menu={{ secondary: true }} panes={panes} />
                            </div>
                        </div>

                    </div>
                </div>
                : ""}
        </div>
    );
}