import React, { useState, useContext, useEffect, useRef } from 'react';
import './circleaudioplayer.css';
import axios from 'axios';
import { Button, Divider, Form, Checkbox, Dropdown, Modal, Transition, Icon, Header, Tab, TextArea, Popup } from 'semantic-ui-react';
import ReactPlayer from 'react-player';
import CircleControls from 'react-player-circle-controls';
import 'react-player-circle-controls/dist/styles.css';



export const CircleAudioPlayer = (props) => {

    const player = useRef(null);
    const [playing, setPlaying] = useState(false);
    const [playerState, setPlayerState] = useState({
        played: 0,
        loaded: 0
    });

    const onSeek = amount => {
        if (player.current) {
            player.current.seekTo(amount, 'fraction');
        }
    };

    return (
        <>
           
              
            <ReactPlayer
                ref={player}
                url={props.AudioLink}
                playing={playing}
                height="0"
                width="0"
                onProgress={setPlayerState}
                onEnded={() => setPlaying(false)}
            />

            <CircleControls
                played={playerState.played}
                loaded={playerState.loaded}
                playing={playing}
                onSeek={onSeek}
                onTogglePlaying={() => setPlaying(!playing)}
                color="#cbcbcc"
                progressSize={5}
                size={40}
            />
        </>
    );
}