import React, { useContext, useEffect, useState, useRef } from 'react';
import { Image, Reveal, Button, Header, Loader } from 'semantic-ui-react';
import './dashboardgeneral.css';
import Cookies from 'universal-cookie';
import axios from 'axios';

const cookies = new Cookies();

export const DashboardGeneral = (props) => {

    useEffect(() => {
        getCompanyData();
    }, []);

    const fileInputRef = useRef();

    const fileInputClicked = () => {
        fileInputRef.current.click();
    }

    const [companyLogo, setCompanyLogo] = useState("");
    const [startUpload, setStartUpload] = useState(false);

    const filesSelected = () => {
        if (fileInputRef.current.files.length) {
            uploadFiles(fileInputRef.current.files[0]);
        }
    }

    const uploadFiles = (file) => {


       
        if (file.size > 0) {
            setStartUpload(true);
            const formData = new FormData();

            formData.append('Data', file);
            formData.append('AuthToken', cookies.get('Token'));
            formData.append('CompanyId', cookies.get('CompanyId'));
           

            axios({
                method: 'POST',
                url: "/Company/UploadLogo",
                data: formData,
                processData: false,
                contentType: false
            }).then((response) => {
                console.log(response);
                if (response.data.result.result.resultCode == 0) {
                    setCompanyLogo(response.data.result.result.data.logo);
                    setStartUpload(false);
                }
            });



        } 


    }

    const getCompanyData = () => {

        var company = {
            Id: cookies.get('CompanyId')
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: company
        });

        axios.post('/Company/GetById', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            if (response.data.resultCode == 0) {
                var companyData = response.data.data;
                setCompanyLogo(companyData.logo == null ? "https://www.dropbox.com/s/ev83fn0g1adgd1x/Digitallica_Logo_h.png?raw=1" : companyData.logo);
            }
        });
    }

    return (
        <div>
            <Header disabled as='h2'>Logo</Header>
            <div className="admin-dasboard-company-logo-box">
                {startUpload ? <Loader active inline /> : <Image
                    size="medium"
                    src={companyLogo}
                />}        
            </div> 

            <Button
                className="company-logo-upload-button"
                onClick={fileInputClicked}
                size="mini">
                Upload
                
            </Button>  

            <input
                ref={fileInputRef}
                className="file-input"
                type="file"
                onChange={filesSelected}
            />
           
    
        </div>
        )
}