import React, { useContext, useEffect, useState } from 'react';
import { QuestionOption } from '../../../UserProfile/UserOtherSimulations/OtherSimulationQuestion/QuestionOption/QuestionOption';
import { Button, Transition, Popup, Modal, Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const ReportQuestionCard = (props) => {

    const [question] = useState(props.QuestionData);
    const [questionJSON] = useState(JSON.parse(props.QuestionData.questionJSON));
    const [options, setOptions] = useState([]);
    const [isShow, setIsShow] = useState(props.ReportDetail || false);
    const [isSelected, setIsSelected] = useState(props.Selected || false);
    const [questionId] = useState(props.QuestionId);


    useEffect(() => {

        var newQuestionOptionArray = [];

        [questionJSON].forEach(e => {
            ["A", "B", "C", "D", "E"].forEach(k => {

                let newQuestionObject = {
                    Id: k,
                    Text: e[k],
                    IsCorrect: e.CorrectOption == k ? true : ((e.CorrectOption != k) && (k != props.UserAnswer)) ? null : false,
                    CorrectOption: e.CorrectOption,
                }

                if (newQuestionObject.Text != "")
                    newQuestionOptionArray.push(newQuestionObject)
            });
        });

        setOptions(newQuestionOptionArray);

        setIsSelected(props.ControllSelectedQuestion(questionId) && true);

    }, []);

    const reportQuestionCardStyle = {
        padding: "20px",
        width: props.Width || "30%",
        boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column"
    }

    const questionHeader = {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    }

    const optionStyle = {
        width: "100%",
        maxHeight: isShow ? "395px" : "1px",
        transition: "all 0.6s ease",
    }

    const buttonBox = {
        transition: "all 0.6s ease",
        transform: isShow ? "rotate(180deg)" : "rotate(0deg)"
    }

    const showOption = () => {
        setIsShow(!isShow);
    }

    const selectQuestion = (questionId,question) => {

        setIsSelected(!isSelected);

        if (!isSelected) {

            props.SelectQuestion(questionId, question);

        }
        else {
            props.RemoveQuestion(questionId);
        }
            
    }

    const removeQuestion = (questionId) => {
        props.RemoveQuestion(questionId);
    }

    const showReport = (question) => {
        props.OpenQuestionReport(question);
    }

    return (

            

        <div style={reportQuestionCardStyle}>



                <div style={questionHeader}>
                    <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
                        {!props.ReportDetail &&
                            <div>
                                <Popup
                                    trigger={
                                        <Button
                                            basic={!isSelected}
                                            icon={props.Selected ? "trash" : isSelected ? "check" : "plus"}
                                            color={props.Selected ? "red" : isSelected ? "green" : ""}
                                            size="tiny"
                                            onClick={props.Selected ? () => { removeQuestion(questionId) } : () => { selectQuestion(questionId, question) }}
                                        />}
                                    content={props.Selected ? "Delete" : isSelected ? "Selected" : "Select for multi process"}
                                    basic
                                />
                            </div>
                        }
                    
                        <div>
                            {questionJSON.Body}

                        </div>
                    </div>


                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Transition visible={isShow && !props.ReportDetail} animation='scale' duration={600}>
                            <div>
                                <Popup
                                    trigger={
                                        <Button
                                            icon='folder open'
                                            onClick={() => { showReport(question); }}
                                        />
                                    }
                                    content="Report"
                                    basic
                                />
                            </div>
                        </Transition>

                        <div style={buttonBox}>
                            <Button
                                size="small"
                                icon='long arrow alternate down'
                                onClick={showOption}
                                color={isShow ? "black" : ""}
                            />
                        </div>
                    </div>

                </div>


                <Transition visible={isShow} animation='scale' duration={650}>
                    <div>
                        <div style={optionStyle}>
                            {options.map((item, index) =>
                                <QuestionOption
                                    Key={index}
                                    Text={item.Text}
                                    Option={item.Id}
                                    IsCorrect={item.IsCorrect}
                                    UserAnswer={props.UserAnswer}
                                    CorrectOption={item.CorrectOption}
                                    Color={item.IsCorrect ? "#00800054" : item.IsCorrect == null ? "white" : "#ff000054"}
                                />
                            )}
                        </div>


                    </div>
                </Transition>


             </div>
        
    )
}