import React, { useEffect, useContext, useState } from 'react';
import * as PIXI from 'pixi.js'
import TweenMax from 'gsap';
import { Howl } from 'howler';
import Broadcast from '../../Broadcast';




export const Main = (props) => {
    const app = new PIXI.Application();

    let baseUrl = window.location.origin;

    let currentSimulationIndex = -1;
    let dialogCount = 0;
    let dialogCountIndex = 0;

    let backgroundUrl;
    let foregroundUrl;
    let sounds = {};
    const sprites = {};
    let dialogs = {};

    var gameTimer;

    let progressBarStepWidth = 0;
    let progressBarCurrentStep = 0;
    let progressBarOverlayStep = 0;
    let progressBarOverlayTotal = 0;
    let progressBarMask;

    const popupStyle = new PIXI.TextStyle();
    popupStyle.fontSize = 16;
    popupStyle.wordWrap = true;
    popupStyle.wordWrapWidth = 250;

    const overlayStyle = new PIXI.TextStyle();
    overlayStyle.fontSize = 25;
    overlayStyle.fill = "#8b8b8b";
    overlayStyle.fontWeight = "800";
    overlayStyle.fontFamily = "Gelion-Bold"

    let loaderText;

    var charLeftIdleFrames = [];
    var charRightIdleFrames = [];
    var charLeftTalkFrames = [];
    var charRightTalkFrames = [];

    var characterLeftID;
    var characterRightID;
    var characters;
    let isIntervalPause = false;
    let isGeneralIntervalPause = false;

    let WomanChar;
    let ManChar;


    let progressbarOverlayText;

    function initData(props) {
        backgroundUrl = props.SimulationData.background;
        foregroundUrl = props.SimulationData.foreground;
        console.log(foregroundUrl);
        sounds["ThemeMusic"] = new Howl({
            src: baseUrl + "/Assets/BackgroundMusics/" + props.SimulationData.backgroundMusic,
            volume: 0.10,
            loop: true
        }, this);
        sounds["ThemeMusic"].play();
        characterLeftID = props.SimulationData.characters[0].character;
        characterRightID = props.SimulationData.characters[1].character;

        initSounds(props.SimulationData.sounds);
        initDialogs(props.SimulationData.dialogs);
        dialogCount = props.SimulationData.dialogs.length;
        currentSimulationIndex = props.SimulationData.currentIndex;
        initEvents();
        console.log("init Events");
    }

    function initSounds(datasounds) {
        for (let i = 0; i < datasounds.length; i++) {
            sounds[datasounds[i].sound] = new Howl({
                src: datasounds[i].url
            }, this);

        }
        console.log(sounds);
    }
    function soundsStop() {
        for (var sound in sounds) {
            sounds[sound].stop();
        }
    }
    function initDialogs(datadialogs) {
        dialogs = datadialogs;
        console.log(dialogs);
    }

    function popupAnimation(popup, speed, text, count) {
        TweenMax.to(popup.scale, speed, {
            x: popup.name == "LeftPopup" ? -1.1 : 1.1, y: 1.1,
            onStart: () => {
                if (dialogs[count].sound != null) {

                    sounds[dialogs[count].sound].play();
                }
            }, onComplete: () => {
                createPopupText(popup, text, popup.name);
            }
        });

        setTimeout(() => {
            TweenMax.to(popup.scale, speed, {
                x: 0, y: 0, onComplete: () => {
                    if (!isGeneralIntervalPause) {
                        isIntervalPause = false;
                    }
                }
            });
            if (popup.name == "LeftPopup") {
                playAnimation("Idle", ManChar, "Left");
            } else {
                playAnimation("Idle", WomanChar, "Right");
            }
            if (popup.children.length > 0) {
                popup.getChildAt(0).destroy();
            }
        }, dialogs[count].time * 1000);
    }

    function createPopupText(popup, text, name) {
        let popupText = new PIXI.Text(text, popupStyle);
        popupText.style.fontSize = 16;
        while (popupText.height > 72) {
            popupText.style.fontSize = popupText.style.fontSize - 1;
        }
        //if (popupText.width > 300) {
        //    popupText.style.fontSize = 12;
        //} else {
        //    popupText.style.fontSize = 16;
        //}
        popupText.anchor.set(0.5);
        popupText.position.y = 0;
        popupText.resolution = 2.1;
        if (name == "LeftPopup") {
            popupText.scale.x = -1;
        }
        popup.addChild(popupText);


    }
    function playAnimation(animationName, AnimatedSprite, charSide) {
        switch (animationName) {
            case "Talk":
                if (charSide == "Left") {
                    AnimatedSprite.stop();
                    AnimatedSprite.textures = charLeftTalkFrames;
                    AnimatedSprite.gotoAndPlay(0);

                } else {
                    AnimatedSprite.stop();
                    AnimatedSprite.textures = charRightTalkFrames;
                    AnimatedSprite.gotoAndPlay(0);
                }
                break;
            case "Idle":
                if (charSide == "Left") {
                    AnimatedSprite.stop();
                    AnimatedSprite.textures = charLeftIdleFrames;
                    AnimatedSprite.gotoAndPlay(0);
                } else {
                    AnimatedSprite.stop();
                    AnimatedSprite.textures = charRightIdleFrames;
                    AnimatedSprite.gotoAndPlay(0);
                }
                break;
            default:
        }
    }

    function create() {
        const background = new PIXI.Sprite(sprites.background);
        background.x = app.renderer.width / 2;
        background.y = app.renderer.height / 2;
        background.name = "Background";
        app.stage.addChild(background);
        background.anchor.x = 0.5;
        background.anchor.y = 0.5;
        while (background.width < app.renderer.width) {
            background.scale.x += 0.1;
        }


        ManChar = new PIXI.AnimatedSprite(charLeftIdleFrames);
        ManChar.x = app.renderer.width / 2 - 200;
        ManChar.y = app.renderer.height / 2 + 50;
        ManChar.scale.set(-0.85, 0.85);
        ManChar.anchor.set(0.5);
        ManChar.animationSpeed = 30 / 60;
        ManChar.gotoAndPlay(0);
        app.stage.addChild(ManChar);

        WomanChar = new PIXI.AnimatedSprite(charRightIdleFrames);
        WomanChar.x = app.renderer.width / 2 + 200;
        WomanChar.y = app.renderer.height / 2 + 50;
        WomanChar.scale.set(0.85, 0.85);
        WomanChar.anchor.set(0.5);
        WomanChar.animationSpeed = 30 / 60;
        WomanChar.gotoAndPlay(0);
        app.stage.addChild(WomanChar);

        const forebackground = new PIXI.Sprite(sprites.foreground);
        forebackground.x = app.renderer.width / 2;
        forebackground.y = app.renderer.height / 2;
        forebackground.name = "Forebackground";
        app.stage.addChild(forebackground);
        forebackground.anchor.x = 0.5;
        forebackground.anchor.y = 0.5;

        const progressBarBG = new PIXI.Sprite(sprites.progressbarbg);
        progressBarBG.x = app.renderer.width / 2;
        progressBarBG.y = app.renderer.height / 2 - 265;
        progressBarBG.name = "progressBarBG";
        app.stage.addChild(progressBarBG);
        progressBarBG.anchor.x = 0.5;
        progressBarBG.anchor.y = 0.5;

        const progressBar = new PIXI.Sprite(sprites.progressbar);
        progressBar.x = app.renderer.width / 2;
        progressBar.y = app.renderer.height / 2 - 265;
        progressBar.name = "progressBar";
        progressBar.anchor.x = 0.5;
        progressBar.anchor.y = 0.5;
        app.stage.addChild(progressBar);

        progressbarOverlayText = new PIXI.Text("%0", overlayStyle);
        progressbarOverlayText.resolution = 2;
        progressbarOverlayText.position.x = 120;
        progressbarOverlayText.anchor.set(0.5, 0.5);
        progressBarBG.addChild(progressbarOverlayText);

        progressBarMask = new PIXI.Sprite(sprites.progressbar);
        progressBarMask.x = app.renderer.width / 2 - progressBarMask.width;
        progressBarMask.y = app.renderer.height / 2 - 266;
        progressBarMask.name = "progressBarMask";
        app.stage.addChild(progressBarMask);
        progressBarMask.anchor.x = 0.5;
        progressBarMask.anchor.y = 0.5;
        progressBar.mask = progressBarMask;

        progressBarStepWidth = progressBarMask.width / dialogCount;
        progressBarOverlayStep = 100 / dialogCount;


        const leftPopup = new PIXI.Sprite(sprites.talkpopup);
        leftPopup.x = app.renderer.width / 2;
        leftPopup.y = app.renderer.height / 2 - 190;
        leftPopup.scale.set(0, 0);
        leftPopup.anchor.set(0.5);
        app.stage.addChild(leftPopup);
        leftPopup.name = "LeftPopup";

        const rightPopup = new PIXI.Sprite(sprites.talkpopup);
        rightPopup.x = app.renderer.width / 2;
        rightPopup.y = app.renderer.height / 2 - 190;
        rightPopup.scale.set(0, 0);
        rightPopup.anchor.set(0.5);
        app.stage.addChild(rightPopup);
        rightPopup.name = "RightPopup";

        dialogCountIndex = 0;

        if (currentSimulationIndex != -1) {
            Broadcast.emit('DIALOG_CONTINUE');
            console.log("DIALOG_CONTINUE=");

            isIntervalPause = true;
        }

        gameTimer = setInterval(() => {
            console.log("interval start");
            if (isIntervalPause) {
                return;
            }
            console.log("interval on");
            if (dialogCountIndex >= dialogs.length) {
                playAnimation("Idle", ManChar, "Left");
                playAnimation("Idle", WomanChar, "Right");
                Broadcast.emit("SIMULATION_FINISH");
                clearInterval(gameTimer);
                return;
            }
            if (dialogs[dialogCountIndex].question.isQuestion == true) {
                Broadcast.emit('ON_QUESTION_OPEN', dialogs[dialogCountIndex].question.questionId);
                console.log("ON_QUESTION_OPEN");
                isIntervalPause = true;
                playAnimation("Idle", ManChar, "Left");
                playAnimation("Idle", WomanChar, "Right");
                dialogCountIndex++;
                SimulationProgressBarAdd(progressBarMask, 1);
                currentSimulationIndex = dialogCountIndex;
                Broadcast.emit('DIALOG_PROGRESS', {
                    currentSimulationIndex: currentSimulationIndex,
                    dialogCount: dialogCount
                });
                return;
            }

            if (dialogs[dialogCountIndex].character == characterRightID) {
                playAnimation("Talk", ManChar, "Left");
                //playAnimation("Idle", WomanChar, "Right");

            } else {
                playAnimation("Talk", WomanChar, "Right");
                //    playAnimation("Idle", ManChar, "Left");
            }
            popupAnimation(dialogs[dialogCountIndex].character == characterRightID ? leftPopup : rightPopup, 0.25, dialogs[dialogCountIndex].text, dialogCountIndex);
            isIntervalPause = true;
            dialogCountIndex++;
            SimulationProgressBarAdd(progressBarMask, 1);
            currentSimulationIndex = dialogCountIndex;
            Broadcast.emit('DIALOG_PROGRESS', {
                currentSimulationIndex: currentSimulationIndex,
                dialogCount: dialogCount
            });
        }, 1000);

    }

    function SimulationProgressBarAdd(progressbarmask, index) {
        TweenMax.to(progressbarmask, 0.5, { x: progressbarmask.position.x + (progressBarStepWidth * index) });
        progressBarOverlayTotal += Math.ceil(progressBarOverlayStep) * index;
        if (progressBarOverlayTotal > 100)
            progressBarOverlayTotal = 100;
        progressbarOverlayText.text = "%" + progressBarOverlayTotal;
    }

    function SimulationProgressBarSet(index) {
        SimulationProgressBarAdd(progressBarMask, index);
    }

    function initEvents() {
        Broadcast.on("ON_QUESTION_CLOSE", () => {
            isIntervalPause = false;
        });
        Broadcast.on("DIALOG_CONTINUE_RESPONSE", (data) => {
            currentSimulationIndex = data.currentIndex;
            dialogCountIndex = data.currentIndex == -1 ? 0 : data.currentIndex - 1;
            isIntervalPause = false;
            setTimeout(() => { SimulationProgressBarSet(dialogCountIndex - 1); }, 1000)
        });
        Broadcast.on('PAGE_CHANGED', (data) => {
            clearInterval(gameTimer);
            soundsStop();
        });
        Broadcast.on('SIMULATION_VOLUME', (data) => {
            sounds['ThemeMusic'].volume(data);
        });
        Broadcast.on('SIMULATION_PLAY', (data) => {
            console.log(data);
            isGeneralIntervalPause = data;
            if (!data) {
                isIntervalPause = data;
            }
        });
    }

    function Dispose() {
        Broadcast.off("ON_QUESTION_CLOSE");
        Broadcast.off("DIALOG_CONTINUE_RESPONSE", (data) => { });
        Broadcast.off('PAGE_CHANGED', (data) => { });
    }

    useEffect(() => {
        //window.addEventListener('beforeunload', function (e) {
        //    e.preventDefault();
        //    e.returnValue = '';
        //    console.log(currentSimulationIndex);
        //    console.log(dialogCount);
        //    Broadcast.emit('DIALOG_PROGRESS', {
        //        currentSimulationIndex: currentSimulationIndex,
        //        dialogCount: dialogCount
        //    });
        //});


        loaderText = new PIXI.Text("Loading...", overlayStyle);
        loaderText.x = app.renderer.width / 2;
        loaderText.y = app.renderer.height / 2;
        loaderText.name = "popupText";
        loaderText.anchor.x = 0.5;
        loaderText.anchor.y = 0.5;

        app.stage.addChild(loaderText);

        const loader = new PIXI.Loader(); // PixiJS exposes a premade instance for you to use.   
        console.clear();
        console.log(props);
        initData(props);
        let character0 = props.SimulationData.characters[0];
        let character1 = props.SimulationData.characters[1];
        loader.add('bunny', 'https://spng.subpng.com/20180224/lxw/kisspng-animation-apng-3d-computer-graphics-business-people-5a90f6c1c98ee5.9095844415194497938256.jpg')
            .add('background', baseUrl + '/Assets/Backgrounds/' + backgroundUrl)
            .add('foreground', baseUrl + '/Assets/ForegroundPhotos/' + foregroundUrl)
            .add('talkpopup', baseUrl + '/Assets/images/talkballoon.png')
            .add('progressbar', baseUrl + '/Assets/images/progressbar.png')
            .add('progressbaroverlay', baseUrl + '/Assets/images/progress_overlay.png')
            .add('progressbarbg', baseUrl + '/Assets/images/progressbarbg.png')
            .add('startbutton', baseUrl + '/Assets/images/start.png')
            .add('stopbutton', baseUrl + '/Assets/images/stop.png')
            .add('soundbuttonon', baseUrl + '/Assets/images/volume-open.png')
            .add('soundbuttonoff', baseUrl + '/Assets/images/volume-close.png');

        console.log("ASDSADSADSADSADSADSADSA", character0.jsonCount);
        for (var i = 0; i <= character0.jsonCount; i++) {
            loader.add('character-' + character0.character + '-' + i, baseUrl + '/Assets/Sprites/characters_' + character0.character + '-' + i + '.json');
        }
        for (var i = 0; i <= character1.jsonCount; i++) {
            loader.add('character-' + character1.character + '-' + i, baseUrl + '/Assets/Sprites/characters_' + character1.character + '-' + i + '.json');
        }


        loader.load((loader, resources) => {
            console.log(resources);
            sprites.bunny = resources.bunny.texture;
            sprites.background = resources.background.texture;
            sprites.talkpopup = resources.talkpopup.texture;
            sprites.progressbar = resources.progressbar.texture;
            sprites.progressbarbg = resources.progressbarbg.texture;
            sprites.progressbaroverlay = resources.progressbaroverlay.texture;
            sprites.foreground = resources.foreground.texture;
            sprites.startbutton = resources.startbutton.texture;
            sprites.stopbutton = resources.stopbutton.texture;
            sprites.soundbuttonon = resources.soundbuttonon.texture;
            sprites.soundbuttonoff = resources.soundbuttonoff.texture;


            resources.progressbar.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST;
            resources.progressbarbg.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST;
            for (var i = 0; i < character0.jsonCount; i++) {
                resources["character-" + character0.character + '-' + i + "_image"].texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST;
            }
            for (var i = 0; i < character1.jsonCount; i++) {
                resources["character-" + character1.character + '-' + i + "_image"].texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST;
            }
            for (var i = 0; i < character0.idleFrameCount; i++) {
                var val = i < 10 ? '0' + i : i;
                charLeftIdleFrames.push(PIXI.Texture.from(character0.character + '/idle/000' + val + '.png'));
            }
            for (var i = 0; i < character0.talkFrameCount; i++) {
                var val = i < 10 ? '0' + i : i;
                charLeftTalkFrames.push(PIXI.Texture.from(character0.character + '/talk/000' + val + '.png'));
            }
            for (var i = 0; i < character1.idleFrameCount; i++) {
                var val = i < 10 ? '0' + i : i;
                charRightIdleFrames.push(PIXI.Texture.from(character1.character + '/idle/000' + val + '.png'));
            }
            for (var i = 0; i < character1.talkFrameCount; i++) {
                var val = i < 10 ? '0' + i : i;
                charRightTalkFrames.push(PIXI.Texture.from(character1.character + '/talk/000' + val + '.png'));
            }

        });

        loader.onProgress.add(() => {
            loaderText.text = "Loading " + loader.progress.toFixed(2) + "%";
        }); // called once per loaded/errored file
        loader.onError.add(() => {
            console.log("onError");
        }); // called once per errored file
        loader.onLoad.add(() => {
        }); // called once per loaded file
        loader.onComplete.add(() => {
            loaderText.visible = false;
            create();
        }); // called once when the queued resources all load.
        app.renderer.resolution = 1;
        document.getElementById("preview-canvas").appendChild(app.view);

    }, []);

    return (
        <div>

        </div>
    );
}