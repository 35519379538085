import React, { useState, useContext, useEffect } from 'react';
import { Header, Icon, Label } from 'semantic-ui-react';

export const QuestionOption = (props) => {

    useEffect(() => {
        //console.log(props);
    }, []);

    const questionBoxStyle = {
        height: "auto",
        width: "100%",
        backgroundColor: props.Color,
        boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
        padding: props.Padding || "20px",
        borderRadius: props.borderRadius || "10px",
        display: "flex",
        flexDirection: "column",
        color: "#9c9c9c",
        marginTop:"13px"
    }

    const optionStyle = {
        display: "flex",
        alignItems: "center"
    }

    return (
        <div style={questionBoxStyle}>
            <div style={optionStyle}>
                <Label style={{ backgroundColor: props.Color != "white" ? props.Color : ""}} size={"medium"}>
                    {props.Option}
                </Label>
                <p style={{ marginLeft: "5px", color:"#000000de"}}>{props.Text}</p>
            </div>
        </div>
    )
}