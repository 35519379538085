import React, { useState, useContext, useEffect } from 'react';
import './navbar.css'
import {
    Menu,
    Image,
    Button,
    Icon,
    Dropdown,
    Transition,
    Message,
    Dimmer,
    Loader,
    Header

} from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { loginContext, fullNameContext } from '../../../../Store';
import axios from 'axios';
import { clearCookies } from '../../../../helper/helper';

const cookies = new Cookies();

export const Navbar = () => {

    const [isLoginControl, setLoginControl] = useContext(loginContext);

    const [userFullName, setUserFullName] = useContext(fullNameContext);
    const [companyLogo, setCompanyLogo] = useState("");
    const [isActiveNotification, setIsActiveNotification] = useState(false);
    const [notificationText, setNotificationText] = useState("Notification text");
    const [messageType, setMessageType] = useState("");

    const [isActiveDimmer, setIsActiveDimmer] = useState(false);

    useEffect(() => {
        getCompanyData();
        getSettingsById();
    }, []);

    const getSettingsById = () => {
        var data = {
            Id: "47d88bff-0fef-4e9c-b75b-cf68191d5dc5",
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: data
        });

        axios.post('/Settings/GetById', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);

            if (response.data.resultCode == 0) {
                let settings = response.data.data;
                if (settings.isActive) {
                    let settingsJsonData = JSON.parse(settings.settingsJSON);

                    setIsActiveNotification(settings.isActive);
                    setNotificationText(settingsJsonData.Text);
                    setMessageType(settingsJsonData.Color);
                }


            }

        });
    }

    const getCompanyData = () => {

        var company = {
            Id: cookies.get('CompanyId')
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: company
        });

        axios.post('/Company/GetById', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            if (response.data.resultCode == 0) {
                var companyData = response.data.data;
                setCompanyLogo(companyData.logo == null ? "https://www.dropbox.com/s/ev83fn0g1adgd1x/Digitallica_Logo_h.png?raw=1" : companyData.logo);
            }
        });
    }

    const logout = async() => {
        await clearCookies();
        setLoginControl(false);
        window.location.href = '/';
    }

    const options = [

        { key: 'profile', text: 'Profile', as: Link, to: '/profile' },
        { key: 'sign-out', text: 'Exit', as: Link, onClick: logout },
    ]

    const DropdownTriggerExample = () => (
        <div>
            <Button.Group color='teal'>
                <Button className={"navbar-login-button-dashboard"}> {userFullName}</Button>
                <Dropdown
                    className='button icon navbar-login-button-dashboard-arrow'
                    floating
                    options={options}
                    trigger={<></>}
                />
            </Button.Group>
        </div>
    )

    async function removeCookies() {
        cookies.remove('Token');
        cookies.remove('Name');
        cookies.remove('Surname');
        cookies.remove('Email');
        cookies.remove('UserId');
        cookies.remove('IsLogin');
        cookies.remove('Password');
        cookies.remove('FullName');
        cookies.remove('Username');
        cookies.remove('UserType');
    }

    async function reSetCookies() {
        cookies.set('Token', cookies.get('adminToken'));
        cookies.set('Name', cookies.get('adminName'));
        cookies.set('Surname', cookies.get('adminSurname'));
        cookies.set('FullName', cookies.get('adminName') + " " + cookies.get('adminSurname'));
        cookies.set('UserId', cookies.get('adminUserId'));
        cookies.set('CompanyId', cookies.get('adminCompanyId'));
        cookies.set('Email', cookies.get('adminEmail'));
        cookies.set('Username', cookies.get('adminUsername'));
        cookies.set('UserType', cookies.get('adminUserType'));
        cookies.set('IsLogin', true);

        cookies.remove('adminToken');
        cookies.remove('adminName');
        cookies.remove('adminSurname');
        cookies.remove('adminEmail');
        cookies.remove('adminUserId');
        cookies.remove('adminUsername');
        cookies.remove('adminUserType');
    }


    async function backToAdminAccount () {
        setIsActiveDimmer(true);
        await removeCookies();
        await reSetCookies();
        redirectPage();
    }

    async function redirectPage() {
        window.location.href = "/users";
    }


    return (
        <div>

            <Dimmer active={isActiveDimmer} page>
                <Header as='h2' icon inverted>
                    <Loader size="huge" active inline='centered' />
                    Logging in...
                    <Header.Subheader>Please wait</Header.Subheader>
                </Header>
            </Dimmer>
            <div className="system-settings-message-box">
                <Transition visible={isActiveNotification} animation='scale' duration={500}>
                    <Message
                        style={{ marginTop: "1%" }}
                        color={messageType}
                        onDismiss={(e) => { setIsActiveNotification(false); }}
                        header={notificationText}
                    />
                </Transition>
            </div>

            <Menu id="main-page-nav-id" className="main-page-nav" secondary>

                <Link to="/user-dashboard" >
                    <Menu.Item name='home'>
                        <Image
                            id='main-page-logo'
                            size="medium"
                            src={companyLogo} />
                    </Menu.Item>
                </Link>



                <Menu.Menu position='right'>
                    {cookies.get('forceLogin') != undefined &&
                        <Menu.Item name='backAdminAccount'>
                            <Button
                                onClick={backToAdminAccount}
                                primary
                                icon
                                labelPosition='left'
                            >
                                Back to admin account
                                <Icon
                                    name='left arrow'
                                />
                            </Button>
                        </Menu.Item>
                    }

                    <Menu.Item name='home'>
                        <DropdownTriggerExample />
                    </Menu.Item>
                </Menu.Menu>

            </Menu>

        </div>
    );

}
