import React, { useContext, useEffect, useState } from 'react';
import {
    Checkbox,
    Input,
    Popup,
    Button,
    Transition,
    Loader,
    Label
} from 'semantic-ui-react';

import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const ReportSimulationFilter = (props) => {

    const [filterSettings, setFilterSettings] = useState(props.FilterData);
    const [isActive, setIsActive] = useState(false);
    const [startingDate, setStartingDate] = useState("");
    const [dueDate, setDueDate] = useState("");
    const [isFiltered, setIsFiltered] = useState(false);

    const filterRowStyle = {
        width: "100%",
        height: "60px",
        padding: "15px",
        borderRadius: "10px",
        boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
        marginTop: "20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"

    }

    const filterRowColumnStyle = {
        width: "100%",
        height: "56px",
        padding: "15px",
        borderRadius: "10px",
        boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
        marginTop: "20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        gap: "1em",
        flexDirection: "column",
        height: "auto"
    }

    const filterRowInfoBoxStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "0.5em"
    }

    const filterContainerFlexStyle = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "530px"

    }

    const handleStatus = (event, data) => {
        setIsActive(data.checked);
    }

    const handleDate = (event, data) => {

        data.name == "start" ? setStartingDate(data.value) : setDueDate(data.value)

        console.log(data);
    }

    const filterUser = () => {
        setIsFiltered(true);

        filterSettings.Simulations.isActive = isActive;
        filterSettings.Simulations.startingDate = startingDate;
        filterSettings.Simulations.dueDate = dueDate;


        let filterData = {
            CompanyId: cookies.get('CompanyId').toString(),
            IsActive: isActive,
            StartingDate: startingDate,
            DueDate: dueDate

        }

        console.log(filterSettings);

        props.GetData(true, filterData,true);
        props.SetFilterData(filterSettings);

         setTimeout(function () {
             setIsFiltered(false);
         }, 500);

    }

    console.log(props);

    return (
        <div style={filterContainerFlexStyle}>
            <div>
                <div style={filterRowStyle}>
                    <div style={filterRowInfoBoxStyle}>
                        <Popup
                            content='active or passive'
                            trigger={
                                <Button size="mini" icon='info' />
                            }
                        />

                        <p>Status</p>
                    </div>

                    <Checkbox
                        slider
                        onChange={handleStatus}
                    />
                </div>

                <div style={filterRowStyle}>
                    <div style={filterRowInfoBoxStyle}>
                        <p>Starting Date</p>
                    </div>

                    <Input
                        type="date"
                        name="start"
                        onChange={handleDate}
                    />

                </div>

                <div style={filterRowStyle}>
                    <div style={filterRowInfoBoxStyle}>
                        <p>End Date</p>
                    </div>

                    <Input
                        type="date"
                        name="due"
                        onChange={handleDate}
                    />

                </div>
            </div>


            <div>
                <Transition visible={!isFiltered} animation='scale' duration={500}>
                <Button
                    size="big"
                    fluid
                    color="black"
                    onClick={filterUser}
                >
                    Filter
                    </Button>
                </Transition>

                <Transition visible={isFiltered} animation='scale' duration={500}>
                    <Label style={{width: "90%", position:"absolute", bottom: "-40px", margin: "0 auto"}} fluid>
                        <Loader active inline='centered' />
                    </Label>

                </Transition>
            </div>


        </div>
    )
}