import React, { useState, useContext, useEffect } from 'react';
import { Header } from 'semantic-ui-react'
import './superadmindashboard.css';
import { loginContext } from '../../../Store';
import { Navbar } from './Navbar/Navbar';


export const SuperAdminDashboard = (props) => {

    const [isLoginControl, setLoginControl] = useContext(loginContext);

    return (
        <div>
            { isLoginControl ?
                <div>
                    <div className="super-admin-dashboard-container">
                        <div className="super-admin-dashboard-nav">
                            <Navbar ActiveMenuItem={"dashboard"}/>
                        </div>
                        <div className="super-admin-dashboard-content">

                            <Header size='huge'>Dashboard</Header>
                        </div>
                    </div>
                </div>
                : ""}
        </div>
    );
}