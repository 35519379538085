import React, { useState, useContext, useEffect } from 'react';
import './canvas.css'
import { DashboardNavBar } from '../DashboardNavBar/DashboardNavBar';
import { Main } from '../../../Canvas/Main';


export const Canvas = (props) => {

    return (
        <div>

            <DashboardNavBar />
            <div className="dashboard-container-content profile-container-content">
                <div>
                    <h1>Canvas</h1>
                    <div className="main-canvas-box">
                        <Main />
                    </div>
                   
                
                </div>
            </div>
        </div>
    );

}
