import React, { useState, useEffect} from "react";
import { Button, Popup } from 'semantic-ui-react';
import './volume-slider.css';
import Broadcast from '../../../../../Broadcast';

export const VolumeSlider = () => {

    //canvas media butttons
    const [isPlayingCanvas, setIsPlayingCanvas] = useState(true);
    const [isFullScreenCanvas, setIsFullScreenCanvas] = useState(false);
    const [volume, setVolume] = useState(1)
    const [muted, setMuted] = useState(false)
    const [volumeIcon, setVolumeIcon] = useState("volume up");
    const [tempVolume, setTempVolume] = useState(0);

    const styles = {
        mediaContainer:{
            width: "100%",
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            textAlign: "center"
        },

        subContainer: {
            width: "86%",
            justifyContent: "space-between",
            display: "flex",
            margin: "0 auto",
            padding: "10px",
        },

        leftSideControllerContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }

    }

    const sendBroadcast = (type, value=null) => {
        switch (type) {
            case "play":
                Broadcast.emit('SIMULATION_PLAY', isPlayingCanvas);
                break;

            case "volume":
                console.log(value);
                Broadcast.emit('SIMULATION_VOLUME', value);
                break;

            case "replay":
                Broadcast.emit('SIMULATION_REPLAY', true);
                break;

            default:
        }
    }

    const isPlaying = () => {
        setIsPlayingCanvas(!isPlayingCanvas);
        sendBroadcast("play");
    }

    const isMuted = () => {
        setMuted(m => !m);
        setVolume(muted ? tempVolume : 0);
        setVolumeIcon(muted ? "volume up" : "volume off");

        sendBroadcast("volume", muted ? tempVolume : 0.0);
    }

    const volumeSliderHandle = (event) => {
        let volume = event.target.valueAsNumber;
        setVolume(volume);
        setTempVolume(volume);
        setVolumeIcon(volume == 0 ? "volume off" :
            volume >= 0.01 && volume <= 0.5 ?
                "volume down" : "volume up");

        sendBroadcast("volume", volume);
    }

    const replay = () => {
        sendBroadcast("replay");
    }

    const fullScreen = () => {

    }

    return (

        <div style={styles.mediaContainer}>
            <div style={styles.subContainer}>
                <div style={styles.leftSideControllerContainer}>

                    <Popup
                        content={isPlayingCanvas ? "pause" : "play"}
                        trigger={
                            <Button
                                size="big"
                                onClick={isPlaying}
                                icon={isPlayingCanvas ? "pause" : "play"}
                            />
                        }
                    />

                    <Popup
                        content='volume'
                        trigger={
                            <Button
                                size="big"
                                onClick={isMuted}
                                icon={volumeIcon}
                            />
                        } />

                    <div>

                        <input
                            style={{ margin: "5px" }}
                            type="range"
                            min={0}
                            max={1}
                            step={0.01}
                            value={volume}
                            onChange={event => volumeSliderHandle(event)}
                        />

                    </div>
                </div>

                <div style={styles.leftSideControllerContainer}>

                    <Popup content='restart' trigger={
                        <Button
                            onClick={replay}
                            size="big"
                            icon='redo'
                        />}
                    />

                    <Popup content='full screen' trigger={
                        <Button
                            disabled
                            size="big"
                            onClick={() => {
                                setIsFullScreenCanvas(!isFullScreenCanvas);
                            }}
                            icon='expand'
                        />
                    } />
                </div>
            </div>
        </div>
    )
}