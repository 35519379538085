import React, { useState, useContext, useEffect } from 'react';
import { Header } from 'semantic-ui-react'
import './superadminprofile.css';
import { loginContext } from '../../../../Store';
import { Navbar } from '../Navbar/Navbar';


export const SuperAdminProfile = (props) => {

    const [isLoginControl, setLoginControl] = useContext(loginContext);

    return (
        <div>
            { isLoginControl ?
                <div>
                    <div className="super-admin-dashboard-container">
                        <div className="super-admin-dashboard-nav">
                            <Navbar />
                        </div>
                        <div className="super-admin-dashboard-content">

                            <Header size='huge'>Profile</Header>
                        </div>
                    </div>
                </div>
                : ""}
        </div>
    );
}