import React, { useContext, useEffect, useState } from 'react';
import { Popup, Icon, Table, Label, Progress, Header, Loader, Transition } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const UserDegreeTable = (props) => {

    const degreeContainerStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "1.6em",
        height: "400px",
        overflow: "auto",
        padding: "10px"
    }

    const simulationRow = {
        display: "flex",
        padding: "18px",
        justfyContent: "space-between",
        boxShadow: "rgb(149 157 165 / 10%) 0px 8px 24px",
        borderRadius: "10px",
        fontSize: "1.1em",
        alignItems: "center",
        gap: "1em",
        width: "300px"
    }

    const colorDegree = {
        yellow: "1st",
        orange: "2st",
        grey: "3st"
    }
  
    return (
        <div style={degreeContainerStyle}>
            {props.Simulations.map((simulation, index) => (
                <div style={simulationRow}>
                    <Popup content={colorDegree[simulation.color]} trigger={<Icon size="big" color={simulation.color} key={index} name="trophy" />} />
                    
                    {simulation.name}
                </div>
            ))}
        </div>
    )
}