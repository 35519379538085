import React, { useState, useContext, useEffect } from 'react';
import './watch-simulation.css';
import {
    Header,
    Button,
    Icon,
    Transition,
    Modal,
    Label
} from 'semantic-ui-react';
import { loginContext } from '../../../../../Store';
import { Navbar } from '../../Navbar/Navbar';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Main } from "../../../../Canvas/Main";
import { QuestionPanelFinal } from '../../../../DashboardPages/Simulations/Detail/QuestionPanelFinal/QuestionPanelFinal';
import { ContinuePopup } from '../../../../DashboardPages/Simulations/Detail/ContinuePopup/ContinuePopup';
import Cookies from 'universal-cookie';
import Broadcast from '../../../../../../src/Broadcast';
import { VolumeSlider } from '../../../../DashboardPages/Simulations/Detail/VolumeSlider/VolumeSlider';



const cookies = new Cookies();

export const WatchSimulation = (props) => {
    var pixiData = "";
    const [isLoginControl, setLoginControl] = useContext(loginContext);
    const [simulationPrevCanvas, setSimulationPrevCanvas] = useState("");
    const [questionPanelComponent, setQuestionPanelCompanent] = useState("");
    const [continuePopupComponent, setContinueComponent] = useState("");
    const [showCanvas, setShowCanvas] = useState(false);
    const [showFinishScreen, setShowFinishScreen] = useState(false);

    const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);
    const [showCorrectAnswerOption, setShowCorrectAnswerOption] = useState("");
    const [showScore, setShowScore] = useState(false);
    const [simulationFinishReport, setSimulationFinishReport] = useState({
        point: 0,
        isSuccess: false
    });


    const [isLoading, setIsLoading] = useState(true);

    const [simulationSettingsJson, setSimulationSettingsJson] = useState({
        questionTime: {
            point: null,
            isActive: false
        },
        truePoint: {
            point: null,
            isActive: false
        },
        successScore: {
            point: null,
            isActive: false
        },
        showQuestionAgain: {
            isActive: false,
            times: null
        },
        showCorrectAnswer: {
            isActive: false,
            selectedOption: null
        },
        showScore: false,
        notification: {
            newSimulationAssigned: false,
            userCompletedSimulation: false
        }
    });

    const [showIntroVideo, setShowIntroVideo] = useState(false);
    const [showOutroVideo, setShowOutroVideo] = useState(false);

    const [introVideo, setIntroVideo] = useState(null);
    const [outroVideo, setOutroVideo] = useState(null);

    let outroVideoUrl = null;

    useEffect(() => {

        if (!isLoginControl) {
            window.location.href = "/";
        }

        getSimulationDataForPixi();

        Broadcast.on('DIALOG_PROGRESS', (progressObject) => {
            var simulationProgressData = {
                UserId: cookies.get('UserId'),
                SimulationId: props.match.params.id,
                DialogCount: progressObject.dialogCount,
                CurrentSimulationIndex: progressObject.currentSimulationIndex
            }

            console.log(simulationProgressData)

            var Token = JSON.stringify({
                AuthToken: cookies.get('Token'),
                Data: simulationProgressData
            });

            axios.post('/Simulation/SimulationProgress', Token, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then((response) => {
                console.log(response);
                if (response.data.resultCode == 0) {

                } else {

                }
            });
        });

        Broadcast.on('DIALOG_CONTINUE', () => {
            setContinueComponent(
                <ContinuePopup
                    SimulationId={props.match.params.id}
                    ContinuePopupOpen={true}
                    CurrentIndex={pixiData.currentIndex}
                />
            );
        });

        Broadcast.on('SIMULATION_FINISH', () => {
            saveSimulationOtherReport();

        });


        getSimulationSettings();
        getIntroOutroVideos();

    }, []);

    const getSimulationSettings = () => {

        var simulationSettingsData = {
            SimulationId: props.match.params.id
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: simulationSettingsData
        });

        axios.post('/SimulationSettings/GetBySimulationId', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response.data.data);
            if (response.data.resultCode == 0) {

                if (response.data.data != null) {
                    let simulationSettingsData = JSON.parse(response.data.data.settingJSON);
                    setSimulationSettingsJson(simulationSettingsData);

                    console.log(simulationSettingsData);

                    let completionSettings = simulationSettingsData.showCorrectAnswer;

                    setShowCorrectAnswer(completionSettings.isActive && true);
                    setShowScore(simulationSettingsData.showScore);

                    setShowCorrectAnswerOption(completionSettings.selectedOption);


                    Broadcast.on('ON_QUESTION_OPEN', (questionId) => {
                        setQuestionPanelCompanent("");
                        //console.log(questionId);
                        setQuestionPanelCompanent(
                            <QuestionPanelFinal
                                Style={questionPanelStyle}
                                QuestionPanelVisible={true}
                                SimulationId={props.match.params.id}
                                Duration={10}
                                QuestionId={questionId}
                                SimulationSettings={simulationSettingsData}
                            />
                        );
                    });
                }
            } else {

            }
        });
    }

    const getSimulationDataForPixi = () => {
        setSimulationPrevCanvas("");
        var simulation = {
            Id: props.match.params.id,
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: simulation
        });

        axios.post('/Simulation/GetSimulationDataForPixi', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            if (response.data.resultCode == 0) {
                setSimulationPrevCanvas("");
                pixiData = response.data.data;
                console.log(response.data.data);
                setSimulationPrevCanvas(<Main SimulationData={response.data.data} />)
            }
        });
    }


    const saveSimulationOtherReport = () => {


        var simulation = {
            SimulationId: props.match.params.id,
            UserId: cookies.get('UserId')
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: simulation
        });

        axios.post('/Simulation/SaveOtherSimulationReport', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);
            if (response.data.resultCode == 0) {

                setSimulationFinishReport(response.data.data.at(-1));

                setTimeout(() => {
                    if (outroVideoUrl == null)
                        setShowFinishScreen(true);
                    else {
                        setShowOutroVideo(true);
                    }


                }, 1000);

            }
        });
    }

    const finishSimulationShowQuestion = () => {
        setShowFinishScreen(true);
    }

    const getIntroOutroVideos = () => {
        var simulation = {
            Id: props.match.params.id,
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: simulation
        });

        axios.post('/Simulation/GetIntroOutroVideos', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(response);
            if (response.data.resultCode == 0) {
                setIntroVideo(response.data.data[0].videoUrl);
                setOutroVideo(response.data.data[1].videoUrl);
                outroVideoUrl = response.data.data[1].videoUrl

                setIsLoading(false);
            }
        });
    }



    const questionPanelStyle = {
        width: "78% !important",
        bottom: "-226px"
    };

    const startScreenStyle = {
        width: "80%",
        height: "600px",
        borderRadius: "15px",
        margin: "0 auto",
        backgroundColor: "#fafafa",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }

    const finishSimulationStyle = {
        height: "auto",
        position: "initial"
    }

    const videoPanelStyle = {
        height: "auto",
        position: "relative"
    }


    return (
        <div>
            {isLoginControl ?
                <div>




                    <Navbar />

                    <div className="dashboard-container-content">

                        <Transition visible={showIntroVideo && introVideo != null} animation='scale' duration={500}>

                            <Modal
                                style={videoPanelStyle}
                                centered={false}
                                open={showIntroVideo}
                                onClose={() => {
                                    setShowIntroVideo(false);
                                    setShowCanvas(true);
                                }}
                            >
                                <Modal.Header>Intro video</Modal.Header>
                                <Modal.Content>
                                    <Modal.Description>
                                        <video autoPlay width="100%" controls>
                                            <source
                                                src={window.location.origin + `/Assets/Videos/` + introVideo}
                                                type="video/mp4">
                                            </source>
                                        </video>
                                    </Modal.Description>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button onClick={() => {
                                        setShowIntroVideo(false);
                                        setShowCanvas(true);
                                    }}>Close</Button>
                                </Modal.Actions>
                            </Modal>

                        </Transition>

                        <Transition visible={showOutroVideo} animation='scale' duration={500}>

                            <Modal
                                style={videoPanelStyle}
                                centered={false}
                                open={showOutroVideo}
                                onClose={() => {
                                    setShowOutroVideo(false);
                                    finishSimulationShowQuestion();
                                }}
                            >
                                <Modal.Header>Outro video</Modal.Header>
                                <Modal.Content>
                                    <Modal.Description>
                                        <video autoPlay width="100%" controls>
                                            <source
                                                src={window.location.origin + `/Assets/Videos/` + outroVideo}
                                                type="video/mp4">
                                            </source>
                                        </video>
                                    </Modal.Description>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button onClick={() => {
                                        setShowOutroVideo(false);
                                        finishSimulationShowQuestion();
                                    }}>Close</Button>
                                </Modal.Actions>
                            </Modal>

                        </Transition>

                        <div className="dashboard-content-box">

                            <div className="simulations-header">
                                <div className="page-header-text">
                                    <Header as='h1' disabled>
                                        <Link
                                            onClick={() => { Broadcast.emit('PAGE_CHANGED', true); }}
                                            to={"/simulation-detail/" + props.match.params.id}
                                        ><Button className="simulation-back-button" size="massive" icon>
                                                <Icon name='left chevron' />
                                            </Button></Link>
                                        {"test"}
                                    </Header>
                                </div>



                            </div>

                            <div className="user-simulations-detail-content">


                                {!showCanvas && !showFinishScreen && !isLoading &&
                                    <div className={"startScreenStyle"}>
                                        <Button
                                            color="yellow"
                                            size="massive"
                                            circular
                                            icon='play'
                                            onClick={() => {
                                                introVideo != null ?
                                                    setShowIntroVideo(true) :
                                                    setShowCanvas(true)
                                            }}
                                        />
                                    </div>
                                }


                                <Transition visible={showFinishScreen} animation='scale' duration={500}>
                                    <Modal
                                        closeOnEscape={false}
                                        closeOnDimmerClick={false}
                                        style={finishSimulationStyle}
                                        size={"small"}
                                        open={showFinishScreen}
                                        onClose={() => setShowFinishScreen(false)}
                                    >
                                        <Modal.Header>
                                            Simulation is completed



                                        </Modal.Header>
                                        <Modal.Content>
                                            {showScore &&
                                                <div>
                                                    <div>
                                                        Your Score: {simulationFinishReport.point.toFixed(1)}
                                                    </div>

                                                    <div>
                                                        Success Score: {simulationSettingsJson.successScore.point}
                                                    </div>

                                                    <div>
                                                        Success Info:
                                                        {simulationFinishReport.successInfo ?
                                                            <Label color="green">
                                                                Success
                                                            </Label>
                                                            :
                                                            <Label color="red">
                                                                Unsuccess
                                                            </Label>

                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </Modal.Content>
                                        <Modal.Actions>

                                            {
                                                (showCorrectAnswerOption == "always" &&
                                                    showCorrectAnswer == true

                                                    ||
                                                    showCorrectAnswerOption == "whenpassed" &&
                                                    showCorrectAnswer == true &&
                                                    simulationFinishReport.point.toFixed(1) >=
                                                    parseInt(simulationSettingsJson.successScore.point)) &&

                                                <Link to={"/question-report/" + props.match.params.id + "/" + cookies.get('UserId')}>
                                                    <Button primary>
                                                        Show question report
                                                    </Button>
                                                </Link>
                                            }

                                            <Link to={"/user-dashboard"}>
                                                <Button positive>
                                                    Go simulations list
                                                </Button>
                                            </Link>

                                        </Modal.Actions>
                                    </Modal>
                                </Transition>



                                <Transition visible={showCanvas} animation='scale' duration={500}>
                                    <div style={{ width: "100%" }}>
                                        <div
                                            style={{
                                                textAlign: "center",
                                                width: "100%",
                                                position: "relative",
                                                visibility: showCanvas ? "visible" : "hidden"

                                            }}
                                            id="preview-canvas"
                                        >
                                            {simulationPrevCanvas}
                                            {questionPanelComponent}
                                            {continuePopupComponent}
                                        </div>

                                        {/*media buttons*/}
                                        <VolumeSlider />
                                    </div>
                                </Transition>
                            </div>
                        </div>
                    </div>
                </div>
                : ""}
        </div>
    );
}