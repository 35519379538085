import React, { useState, useContext, useEffect, useRef } from 'react';
import './dialog.css';
import axios from 'axios';
import { Button, Divider, Form, Checkbox, Dropdown, Modal, Transition, Icon, Header, Tab, TextArea, Popup, Input, Loader, Dimmer } from 'semantic-ui-react';
import { CircleAudioPlayer } from './CircleAudioPlayer/CircleAudioPlayer';

import { Recorder } from 'react-voice-recorder'
import 'react-voice-recorder/dist/index.css'

import Cookies from 'universal-cookie';

const cookies = new Cookies();



export const Dialog = (props) => {

    const inputFile = useRef(null);
    const [audioUpload, setAudioUpload] = useState(false);
    const [messageModalOpen, setMessageModalOpen] = useState(false);
    const [header, setHeader] = useState("");
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [currentDataIndex, setCurrentDataIndex] = useState();
    const [uploadRecord, setUploadRecord] = useState(false);

    const [allowMicrophone, setAllowMicrophone] = useState(false);

    const [audioDetails, setAudioDetails] = useState({
        url: null,
        blob: null,
        chunks: null,
        duration: {
            h: 0,
            m: 0,
            s: 0
        }
    });

    useEffect(() => {

        console.log(currentDataIndex);
    }, [currentDataIndex]);

    useEffect(() => {

        /*navigator.permissions.query({ name: 'microphone' }).then(res => {
   
            if (res.state === "granted")
                setAllowMicrophone(true);

            res.onchange = ((e) => {
                if (res.state === "granted")
                    setAllowMicrophone(true);

                // detecting if the event is a change
                if (e.type === 'change') {
                    // checking what the new permissionStatus state is
                    const newState = e.target.state
                    if (newState === 'denied') {
                        //console.log('why did you decide to block us?')
                        setAllowMicrophone(false);
                    } else if (newState === 'granted') {
                        //console.log('allow')
                        setAllowMicrophone(true);
                    } else {
                        //console.log('No allow')
                        setAllowMicrophone(false);
                    }
                }
            })
        })*/

        //setAllowMicrophone(false);
        /*navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream) => {
                setAllowMicrophone(true);
            },
                e => {
                    setAllowMicrophone(false);
                });*/


    }, []);


    

    const [recordModalOpen, setRecordModalOpen] = useState(false); 

    const rightStyle = {
        flexDirection: "row-reverse",
        float: "right",
         fontSize: "2em"
    }

    const leftStyle = {
        flexDirection: "row",
        float: "left",
        fontSize: "2em"
    }

    const openVoiceUpload = () => {
        // `current` points to the mounted file input element
        inputFile.current.click();
    };

    const voiceInputChange = (e) => {
        //for loading effect
        setAudioUpload(true);

        


        let isUpgrade = e.target.dataset.upload;

        if (isUpgrade) {
            setIsLoading(true);
            setMessageModalOpen(true);
        }

        let dialogIndex = (parseInt(e.target.dataset.index));

        var file = e.target.files[0];
        var reader = new FileReader();

        reader.onload = function (event) {
            var audioContext = new (window.AudioContext || window.webkitAudioContext)();
            audioContext.decodeAudioData(event.target.result, function (buffer) {
                var duration = Math.ceil(buffer.duration);
                console.log("The duration of the song is of: " + duration + " seconds");

                var formData = new FormData();

                formData.append('AuthToken', cookies.get('Token'));
                formData.append('Data', file);
                formData.append('Duration', duration);
                formData.append('companyid', cookies.get('CompanyId').toString());
                formData.append('userid', cookies.get('UserId').toString());
                formData.append('simulationid', props.SimulationId);

                axios.post('/Audio/UploadDialogAudioToDropbox', formData, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).then((response) => {
                    console.log(response);
                    if (response.data.result.resultCode == 0) {
                        setAudioUpload(false);

                        let data = response.data.result.data;

                        let audioObject = {
                            Id: data.id,
                            Second: data.second,
                            AudioLink: data.audioLink,
                            DataIndex: dialogIndex
                        }

                        props.ChangeAudio(audioObject);

                        if (isUpgrade) {
                            setIsLoading(false);
                            setHeader("Sucess");
                            setMessage("Audio file updated");
                            setMessageModalOpen(true);
                        }

                    } else {
                       
                    }
                });

            });
        };

        reader.onerror = function (event) {
            console.error("An error ocurred reading the file: ", event);
        };

        reader.readAsArrayBuffer(file); 
    }

    const changeSecondInput = (e) => {

        let audioObject = {
            Second: parseInt(e.target.value),
            DataIndex: parseInt(e.target.parentElement.dataset.index)
        }

        props.ChangeAudio(audioObject);
    }

  
    const [dialog, setDialog] = useState("");




    const handleAudioStop = (data) =>{
        console.log(data)
        setAudioDetails(data);
    }

    const handleAudioUpload = (file) => {
        console.log(file);
        setUploadRecord(true);

        setAudioUpload(true);

        var reader = new FileReader();

        reader.onload = function (event) {
            var audioContext = new (window.AudioContext || window.webkitAudioContext)();
            audioContext.decodeAudioData(event.target.result, function (buffer) {
                var duration = Math.ceil(buffer.duration);
                console.log("The duration of the song is of: " + duration + " seconds");

                var formData = new FormData();

                formData.append('AuthToken', cookies.get('Token'));
                formData.append('Data', file);
                formData.append('Duration', duration);
                formData.append('companyid', cookies.get('CompanyId').toString());
                formData.append('userid', cookies.get('UserId').toString());
                formData.append('simulationid', props.SimulationId);

                axios.post('/Audio/UploadDialogAudioToDropbox', formData, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).then((response) => {
                    console.log(response);
                    if (response.data.result.resultCode == 0) {
                        setAudioUpload(false);
                        setUploadRecord(false);
                        let data = response.data.result.data;

                        let audioObject = {
                            Id: data.id,
                            Second: data.second,
                            AudioLink: data.audioLink,
                            DataIndex: currentDataIndex
                        }

                        props.ChangeAudio(audioObject);

                       
                            setRecordModalOpen(false);
                            setIsLoading(false);
                            setHeader("Sucess");
                            setMessage("Record file updated");
                            setMessageModalOpen(true);
                        

                    } else {

                    }
                });

            });
        };

        reader.onerror = function (event) {
            console.error("An error ocurred reading the file: ", event);
        };

        reader.readAsArrayBuffer(file); 

    }

    const handleRest = () => {
        const reset = {
            url: null,
            blob: null,
            chunks: null,
            duration: {
                h: 0,
                m: 0,
                s: 0
            }
        };

        setAudioDetails(reset);

        setAllowMicrophone(false);

        navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream) => {
                setAllowMicrophone(true);
            },
                e => {
                    setAllowMicrophone(false);
                });
    }



    return (
        <div>
            <Transition visible={messageModalOpen} animation='scale' duration={500}>
                <Modal dimmer="blurring" className="users-delete-ask-modal" size={"mini"} open={messageModalOpen} onClose={() => { setMessageModalOpen(false) }}>
                    <Modal.Header><span className={"account-text"}>{header}</span></Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Header>
                                {isLoading ? <Dimmer active>
                                    <Loader size='big'>Uploading...</Loader>
                                </Dimmer> : message}
                                
                            </Header>
                        </Modal.Description>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => { setMessageModalOpen(false); }} >
                            OKEY 
                        </Button>

                    </Modal.Actions>
                </Modal>
            </Transition>

            <Transition visible={recordModalOpen} animation='scale' duration={500}>
                <Modal dimmer="blurring" className="record-modal" size={"big"} open={recordModalOpen} onClose={() => { setRecordModalOpen(false) }}>
                    <Modal.Header><span className={"account-text"}>Record</span></Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Header>

                                

                                {uploadRecord ? <Dimmer active>
                                    <Loader size='big'>Uploading...</Loader>
                                </Dimmer> : ""}

                                {!allowMicrophone ? <Dimmer active>
                                    <Loader size='big'>Please allow microphone...</Loader>
                                </Dimmer> : ""}

                                <div style={{ visibility: allowMicrophone ? "visible" : "hidden" }}>
                                    <Recorder
                                        record={false}
                                        title={"New recording"}
                                        audioURL={audioDetails.url}
                                        showUIAudio
                                        handleAudioStop={data => handleAudioStop(data)}
                                        handleAudioUpload={data => handleAudioUpload(data)}
                                        handleRest={() => handleRest()}
                                     />
                                </div>

                                
                            </Header>
                        </Modal.Description>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => { setRecordModalOpen(false); }} >
                            CLOSE
                        </Button>

                    </Modal.Actions>
                </Modal>
            </Transition>

        <div style={props.Right ? rightStyle : leftStyle} className="dialog-box">
            <div>
                <Button onClick={props.DeleteDialog} data-comment={props.Comment} data-right={props.Right} data-image={props.Image} data-index={props.Index} color="red" circular inverted icon>
                    <Icon data-comment={props.Comment} data-right={props.Right} data-index={props.Index} name='delete' />
                </Button>
            </div>
            <div className="dialog-left-section">
                <div>
                    <img className="dialog-mini-rounded-image" src={props.Right ? props.CharacterTwo : props.Image} />
                </div>
                <div className="dialog-microphone-box">

                        {props.Audio != null ? <Popup trigger={

                            <button className="audioContentButton">
                                {
                                    props.Audio.AudioLink != null ? <CircleAudioPlayer AudioLink={props.Audio.AudioLink} /> : <Button circular icon content='Button'><Icon name="clock" /></Button>
                                }
                                
                            </button>} flowing hoverable>

                            <Input
                                value={props.Audio.AudioLink == null ? props.Audio.Second : ""}
                                onChange={changeSecondInput}
                                data-index={props.Index}
                                style={{ marginRight: "6px", width: "100px" }}
                                icon='time'
                                placeholder='second'
                            />

                        <input
                            data-index={props.Index}
                            onChange={voiceInputChange}
                            data-upload={true}
                            type='file'
                            id='file-upload'
                            ref={inputFile}
                            style={{ display: 'none' }}
                        />

                        <Button
                            data-index={props.Index}
                            onClick={openVoiceUpload}
                            content={audioUpload ?<Loader size='tiny' active inline /> : 'Upload'}

                            icon='cloud upload'
                            labelPosition='right'
                            />

                            <Button
                                onClick={() => { handleRest(); setRecordModalOpen(true); setCurrentDataIndex(props.Index) }}
                                content='Record'
                                color="red"
                                icon='circle'
                                labelPosition='right'
                            />

                    </Popup> : 
                        
                        <Popup
                            position={props.Right ? "right center" : "left center"}
                            on='click'
                            pinned
                            trigger={<Button circular icon content='Button'><Icon name="microphone" /></Button>}
                        >
                            <div className="add-comment-choose-character-box">
                                <div>
                                   
                                            <Input
                                                onChange={changeSecondInput}
                                                data-index={props.Index}
                                                style={{ marginRight: "6px", width: "100px" }}
                                                icon='time'
                                                placeholder='second'
                                                data-index={props.Index}
                                            />
                                   
                                </div>

                                <div>
                                    <input
                                        data-index={props.Index}
                                        onChange={voiceInputChange}
                                        type='file'
                                        id='file'
                                        ref={inputFile}
                                        style={{ display: 'none' }}
                                    />

                                    <Button
                                        data-index={props.Index}
                                        onClick={openVoiceUpload}
                                        content={audioUpload ? <Loader size='tiny' active inline /> : 'Upload'}

                                        icon='cloud upload'
                                        labelPosition='right'
                                    />
                                </div>

                                    <div>
                                        <Button
                                            onClick={() => { handleRest(); setRecordModalOpen(true); setCurrentDataIndex(props.Index) }}
                                            content='Record'
                                            color="red"
                                            icon='circle'
                                            labelPosition='right'
                                        />
                                </div>

                            </div>

                        </Popup>
                        
                        }
             
                    

                </div>
                <div>
                    <Popup
                        position={props.Right ? "right center" : "left center"}
                        content='I will not flip!'
                        on='click'
                        pinned
                        trigger={<Button circular icon content='Button'><Icon name="comments" /></Button>}
                    >
                        <div className="add-comment-choose-character-box">
                            <div>
                                <Button data-id={props.Right ? "right" : "left"} onClick={props.AddDialogInsert} data-index={props.Index}>
                                    <img
                                        data-id={props.Right ? "right" : "left"}
                                        data-index={props.Index}
                                        className="dialog-mini-image"
                                        src={props.Right ? props.CharacterTwo : props.Image}
                                    />
                                </Button>
                            </div>
                            <div>
                                <Button data-id={props.Right ? "left" : "right"} onClick={props.AddDialogInsert} data-index={props.Index}>
                                    <img
                                        data-id={props.Right ? "left" : "right"}
                                        data-index={props.Index}
                                        className="dialog-mini-image"
                                        src={props.Right ? props.Image : props.CharacterTwo}
                                    />
                                </Button>
                            </div>
                            <div>
                                <Button className="insert-question-button" color='yellow' data-id={"left"} onClick={props.addQuestion} data-index={props.Index}>
                                    <div data-id={"left"} data-index={props.Index}><Icon data-id={"left"} data-index={props.Index} size="big" name="question" /></div>
                                </Button>
                            </div>
                        </div>
                        
                    </Popup>
                </div>
            </div>
            <div className="dialog-textare-section">
                <TextArea
                    onChange={e => { setDialog(e.target.value); props.ChangeTextarea(e); }}
                    value={props.Comment}
                    data-index={props.Index}
                    className="dialog-textarea"
                    placeholder='dialog...'
                    style={{ minHeight: 160 }} />
            </div>
            </div>
        </div>
    )
}
