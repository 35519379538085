import React, { useEffect, useState } from 'react';
import { Popup, Icon, Loader, Header, Label, Table, Transition, Button } from 'semantic-ui-react';
import { OtherSimulationCard } from '../../UserProfile/UserOtherSimulations/OtherSimulationCard/OtherSimulationCard';
import { ReportSimulationUserTable } from './ReportSimulationUserTable/ReportSimulationUserTable';
import { ReportSimulationsQuestions } from './ReportSimulationsQuestions/ReportSimulationsQuestions';
import { dateFormat, downloadFile } from '../../../../helper/helper';
import axios from 'axios';
import Cookies from 'universal-cookie';


const cookies = new Cookies();



export const ReportSimulation = (props) => {

    const [loading, setLoading] = useState(true);
    const [simulationReportData, setSimulationReportData] = useState({});

    const [simulationToExcelData, setSimulationToExcelData] = useState([]);

    const headerStyle = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
        alignContent: "center"
    }

    const subHeaderStyle = {
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        marginTop: "40px",
        justifyContent: "flex-start",
        flexWrap: "wrap"
    }

    const tableContentStyle = {
        marginTop: "40px",
        padding: "18px"
    }

    useEffect(() => {
        
        if (props.Id) {
            getSimulationReportById(props.Id);
        }


    }, [props.Id]);



    const getSimulationReportById = (id) => {

        props.SetWhenSelectDataLoading(true);
       
        setLoading(true);
        let data = {
            SimulationId: id,
            CompanyId: cookies.get("CompanyId")
        }

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: data
        });

        axios.post('/Simulation/GetSimulationReport', Token, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            
            if (response.data.resultCode == 0) {
                setSimulationReportData([]);
                setSimulationReportData(response.data.data);
                setSimulationToExcelData([response.data.data]);
                setLoading(false);

                props.SetMultiSelectedSimulationReportExcel([...props.MultiSelectedSimulationReportExcel, response.data.data]);
                props.SetWhenSelectDataLoading(false);
            } else {

            }
        });

    }


    const simulationExportDataToExcel = () => {

        var Token = JSON.stringify({
            AuthToken: cookies.get('Token'),
            Data: simulationToExcelData
        });

        axios.post('/Simulation/SimulationReportToExcel', Token, {
            headers: {
                'Content-Type': 'application/json',
            }

        }).then((response) => {
            downloadFile(response.data.result.value, "SimulationReport-" + simulationReportData.simulationName + "--" + dateFormat(new Date(), true) + ".xlsx");
        });

    }



    return (
        <div>
            <Loader
                style={{ marginTop: "18%" }}
                size="huge"
                active={loading}
                inline='centered'
            />

            <Transition visible={!loading} animation='scale' duration={500}>
                <div>
                    <div style={headerStyle}>
                        <div style={{ display: "flex" }}>
                            <div>
                                <Icon
                                    disabled
                                    style={{ marginLeft: "5px" }}
                                    size="big"
                                    name="circle thin"
                                    color={simulationReportData.isActive === true ? "green" : "grey"}

                                />
                            </div>
                            <div>
                                <Header as='h1'>
                                    {simulationReportData.simulationName}
                                </Header>
                            </div>

                            
                        </div>

                        <div>
                            <Popup
                                content='Export Excel Report'
                                trigger={
                                    <Button

                                        onClick={simulationExportDataToExcel}
                                        color="green"
                                        icon>
                                        <Icon
                                            name='file excel'
                                        />
                                    </Button>
                                }
                            />


                        </div>
                       

                    </div>


                    <div style={subHeaderStyle}>
                        <OtherSimulationCard
                            Width={"200px"}
                            Height={"120px"}
                            Header={"Enrolled User Count"}
                            Text={simulationReportData.enrolledUserCount}
                            FontSize={"1.2em"}
                            AlignItems={"flex-start"}
                            Height={"85px"}
                            Padding={"27px"}
                            HeaderMarginBottom={"0"}
                            Margin={"10px"}
                        />

                        {simulationReportData.estimatedTime ?
                            <OtherSimulationCard
                                Width={"200px"}
                                Height={"120px"}
                                Header={"Estimated Time"}
                                Text={simulationReportData.estimatedTime}
                                FontSize={"1.2em"}
                                AlignItems={"flex-start"}
                                Height={"85px"}
                                Padding={"27px"}
                                HeaderMarginBottom={"0"}
                                Margin={"10px"}
                            /> : ""

                        }

                        {simulationReportData.numberOfReplies ?
                            <OtherSimulationCard
                                Width={"200px"}
                                Height={"120px"}
                                Header={"Number Of Replies"}
                                Text={simulationReportData.numberOfReplies}
                                FontSize={"1.2em"}
                                AlignItems={"flex-start"}
                                Height={"85px"}
                                Padding={"27px"}
                                HeaderMarginBottom={"0"}
                                Margin={"10px"}
                            /> : ""
                        }

                        {simulationReportData.oneQuestionScore ?
                            <OtherSimulationCard
                                Width={"200px"}
                                Height={"120px"}
                                Header={"One Question Score"}
                                Text={simulationReportData.oneQuestionScore.toFixed(1)}
                                FontSize={"1.2em"}
                                AlignItems={"flex-start"}
                                Height={"85px"}
                                Padding={"27px"}
                                HeaderMarginBottom={"0"}
                                Margin={"10px"}
                            /> : ""
                        }

                        {simulationReportData.startingDate ?
                            <OtherSimulationCard
                                Width={"200px"}
                                Height={"120px"}
                                Header={"Starting Date"}
                                Text={dateFormat(simulationReportData.startingDate,true)}
                                FontSize={"1.2em"}
                                AlignItems={"flex-start"}
                                Height={"85px"}
                                Padding={"27px"}
                                HeaderMarginBottom={"0"}
                                Margin={"10px"}
                            /> : ""
                        }

                        {simulationReportData.dueDate ?
                            <OtherSimulationCard
                                Width={"200px"}
                                Height={"120px"}
                                Header={"End Date"}
                                Text={dateFormat(simulationReportData.dueDate,true)}
                                FontSize={"1.2em"}
                                AlignItems={"flex-start"}
                                Height={"85px"}
                                Padding={"27px"}
                                HeaderMarginBottom={"0"}
                                Margin={"10px"}
                            /> : ""
                        }

                    </div>

                    <div style={tableContentStyle}>
                        <Header disabled style={{marginBottom:"30px"}} as='h2'>
                            Enrolled Users
                        </Header>
                        <ReportSimulationUserTable
                            Users={simulationReportData.reportSimulationUsers || []}
                        />
                    </div>

                    <div>
                        <Header as='h2' disabled>
                            Questions
                        </Header>

                        <ReportSimulationsQuestions
                            Questions={simulationReportData.questionSimulationReport || []}
                            
                        />

                    </div>


                </div>
            </Transition>

        </div>
    )
}